import { DoubleRightOutlined } from "@ant-design/icons"
import { Tooltip, Tag, Button, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { EResultTagAdequacy } from "../../../components/commons-ts/common"
import { Equipment, Impact, UUID, Zone } from "../../../generated/proto-ts/main"
import { Translated } from "../../../utils/translated"
import { pbUUIDToUuid, COLOR_BG_ROW_DELETED } from "../../../utils/utils"
import { getEquipmentResultPF_Adq, getEquipmentResultPF_CFH } from "../../../calculus/calculus_PF"
import { TableImageThumbnail } from "../../../components/commons-ts/tableImageThumbnail"
import { useUsercommSiteChildrenRecursiveBimodal } from "../../../usercomm/common/usercommAsyncRequestBimodal"
import dayjs from "dayjs"
import { IEquipmentResult } from "../../../calculus/types"
import { useSyncronizationContext } from "../../../providers/syncronizationProvider"

interface IEquipmentProcessed {
  equipment: Equipment
  nb_zones: number
  nb_impacts: number
  result: IEquipmentResult | null
}

export const SiteEquipmentsTableMetadataPF_Print: FC<{
  siteUUID: UUID | null
  equipments: Equipment[] | null
  equipmentZonesMap: Record<string, Zone[]> | null
  zoneImpactsMap: Record<string, Impact[]> | null
}> = ({ siteUUID, equipments, equipmentZonesMap, zoneImpactsMap }) => {
  const memoFilteredEquipments = useMemo(() => {
    if (equipments === null) {
      return null
    }
    let filteredEquipments: Equipment[] = []
    for (let equipment of equipments) {
      if (equipment.deleted_at > 0) {
        continue
      }
      filteredEquipments.push(equipment)
    }
    filteredEquipments.sort((a, b) => {
      return a.created_at - b.created_at
    })
    console.log(
      `filteredEquipments:`,
      filteredEquipments.map((e) => e.toObject()),
    )
    return filteredEquipments
  }, [equipments])

  const columns = useMemo((): ColumnsType<Equipment> => {
    return [
      {
        title: "", // Photo
        width: "3rem",
        render: (_, equipment) => {
          let pictureUUID = equipment.pictures[0]
          if (pictureUUID === undefined) {
            return <div style={{ width: "3rem" }} />
          }
          return <TableImageThumbnail pictureUUID={pictureUUID} alt={equipment.equipment_name} />
        },
      },
      {
        key: "equipment",
        title: <Translated keyEn="Equipment" />,
        render: (_, equipment) => {
          return equipment.equipment_name ?? "—"
        },
      },
      {
        key: "reference",
        title: <Translated keyEn="Reference" />,
        render: (_, equipment) => {
          return equipment.equipment_reference ?? "—"
        },
      },
      {
        key: "manufacturer",
        title: <Translated keyEn="Manufacturer" />,
        render: (_, equipment) => {
          return equipment.equipment_manufacturer ?? "—"
        },
      },
      {
        key: "installed_at",
        title: <Translated keyEn="Installation" />,
        render: (_, equipment) => {
          if (equipment.equipment_installation_date === 0) {
            return "—"
          }
          return dayjs(equipment.equipment_installation_date).format("DD-MM-YYYY")
        },
      },
      {
        title: <Translated keyEn="#&nbsp;Zones" />,
        render: (_, equipment) => {
          if (equipmentZonesMap === null) {
            return null
          }
          let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
          let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
          let nbZones = 0
          if (!equipmentZones) {
            return <span>{nbZones}</span>
          }
          for (let zone of equipmentZones) {
            if (zone.deleted_at === 0) {
              nbZones++
            }
          }
          return <span>{nbZones}</span>
        },
      },
    ]
  }, [equipmentZonesMap, zoneImpactsMap])

  return (
    <Table
      rootClassName="printed-table"
      dataSource={memoFilteredEquipments ?? []}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      bordered
      showSorterTooltip={false}
      style={{
        width: "100%",
      }}
    />
  )
}

export const SiteEquipmentsTablePF_CFH: FC<{
  siteUUID: UUID | null
  equipments: Equipment[] | null
}> = ({ siteUUID, equipments }) => {
  const [_, equipmentZonesMap, zoneImpactsMap, getEntitiesRecursive] =
    useUsercommSiteChildrenRecursiveBimodal()
  const [equipmentsProcessed, setEquipmentsProcessed] = useState<IEquipmentProcessed[] | null>(null)
  const { isAdmin } = useSyncronizationContext()

  useEffect(() => {
    if (siteUUID === null) {
      return
    }
    getEntitiesRecursive(siteUUID)
  }, [siteUUID, equipments])

  useEffect(() => {
    if (equipments === null || equipmentZonesMap === null || zoneImpactsMap === null) {
      return
    }
    let _equipmentsProcessed: IEquipmentProcessed[] = []
    for (let equipment of equipments) {
      let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
      let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
      if (equipmentZones === undefined) {
        continue
      }
      let nbZones = 0
      let nbImpacts = 0
      for (let zone of equipmentZones) {
        if (zone.deleted_at > 0) {
          continue
        }
        nbZones++
        let impacts = zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
        if (impacts === undefined) {
          continue
        }
        for (let impact of impacts) {
          if (impact.deleted_at > 0) {
            continue
          }
          nbImpacts++
        }
      }

      let result = getEquipmentResultPF_CFH(equipmentZones, zoneImpactsMap)
      _equipmentsProcessed.push({
        equipment: equipment,
        nb_zones: nbZones,
        nb_impacts: nbImpacts,
        result: result,
      })
    }
    setEquipmentsProcessed(_equipmentsProcessed)
  }, [equipments, equipmentZonesMap, zoneImpactsMap])

  const columns = useMemo((): ColumnsType<IEquipmentProcessed> => {
    return [
      {
        title: "📷", // Photo
        render: (_, equipment) => {
          let pictureUUID = equipment.equipment.pictures[0]
          if (pictureUUID === undefined) {
            return <div style={{ width: "3rem" }} />
          }
          return (
            <Link to={`/equipments/${pbUUIDToUuid(equipment.equipment.uuid)}`}>
              <TableImageThumbnail
                pictureUUID={pictureUUID}
                alt={equipment.equipment.equipment_name}
              />
            </Link>
          )
        },
      },
      {
        key: "equipment",
        title: <Translated keyEn="Equipment" />,
        ellipsis: true,
        render: (_, equipment) => {
          return (
            <Link
              to={`/equipments/${pbUUIDToUuid(equipment.equipment.uuid)}`}
              style={{
                textDecoration: "none",
              }}
            >
              {equipment.equipment.equipment_name}
            </Link>
          )
        },
        sorter: (a, b) => {
          return a.equipment.equipment_name.localeCompare(b.equipment.equipment_name)
        },
      },
      {
        title: <Translated keyEn="# zones" />,
        render: (_, equipment) => {
          return <span>{equipment.nb_zones}</span>
        },
        sorter: (a, b) => a.nb_zones - b.nb_zones,
      },
      {
        title: <Translated keyEn="# impacts" />,
        render: (_, equipment) => {
          return <span>{equipment.nb_impacts}</span>
        },
        sorter: (a, b) => a.nb_impacts - b.nb_impacts,
      },
      {
        key: "result",
        title: (
          <Tooltip overlay={<Translated keyEn="Result" />}>
            <Translated keyEn="Result" />
          </Tooltip>
        ),
        render: (_, equipment) => {
          if (equipment.result === null) {
            return <Tag>N/A</Tag>
          }
          return <EResultTagAdequacy result={equipment.result.result} />
        },
        sorter: (a, b) => {
          if (a.result === null) {
            return -1
          }
          if (b.result === null) {
            return 1
          }
          return a.result.result - b.result.result
        },
      },
      {
        title: "",
        width: "2rem",
        render: (_, equipment) => {
          return (
            <Link to={`/equipments/${pbUUIDToUuid(equipment.equipment.uuid)}`}>
              <Button type="link" size="small">
                <DoubleRightOutlined />
              </Button>
            </Link>
          )
        },
      },
    ]
  }, [])

  return (
    <Table
      dataSource={equipmentsProcessed ?? []}
      loading={equipments === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      onRow={(record) => {
        if (record.equipment.deleted_at === 0) {
          return {}
        }
        if (isAdmin) {
          return {
            style: {
              backgroundColor: COLOR_BG_ROW_DELETED,
            },
          }
        } else {
          return {
            style: {
              display: "none",
            },
          }
        }
      }}
      footer={() => (
        <Translated keyEn="Equipments table for EN 1177 - PLAYGROUND FLOORS (Method 1: Critical Fall Height)" />
      )}
    />
  )
}

export const SiteEquipmentsTablePF_ADQ: FC<{
  siteUUID: UUID | null
  equipments: Equipment[] | null
}> = ({ siteUUID, equipments }) => {
  const [_, equipmentZonesMap, zoneImpactsMap, getEntitiesRecursive] =
    useUsercommSiteChildrenRecursiveBimodal()
  const [equipmentsProcessed, setEquipmentsProcessed] = useState<IEquipmentProcessed[] | null>(null)
  const { isAdmin } = useSyncronizationContext()

  useEffect(() => {
    if (siteUUID === null) {
      return
    }
    getEntitiesRecursive(siteUUID)
  }, [siteUUID, equipments])

  useEffect(() => {
    if (equipments === null || equipmentZonesMap === null || zoneImpactsMap === null) {
      return
    }
    let _equipmentsProcessed: IEquipmentProcessed[] = []
    for (let equipment of equipments) {
      let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
      let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
      if (equipmentZones === undefined) {
        continue
      }
      let nbZones = 0
      let nbImpacts = 0
      for (let zone of equipmentZones) {
        if (zone.deleted_at > 0) {
          continue
        }
        nbZones++
        let impacts = zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
        if (impacts === undefined) {
          continue
        }
        for (let impact of impacts) {
          if (impact.deleted_at > 0) {
            continue
          }
          nbImpacts++
        }
      }

      let result = getEquipmentResultPF_Adq(equipmentZones, zoneImpactsMap)
      _equipmentsProcessed.push({
        equipment: equipment,
        nb_zones: nbZones,
        nb_impacts: nbImpacts,
        result: result,
      })
    }
    setEquipmentsProcessed(_equipmentsProcessed)
  }, [equipments, equipmentZonesMap, zoneImpactsMap])

  const columns = useMemo((): ColumnsType<IEquipmentProcessed> => {
    return [
      {
        key: "equipment",
        title: <Translated keyEn="Equipment" />,
        ellipsis: true,
        render: (_, equipment) => {
          return (
            <Link
              to={`/equipments/${pbUUIDToUuid(equipment.equipment.uuid)}`}
              style={{
                textDecoration: "none",
              }}
            >
              {equipment.equipment.equipment_name}
            </Link>
          )
        },
        sorter: (a, b) => {
          return a.equipment.equipment_name.localeCompare(b.equipment.equipment_name)
        },
      },
      {
        title: <Translated keyEn="# zones" />,
        render: (_, equipment) => {
          return <span>{equipment.nb_zones}</span>
        },
        sorter: (a, b) => a.nb_zones - b.nb_zones,
      },
      {
        title: <Translated keyEn="# impacts" />,
        render: (_, equipment) => {
          return <span>{equipment.nb_impacts}</span>
        },
        sorter: (a, b) => a.nb_impacts - b.nb_impacts,
      },
      {
        key: "result",
        title: (
          <Tooltip overlay={<Translated keyEn="Result" />}>
            <Translated keyEn="Result" />
          </Tooltip>
        ),
        render: (_, equipment) => {
          if (equipment.result === null) {
            return <Tag>N/A</Tag>
          }
          return <EResultTagAdequacy result={equipment.result.result} />
        },
        sorter: (a, b) => {
          if (a.result === null) {
            return -1
          }
          if (b.result === null) {
            return 1
          }
          return a.result.result - b.result.result
        },
      },
      {
        title: "",
        width: "2rem",
        render: (_, equipment) => {
          return (
            <Link to={`/equipments/${pbUUIDToUuid(equipment.equipment.uuid)}`}>
              <Button type="link" size="small">
                <DoubleRightOutlined />
              </Button>
            </Link>
          )
        },
      },
    ]
  }, [])

  return (
    <Table
      dataSource={equipmentsProcessed ?? []}
      loading={equipments === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      onRow={(record) => {
        if (record.equipment.deleted_at === 0) {
          return {}
        }
        if (isAdmin) {
          return {
            style: {
              backgroundColor: COLOR_BG_ROW_DELETED,
            },
          }
        } else {
          return {
            style: {
              display: "none",
            },
          }
        }
      }}
      footer={() => (
        <Translated keyEn="Equipments table for EN 1177 - PLAYGROUND FLOORS (Method 2: Adequacy)" />
      )}
    />
  )
}
