import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale,
  Title,
  ChartOptions,
  ChartData,
} from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"
import { BoxPlotChart, BoxPlotController, BoxAndWiskers } from "@sgratzl/chartjs-chart-boxplot"
import { Bar, Chart } from "react-chartjs-2"
import { FC } from "react"
import {
  COLOR_GMAX,
  COLOR_HIC,
  colorHexToRgba,
  useMediaQuery as useMediaQueryForMobile,
} from "../../utils/utils"
import { Impact, Zone } from "../../generated/proto-ts/main"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BoxPlotChart,
  BoxPlotController,
  BoxAndWiskers,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
)

export const TrampolineReferenceBarChart: FC<{
  performanceFactor: number | null
}> = ({ performanceFactor }) => {
  let yMin = 65
  let yMax = 125
  if (performanceFactor !== null && performanceFactor < yMin) {
    yMin = performanceFactor - 10
  }
  if (performanceFactor !== null && performanceFactor > yMax) {
    yMax = performanceFactor + 10
  }

  const isMobile = useMediaQueryForMobile()

  const options: ChartOptions<"bar"> = {
    responsive: true,
    aspectRatio: isMobile ? 1 : 1.7,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Trampoline Performance Factor Classification",
      },
      annotation: {
        annotations: [
          {
            type: "line",
            scaleID: "y",
            value: performanceFactor ?? 0,
            borderColor: "red",
            borderDash: [5, 5],
            borderWidth: 1,
            display: performanceFactor !== null,
          },
          {
            type: "line",
            scaleID: "y",
            value: 98,
            borderColor: "black",
            borderDash: [10, 5, 5, 5],
            borderWidth: 1,
          },
          {
            type: "line",
            scaleID: "y",
            value: 88,
            borderColor: "black",
            borderDash: [10, 5, 5, 5],
            borderWidth: 1,
          },
        ],
      },
    },
    skipNull: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Trampoline",
        },
      },
      y: {
        min: yMin,
        max: yMax,
        title: {
          display: true,
          text: "Performance Factor",
        },
      },
    },
    datasets: {
      bar: {
        categoryPercentage: 1,
        barPercentage: 0.8,
        borderWidth: 1,
      },
    },
  }
  let refLabels = [
    // Professional
    "HP:P1T2",
    "HP:P1T6",
    "HP:P7T1",
    "HP:P3T1",
    "HP:P7T2",
    "HP:P1T3",
    "HP:P7T3",
    "HP:P5T4",
    "HP:P1T5",
    "HP:P7T4",
    "HP:P3T2",
    "HP:P5T1",

    // Intermediate
    "HP:P7T5",
    "HP:P5T2",
    "HP:P7T6",
    "HP:P2T2",

    // Recreational (13)
    "MC:P2T3",
    "MC:P7T7",
    "MC:P4T2",
    "MC:P3T3",
    "MC:P4T1",
    "MC:P2T1",
    "MC:P6T2",
    "MC:P6T1",
    "MC:P1T1",
    "MC:P1T4",
    "MC:P4T3",
    "MC:P5T3",
    "MC:P5T5",
  ]
  let refValues: number[] = [
    // Professional (12)
    120, 119.8, 119.6, 116, 116.2, 113, 113.2, 107.5, 105.5, 105.1, 105.5, 104,
    // Intermediate (4)
    97.5, 97, 96, 90,
    // Recreational (13)
    87.5, 85, 84, 81, 81.1, 80, 79, 78, 77.5, 76, 68, 67.5, 67,
  ]
  const data: ChartData<"bar", (number | null)[], string> = {
    labels: refLabels,
    datasets: [
      {
        label: "Professional",
        data: refValues.slice(0, 12),
        // violet
        backgroundColor: ["rgba(153, 102, 255, 0.2)"],
        borderColor: ["rgba(153, 102, 255, 1)"],
      },
      {
        label: "Intermediate",
        data: [...new Array(12).fill(null), ...refValues.slice(12, 16)],
        backgroundColor: ["rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)"],
      },
      {
        label: "Recreational",
        data: [...new Array(16).fill(null), ...refValues.slice(16)],
        backgroundColor: ["rgba(75, 192, 192, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)"],
      },
    ],
  }

  if (performanceFactor !== null && performanceFactor > 0) {
    let values = []
    for (let i = 0; i < refLabels.length; i++) {
      let refValue = refValues[i]! // refValues.length = refLabels.length
      if (refValue <= performanceFactor) {
        values.push(performanceFactor)
        break
      }
      values.push(null)
    }
    if (values[values.length - 1] === null) {
      values[values.length - 1] = performanceFactor
    }
    data.datasets.push({
      label: "Measured Trampoline",
      data: values,
      backgroundColor: ["rgba(255, 0, 0, 0.3)"],
      borderColor: ["rgba(255, 0, 0, 1)"],
    })
  }

  return <Bar data={data} options={options} />
}

export const ZoneChartPF_ADQ: FC<{
  zone: Zone
  impacts: Impact[]
  impact_key: "impact_gmax" | "impact_hic"
}> = ({ zone, impacts, impact_key }) => {
  const isMobile = useMediaQueryForMobile()

  let values: number[] = []
  let _gmaxScale = 200
  for (let impact of impacts) {
    if (impact.deleted_at > 0) {
      continue
    }
    values.push(impact[impact_key])
    if (impact.impact_gmax > _gmaxScale) {
      _gmaxScale = impact.impact_gmax
    }
  }
  _gmaxScale *= 1.2

  let scaleMax = _gmaxScale
  if (impact_key === "impact_hic") {
    scaleMax *= 5
  }
  let title = impact_key === "impact_gmax" ? "Gmax" : "HIC"
  let color = impact_key === "impact_gmax" ? COLOR_GMAX : COLOR_HIC
  let position: "left" | "right" = impact_key === "impact_gmax" ? "left" : "right"
  let hlineValue = impact_key === "impact_gmax" ? 200 : 1000

  const options: ChartOptions<"boxplot"> = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      annotation: {
        annotations: [
          {
            type: "line",
            scaleID: "y",
            borderColor: "red",
            value: hlineValue,
            borderDash: [10, 5, 5, 5],
            borderWidth: 2,
          },
        ],
      },
    },
    scales: {
      x: {
        title: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: title,
        },
        min: 0,
        max: scaleMax,
        position: position,
      },
    },
    boxplot: {
      datasets: {
        minStats: "q1",
        maxStats: "q3",
      },
    },
    elements: {
      boxandwhiskers: {
        itemBackgroundColor: colorHexToRgba(color, 0.7),
        itemBorderColor: color,
        itemRadius: 3,
        itemHitRadius: 5,
      },
    },
  }

  const data: ChartData<"boxplot", number[][], string> = {
    labels: [zone.zone_name],
    datasets: [
      {
        data: [values],
        backgroundColor: colorHexToRgba(color, 0.2),
        borderColor: color,
      },
    ],
  }

  console.log(`ZoneChartPF_ADQ: ${JSON.stringify(data)}`)

  return (
    <Chart
      type="boxplot"
      data={data}
      options={options}
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  )
}
