import { FC, useMemo } from "react"
import { UUID, Equipment, Zone, Impact } from "../../../generated/proto-ts/main"
import { Translated } from "../../../utils/translated"
import Table, { ColumnsType } from "antd/es/table"
import { pbUUIDToUuid } from "../../../utils/utils"
import dayjs from "dayjs"
import { FlexCol } from "../../../components/commons-ts/common"
import { TableImageThumbnail } from "../../../components/commons-ts/tableImageThumbnail"
import { IStatEnvelope, StatEnvelope } from "../../../utils/statEnvelope"

interface IEnvironmentalConditions {
  timestampMs: IStatEnvelope
  temperature: IStatEnvelope
  relativeHumidity: IStatEnvelope
}

export const SiteEnvironmentalConditionsTable_PF: FC<{
  siteUUID: UUID | null
  equipments: Equipment[] | null
  equipmentZonesMap: Record<string, Zone[]> | null
  zoneImpactsMap: Record<string, Impact[]> | null
}> = ({ siteUUID, equipments, equipmentZonesMap, zoneImpactsMap }) => {
  const memoEquipmentImpactsMap = useMemo((): Record<string, Impact[]> => {
    let equipmentImpactsMap: Record<string, Impact[]> = {}
    if (equipments === null || zoneImpactsMap === null || equipmentZonesMap === null) {
      return equipmentImpactsMap
    }
    for (let equipmentUUIDStr in equipmentZonesMap) {
      let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
      if (equipmentZones === undefined) {
        continue
      }
      for (let zone of equipmentZones) {
        if (zone.deleted_at > 0) {
          continue
        }
        for (let zoneUUIDStr in zoneImpactsMap) {
          if (zoneUUIDStr !== pbUUIDToUuid(zone.uuid)) {
            continue
          }
          let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
          if (zoneImpacts === undefined) {
            continue
          }

          for (let impact of zoneImpacts) {
            if (impact.deleted_at > 0) {
              continue
            }
            if (equipmentImpactsMap[equipmentUUIDStr] === undefined) {
              equipmentImpactsMap[equipmentUUIDStr] = []
            }
            equipmentImpactsMap[equipmentUUIDStr]!.push(impact)
          }
        }
      }
    }
    return equipmentImpactsMap
  }, [equipments, equipmentZonesMap, zoneImpactsMap])

  const memoEquipmentConditionsMap = useMemo((): Record<string, IEnvironmentalConditions> => {
    let equipmentConditionsMap: Record<string, IEnvironmentalConditions> = {}
    if (memoEquipmentImpactsMap === null) {
      return equipmentConditionsMap
    }
    for (let equipmentUUIDStr in memoEquipmentImpactsMap) {
      let equipmentImpacts = memoEquipmentImpactsMap[equipmentUUIDStr]!
      let dateEnvelope = new StatEnvelope()
      let temperatureEnvelope = new StatEnvelope()
      let humidityEnvelope = new StatEnvelope()
      for (let impact of equipmentImpacts) {
        dateEnvelope.addValue(impact.updated_at)
        temperatureEnvelope.addValue(impact.temperature)
        humidityEnvelope.addValue(impact.relative_humidity)
      }
      equipmentConditionsMap[equipmentUUIDStr] = {
        timestampMs: dateEnvelope,
        temperature: temperatureEnvelope,
        relativeHumidity: humidityEnvelope,
      }
    }
    return equipmentConditionsMap
  }, [memoEquipmentImpactsMap])

  const memoFilteredEquipments = useMemo(() => {
    if (equipments === null) {
      return null
    }
    let filteredEquipments: Equipment[] = []
    for (let equipment of equipments) {
      if (equipment.deleted_at > 0) {
        continue
      }
      filteredEquipments.push(equipment)
    }
    filteredEquipments.sort((a, b) => {
      return a.created_at - b.created_at
    })
    return filteredEquipments
  }, [equipments])

  const columns: ColumnsType<Equipment> = useMemo(() => {
    return [
      {
        title: "", // Photo
        width: "3rem",
        render: (_, equipment) => {
          let pictureUUID = equipment.pictures[0]
          if (pictureUUID === undefined) {
            return <div style={{ width: "3rem" }} />
          }
          return (
            <FlexCol style={{ height: "100%", justifyContent: "center" }}>
              <TableImageThumbnail pictureUUID={pictureUUID} alt={equipment.equipment_name} />
            </FlexCol>
          )
        },
      },
      {
        key: "equipment",
        title: <Translated keyEn="Equipment" />,
        render: (equipment: Equipment) => {
          return equipment.equipment_name
        },
      },
      {
        key: "hour",
        title: <Translated keyEn="Day/Hour" />,
        render: (equipment: Equipment) => {
          if (memoEquipmentConditionsMap === null) {
            return null
          }
          let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
          let equipmentConditions = memoEquipmentConditionsMap[equipmentUUIDStr]
          if (equipmentConditions === undefined) {
            return null
          }
          let djMin = dayjs(equipmentConditions.timestampMs.min)
          let djMax = dayjs(equipmentConditions.timestampMs.max)

          if (!djMax.isSame(djMin, "day")) {
            return (
              <span>
                {djMin.format("DD-MM-YYYY")} - {djMax.format("DD-MM-YYYY")}
              </span>
            )
          } else {
            return (
              <FlexCol
                style={{
                  alignItems: "center",
                  width: "fit-content",
                  gap: 0,
                }}
              >
                <span>{djMin.format("DD-MM-YYYY")}</span>
                <span
                  style={{
                    fontSize: "0.8em",
                  }}
                >
                  {djMin.format("HH:mm")} - {djMax.format("HH:mm")}
                </span>
              </FlexCol>
            )
          }
        },
      },
      {
        key: "temperature",
        title: <Translated keyEn="Temperature" />,
        render: (equipment: Equipment) => {
          if (memoEquipmentConditionsMap === null) {
            return null
          }
          let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
          let equipmentConditions = memoEquipmentConditionsMap[equipmentUUIDStr]
          if (equipmentConditions === undefined) {
            return null
          }
          return <span>{equipmentConditions.temperature.format(0, 1)} ℃</span>
        },
      },
      {
        key: "humidity",
        title: <Translated keyEn="Humidity" />,
        render: (equipment: Equipment) => {
          if (memoEquipmentConditionsMap === null) {
            return null
          }
          let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
          let equipmentConditions = memoEquipmentConditionsMap[equipmentUUIDStr]
          if (equipmentConditions === undefined) {
            return null
          }
          return <span>{equipmentConditions.relativeHumidity.format(0, 1)} %</span>
        },
      },
    ]
  }, [memoEquipmentConditionsMap])

  return (
    <Table
      className="printed-table"
      dataSource={memoFilteredEquipments ?? []}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      bordered
      style={{
        width: "100%",
      }}
    />
  )
}
