import { FC, useCallback, useMemo } from "react"
import { Avatar, Button, Upload } from "antd"
import { CloseOutlined, PictureOutlined } from "@ant-design/icons"

import ImageBlobReduce from "image-blob-reduce"
import Pica from "pica"
import { FlexRow } from "../commons-ts/common"
import { COLOR_BG_DARK_BLUE_TEXT, COLOR_BG_LIGHT_BLUE, colorHexToRgba } from "../../utils/utils"
import { HEADER_HEIGHT } from "."
const pica = Pica({ features: ["js", "wasm", "cib"] })

export const ImageUploader: FC<{
  dataURL: string | null
  setDataURL: (dataURL: string | null) => void
  icon?: React.ReactNode
  size?: number
}> = ({ dataURL, setDataURL, icon, size }) => {
  const beforeUpload = useCallback(
    async (file: File) => {
      console.log(`ImageUploader: onBeforeUpload: `, file)
      let resizedBlob = await new ImageBlobReduce({ pica }).toBlob(file, {
        max: 2 * HEADER_HEIGHT,
      })
      const reader = new FileReader()
      reader.readAsDataURL(resizedBlob)
      reader.onload = () => {
        let _dataURL = reader.result
        console.log(`ImageUploader: onbeforeUpload: readeronload: file size: `, _dataURL)
        if (typeof _dataURL === "string") {
          console.log(`ImageUploader: dataURL length: `, _dataURL.length)
          setDataURL(_dataURL)
        } else {
          console.error(`ImageUploader: onbeforeUpload: readeronload: invalid dataURL: `, _dataURL)
        }
      }
      return false
    },
    [setDataURL],
  )

  const onChange = useCallback((info: any) => {
    console.log(info)
  }, [])

  const memoUploadChildren = useMemo(() => {
    if (dataURL !== null) {
      return (
        <img
          src={dataURL}
          alt="LOGO"
          style={{
            height: size ?? HEADER_HEIGHT,
            cursor: "pointer",
            objectFit: "contain",
          }}
        />
      )
    }
    return (
      <Avatar
        // className="pdf-invisible"
        icon={icon ?? <PictureOutlined />}
        shape="square"
        size={size ?? HEADER_HEIGHT}
        style={{
          cursor: "pointer",
          backgroundColor: colorHexToRgba(COLOR_BG_LIGHT_BLUE, 0.1),
        }}
      />
    )
  }, [dataURL, icon])

  return (
    <FlexRow style={{ gap: 0 }}>
      <Upload
        accept="image/*"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={onChange}
        style={{
          cursor: "pointer",
          height: "100%",
        }}
      >
        {memoUploadChildren}
      </Upload>
      {dataURL && (
        <Button
          className="pdf-invisible"
          size="small"
          type="link"
          icon={<CloseOutlined />}
          onClick={() => setDataURL(null)}
          style={{
            marginLeft: -3,
            marginTop: -5,
            color: COLOR_BG_DARK_BLUE_TEXT,
          }}
        />
      )}
    </FlexRow>
  )
}
