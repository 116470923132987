import { Impact } from "../generated/proto-ts/main"
import { IKinematicPoint } from "../types"
import { meanArr } from "./common"
import { IImpactKinematics, G, FREE_FALL_ACCELERATION_THRESHOLD } from "./types"

export const getSignedQuadraticSum = (
  ax_points: number[],
  ay_points: number[],
  az_points: number[],
): number[] => {
  let aq_points: number[] = []
  for (let i = 0; i < ax_points.length; i++) {
    let values: number[] = [ax_points[i]!] // ax point is guaranteed, ay and az point might be undefined
    let ay = ay_points[i]
    if (ay !== undefined) {
      values.push(ay)
    }
    let az = az_points[i]
    if (az !== undefined) {
      values.push(az)
    }
    let mean = meanArr(values)
    let quadraticSum: number = 0
    for (let j = 0; j < values.length; j++) {
      quadraticSum += Math.pow(values[j]!, 2)
    }
    quadraticSum = Math.sqrt(quadraticSum)
    if (mean < 0) {
      quadraticSum = -quadraticSum
    }
    aq_points.push(quadraticSum)
  }
  return aq_points
}

export const getImpactKinematics = (
  impact: Impact,
  aq_points: number[],
): IImpactKinematics | null => {
  let f = impact.decimated_frequency
  if (f === 0) {
    f = impact.sampling_frequency
  }
  if (f === 0) {
    console.warn(`getImpactKinematics: f=0 Hz, using 1kHz as default`)
    f = 1e3
  }
  console.log(`getImpactKinematics: f=${f} Hz`)

  // UPD: 2024-06-01 frequency is now transmitted in the impact message
  // let nFF = impact.impact_index // n
  // let fEstimate = nFF / tFF // Hz (1/s)
  // let fCorrected = Math.round(fEstimate / 1000) * 1000 // 1kHZ step, e.g. 5kHz, 10kHz, 20kHz etc...

  // UPD 2024-06-26 tFF is now calculated based on the impact index (impact_tstart_sample_index)
  let calculatedTFF = impact.impact_sample_impact_index / f // s
  let calculatedFFH = (G * Math.pow(calculatedTFF, 2)) / 2 // m

  let tFF_T0 = (impact.impact_sample_impact_index - impact.impact_table_impact_index) / f

  let vInit = G * tFF_T0 // m/s

  // console.log(
  //     `getImpactKinematicsSM: impactHeight(.impact_ffh)=${impact.impact_ffh}m, calculatedFFH=${calculatedFFH}m, , calculatedTFF=${calculatedTFF}s, tFF@T0=${tFF_T0}, vInit=${vInit}m/s`,
  // )

  let vCurr = vInit
  let tCurr = 0

  let vMax = 0
  let vMin = 0
  let gMax = 0
  let vAtGmax = 0
  let kinematicPoints: IKinematicPoint[] = []

  // First integral: velocity
  for (let i = 0; i < aq_points.length; i++) {
    let currQ = aq_points[i]!
    let nextQ = aq_points[i + 1]
    if (nextQ === undefined) {
      break
    }
    // point index (sequence) and its delta
    // let dN = i + 1 - i
    // let nCurr = (i + i + 1) / 2 // n; i.e. trapezoidal rule

    // time and its delta
    let dT = 1 / f // s
    tCurr = i / f // s

    // mean acceleration (trapezoidal rule)
    let qMean = (currQ + nextQ) / 2 // g
    let aCurr = G * qMean // m/s^2

    // integrated velocity
    let dV = dT * aCurr // m/s
    vCurr -= dV
    vMin = Math.min(vCurr, vMin)
    vMax = Math.max(vCurr, vMax)
    if (qMean > gMax) {
      gMax = qMean
      vAtGmax = vCurr
    }

    kinematicPoints.push({ g: qMean, a: aCurr, v: vCurr, t: tCurr, d: NaN })
  }

  // UPD 2024-09-03:
  if (vAtGmax > 10) {
    console.warn(`getImpactKinematics: vAtGmax=${vAtGmax} m/s`)
    // let recoveredImpactTFF = (vMax - vAtGmax) / G // recovered impact TFF = offsetVelocity / G
    // impact.impact_ffh = (G * Math.pow(recoveredImpactTFF, 2)) / 2
    // impact.impact_sample_impact_index = recoveredImpactTFF * f
    // impact.impact_sample_threshold_index =
    //     impact.impact_sample_impact_index +
    //     (impact.impact_table_threshold_index -
    //         impact.impact_table_impact_index)
    // return getImpactKinematics(impact, smThicknessMM)
  }

  let dCurr = 0
  // Second integral: displacement
  for (let i = 0; i < kinematicPoints.length; i++) {
    let currKinematicPoint = kinematicPoints[i]!
    let nextKinematicPoint = kinematicPoints[i + 1]
    if (nextKinematicPoint === undefined) {
      break
    }
    let tCurr = currKinematicPoint.t
    let tNext = nextKinematicPoint.t

    let vCurr = currKinematicPoint.v
    let vNext = nextKinematicPoint.v

    let dT = tNext - tCurr // s
    let vMean = (vCurr + vNext) / 2 // m/s (trapezoidal rule)

    let dD = dT * vMean // m
    dCurr -= dD
    nextKinematicPoint.d = dCurr
  }

  // Calculate INIT and FINAL values
  let iInit = 0
  let tInit = 0
  let dInit = 0
  // let vInit = 0
  for (let i = 0; i < kinematicPoints.length; i++) {
    let kp = kinematicPoints[i]!
    if (kp.a > FREE_FALL_ACCELERATION_THRESHOLD) {
      break
    }
    iInit = i + 1 // i+1 beacuse there is a shift when integrating
    tInit = kp.t
    vInit = kp.v
    dInit = kp.d
  }

  // Offset distance
  for (let i = 0; i < kinematicPoints.length; i++) {
    let kp = kinematicPoints[i]!
    kp.d -= dInit
  }
  dInit -= dInit

  // console.log(
  //     `getImpactKinematicsSM: vInit=${vInit} m/s, tInit=${tInit}, dInit=${dInit} m`,
  // )

  let iFinal = 0
  let tFinal = 0
  let vFinal = 0
  let dFinal = 0
  for (let i = iInit; i < kinematicPoints.length; i++) {
    let kp = kinematicPoints[i]!
    if (kp.a < FREE_FALL_ACCELERATION_THRESHOLD) {
      break
    }
    iFinal = i
    tFinal = kp.t
    vFinal = kp.v
    dFinal = kp.d
  }
  // console.log(
  //     `getImpactKinematicsSM: vFinal=${vFinal} m/s, tFinal=${tFinal}, dFinal=${dFinal} m, iFinal=${iFinal}`,
  // )

  let tSecondarySuspense = 0
  let dSecondarySuspense = 0
  for (let i = iFinal; i < kinematicPoints.length - 1; i++) {
    if (i === 0) {
      continue
    }
    let _vPrev = kinematicPoints[i - 1]!.v
    let _vCurr = kinematicPoints[i]!.v
    // negative velocity here is going up, positive - down
    if (_vPrev < 0 && _vCurr > 0) {
      break
    }
    let kp = kinematicPoints[i + 1]! // i+1 because there is a shift when integrating
    tSecondarySuspense = kp.t
    dSecondarySuspense = kp.d
  }
  let dSecondarySuspenseFromFinal = dSecondarySuspense - dFinal
  console.log(
    `getImpactKinematicsSM: tSecondarySuspense=${tSecondarySuspense}, dSecondarySuspense=${dSecondarySuspense} m, dSecondarySuspenseFromFinal=${dSecondarySuspenseFromFinal} m`,
  )

  // Search for the dMin value somewhere between iInit and iFinal
  let dMaxDeflection = 0
  let tMaxDeflection = 0
  for (let i = iInit; i < iFinal; i++) {
    let kp = kinematicPoints[i]!
    if (kp.d < dMaxDeflection) {
      dMaxDeflection = kp.d
      tMaxDeflection = kp.t
    }
  }
  // console.log(`getImpactKinematicsSM: dMin=${dMin} m, tAtDMin=${tAtDMin} s`)

  let impactDurationS = tFinal - tInit // s
  let calculatedDurationMS = 1000 * impactDurationS // ms

  let hicMax = 0
  let hicDt = 0
  let hicT0 = 0
  let initialDi = 0.036 * f // 36ms
  let hicNbIterations = 0
  // for (let di = initialDi; di < iFinal - iInit; di++) {
  //     // do not search for HIC with < 36ms
  //     for (let hicI1 = iInit; hicI1 < iFinal - di; hicI1++) {
  //         let sumQ = 0
  //         for (let i = 0; i < kinematicPoints.length; i++) {
  //             if (i < hicI1) {
  //                 continue
  //             }
  //             if (i > hicI1 + di) {
  //                 break
  //             }
  //             let kp = kinematicPoints[i]
  //             sumQ += Math.abs(kp.g) // gs
  //         }
  //         let dt = di / fCorrected // s
  //         let hic = dt * Math.pow(sumQ / fCorrected / dt, 2.5)
  //         if (hic > hicMax) {
  //             hicMax = hic
  //             hicDt = 1000 * dt
  //             hicT0 = 1000 * (hicI1 / fCorrected)
  //         }
  //     }
  //     hicNbIterations++
  // }

  // console.log(
  //     `getImpactKinematicsSM: HIC[@${hicT0}:${hicDt}]=${hicMax}; iterations=${hicNbIterations}`,
  // )

  let resilience = Math.pow(vFinal, 2) / Math.pow(vInit, 2) // ratio
  // console.log(
  //     `getImpactKinematicsSM: vFinal=${vFinal} m/s, tFinal=${tFinal}, dFinal=${dCurr} m, R=${resilience}, dEnf=${dCurr / 2} m`,
  // )
  let deflectionDistanceMM = Math.abs(dMaxDeflection * 1000) // mm
  let resiliencePerc = 100 * resilience // %
  return {
    samplingFrequency: f,
    calculatedDurationMS,
    calculatedTFF,
    calculatedFFH,
    calculatedHIC: {
      hicMax: hicMax,
      hicTms: hicT0,
      hicDTms: hicDt,
    },
    calculatedGmax: gMax,
    initialVelocity: vInit,
    finalVelocity: vFinal,
    deflectionDistanceMM,
    deflectionTimeMS: tMaxDeflection * 1000,
    resiliencePerc,
    kinematicPoints,

    secondarySuspenseHeightM: dSecondarySuspense,
    secondarySuspenseTimeMS: 1000 * tSecondarySuspense,
  }
}
