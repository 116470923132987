import { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  ENTITY_SAVE_DEBOUNCE_DELAY_MS,
  CENTRAL_CONTAINER_MAX_WIDTH,
  parsePathForEntityUUID,
  pbUUIDToUuid,
  uuidToPbUUID,
  CENTRAL_CONTAINER_GAP,
} from "../utils/utils"
import { Link, useHistory, useLocation } from "react-router-dom"
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from "antd"
import dayjs from "dayjs"
import { FlexCol, FlexRow, UnderlinedSectionTitle } from "../components/commons-ts/common"
import { ErrorAlert } from "../components/commons-ts/errorAlert"
import { SimplifiedBreadcrumb } from "../components/commons-ts/simplifiedBreadcrumb"
import { Label, LabeledInput } from "../components/commons-ts/input"
import { Translated } from "../utils/translated"
import {
  normTypeElement,
  methodElement,
  isLabTestElement,
  normTypeDescriptionStr,
  methodDescriptionStr,
} from "../components/commons-ts/tags"
import {
  ConsoleSqlOutlined,
  DeleteOutlined,
  FileAddOutlined,
  FilePdfOutlined,
  SaveOutlined,
} from "@ant-design/icons"
import {
  Annex,
  Equipment,
  MethodTypeEN1177,
  NormType,
  Site,
  UUID,
} from "../generated/proto-ts/main"

import {
  useUsercommUpdateAnnexBimodal,
  useUsercommDeleteAnnexBimodal,
  useUsercommCreateEquipmentBimodal,
  useUsercommSiteBimodal,
  useUsercommSiteAnnexesBimodal,
  useUsercommCreateAnnexBimodal,
  useUsercommSiteEquipmentsBimodal,
  useUsercommDeleteSiteBimodal,
  useUsercommUpdateSiteBimodal,
  useUsercommSiteChildrenRecursiveBimodal,
} from "../usercomm/common/usercommAsyncRequestBimodal"

import { UploadImageListV2 } from "../components/commons-ts/uploadImageV2"
import { DataTreeDrawer } from "../components/commons-ts/dataTreeDrawer"
import { SiteEquipmentsTableSM } from "./Tables/01_SiteEquipments/SiteEquipmentsTable_SM"
import {
  SiteEquipmentsTablePF_ADQ,
  SiteEquipmentsTablePF_CFH,
} from "./Tables/01_SiteEquipments/SiteEquipmentsTable_PF"
import { SiteEquipmentsTable_AB } from "./Tables/01_SiteEquipments/SiteEquipmentsTable_AB"
import { SiteEquipmentsTable_TL } from "./Tables/01_SiteEquipments/SiteEquipmentsTable_TL"
import { GoogleAddressWidget } from "../components/commons-ts/googleMapsApi/googleAddress"
import { useTranslationContext } from "../providers/translationProvider"
import { v4 as uuidv4 } from "uuid"
import { useCloudUsercommSiteChildrenRecursiveWS } from "../usercomm/cloud/cloudUsercommAsyncRequestWS"
import { useDebounce } from "../utils/debounce"
import { EntityTimestamps } from "../components/commons-ts/entityTimestamps"

const AnnexWidget: FC<{
  annex: Annex | null
  idx: number
  getAnnexes: () => void
}> = ({ annex, idx, getAnnexes }) => {
  const [annexContent, setAnnexContent] = useState<string | null>(null)
  const [pictures, setPictures] = useState<UUID[]>([])

  const [updateAnnexResponse, updateAnnexRequest] = useUsercommUpdateAnnexBimodal()
  const [deleteAnnexResponse, deleteAnnexRequest] = useUsercommDeleteAnnexBimodal()

  const { translated } = useTranslationContext()

  useEffect(() => {
    if (annex === null) {
      return
    }
    setAnnexContent(annex.annex_content)
    setPictures(annex.pictures)
  }, [annex])

  // UPDATE ANNEX REQUEST
  const debouncedOnUpdate = useDebounce(async () => {
    if (annex === null) {
      return
    }
    let updatedAnnex = annex.clone()
    updatedAnnex.annex_idx = idx
    updatedAnnex.annex_content = annexContent ?? ""
    updatedAnnex.pictures = pictures
    console.log(`AnnexWidget: about to update annex`, updatedAnnex.toObject())
    updatedAnnex.updated_at = Date.now()
    updateAnnexRequest(updatedAnnex)
  })
  // UPDATE ANNEX RESPONSE
  useEffect(() => {
    if (updateAnnexResponse === null) {
      return
    }
    console.log(`AnnexWidget: updateAnnexResponse`, updateAnnexResponse.toObject())
    // UPD 2024-09-06: do not re-fetch annexes to save bandwidth
    // getAnnexes()
  }, [updateAnnexResponse])

  useEffect(() => {
    debouncedOnUpdate()
  }, [annexContent, pictures, annex])

  // DELETE ANNEX REQUEST
  const onDelete = useCallback(async () => {
    if (annex === null) {
      return
    }
    deleteAnnexRequest(annex.uuid)
  }, [annex, idx])
  // DELETE ANNEX RESPONSE
  useEffect(() => {
    if (deleteAnnexResponse === null) {
      return
    }
    console.log(`AnnexWidget: deleteAnnexResponse`, deleteAnnexResponse.toObject())
    getAnnexes()
  }, [deleteAnnexResponse, idx])

  if (annex === null) {
    return <Spin size="large" />
  }

  return (
    <div>
      <Label>
        <Translated keyEn="Annex" /> #{idx}
      </Label>
      <FlexCol style={{ gap: 5 }}>
        <Space.Compact>
          <Input
            size="large"
            value={annexContent ?? ""}
            onChange={(e) => {
              setAnnexContent(e.target.value)
            }}
            placeholder={translated("Annex content") ?? ""}
            variant="filled"
          />
          <Popconfirm
            title={
              <>
                <Translated keyEn="Are you sure you want to delete this annex" />?
              </>
            }
            onConfirm={onDelete}
          >
            <Button icon={<DeleteOutlined />} size="large" danger />
          </Popconfirm>
          <Button icon={<SaveOutlined />} onClick={debouncedOnUpdate} size="large" type="primary" />
        </Space.Compact>
        <UploadImageListV2 pictures={pictures} setPictures={setPictures} />
      </FlexCol>
    </div>
  )
}

const NewEquipmentButton: FC<{
  siteUUID: UUID | null
  equipmentName: string | null
}> = ({ siteUUID: siteUUID, equipmentName }) => {
  const [antdMessageCtx] = message.useMessage()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()

  const [createEquipmentNewUUID, createEquipmentRequest] = useUsercommCreateEquipmentBimodal()

  // CREATE EQUIPMENT REQUEST
  const onCreate = useCallback(async () => {
    setIsLoading(true)
    if (siteUUID === null) {
      throw new Error("siteUUID is null")
    }
    if (equipmentName === null) {
      throw new Error("equipmentName is null")
    }
    let ts = Date.now()
    createEquipmentRequest(
      siteUUID,
      new Equipment({
        uuid: uuidToPbUUID(uuidv4()), // IT IS CREATOR'S RESPONSIBILITY TO GENERATE A NEW UUID
        site_uuid: siteUUID,
        equipment_name: equipmentName,
        created_at: ts,
        updated_at: ts,
      }),
    )
  }, [siteUUID, equipmentName])
  // CREATE EQUIPMENT RESPONSE
  useEffect(() => {
    if (createEquipmentNewUUID === null) {
      return
    }
    console.log(`NewEquipmentButton: createEquipmentNewUUID`, createEquipmentNewUUID.toObject())
    setIsLoading(false)
    if (createEquipmentNewUUID.value) {
      antdMessageCtx.success(
        <>
          Equipment <b>{equipmentName}</b> was successfully created!
        </>,
      )
      history.push(`/equipments/${pbUUIDToUuid(createEquipmentNewUUID)}`)
    }
  }, [createEquipmentNewUUID])

  return (
    <Button
      disabled={siteUUID === null || equipmentName === null}
      onClick={onCreate}
      loading={isLoading}
      size="large"
      type="primary"
    >
      <span
        style={{
          textTransform: "uppercase",
        }}
      >
        <Translated keyEn="Start" />
      </span>
    </Button>
  )
}

export const SitePage: FC = () => {
  const [antdMessageCtx] = message.useMessage()
  const location = useLocation()
  const history = useHistory()

  const memoSiteUUID = useMemo((): UUID | null => {
    let siteUUidStr = parsePathForEntityUUID(location.pathname)
    if (siteUUidStr === null) {
      return null
    }
    return uuidToPbUUID(siteUUidStr)
  }, [location.pathname])

  const [site, getSite, externallySetSite] = useUsercommSiteBimodal()
  const [updateSiteAck, updateSite] = useUsercommUpdateSiteBimodal()
  const [deleteSiteAck, deleteSite] = useUsercommDeleteSiteBimodal()

  const [annexes, getAnnexes] = useUsercommSiteAnnexesBimodal()
  const [createAnnexNewUUID, createAnnexRequest] = useUsercommCreateAnnexBimodal()

  const [equipments, getEquipments] = useUsercommSiteEquipmentsBimodal()

  const [_, __, siteZoneImpactsMap, getSiteChildrenRecursive] =
    useCloudUsercommSiteChildrenRecursiveWS()

  const [siteName, setSiteName] = useState<string | null>(null)
  const [clientName, setClient] = useState<string | null>(null)
  const [missionName, setMission] = useState<string | null>(null)
  const [normType, setNormType] = useState<NormType | null>(null)
  const [methodType, setMethodType] = useState<MethodTypeEN1177 | null>(null)
  const [isLabTest, setIsLabTest] = useState<boolean | null>(null)
  const [address, setAddress] = useState<string | null>(null)
  const [executionDate, setExecutionDate] = useState<dayjs.Dayjs | null>(null)
  const [pictures, setPictures] = useState<Site["pictures"]>([])

  // NormType / Method selectors state
  const [selectedNormTypeToChange, setSelectedNormTypeToChange] = useState<NormType | null>(null)
  const [selectedMethodTypeToChange, setSelectedMethodTypeToChange] =
    useState<MethodTypeEN1177 | null>(null)

  const [newEquipmentName, setNewEquipmentName] = useState<string | null>(null)

  const { translated } = useTranslationContext()

  // SITE REQUEST
  useEffect(() => {
    if (memoSiteUUID === null) {
      return
    }
    getSite(memoSiteUUID)
  }, [memoSiteUUID])
  // SITE RESPONSE
  useEffect(() => {
    if (site == null) {
      return
    }
    console.log(`SitePage: site`, site.toObject())
    setSiteName(site.site_name)
    setClient(site.client_name)
    setMission(site.mission_name)
    setNormType(site.norm_type)
    setSelectedNormTypeToChange(site.norm_type)
    setMethodType(site.method_type_en_1177)
    setSelectedMethodTypeToChange(site.method_type_en_1177)
    setIsLabTest(site.is_lab_test)
    setAddress(site.address)
    if (site.execution_date === 0) {
      setExecutionDate(null)
    } else {
      setExecutionDate(dayjs(site.execution_date))
    }
    setPictures(site.pictures)
  }, [site])

  // ANNEXES REQUEST
  useEffect(() => {
    if (memoSiteUUID === null) {
      return
    }
    getAnnexes(memoSiteUUID)
  }, [memoSiteUUID])
  // ANNEXES RESPONSE
  useEffect(() => {
    if (annexes !== null) {
      console.log(
        `SitePage: anexes`,
        annexes.map((a) => a.toObject()),
      )
    }
  }, [annexes])

  // EQUIPMENTS REQUEST
  useEffect(() => {
    if (memoSiteUUID === null) {
      return
    }
    getEquipments(memoSiteUUID)
  }, [memoSiteUUID])
  // EQUIPMENTS RESPONSE
  useEffect(() => {
    if (equipments === null) {
      return
    }
    console.log(
      `SitePage: equipments`,
      equipments.map((e) => e.toObject()),
    )
  }, [equipments])

  // SITE CHILDREN (IMPACTS) REQUEST
  useEffect(() => {
    if (memoSiteUUID === null) {
      return
    }
    getSiteChildrenRecursive(memoSiteUUID)
  }, [memoSiteUUID])

  // CREATE ANNEX REQUEST
  const onAddAnnex = useCallback(async () => {
    if (memoSiteUUID === null) {
      return
    }
    let ts = Date.now()
    let annex = new Annex({
      uuid: uuidToPbUUID(uuidv4()), // IT IS CREATOR'S RESPONSIBILITY TO GENERATE A NEW UUID
      site_uuid: memoSiteUUID,
      created_at: ts,
      updated_at: ts,
    })
    createAnnexRequest(memoSiteUUID, annex)
  }, [memoSiteUUID])
  // NEW ANNEX RESPONSE
  useEffect(() => {
    if (createAnnexNewUUID === null) {
      return
    }
    if (createAnnexNewUUID.value) {
      antdMessageCtx.success(translated("New annex created!"))
    } else {
      antdMessageCtx.error(translated("Could not create annex!"))
    }
    getAnnexes(memoSiteUUID)
  }, [createAnnexNewUUID])

  // DELETE SITE REQUEST
  const onDelete = useCallback(async () => {
    if (memoSiteUUID === null) {
      return
    }
    deleteSite(memoSiteUUID)
  }, [memoSiteUUID])
  // DELETE SITE RESPONSE
  useEffect(() => {
    if (deleteSiteAck === null) {
      return
    }
    antdMessageCtx.info(translated("Site deleted!"))
    setTimeout(() => {
      history.push("/")
    }, 1000)
  }, [deleteSiteAck])

  useEffect(() => {
    if (normType !== NormType.EN_1177) {
      setMethodType(MethodTypeEN1177.UNKNOWN_METHOD_TYPE_EN_1177)
      setSelectedMethodTypeToChange(MethodTypeEN1177.UNKNOWN_METHOD_TYPE_EN_1177)
      return
    }
    if (
      normType === NormType.EN_1177 &&
      (methodType === null || methodType === MethodTypeEN1177.UNKNOWN_METHOD_TYPE_EN_1177)
    ) {
      setMethodType(MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION)
      setSelectedMethodTypeToChange(MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION)
      return
    }
  }, [normType, methodType, isLabTest])

  const memoNormTypeElement = useMemo(() => {
    return normTypeElement(normType)
  }, [normType])

  const memoMethodElement = useMemo(() => {
    if (normType === null || normType === NormType.EN_12503) {
      return null
    }
    return methodElement(methodType)
  }, [normType, methodType])

  const memoIsLabTestElement = useMemo(() => {
    return isLabTestElement(isLabTest)
  }, [isLabTest])

  const memoEquipmentsTable = useMemo(() => {
    if (normType === null) {
      return null
    }
    if (normType === NormType.EN_12503) {
      return <SiteEquipmentsTableSM siteUUID={memoSiteUUID} equipments={equipments} />
    } else if (normType === NormType.EN_14960) {
      return <SiteEquipmentsTable_AB equipments={equipments} siteUUID={memoSiteUUID} />
    } else if (normType === NormType.EN_ISO_23659) {
      return <SiteEquipmentsTable_TL equipments={equipments} siteUUID={memoSiteUUID} />
    } else if (normType === NormType.EN_1177) {
      if (methodType === MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION) {
        return <SiteEquipmentsTablePF_CFH siteUUID={memoSiteUUID} equipments={equipments} />
      } else if (methodType === MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE) {
        return <SiteEquipmentsTablePF_ADQ siteUUID={memoSiteUUID} equipments={equipments} />
      }
    }
  }, [memoSiteUUID, equipments, normType, methodType])

  const getAnnexesCallback = useCallback(() => {
    getAnnexes(memoSiteUUID)
  }, [memoSiteUUID])

  const memoAnnexesElements = useMemo(() => {
    if (annexes === null) {
      return null
    }
    let children: ReactElement[] = []

    annexes.sort((a, b) => a.annex_idx - b.annex_idx)

    for (let i = 0; i < annexes.length; i++) {
      let annex = annexes[i]
      if (annex === undefined || annex.deleted_at > 0) {
        continue
      }
      children.push(
        <AnnexWidget annex={annex} idx={i + 1} getAnnexes={getAnnexesCallback} key={i} />,
      )
      if (i !== annexes.length - 1) {
        children.push(<Divider key={`div-${i}`} />)
      }
    }
    return <div style={{ width: "100%" }}>{children}</div>
  }, [memoSiteUUID, annexes])

  const memoSiteIsMutated = useMemo(() => {
    if (site === null) {
      return false
    }
    if (
      siteName === null ||
      clientName === null ||
      missionName === null ||
      normType === null ||
      methodType === null ||
      isLabTest === null ||
      address === null ||
      executionDate === null
      // pictures === null // pictures are never null even on initialization
    ) {
      return false
    }
    let prevExecutionDateInt: number | null = site.execution_date
    let currExecutionDateInt: number | null = null
    if (executionDate !== null) {
      currExecutionDateInt = executionDate.toDate().getTime()
    }
    let executionDateChanged = true
    if (
      (prevExecutionDateInt === null && currExecutionDateInt === null) ||
      prevExecutionDateInt === currExecutionDateInt
    ) {
      executionDateChanged = false
    }
    let prevPicturesStr = site.pictures.map((u) => pbUUIDToUuid(u)).join(",")
    let currPicturesStr = ""
    if (pictures !== null) {
      currPicturesStr = pictures.map((u) => pbUUIDToUuid(u)).join(",")
    }
    let isMutated =
      site.site_name !== (siteName ?? "") ||
      site.client_name !== (clientName ?? "") ||
      site.mission_name !== (missionName ?? "") ||
      site.norm_type !== normType ||
      site.method_type_en_1177 !== methodType ||
      site.is_lab_test !== isLabTest ||
      site.address !== address ||
      executionDateChanged ||
      prevPicturesStr !== currPicturesStr
    // if (isMutated) {
    //     console.debug(
    //         `SitePage: isMutated:
    //         site: ${site.site_name} vs ${siteName}
    //         client: ${site.client_name} vs ${clientName}
    //         mission: ${site.mission_name} vs ${missionName}
    //         norm_type: ${site.norm_type} vs ${normType}
    //         method: ${site.method_type_en_1177} vs ${methodType}
    //         is_lab_test: ${site.is_lab_test} vs ${isLabTest}
    //         address: ${site.address} vs ${address}
    //         execution_date: ${prevExecutionDateInt} vs ${currExecutionDateInt}
    //         pictures: ${prevPicturesStr} vs ${currPicturesStr}
    //         `,
    //     )
    // }
    return isMutated
  }, [
    site,
    siteName,
    clientName,
    missionName,
    normType,
    methodType,
    isLabTest,
    address,
    executionDate,
    pictures,
  ])

  // const debouncedUpdateSite = useMemo(() => {
  //     return debounce(updateSite, ENTITY_SAVE_DEBOUNCE_DELAY_MS)
  // }, [updateSite])

  // UPDATE SITE REQUEST
  const onUpdate = useDebounce(async () => {
    if (site === null) {
      return
    }
    let updatedSite = site.clone()
    updatedSite.site_name = siteName ?? ""
    updatedSite.client_name = clientName ?? ""
    updatedSite.mission_name = missionName ?? ""
    updatedSite.norm_type = normType ?? NormType.EN_1177
    updatedSite.method_type_en_1177 = methodType ?? MethodTypeEN1177.UNKNOWN_METHOD_TYPE_EN_1177
    updatedSite.is_lab_test = isLabTest ?? false
    updatedSite.address = address ?? ""
    updatedSite.execution_date = executionDate !== null ? executionDate.toDate().getTime() : 0
    updatedSite.pictures = pictures ?? []
    updatedSite.updated_at = Date.now()
    updateSite(updatedSite)
    externallySetSite(updatedSite)
  })
  // UPDATE SITE RESPONSE
  useEffect(() => {
    if (memoSiteUUID === null || updateSiteAck === null) {
      return
    }
    // UPD 2024-09-06: do not re-fetch site to save bandwidth
    // and to avoid input "glitch" when the user is typing
    //
    // use the externallySetSite() to update the local state
    //
    // // getSite(memoSiteUUID)
  }, [memoSiteUUID, updateSiteAck])

  useEffect(() => {
    console.debug(`SitePage: memoSiteIsMutated`, memoSiteIsMutated)
    if (memoSiteIsMutated === false) {
      return
    }
    onUpdate()
  }, [memoSiteIsMutated])

  const memoImpactMarkerCoordinates = useMemo(() => {
    let _markers: { lat: number; lng: number }[] = []
    if (siteZoneImpactsMap === null) {
      return _markers
    }
    for (let [_, impacts] of Object.entries(siteZoneImpactsMap)) {
      for (let impact of impacts) {
        if (impact.latitude === 0 || impact.longitude === 0) {
          continue
        }
        _markers.push({
          lat: impact.latitude,
          lng: impact.longitude,
        })
      }
    }
    // console.log(`SitePage: memoImpactMarkerCoordinates`, _markers)
    return _markers
  }, [siteZoneImpactsMap])

  if (site === null) {
    return (
      <FlexCol
        style={{
          width: "100%",
          height: "60vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </FlexCol>
    )
  }

  return (
    <>
      <FlexCol
        style={{
          width: "100%",
          margin: "auto",
          maxWidth: CENTRAL_CONTAINER_MAX_WIDTH,
          gap: CENTRAL_CONTAINER_GAP,
        }}
      >
        <SimplifiedBreadcrumb previousItems={[]} currentItem={{ label: siteName }} />
        <Row
          justify="space-between"
          style={{
            marginTop: "1rem",
          }}
        >
          <Col>
            <FlexCol style={{ gap: 0 }}>
              <Label>
                <Translated keyEn="Site" />
              </Label>
              <Typography.Text
                style={{
                  fontSize: "2rem",
                }}
              >
                {siteName}
              </Typography.Text>
            </FlexCol>
          </Col>
          <Col>
            <FlexCol style={{ gap: 3 }}>
              {memoNormTypeElement}
              {memoMethodElement}
              {memoIsLabTestElement}
            </FlexCol>
          </Col>
        </Row>
        {/* Site name */}
        <LabeledInput
          translatableLabel={translated("Site")}
          value={siteName}
          setValue={setSiteName}
        />
        {/* New equipment */}
        <div>
          <Label>
            <Translated keyEn="New equipment" />
          </Label>
          <Space.Compact
            style={{
              width: "100%",
            }}
          >
            <Input
              size="large"
              value={newEquipmentName ?? ""}
              onChange={(e) => {
                setNewEquipmentName(e.target.value)
              }}
              placeholder={translated("New equipment") ?? ""}
            />
            <NewEquipmentButton siteUUID={memoSiteUUID} equipmentName={newEquipmentName} />
          </Space.Compact>
        </div>
        {/* Site equipments */}
        <div>
          <Label>
            <Translated keyEn="Equipments" />
          </Label>
        </div>
        {memoEquipmentsTable}
        {/* Site picture */}
        <FlexCol style={{ gap: 0 }}>
          <Label>
            <Translated keyEn="Picture" />
          </Label>
          <UploadImageListV2 pictures={pictures} setPictures={setPictures} />
        </FlexCol>
        {/* Site data */}
        <div>
          <Row
            gutter={[10, 10]}
            style={{
              marginTop: 20,
            }}
          >
            <Col xs={24} md={12}>
              <LabeledInput
                translatableLabel={translated("Client")}
                value={clientName}
                setValue={setClient}
              />
            </Col>
            <Col xs={24} md={12}>
              <LabeledInput
                translatableLabel={translated("Mission")}
                value={missionName}
                setValue={setMission}
              />
            </Col>
            <Col xs={24} md={12}>
              <FlexCol style={{ gap: 0 }}>
                <Label>
                  <Translated keyEn="Execution date" />
                </Label>
                <DatePicker
                  size="large"
                  value={executionDate}
                  onChange={(value) => {
                    setExecutionDate(value)
                  }}
                  variant="filled"
                />
              </FlexCol>
            </Col>
            <Col xs={24}>
              <FlexCol style={{ gap: 0 }}>
                <Label>
                  <Translated keyEn="Address" />
                </Label>
                <GoogleAddressWidget
                  address={address}
                  setAddress={setAddress}
                  markerCoordinates={memoImpactMarkerCoordinates}
                />
              </FlexCol>
            </Col>
          </Row>
        </div>
        {/* Norm and method types swap */}
        <FlexCol
          style={{
            width: "100%",
          }}
        >
          <UnderlinedSectionTitle>
            <Translated keyEn="Norm type" />
          </UnderlinedSectionTitle>
          {normType !== null && (
            <FlexRow>
              <Select
                value={selectedNormTypeToChange}
                style={
                  {
                    // minWidth: "30rem",
                  }
                }
                options={[
                  {
                    value: NormType.EN_1177,
                    label: (
                      <span
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <Translated keyEn={normTypeDescriptionStr(NormType.EN_1177)} />
                      </span>
                    ),
                  },
                  {
                    value: NormType.EN_12503,
                    label: (
                      <span
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <Translated keyEn={normTypeDescriptionStr(NormType.EN_12503)} />
                      </span>
                    ),
                  },
                  {
                    value: NormType.EN_14960,
                    label: (
                      <span
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <Translated keyEn={normTypeDescriptionStr(NormType.EN_14960)} />
                      </span>
                    ),
                  },
                  {
                    value: NormType.EN_ISO_23659,
                    label: (
                      <span
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <Translated keyEn={normTypeDescriptionStr(NormType.EN_ISO_23659)} />
                      </span>
                    ),
                  },
                ]}
                onChange={(value) => {
                  setSelectedNormTypeToChange(value)
                }}
              />
              <Button
                onClick={() => {
                  setNormType(selectedNormTypeToChange)
                  antdMessageCtx.info(
                    <>
                      Changed norm type to{" "}
                      <span
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <b>{normTypeDescriptionStr(selectedNormTypeToChange)}</b>
                      </span>
                    </>,
                  )
                }}
              >
                Apply
              </Button>
            </FlexRow>
          )}
          {methodType !== null && methodType !== MethodTypeEN1177.UNKNOWN_METHOD_TYPE_EN_1177 && (
            <FlexRow>
              <Select
                value={selectedMethodTypeToChange}
                style={{
                  minWidth: "15rem",
                }}
                options={[
                  {
                    value: MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION,
                    label: (
                      <span
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        Method 1:{" "}
                        <Translated
                          keyEn={methodDescriptionStr(
                            MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION,
                          )}
                        />
                      </span>
                    ),
                  },
                  {
                    value: MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE,
                    label: (
                      <span
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        Method 2:{" "}
                        <Translated
                          keyEn={methodDescriptionStr(
                            MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE,
                          )}
                        />
                      </span>
                    ),
                  },
                ]}
                onChange={(value) => {
                  setSelectedMethodTypeToChange(value)
                }}
              />
              <Button
                onClick={() => {
                  setMethodType(selectedMethodTypeToChange)
                  antdMessageCtx.info(
                    <>
                      Changed method type to{" "}
                      <span
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <b>{methodDescriptionStr(selectedMethodTypeToChange)}</b>
                      </span>
                    </>,
                  )
                }}
              >
                Apply
              </Button>
            </FlexRow>
          )}
        </FlexCol>
        {/* Annexes */}
        <UnderlinedSectionTitle>
          <Translated keyEn="Annexes" />
        </UnderlinedSectionTitle>
        <div>
          <FlexCol
            style={{
              alignItems: "start",
              gap: 0,
            }}
          >
            {memoAnnexesElements}
            <Button
              type="primary"
              size="large"
              onClick={onAddAnnex}
              style={{
                width: 200,
              }}
              icon={<FileAddOutlined />}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
              >
                <Translated keyEn="Add annex" />
              </span>
            </Button>
          </FlexCol>
        </div>
        {/* Delete & Save */}
        <FlexRow
          style={{
            alignItems: "center",
            alignSelf: "flex-end",
          }}
        >
          <Popconfirm
            title={
              <>
                <Translated keyEn="Are you sure you want to delete this site" />?
              </>
            }
            onConfirm={onDelete}
          >
            <Button type="link" danger icon={<DeleteOutlined />} size="small">
              <span
                style={{
                  textTransform: "uppercase",
                  fontSize: "0.8rem",
                }}
              >
                <Translated keyEn="Delete" />
              </span>
            </Button>
          </Popconfirm>
          <div
            style={{
              width: 200,
            }}
          >
            <Button
              type="primary"
              icon={<SaveOutlined />}
              size="large"
              block
              disabled={!memoSiteIsMutated}
              onClick={onUpdate}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
              >
                <Translated keyEn="Save" />
              </span>
            </Button>
          </div>
          <div
            style={{
              width: 200,
            }}
          >
            <Link to={`/report/${memoSiteUUID ? pbUUIDToUuid(memoSiteUUID) : null}`}>
              <Button type="primary" icon={<FilePdfOutlined />} size="large" block>
                <span
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <Translated keyEn="Report" />
                </span>
              </Button>
            </Link>
          </div>
        </FlexRow>
        <EntityTimestamps entity={site} />
      </FlexCol>
      <DataTreeDrawer site={site} selectedKey={memoSiteUUID ? pbUUIDToUuid(memoSiteUUID) : null} />
    </>
  )
}
