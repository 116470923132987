import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  loadLocalStorageLanguageCode,
  loadLocalStorageTranslations,
  saveLocalStorageLanguageCode,
  saveLocalStorageTranslations,
} from "../utils/localStorage"
import { callCloudApiV2 } from "../utils/cloudApiV2"
import { ICloudTranslation } from "../types"

interface TranslationState {
  languageCode: string
  isAdminMode: boolean
}

interface TranslationContextType extends TranslationState {
  setLanguageCode: (languageCode: string) => void
  setIsAdminMode: (isAdminMode: boolean) => void
  translated: (keyEn: string | null) => string | null
  translationExists: (keyEn: string | null) => boolean
  addTranslation(keyEn: string | null, translation: string): void
}

const TranslationContext = createContext<TranslationContextType | undefined>(undefined)

export const useTranslationContext = (): TranslationContextType => {
  let context = useContext(TranslationContext)
  if (context === undefined) {
    throw new Error("useTranslationContext must be used within a TranslationProvider")
  }
  return context
}

type TranslationMap = Record<string, ICloudTranslation>

export const TranslationProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  const [languageCode, setLanguageCode] = useState(loadLocalStorageLanguageCode())
  const [currentTranslationMap, setCurrentTranslationMap] = useState<TranslationMap | null>(null)
  const [isAdminMode, setIsAdminMode] = useState(false)

  const missingTranslationsRef = useRef<Record<string, ICloudTranslation>>({})

  // Fetch translations on languageCode change
  useEffect(() => {
    setCurrentTranslationMap(loadLocalStorageTranslations(languageCode))
    callCloudApiV2<ICloudTranslation[]>(`/translations?language=${languageCode}`).then(
      ({ entity: translations }) => {
        if (translations === null) {
          console.warn(
            `TranslationsProvider: fetch translations for language code ${languageCode}: translations is null`,
          )
          return
        }
        let _translationMap: TranslationMap = {}
        translations.forEach((translation) => {
          _translationMap[translation.KeyEN] = translation
        })
        // UPD 2024-12-15: cache translations in local storage just in case the client goes offline
        saveLocalStorageTranslations(languageCode, _translationMap)
        setCurrentTranslationMap(_translationMap)
        missingTranslationsRef.current = {}
      },
    )
  }, [languageCode])

  const setLanguageCodeCB = useCallback(
    (languageCode: string) => {
      setLanguageCode(languageCode)
      saveLocalStorageLanguageCode(languageCode)
    },
    [setLanguageCode],
  )
  useEffect(() => {
    const localLanguageCode = loadLocalStorageLanguageCode()
    if (localLanguageCode !== null) {
      setLanguageCode(localLanguageCode)
    }
  }, [])

  // Handle non-existent translations: upsert them to the backend
  const upsertTranslationsCB = useCallback(async (translations: ICloudTranslation[]) => {
    console.log(`TranslationsProvider: about to upsert ${translations.length} translations`)
    callCloudApiV2<ICloudTranslation[]>("/translations", {
      method: "PUT",
      body: JSON.stringify(translations),
    })
  }, [])

  useEffect(() => {
    let t = setInterval(() => {
      const newTranslations = Object.values(missingTranslationsRef.current)
      if (newTranslations.length === 0) {
        return
      }
      upsertTranslationsCB(newTranslations).then(() => {
        console.log(`TranslationsProvider: upserted ${newTranslations.length} missing translations`)
        missingTranslationsRef.current = {}
      })
    }, 10e3)
    return () => {
      clearInterval(t)
    }
  }, [])

  // Translation functions
  const translated = useCallback(
    (keyEn: string | null) => {
      if (keyEn === null) {
        return null
      }
      if (
        currentTranslationMap === null ||
        currentTranslationMap[keyEn] === undefined ||
        currentTranslationMap[keyEn]!.Value === undefined
      ) {
        missingTranslationsRef.current[keyEn] = {
          UUID: null,
          Language: languageCode,
          KeyEN: keyEn,
          Value: "",
          Description: "",
        }
        return keyEn
      }
      if (currentTranslationMap[keyEn]?.Value === "") {
        // empty string is an invalid translation
        // but we dont want to add it to missing translations again
        return keyEn
      }
      // console.debug(
      //     `Translated: ${keyEn} -> ${currentTranslationMap[keyEn].Value}`,
      // )
      return currentTranslationMap[keyEn]?.Value || keyEn
    },
    [languageCode, currentTranslationMap],
  )

  const translationExists = useCallback(
    (keyEn: string | null) => {
      if (keyEn === null || currentTranslationMap === null) {
        return false
      }
      if (
        currentTranslationMap[keyEn] === undefined ||
        currentTranslationMap[keyEn]!.Value === undefined ||
        currentTranslationMap[keyEn]!.Value === ""
      ) {
        return false
      }
      return true
    },
    [currentTranslationMap],
  )

  const addTranslation = useCallback(
    (keyEn: string | null, translationValue: string) => {
      // Update translation
      if (currentTranslationMap === null || keyEn === null) {
        return
      }
      let translation = currentTranslationMap[keyEn]
      if (translation === undefined) {
        translation = {
          UUID: null, // UUID will be generated by the backend for new translations
          Language: languageCode,
          KeyEN: keyEn,
          Value: translationValue,
          Description: "",
        }
      }
      translation.Value = translationValue
      setCurrentTranslationMap({ ...currentTranslationMap, [keyEn]: translation })
      // Update backend
      upsertTranslationsCB([translation]).then(() => {
        console.log(`TranslationsProvider: translation ${keyEn} updated`)
      })
    },
    [languageCode, currentTranslationMap],
  )

  return (
    <TranslationContext.Provider
      value={{
        languageCode,
        isAdminMode,

        setLanguageCode: setLanguageCodeCB,
        setIsAdminMode,

        translated,
        translationExists,
        addTranslation,
      }}
    >
      {children}
    </TranslationContext.Provider>
  )
}
