import { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import {
  COLOR_BG_BLUE,
  COLOR_BG_GRAY,
  colorHexToRgba,
  parsePathForEntityUUID,
  pbUUIDToUuid,
  uuidToPbUUID,
} from "../utils/utils"
import { Translated } from "../utils/translated"
import {
  Annex,
  Equipment,
  Impact,
  MethodTypeEN1177,
  NormType,
  Site,
  UUID,
  Zone,
} from "../generated/proto-ts/main"
import { Col, Divider, FloatButton, Popover, Row, Spin, Typography } from "antd"
import {
  useUsercommSiteBLE,
  useUsercommSiteChildrenRecursiveBLE,
} from "../usercomm/local/ble/usercommAsyncRequestBLE"
import {
  EResultTagAdequacy,
  FlexCol,
  FlexRow,
  RequirementsAlertSM,
} from "../components/commons-ts/common"
import { callCloudApiV2 } from "../utils/cloudApiV2"
import {
  ECloudDeviceEventType,
  ICloudDevice,
  ICloudDeviceEvent,
  ICloudUser,
  ICoordinate,
  IImpactPoints,
  MeanWithStd,
} from "../types"
import {
  FilePdfOutlined,
  GlobalOutlined,
  LeftCircleOutlined,
  SignatureOutlined,
} from "@ant-design/icons"
import { sportsMatTypeDescriptionStr } from "../components/commons-ts/tags"
import { getEquipmentSportsMatThicknessMeanAndStd } from "./02_Equipment"

import { EquipmentZonesTableSM_Print } from "./Tables/02_EquipmentZones/EquipmentZonesTable_SM"
import { getRequirementsSM } from "../calculus/calculus_SM"
import { ReportConfigProvider, useReportConfigContext } from "../providers/reportConfigProvider"
import { ImageUploader } from "../components/report-ts/reportImageUploader"
import {
  PDFInputTextArea,
  PDFInput,
  PDFHidableSpan,
  PDFHidableDiv,
  PDFControlledHidableSpan,
  PDFControlledInput,
} from "../components/report-ts/reportInputs"
import { FirstPicture, PicturesRow } from "../components/report-ts/reportPicturesRow"
import {
  useUsercommImpactBimodal,
  useUsercommSiteAnnexesBimodal,
  useUsercommSiteBimodal,
  useUsercommSiteChildrenRecursiveBimodal,
} from "../usercomm/common/usercommAsyncRequestBimodal"
import { FOOTER_HEIGHT, HEADER_HEIGHT } from "../components/report-ts"
import { useTranslationContext } from "../providers/translationProvider"
import { PDFEmptySpacePlaceholder } from "../components/report-ts/reportEmptySpacePlaceholder"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SiteEquipmentsTableMetadataPF_Print } from "./Tables/01_SiteEquipments/SiteEquipmentsTable_PF"
import { SiteEnvironmentalConditionsTable_PF } from "./Tables/00_SiteEnvironmentalConditions/00_SiteEnvironmentalConditionsTable_PF"

import dayjs from "dayjs"
import {
  EquipmentZonesTablePF_ADQ_Print,
  EquipmentZonesTablePF_CFH_Print,
  IZoneProcessed_ADQ,
  IZoneProcessed_CFH,
} from "./Tables/02_EquipmentZones/EquipmentZonesTable_PF"
import { EquipmentZonesTableTL_Print } from "./Tables/02_EquipmentZones/EquipmentZonesTable_TL"
import {
  ZoneImpactsTablePF_ADQ,
  ZoneImpactsTablePF_ADQ_Print,
  ZoneImpactsTablePF_CFH_Print,
} from "./Tables/03_ZoneImpacts/ZoneImpactsTable_PF"
import { ImpactChartRawAcceleration, ZoneChartPF_CFH } from "../components/commons-ts/uplotCharts"
import {
  decodeImpactDataPoints,
  offsetImpactDataPointsInplace,
} from "../usercomm/common/usercommUtils"
import { ZoneChartPF_ADQ } from "../components/commons-ts/simpleCharts"
import { StatEnvelope } from "../utils/statEnvelope"
import {
  getCFH_Global,
  getEquipmentResultPF_CFH,
  getSiteResultPF_CFH,
  getZoneResultPF_Adq,
  getZoneResultPF_CFH,
} from "../calculus/calculus_PF"
import { TranslationLanguageSelectWidget } from "../components/commons-ts/translationLanguageSelectWidget"
import { GoogleAddressWidget } from "../components/commons-ts/googleMapsApi/googleAddress"
import { EResult } from "../calculus/types"
import { decimalCoordinateToDegreesMinutesSeconds } from "./Settings/GNSSUbloxSettings"

const PDF_INPUT_TEXT_AREA_STYLE = {
  fontSize: 14,
}

const PDFHeader: FC<{
  site: Site | null
  user: ICloudUser | null
}> = ({ site, user }) => {
  const {
    leftDataURL,
    rightHeaderReferenceNote,
    rightHeaderDateNote,
    rightHeaderVersionNote,
    rightHeaderVersionSubscriptIsVisible,
    setLeftDataURL,
    setRightHeaderReferenceNote,
    setRightHeaderDateNote,
    setRightHeaderVersionNote,
    setRightHeaderVersionSubscriptIsVisible,
  } = useReportConfigContext()

  useEffect(() => {
    if (!site) {
      return
    }
    let ref = site.mission_name + " " + site.client_name + " " + site.site_name
    // ref = ref.replaceAll(" ", "_")
    setRightHeaderReferenceNote(ref)
  }, [site])

  useEffect(() => {
    if (site === null) {
      return
    }
    let normTypeStr = ""
    switch (site.norm_type) {
      case NormType.EN_1177:
        normTypeStr = "EN-1177"
        break
      case NormType.EN_12503:
        normTypeStr = "EN-12503"
        break
      case NormType.EN_14960:
        normTypeStr = "EN-14960"
        break
      case NormType.EN_ISO_23659:
        normTypeStr = "EN-ISO-23659"
        break
    }
    let methodTypeStr = ""
    switch (site.method_type_en_1177) {
      case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
        methodTypeStr = "1-CFH"
        break
      case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
        methodTypeStr = "2-ADQ"
        break
    }

    let documentName = `${rightHeaderDateNote}_${normTypeStr}_${methodTypeStr}_${rightHeaderReferenceNote}_v${rightHeaderVersionNote}`
    document.title = window.parent.document.title = documentName
  }, [site, rightHeaderDateNote, rightHeaderReferenceNote, rightHeaderVersionNote])

  useEffect(() => {
    setRightHeaderDateNote(dayjs().format("YYYY-MM-DD"))
    setRightHeaderVersionNote("1.0")
    setRightHeaderVersionSubscriptIsVisible(true)
  }, [])

  const memoEnterpriseLogoDataUrl = useMemo(() => {
    if (!user) {
      return null
    }
    if (user.Enterprise === null || user.Enterprise.EnterprisePictureUUID === null) {
      return null
    }
    return `/api/uploads/${user.Enterprise.EnterprisePictureUUID}`
  }, [user])

  return (
    <header
      style={{
        borderBottom: `3px solid #ccc`,
        fontSize: "0.9rem",
        textAlign: "center",
      }}
    >
      <FlexRow
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          height: HEADER_HEIGHT,
          marginBottom: 5,
        }}
      >
        <ImageUploader
          dataURL={memoEnterpriseLogoDataUrl ?? leftDataURL}
          setDataURL={setLeftDataURL}
        />
        <FlexCol
          style={{
            alignItems: "end",
            gap: 0,
            width: "100%",
          }}
        >
          <span>
            <Translated keyEn="Ref." />:{" "}
            <b>
              <PDFControlledInput
                value={rightHeaderReferenceNote ?? ""}
                setValue={(v) => setRightHeaderReferenceNote(v?.toString() ?? "")}
                inputProps={{
                  style: { width: "fit-content", textAlign: "end" },
                }}
              />
            </b>
          </span>
          <span>
            <Translated keyEn="Edited on" />:{" "}
            <b>
              <PDFControlledInput
                value={rightHeaderDateNote ?? ""}
                setValue={(v) => setRightHeaderDateNote(v?.toString() ?? "")}
                inputProps={{
                  style: { width: "fit-content", textAlign: "end" },
                }}
              />
            </b>
          </span>
          <span>
            <Translated keyEn="Version" />:{" "}
            <b>
              <PDFControlledInput
                value={rightHeaderVersionNote ?? ""}
                setValue={(v) => setRightHeaderVersionNote(v?.toString() ?? "")}
                inputProps={{
                  style: { width: "fit-content", textAlign: "end" },
                }}
              />
            </b>
          </span>
          <span style={{ fontSize: "0.7rem" }}>
            <i>
              <PDFControlledHidableSpan
                isHidden={!rightHeaderVersionSubscriptIsVisible}
                setIsHidden={(isHidden) => setRightHeaderVersionSubscriptIsVisible(!isHidden)}
              >
                <Translated keyEn="This version replaces and cancels all the previous ones" />
              </PDFControlledHidableSpan>
            </i>
          </span>
        </FlexCol>
      </FlexRow>
    </header>
  )
}

const PDFFootnoteTextArea: FC<{
  footnote: string | null
  setFootnote: (footnote: string | null) => void
  textAlign: "center" | "left" | "right"
}> = ({ footnote, setFootnote, textAlign }) => {
  return (
    <span
      style={{
        textAlign: "center",
        width: "100%",
      }}
    >
      <PDFInputTextArea
        style={{
          textAlign,
        }}
        autoSize={{
          minRows: 2,
          maxRows: 2,
        }}
        value={footnote ?? ""}
        onChange={(e) => {
          setFootnote(e.target.value)
        }}
      />
    </span>
  )
}

const PDFFooter: FC<{
  pageNumber: number
  totalPages: number
}> = ({ pageNumber, totalPages }) => {
  const { translated } = useTranslationContext()
  const { leftFootnote, rightFootnote, setLeftFootnote, setRightFootnote } =
    useReportConfigContext()

  useEffect(() => {
    if (rightFootnote === "") {
      setRightFootnote(
        translated(
          "Reproduction of this inspection report is only permitted in the form of a complete photographic facsimile",
        ),
      )
    }
  }, [])

  return (
    <footer
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        marginLeft: "8mm",
        marginRight: "8mm",
        width: "calc(100% - 16mm)",
        height: FOOTER_HEIGHT,
        borderTop: `3px solid #ccc`,
        paddingTop: 5,
      }}
    >
      <FlexRow
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "0.7rem",
        }}
      >
        <PDFFootnoteTextArea
          footnote={leftFootnote}
          setFootnote={setLeftFootnote}
          textAlign="left"
        />
        <p style={{ width: "fit-content", whiteSpace: "nowrap" }}>
          Page {pageNumber} of {totalPages}
        </p>
        <PDFFootnoteTextArea
          footnote={rightFootnote}
          setFootnote={setRightFootnote}
          textAlign="right"
        />
      </FlexRow>
    </footer>
  )
}

const PDFPage: FC<{
  site: Site | null
  user: ICloudUser | null
  pageNumber: number
  totalPages: number
  children: any
}> = ({ site, user, pageNumber, totalPages, children }) => {
  return (
    <section
      className="sheet p-8mm"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      <PDFHeader site={site} user={user} />
      {children}
      <PDFFooter pageNumber={pageNumber} totalPages={totalPages} />
    </section>
  )
}

// PAGE CONTENTS

const FirstPageContent: FC<{
  user: ICloudUser | null
  site: Site
  globalResult: EResult | null
}> = ({ user, site, globalResult }) => {
  const { translated } = useTranslationContext()

  const {
    leftSignatureDataURL,
    rightSignatureDataURL,
    leftSignatureName,

    setLeftSignatureDataURL,
    setRightSignatureDataURL,
    setLeftSignatureName,
  } = useReportConfigContext()

  const memoSitePicture = useMemo(() => {
    if (!site.pictures) {
      return <></>
    }
    let pictureUUID = site.pictures[0]
    if (!pictureUUID) {
      return <></>
    }
    return (
      <img
        key={pbUUIDToUuid(pictureUUID)}
        className="max-h-80mm object-contain border-2 border-primary-dark"
        style={{
          maxWidth: "80%",
        }}
        src={`/api/uploads/${pbUUIDToUuid(pictureUUID)}`}
        alt="site"
      />
    )
  }, [site])

  const memoUserFullName = useMemo(() => {
    if (!user) {
      return <PDFInput />
    }
    return <PDFInput key={Math.random()} defaultValue={user.FirstName + " " + user.LastName} />
  }, [user])

  const memoUserPhoneNumber = useMemo(() => {
    if (!user) {
      return <PDFInput />
    }
    return <PDFInput key={Math.random()} defaultValue={user.PhoneNumber} />
  }, [user])

  const memoUserEmail = useMemo(() => {
    if (!user) {
      return <PDFInput />
    }
    return <PDFInput key={Math.random()} defaultValue={user.Email} />
  }, [user])

  const memoUserEnterpriseCofracPictureUrl = useMemo((): string | null => {
    if (!user || !user.Enterprise || !user.Enterprise.CofracPictureUUID) {
      return null
    }
    return `/api/uploads/${user.Enterprise.CofracPictureUUID}`
  }, [user])

  const memoUserEnterpriseCofracLegalNotice = useMemo((): string | null => {
    if (!user || !user.Enterprise) {
      return null
    }
    return user.Enterprise.CofracLegalNotice
  }, [user])

  const memoSignaturesRow = useMemo(() => {
    let userPDFInput = (
      <PDFInput
        key={"nouser"}
        defaultValue={"   "}
        style={{
          width: "100%",
          textAlign: "center",
        }}
      />
    )
    if (user !== null) {
      userPDFInput = (
        <PDFInput
          key={user.UUID}
          defaultValue={user?.FirstName + " " + user?.LastName}
          style={{
            width: "fit-content",
            textAlign: "center",
          }}
        />
      )
    }

    return (
      <Row justify="space-between">
        <Col xs={12}>
          <FlexCol
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ width: "100%", textAlign: "center" }}>
              <Translated keyEn="Head of inspection" />
            </span>
            <FlexCol style={{ alignItems: "center" }}>
              <ImageUploader
                dataURL={leftSignatureDataURL}
                setDataURL={setLeftSignatureDataURL}
                size={50}
                icon={
                  <SignatureOutlined
                    className="pdf-invisible"
                    style={{ fontSize: "2rem", color: COLOR_BG_GRAY }}
                  />
                }
              />
              <PDFControlledInput
                value={leftSignatureName ?? ""}
                setValue={(v) => setLeftSignatureName(v?.toString() ?? "")}
                inputProps={{
                  style: {
                    width: "fit-content",
                    textAlign: "center",
                  },
                }}
              />
            </FlexCol>
          </FlexCol>
        </Col>
        <Col xs={12}>
          <FlexCol
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Translated keyEn="Inspector" />
            <FlexCol style={{ alignItems: "center" }}>
              <ImageUploader
                dataURL={rightSignatureDataURL}
                setDataURL={setRightSignatureDataURL}
                size={50}
                icon={
                  <SignatureOutlined
                    className="pdf-invisible"
                    style={{ fontSize: "2rem", color: COLOR_BG_GRAY }}
                  />
                }
              />
              {userPDFInput}
            </FlexCol>
          </FlexCol>
        </Col>
      </Row>
    )
  }, [
    user,
    leftSignatureDataURL,
    rightSignatureDataURL,
    leftSignatureName,
    setLeftSignatureDataURL,
    setRightSignatureDataURL,
    setLeftSignatureName,
  ])

  const memoCofracRow = useMemo(() => {
    if (
      memoUserEnterpriseCofracPictureUrl === null ||
      memoUserEnterpriseCofracLegalNotice === null
    ) {
      return null
    }
    return (
      <FlexRow
        style={{
          justifyContent: "center",
          alignItems: "end",
          gap: 0,
        }}
      >
        <ImageUploader
          dataURL={memoUserEnterpriseCofracPictureUrl}
          setDataURL={() => null}
          size={50}
        />
        <PDFInputTextArea
          defaultValue={memoUserEnterpriseCofracLegalNotice}
          autoSize
          style={{
            fontSize: "0.7rem",
            marginLeft: 0,
          }}
        />
      </FlexRow>
    )
  }, [memoUserEnterpriseCofracLegalNotice, memoUserEnterpriseCofracPictureUrl])

  const memoMustBeTranslated = useCallback(
    (value: string) => {
      return translated(value) ?? ""
    },
    [translated],
  )

  const memoClientText = useMemo(() => {
    let text = "Address" + "\n"
    text += "Postal Code - City" + "\n"
    text += "Country"
    return text
  }, [site])

  return (
    <FlexCol
      style={{
        justifyContent: "space-between",
        alignItems: "start",
        height: `100%`,
        paddingBottom: FOOTER_HEIGHT,
      }}
    >
      {/* Client coordinates */}
      <FlexCol
        style={{
          alignItems: "start",
          gap: 0,
        }}
      >
        <span style={{ fontSize: "1rem", fontWeight: "bold", marginTop: 25, marginLeft: 2 }}>
          <Translated keyEn="Delivered to" />:
        </span>
        <b>
          <PDFInput defaultValue={site.client_name} />
        </b>
        <PDFInputTextArea
          defaultValue={memoClientText}
          autoSize
          style={{
            width: "fit-content",
            minWidth: "30%",
          }}
        />
      </FlexCol>
      {/* Names: mission, client, site */}
      <FlexCol
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 0,
        }}
      >
        {/* Mission */}
        <FlexRow
          style={{
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "1.7rem",
          }}
        >
          <span>
            <Translated keyEn="Mission" />:
          </span>
          <span>
            <PDFInput
              defaultValue={site.mission_name}
              style={{
                width: "fit-content",
              }}
            />
          </span>
        </FlexRow>
        {/* Site */}
        <FlexRow
          style={{
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "1.3rem",
            borderBottom: `2px solid black`,
          }}
        >
          <span>
            <Translated keyEn="Site" />:
          </span>
          <span>
            <PDFInput
              defaultValue={site.site_name}
              style={{
                width: "fit-content",
              }}
            />
          </span>
        </FlexRow>
        <Divider />
        <Row gutter={[10, 10]} justify="center" style={{ width: "100%" }}>
          <Col xs={14}>
            {/* Details and contact */}
            <FlexCol
              style={{
                gap: 0,
              }}
            >
              <FlexCol>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  <Translated keyEn="Site address" />:
                </span>
                <PDFInputTextArea defaultValue={site.address} autoSize />
              </FlexCol>
              <FlexRow
                style={{
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  <Translated keyEn="Inspection date" />:
                </span>
                <PDFInput defaultValue={new Date(site.execution_date).toLocaleDateString()} />
              </FlexRow>
              <span style={{ fontWeight: "bold", marginTop: 25, marginBottom: 5 }}>
                <Translated keyEn="Inspected by" />:
              </span>
              <FlexRow
                style={{
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  <Translated keyEn="Inspector" />:
                </span>
                {memoUserFullName}
              </FlexRow>
              <FlexRow
                style={{
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  <Translated keyEn="Email" />:{" "}
                </span>
                {memoUserEmail}
              </FlexRow>
              <FlexRow
                style={{
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  <Translated keyEn="Phone" />:{" "}
                </span>
                {memoUserPhoneNumber}
              </FlexRow>
            </FlexCol>
          </Col>
          <Col xs={10}>
            {/* Photo */}
            {memoSitePicture}
          </Col>
        </Row>
      </FlexCol>
      <FlexRow
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span
          style={{
            whiteSpace: "nowrap",
            fontWeight: "bold",
          }}
        >
          <Translated keyEn="Global result" />:{" "}
        </span>
        <EResultTagAdequacy result={globalResult} />
      </FlexRow>
      <Row justify="space-between" align="bottom" style={{ width: "100%" }}>
        <Col xs={12}>{memoCofracRow}</Col>
        <Col xs={12}>{memoSignaturesRow}</Col>
      </Row>
    </FlexCol>
  )
}

const ReportNumberedTitle: FC<{ title: ReactElement; number: number }> = ({ title, number }) => {
  return (
    <Typography.Text style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
      {number}. {title}
    </Typography.Text>
  )
}

const SecondPageContent: FC<{
  user: ICloudUser | null
  device: ICloudDevice | null
  deviceEvents: ICloudDeviceEvent[] | null
  site: Site
  siteEquipmentsMap: Record<string, Equipment[]> | null
  equipmentZonesMap: Record<string, Zone[]> | null
  zoneImpactsMap: Record<string, Impact[]> | null
}> = ({
  user,
  device,
  deviceEvents,
  site,
  siteEquipmentsMap,
  equipmentZonesMap,
  zoneImpactsMap,
}) => {
  const { translated } = useTranslationContext()

  const memoEquipments = useMemo(() => {
    let _equipments: Equipment[] = []
    for (let siteUUID in siteEquipmentsMap) {
      let equipments = siteEquipmentsMap[siteUUID]
      if (equipments === undefined) {
        continue
      }
      _equipments.push(...equipments)
    }
    return _equipments
  }, [siteEquipmentsMap])

  const memoNormType = useMemo((): string => {
    switch (site.norm_type) {
      case NormType.EN_1177:
        return "EN 1177"
      case NormType.EN_12503:
        return "EN 12503"
      case NormType.EN_14960:
        return "EN 14960"
      case NormType.EN_ISO_23659:
        return "EN ISO 23659"
      default:
        return ""
    }
  }, [site])

  const memoMethodTypeEN1177 = useMemo((): string => {
    switch (site.method_type_en_1177) {
      case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
        return "Method 1: Critical Fall Height"
      case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
        return "Method 2: Adequacy"
      default:
        return ""
    }
  }, [site])

  const memoMustBeTranslated = useCallback(
    (value: string) => {
      return translated(value) ?? ""
    },
    [translated],
  )

  const memoIntroductionText = useMemo((): string => {
    // norm
    let text =
      memoMustBeTranslated(
        "The following report presents the results of the tests carried out on the site",
      ) +
      ` "${site.site_name}" ` +
      memoMustBeTranslated("in accordance with the norm") +
      " " +
      memoNormType

    //method if applicable
    if (memoMethodTypeEN1177 !== "") {
      text +=
        " " +
        memoMustBeTranslated("using the") +
        " " +
        `${memoMustBeTranslated(memoMethodTypeEN1177)}`
    }

    // date
    text +=
      " " + memoMustBeTranslated("on") + " " + dayjs(site.execution_date).format("DD-MM-YYYY") + "."

    // reference procedure
    text += "\n"
    text += memoMustBeTranslated("Reference procedure: N/A")

    return text
  }, [memoMustBeTranslated, memoNormType, memoMethodTypeEN1177])

  const memoMethodologyText = useMemo((): string => {
    let text = memoMustBeTranslated(
      "The tests were carried out in accordance with the following procedure.",
    )
    text += "\n"
    switch (site.norm_type) {
      case NormType.EN_1177:
        text += `${memoMustBeTranslated("EN 1177")}: ${memoMustBeTranslated("Impact absorbing playground floors: impact attenuation test method.")} `
        switch (site.method_type_en_1177) {
          case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
            text += memoMustBeTranslated(
              `Determination of the critical fall height of an equipment consists of measuring values of Gmax and HIC by falling an impactor at different fall heights. A dependency curve of HIC/Gmax over fall height is found. Critical fall height (CFH) is defined as the height at which either Gmax or HIC curve meet their corresponding limits: 200g or 1000 HIC.`,
            )
            break
          case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
            text += memoMustBeTranslated(
              `Impact attenuation compliance consists of measuring values of Gmax and HIC by falling an impactor at a fixed fall height. The values are compared to those declared by the manufacturer.`,
            )
            break
        }
        break
      case NormType.EN_12503:
        text += `${memoMustBeTranslated("EN 12503")}: ${memoMustBeTranslated("Sports mats: determination of shock absorption.")} `
        text += memoMustBeTranslated(
          `The test consists of dropping an impactor from a fixed height on the sports mat. Impact shock, retained energy and flexion are measured on different locations on the sports mat. Values are compared to those defined in the norm, according to sports mat type.`,
        )
        break
      case NormType.EN_14960:
        text += `${memoMustBeTranslated("EN 14960")}: ${memoMustBeTranslated("Inflatable play equipment: safety requirements and test methods.")} `
        text += memoMustBeTranslated(
          `The test consists of dropping an impactor from a fixed height on an airbag or inflatable structure. The impactor is equipped with an accelerometer to measure the deceleration. The test is repeated at different locations on the inflatable structure.`,
        )
        break
      case NormType.EN_ISO_23659:
        text += `${memoMustBeTranslated("EN ISO 23659")}: ${memoMustBeTranslated("Recreational and sports installations: trampoline parks - safety requirements and test methods.")} `
        text += memoMustBeTranslated(
          `The test consists of dropping an impactor from a fixed height on a pre-installed trampoline. Impact shock, re-bounce height are measured and trampoline's "Performance Factor" is calculated using the approach defined in the norm.`,
        )
        break
    }
    return text
  }, [memoMustBeTranslated, site])

  const memoReferenceEquipmentInput = useMemo((): ReactElement | null => {
    if (device === null) {
      return <PDFInputTextArea defaultValue={"  "} autoSize />
    }
    let text = memoMustBeTranslated("Tests were performed using the equipment: ")
    text += `\"${device.DeviceType?.Name}\" S/N: ${device.Name} (${device.SerialNumber})` + "."
    if (device.LastMetrology !== null && device.NextMetrology !== null) {
      text +=
        " " +
        memoMustBeTranslated("Metrology valid from") +
        " " +
        dayjs(device.LastMetrology).format("DD-MM-YYYY") +
        " " +
        memoMustBeTranslated("to") +
        " " +
        dayjs(device.NextMetrology).format("DD-MM-YYYY") +
        "."
    }
    if (deviceEvents !== null) {
      let lastMetrologyEvent: ICloudDeviceEvent | null = null
      for (let event of deviceEvents) {
        if (event.EventType === ECloudDeviceEventType.Metrology) {
          lastMetrologyEvent = event
          break
        }
      }
      if (lastMetrologyEvent !== null) {
        text +=
          " " +
          memoMustBeTranslated("Metrology reference") +
          ": " +
          "EVE" +
          lastMetrologyEvent.ID.toString().padStart(4, "0") +
          " " +
          memoMustBeTranslated("of") +
          " " +
          dayjs(lastMetrologyEvent.StartDate).format("DD-MM-YYYY") +
          "."
        for (let file of lastMetrologyEvent.Files) {
          text += `\n\t• ${file.OriginalName}`
        }
      }
    }
    // console.log(`SecondPageContent: memoReferenceEquipmentText`, text)
    return <PDFInputTextArea key={Math.random()} defaultValue={text} autoSize />
  }, [memoMustBeTranslated, device, deviceEvents])

  return (
    <FlexCol
      style={{
        marginTop: 30,
        justifyContent: "start",
        height: `calc(100% - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
      }}
    >
      {/* Introduction */}
      <ReportNumberedTitle number={1} title={<Translated keyEn="Introduction" />} />
      <div style={PDF_INPUT_TEXT_AREA_STYLE}>
        <PDFInputTextArea
          defaultValue={memoIntroductionText}
          autoSize={{
            minRows: 3,
          }}
        />
      </div>
      {/* Identification */}
      <ReportNumberedTitle number={2} title={<Translated keyEn="Identification" />} />
      <div style={PDF_INPUT_TEXT_AREA_STYLE}>
        <PDFInputTextArea
          defaultValue={memoMustBeTranslated("Tests were carried out on the following equipment")}
          autoSize
        />
      </div>
      <SiteEquipmentsTableMetadataPF_Print
        siteUUID={site.uuid}
        equipments={memoEquipments}
        equipmentZonesMap={equipmentZonesMap}
        zoneImpactsMap={zoneImpactsMap}
      />
      {/* Methodology */}
      <ReportNumberedTitle number={3} title={<Translated keyEn="Methodology" />} />
      <div style={PDF_INPUT_TEXT_AREA_STYLE}>
        <PDFInputTextArea defaultValue={memoMethodologyText} autoSize />
      </div>
      {/* Reference equipment */}
      <ReportNumberedTitle number={4} title={<Translated keyEn="Reference equipment" />} />
      <div style={PDF_INPUT_TEXT_AREA_STYLE}>{memoReferenceEquipmentInput}</div>
    </FlexCol>
  )
}

const ThirdPageContent: FC<{
  user: ICloudUser | null
  device: ICloudDevice | null
  site: Site
  siteEquipmentsMap: Record<string, Equipment[]> | null
  equipmentZonesMap: Record<string, Zone[]> | null
  zoneImpactsMap: Record<string, Impact[]> | null
}> = ({ user, device, site, siteEquipmentsMap, equipmentZonesMap, zoneImpactsMap }) => {
  const { translated } = useTranslationContext()

  const [coordinates, setCoordinates] = useState<ICoordinate | null>(null)

  const memoEquipments = useMemo(() => {
    let _equipments: Equipment[] = []
    for (let siteUUID in siteEquipmentsMap) {
      let equipments = siteEquipmentsMap[siteUUID]
      if (equipments === undefined) {
        continue
      }
      _equipments.push(...equipments)
    }
    return _equipments
  }, [siteEquipmentsMap])

  const memoImpactMarkerCoordinates = useMemo(() => {
    let _markers: { lat: number; lng: number }[] = []
    if (zoneImpactsMap === null) {
      return _markers
    }
    for (let [_, impacts] of Object.entries(zoneImpactsMap)) {
      for (let impact of impacts) {
        if (impact.latitude === 0 || impact.longitude === 0) {
          continue
        }
        _markers.push({
          lat: impact.latitude,
          lng: impact.longitude,
        })
      }
    }
    // console.log(`SitePage: memoImpactMarkerCoordinates`, _markers)
    return _markers
  }, [zoneImpactsMap])

  const memoMustBeTranslated = useCallback(
    (value: string) => {
      return translated(value) ?? ""
    },
    [translated],
  )

  const memoEnvironmentalConditionsText = useMemo((): string => {
    let text = ""
    // if (site.norm_type === NormType.EN_1177 && !site.is_lab_test) {
    // }
    text += memoMustBeTranslated(
      "This test was carried out on site with the particular climatic and site conditions occurring on the day of test. The results cannot be used to indicate the performance of the product under any other conditions or in other locations.",
    )
    return text
  }, [memoMustBeTranslated, site])

  const memoGeolocationInputText = useMemo(() => {
    let text = memoMustBeTranslated("The site is located at the following coordinates") + ": "
    // console.log(`SitePage: memoGeolocationInputText`, coordinates)
    if (coordinates !== null) {
      let latitudeLetter = "N"
      if (coordinates.coords.latitude < 0) {
        latitudeLetter = "S"
      }
      let longitudeLetter = "E"
      if (coordinates.coords.longitude < 0) {
        longitudeLetter = "W"
      }
      text += "\n"
      text += `• ${latitudeLetter} ${decimalCoordinateToDegreesMinutesSeconds(coordinates.coords.latitude)} (${coordinates.coords.latitude.toFixed(6)})\n`
      text += `• ${longitudeLetter} ${decimalCoordinateToDegreesMinutesSeconds(coordinates.coords.longitude)} (${coordinates.coords.longitude.toFixed(6)})`
    }
    return <PDFInputTextArea key={Math.random()} defaultValue={text} autoSize={{ minRows: 3 }} />
  }, [memoMustBeTranslated, coordinates])

  return (
    <FlexCol
      style={{
        marginTop: 30,
        justifyContent: "start",
        height: `calc(100% - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
      }}
    >
      {/* Environmental conditions */}
      <ReportNumberedTitle number={5} title={<Translated keyEn="Environmental conditions" />} />
      {memoEnvironmentalConditionsText && (
        <div style={PDF_INPUT_TEXT_AREA_STYLE}>
          <PDFInputTextArea
            defaultValue={memoEnvironmentalConditionsText}
            autoSize={{ minRows: 3 }}
          />
        </div>
      )}
      <SiteEnvironmentalConditionsTable_PF
        siteUUID={site.uuid}
        equipments={memoEquipments}
        equipmentZonesMap={equipmentZonesMap}
        zoneImpactsMap={zoneImpactsMap}
      />
      <ReportNumberedTitle number={6} title={<Translated keyEn="Geolocation" />} />
      <div style={PDF_INPUT_TEXT_AREA_STYLE}>{memoGeolocationInputText}</div>
      <GoogleAddressWidget
        address={site.address}
        setAddress={() => null}
        markerCoordinates={memoImpactMarkerCoordinates}
        autocompleteClassName="pdf-invisible"
        externalSetSelectedCoordinates={setCoordinates}
      />
      <div style={PDF_INPUT_TEXT_AREA_STYLE}>
        <PDFInputTextArea defaultValue={""} autoSize={{ minRows: 2 }} />
      </div>
    </FlexCol>
  )
}

const AnnexPageContent: FC<{
  user: ICloudUser | null
  site: Site
  annexes: Annex[]
}> = ({ user, site, annexes }) => {
  const { translated } = useTranslationContext()

  const memoMustBeTranslated = useCallback(
    (value: string) => {
      return translated(value) ?? ""
    },
    [translated],
  )

  return (
    <FlexCol
      style={{
        marginTop: 30,
        justifyContent: "start",
        height: `calc(100% - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
      }}
    >
      {annexes.map((annex, i) => {
        return (
          <>
            <div key={pbUUIDToUuid(annex.uuid)}>
              <h2>
                <Translated keyEn="Annex" /> {annex.annex_idx}
              </h2>
              <Row gutter={[10, 10]} justify="space-between">
                <Col xs={14}>
                  <div style={PDF_INPUT_TEXT_AREA_STYLE}>
                    <PDFInputTextArea
                      defaultValue={annex.annex_content}
                      autoSize={{ minRows: 3 }}
                    />
                  </div>
                </Col>
                <Col xs={10}>
                  <FirstPicture pictures={annex.pictures} />
                </Col>
              </Row>
            </div>
            {i === annexes.length - 1 ? <PDFEmptySpacePlaceholder /> : <Divider />}
          </>
        )
      })}
    </FlexCol>
  )
}

interface IEquipmentPageContentProps {
  user: ICloudUser | null
  site: Site
  equipment: Equipment
  equipmentZonesMap: Record<string, Zone[]> | null
  zoneImpactsMap: Record<string, Impact[]> | null
}

const EquipmentPageContentSM: FC<IEquipmentPageContentProps> = ({
  user,
  site,
  equipment,
  equipmentZonesMap,
  zoneImpactsMap,
}) => {
  const mooSyncRef = useRef<uPlot.SyncPubSub | null>(null)

  const memoSportsMatTypeDescription = useMemo(() => {
    if (equipment.sports_mat_type === null) {
      return ""
    }
    return `Type "${equipment.sports_mat_type}": ${sportsMatTypeDescriptionStr(equipment.sports_mat_type)}`
  }, [equipment])

  const memoSportsMatThicknessMeanAndStd = useMemo((): MeanWithStd | null => {
    return getEquipmentSportsMatThicknessMeanAndStd([
      equipment.sports_mat_thickness_side_one,
      equipment.sports_mat_thickness_side_two,
      equipment.sports_mat_thickness_side_three,
      equipment.sports_mat_thickness_side_four,
    ])
  }, [equipment])

  const memoEquipmentZones = useMemo(() => {
    if (equipmentZonesMap === null) {
      return []
    }
    return equipmentZonesMap[pbUUIDToUuid(equipment.uuid)] ?? []
  }, [equipmentZonesMap])

  const memoMostUnfavorableImpact = useMemo(() => {
    if (equipmentZonesMap === null || zoneImpactsMap === null) {
      return null
    }
    let zones = equipmentZonesMap[pbUUIDToUuid(equipment.uuid)]
    if (zones === undefined) {
      return null
    }
    let mostUnfavorableImpact: Impact | null = null
    let mostUnfavorableImpactValue = -1
    for (let zone of zones) {
      let impacts = zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
      if (impacts === undefined) {
        continue
      }
      for (let impact of impacts) {
        if (impact.impact_gmax > mostUnfavorableImpactValue) {
          mostUnfavorableImpact = impact
          mostUnfavorableImpactValue = impact.impact_gmax
        }
      }
    }
    return mostUnfavorableImpact
  }, [zoneImpactsMap])

  const memoImpactChartColStyle = useMemo(() => {
    return { height: 300, marginBottom: 50 }
  }, [])

  const memoRequirementsSM = useMemo(() => {
    if (equipment === null) {
      return null
    }
    let requirements = getRequirementsSM(equipment.sports_mat_type)
    return requirements
  }, [equipment])

  // const memoMostUnfavorableImpactChartsRow = useMemo(() => {
  //     if (
  //         memoMostUnfavorableImpact === null ||
  //         memoSportsMatThicknessMeanAndStd === null
  //     ) {
  //         return <></>
  //     }
  //     let smThickness = memoSportsMatThicknessMeanAndStd.mean

  //     if (memoRequirementsSM === null) {
  //         return null
  //     }
  //     return (
  //         <div style={{ width: "100%" }}>
  //             <Row justify="center">
  //                 <Col xs={8} style={memoImpactChartColStyle}>
  //                     <ImpactChartKinematicAcceleration
  //                         kinematicPoints={
  //                             impactKinematicPoints.kinematicPoints
  //                         }
  //                         maxAcceptableG={
  //                             memoRequirementsSM === null
  //                                 ? null
  //                                 : memoRequirementsSM.gmax
  //                         }
  //                         mooSync={mooSyncRef.current}
  //                         shouldDrawRequirements={true}
  //                     />
  //                 </Col>
  //                 <Col xs={8} style={memoImpactChartColStyle}>
  //                     <ImpactChartKinematicVelocity_Resilience
  //                         kinematicPoints={
  //                             impactKinematicPoints.kinematicPoints
  //                         }
  //                         maxAcceptableResiliencePerc={
  //                             memoRequirementsSM !== null &&
  //                             memoRequirementsSM.resiliencePerc !== null
  //                                 ? memoRequirementsSM.resiliencePerc[1]
  //                                 : null
  //                         }
  //                         mooSync={mooSyncRef.current}
  //                         shouldDrawRequirements={true}
  //                     />
  //                 </Col>
  //                 <Col xs={8} style={memoImpactChartColStyle}>
  //                     <ImpactChartKinematicDistance_Deflection
  //                         kinematicPoints={
  //                             impactKinematicPoints.kinematicPoints
  //                         }
  //                         deflectionDistanceMM={
  //                             impactKinematicPoints.deflectionDistanceMM
  //                         }
  //                         deflectionTimeMs={
  //                             impactKinematicPoints.deflectionDistanceMM
  //                         }
  //                         totalDepth={memoSportsMatThicknessMeanAndStd}
  //                         maxAcceptableDeformationDist={
  //                             memoRequirementsSM === null
  //                                 ? null
  //                                 : memoRequirementsSM.deformationDistanceMM
  //                         }
  //                         maxAcceptableDeformationPerc={
  //                             memoRequirementsSM === null
  //                                 ? null
  //                                 : memoRequirementsSM.deformationDistancePerc
  //                         }
  //                         mooSync={mooSyncRef.current}
  //                         shouldDrawRequirements={true}
  //                     />
  //                 </Col>
  //             </Row>
  //         </div>
  //     )
  // }, [
  //     memoMostUnfavorableImpact,
  //     memoSportsMatThicknessMeanAndStd,
  //     memoRequirementsSM,
  // ])

  const memoRenderedSportsMatThicknessMeanAndStd = useMemo(() => {
    if (memoSportsMatThicknessMeanAndStd === null) {
      return ""
    }
    return `${memoSportsMatThicknessMeanAndStd.mean.toFixed(0)} ± ${memoSportsMatThicknessMeanAndStd.std.toFixed(1)}`
  }, [memoSportsMatThicknessMeanAndStd])

  return (
    <FlexCol
      style={{
        justifyContent: "start",
        alignItems: "start",
        maxHeight: "90%",
        overflow: "hidden",
      }}
    >
      <h2
        style={{
          width: "100%",
        }}
      >
        <FlexRow
          style={{
            alignItems: "center",
            width: "100%",
          }}
        >
          <Translated keyEn="Equipment" />:
          <PDFInput defaultValue={equipment.equipment_name} />
        </FlexRow>
      </h2>
      <FlexRow
        style={{
          alignItems: "center",
          width: "100%",
        }}
      >
        <Translated keyEn="Type" />:
        <PDFInput defaultValue={memoSportsMatTypeDescription} />
      </FlexRow>
      <RequirementsAlertSM smType={equipment.sports_mat_type} />
      <Row gutter={[10, 10]} style={{ width: "100%" }}>
        <Col xs={12}>
          <h3>
            <Translated keyEn="References" />
          </h3>
          <FlexCol style={{ gap: 0 }}>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <Translated keyEn="Manufacturer" />:
              <PDFInput defaultValue={equipment.equipment_manufacturer} />
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <Translated keyEn="Reference" />:
              <PDFInput defaultValue={equipment.equipment_reference} />
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <Translated keyEn="Installation&nbsp;date" />:
              <PDFInput
                defaultValue={dayjs(equipment.equipment_installation_date).format("DD-MM-YYYY")}
              />
            </FlexRow>
          </FlexCol>
        </Col>
        <Col xs={12}>
          <h3>
            <Translated keyEn="Measurements" />
          </h3>
          <FlexCol style={{ gap: 0 }}>
            <FlexRow
              style={{
                alignItems: "center",
                width: "100%",
              }}
            >
              <Translated keyEn="Length,&nbsp;cm" />:
              <PDFInput defaultValue={equipment.sports_mat_length} />
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
                width: "100%",
              }}
            >
              <Translated keyEn="Width,&nbsp;cm" />:
              <PDFInput defaultValue={equipment.sports_mat_width} />
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <Translated keyEn="Thickness,&nbsp;mm" />:
              <PDFInput defaultValue={memoRenderedSportsMatThicknessMeanAndStd} />
            </FlexRow>
          </FlexCol>
          <Col xs={12}></Col>
        </Col>
      </Row>
      <h3>
        <Translated keyEn="Results per zone" />
      </h3>
      <EquipmentZonesTableSM_Print
        equipmentUUID={equipment.uuid}
        smThickness={memoSportsMatThicknessMeanAndStd}
        smType={equipment.sports_mat_type}
        zones={memoEquipmentZones}
      />
      {/* {memoMostUnfavorableImpactChartsRow} */}
      <PicturesRow pictures={equipment.pictures} />
    </FlexCol>
  )
}

const EquipmentPageContentPF_CFH: FC<IEquipmentPageContentProps> = ({
  user,
  site,
  equipment,
  equipmentZonesMap,
  zoneImpactsMap,
}) => {
  const memoEquipmentZones = useMemo(() => {
    if (equipmentZonesMap === null) {
      return []
    }
    return equipmentZonesMap[pbUUIDToUuid(equipment.uuid)] ?? []
  }, [equipmentZonesMap])

  return (
    <FlexCol
      style={{
        justifyContent: "start",
        alignItems: "start",
        maxHeight: "90%",
        overflow: "hidden",
      }}
    >
      <h2
        style={{
          width: "100%",
        }}
      >
        <FlexRow
          style={{
            alignItems: "center",
            width: "100%",
          }}
        >
          <Translated keyEn="Equipment" />:
          <PDFInput defaultValue={equipment.equipment_name} />
        </FlexRow>
      </h2>
      <Row gutter={[10, 10]} style={{ width: "100%" }}>
        <Col xs={14}>
          <h3>
            <Translated keyEn="References" />
          </h3>
          <FlexCol style={{ gap: 0 }}>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <Translated keyEn="Manufacturer" />:
              <PDFInput defaultValue={equipment.equipment_manufacturer} />
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <Translated keyEn="Reference" />:
              <PDFInput defaultValue={equipment.equipment_reference} />
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <Translated keyEn="Installation&nbsp;date" />:
              <PDFInput
                defaultValue={dayjs(equipment.equipment_installation_date).format("DD-MM-YYYY")}
              />
            </FlexRow>
          </FlexCol>
        </Col>
        <Col xs={10}>
          <FirstPicture pictures={equipment.pictures} />
        </Col>
      </Row>
      <h3>
        <Translated keyEn="Results" />
      </h3>
      <EquipmentZonesTablePF_CFH_Print equipmentUUID={equipment.uuid} zones={memoEquipmentZones} />
    </FlexCol>
  )
}

const EquipmentPageContentPF_ADQ: FC<IEquipmentPageContentProps> = ({
  user,
  site,
  equipment,
  equipmentZonesMap,
  zoneImpactsMap,
}) => {
  const memoEquipmentZones = useMemo((): Zone[] => {
    if (equipmentZonesMap === null) {
      return []
    }
    return equipmentZonesMap[pbUUIDToUuid(equipment.uuid)] ?? []
  }, [equipmentZonesMap])

  return (
    <FlexCol
      style={{
        justifyContent: "start",
        alignItems: "start",
        maxHeight: "90%",
        overflow: "hidden",
      }}
    >
      <h2
        style={{
          width: "100%",
        }}
      >
        <FlexRow
          style={{
            alignItems: "center",
            width: "100%",
          }}
        >
          <Translated keyEn="Equipment" />:
          <PDFInput defaultValue={equipment.equipment_name} />
        </FlexRow>
      </h2>
      <Row gutter={[10, 10]} justify="space-between" style={{ width: "100%" }}>
        <Col xs={14}>
          <FlexCol>
            <div>
              <h3>
                <Translated keyEn="Equipment references" />
              </h3>
              <FlexCol style={{ gap: 0 }}>
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="Manufacturer" />:
                  <PDFInput defaultValue={equipment.equipment_manufacturer} />
                </FlexRow>
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="Reference" />:
                  <PDFInput defaultValue={equipment.equipment_reference} />
                </FlexRow>
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="Installation&nbsp;date" />:
                  <PDFInput
                    defaultValue={dayjs(equipment.equipment_installation_date).format("DD-MM-YYYY")}
                  />
                </FlexRow>
              </FlexCol>
            </div>
            <div>
              <h3>
                <Translated keyEn="Floor references" />
              </h3>
              <FlexCol style={{ gap: 0 }}>
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="Manufacturer" />:
                  <PDFInput defaultValue={equipment.floor_manufacturer} />
                </FlexRow>
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="Reference" />:
                  <PDFInput defaultValue={equipment.floor_reference} />
                </FlexRow>
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="Installation&nbsp;date" />:
                  <PDFInput
                    defaultValue={dayjs(equipment.floor_installation_date).format("DD-MM-YYYY")}
                  />
                </FlexRow>
              </FlexCol>
            </div>
          </FlexCol>
        </Col>
        <Col xs={10}>
          <FirstPicture pictures={equipment.pictures} />
        </Col>
      </Row>
      <h3>
        <Translated keyEn="Results per zone" />
      </h3>
      <EquipmentZonesTablePF_ADQ_Print equipmentUUID={equipment.uuid} zones={memoEquipmentZones} />
    </FlexCol>
  )
}

const EquipmentPageContentTL: FC<IEquipmentPageContentProps> = ({
  user,
  site,
  equipment,
  equipmentZonesMap,
  zoneImpactsMap,
}) => {
  const memoEquipmentZones = useMemo(() => {
    if (equipmentZonesMap === null) {
      return []
    }
    return equipmentZonesMap[pbUUIDToUuid(equipment.uuid)] ?? []
  }, [equipmentZonesMap])

  return (
    <FlexCol
      style={{
        justifyContent: "start",
        alignItems: "start",
        maxHeight: "90%",
        overflow: "hidden",
      }}
    >
      <h2
        style={{
          width: "100%",
        }}
      >
        <FlexRow
          style={{
            alignItems: "center",
            width: "100%",
          }}
        >
          <Translated keyEn="Equipment" />:
          <PDFInput defaultValue={equipment.equipment_name} />
        </FlexRow>
      </h2>
      <Row gutter={[10, 10]} style={{ width: "100%" }}>
        <Col xs={12}>
          <h3>
            <Translated keyEn="References" />
          </h3>
          <FlexCol style={{ gap: 0 }}>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <Translated keyEn="Manufacturer" />:
              <PDFInput defaultValue={equipment.equipment_manufacturer} />
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <Translated keyEn="Reference" />:
              <PDFInput defaultValue={equipment.equipment_reference} />
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <Translated keyEn="Installation&nbsp;date" />:
              <PDFInput
                defaultValue={dayjs(equipment.equipment_installation_date).format("DD-MM-YYYY")}
              />
            </FlexRow>
          </FlexCol>
        </Col>
      </Row>
      <h3>
        <Translated keyEn="Results" />
      </h3>
      <EquipmentZonesTableTL_Print
        equipmentImpactorWeight={10}
        equipmentUUID={equipment.uuid}
        zones={memoEquipmentZones}
      />
      <PicturesRow pictures={equipment.pictures} />
    </FlexCol>
  )
}

const EquipmentPageContent: FC<IEquipmentPageContentProps> = (props) => {
  const site = props.site
  if (site === null) {
    return null
  }

  switch (site.norm_type) {
    case NormType.EN_1177:
      switch (site.method_type_en_1177) {
        case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
          return <EquipmentPageContentPF_CFH {...props} />
        case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
          return <EquipmentPageContentPF_ADQ {...props} />
      }
    case NormType.EN_12503:
      return <EquipmentPageContentSM {...props} />
    case NormType.EN_ISO_23659:
      return <EquipmentPageContentTL {...props} />
    default:
      return null
  }
}

interface IZonePageContentProps {
  user: ICloudUser | null
  site: Site
  zone: Zone
  zoneImpactsMap: Record<string, Impact[]> | null
}

const ZonePageContentPF_ADQ: FC<IZonePageContentProps> = ({ user, site, zone, zoneImpactsMap }) => {
  const [fullImpact, getFullImpact] = useUsercommImpactBimodal()
  const { translated } = useTranslationContext()

  const [mostUnfavorableImpactChartProps, setMostUnfavorableImpactChartProps] = useState<{
    impactPoints: IImpactPoints
    impactFrequency: number
  } | null>(null)

  const memoZoneImpacts = useMemo(() => {
    if (zoneImpactsMap === null) {
      return []
    }
    let impacts = zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
    if (impacts === undefined) {
      return []
    }
    return impacts
  }, [zoneImpactsMap])

  useEffect(() => {
    if (memoZoneImpacts.length === 0) {
      return
    }
    memoZoneImpacts.sort((a, b) => {
      return b.impact_gmax - a.impact_gmax
    })
    let _impact = memoZoneImpacts[0]! // length > 0
    getFullImpact(_impact.uuid, true, true, false)
  }, [memoZoneImpacts])

  useEffect(() => {
    if (fullImpact === null) {
      return
    }
    // console.log(`ZonePageContentPF_ADQ: fullImpact`, fullImpact.toObject())
    let [aq_points, ax_points, ay_points, az_points] = decodeImpactDataPoints(fullImpact)
    offsetImpactDataPointsInplace(fullImpact, aq_points, ax_points, ay_points, az_points)
    setMostUnfavorableImpactChartProps({
      impactPoints: {
        aq_points,
        ax_points,
        ay_points,
        az_points,
      },
      impactFrequency: fullImpact.sampling_frequency,
    })
  }, [fullImpact])

  const memoMustBeTranslated = useCallback(
    (value: string) => {
      return translated(value) ?? ""
    },
    [translated],
  )

  const memoZoneProcessed = useMemo((): IZoneProcessed_ADQ | null => {
    let nb_impacts = 0
    let heightEnv = new StatEnvelope()
    let gmaxEnv = new StatEnvelope()
    let hicEnv = new StatEnvelope()
    for (let impact of memoZoneImpacts) {
      if (impact.deleted_at > 0) {
        continue
      }
      nb_impacts++
      heightEnv.addValue(impact.impact_ffh)
      gmaxEnv.addValue(impact.impact_gmax)
      hicEnv.addValue(impact.impact_hic)
    }
    let result = getZoneResultPF_Adq(memoZoneImpacts)
    if (result === null) {
      return null
    }
    return {
      zone: zone,
      nb_impacts,
      heightEnv,
      gmaxEnv,
      hicEnv,
      result,
    }
  }, [zone, memoZoneImpacts])

  return (
    <FlexCol
      style={{
        justifyContent: "start",
        alignItems: "start",
        maxHeight: "90%",
        overflow: "hidden",
      }}
    >
      <h2
        style={{
          width: "100%",
        }}
      >
        <FlexRow
          style={{
            alignItems: "center",
            width: "100%",
          }}
        >
          <Translated keyEn="Zone" />:
          <PDFInput defaultValue={zone.zone_name} />
        </FlexRow>
      </h2>
      <Row gutter={[10, 10]} justify="space-between" style={{ width: "100%" }}>
        <Col xs={14}>
          <h3>
            <Translated keyEn="References" />
          </h3>
          <FlexCol>
            <FlexCol style={{ gap: 0 }}>
              <FlexRow
                style={{
                  alignItems: "center",
                }}
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  <Translated keyEn="Floor thickness" />:
                </span>
                <PDFInput defaultValue={zone.floor_thickness} />
                <span> mm</span>
              </FlexRow>
              <FlexRow
                style={{
                  alignItems: "center",
                }}
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  <Translated keyEn="Specimen humidity" />:
                </span>
                <PDFInput defaultValue={zone.specimen_humidity} />
                <span> %</span>
              </FlexRow>
              <FlexRow
                style={{
                  alignItems: "center",
                }}
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  <Translated keyEn="Specimen temperature" />:
                </span>
                <PDFInput defaultValue={zone.specimen_temperature} />
                <span> °C</span>
              </FlexRow>
            </FlexCol>
            {/* Result */}
            {memoZoneProcessed && (
              <FlexCol style={{ gap: 5 }}>
                <div>
                  <h3>
                    <Translated keyEn="Results" />
                  </h3>
                  <i style={{ fontSize: "0.8rem" }}>
                    <Translated keyEn="Format" />: (mean ± std<sub>95%</sub>)<sup>[n]</sup>
                  </i>
                </div>
                <div />
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="Measured FFH" />: {memoZoneProcessed.heightEnv.format(2)} m
                </FlexRow>
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="Gmax" />: {memoZoneProcessed.gmaxEnv.format(0)} g
                </FlexRow>
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="HIC" />: {memoZoneProcessed.hicEnv.format(0)}
                </FlexRow>
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="Result" />:{" "}
                  {
                    <EResultTagAdequacy
                      result={memoZoneProcessed.result?.result ?? EResult.ToReview}
                    />
                  }
                </FlexRow>
              </FlexCol>
            )}
          </FlexCol>
        </Col>
        <Col xs={10}>
          <FirstPicture pictures={zone.pictures} />
        </Col>
      </Row>
      <h3>
        <Translated keyEn="Results per impact" />
      </h3>
      <ZoneImpactsTablePF_ADQ_Print impacts={memoZoneImpacts} />
      <h3>
        <Translated keyEn="Most unfavorable impact" />
      </h3>
      <Row
        gutter={[10, 0]}
        style={{ width: "100%", height: 250, marginTop: -20, marginBottom: -15 }}
      >
        <Col xs={10} style={{ height: "100%" }}>
          {mostUnfavorableImpactChartProps && (
            <ImpactChartRawAcceleration
              {...mostUnfavorableImpactChartProps}
              mooSync={null}
              showLegend={false}
            />
          )}
        </Col>
        <Col xs={7} style={{ height: "100%" }}>
          <ZoneChartPF_ADQ impacts={memoZoneImpacts} impact_key="impact_gmax" zone={zone} />
        </Col>
        <Col xs={7} style={{ height: "100%" }}>
          <ZoneChartPF_ADQ impacts={memoZoneImpacts} impact_key="impact_hic" zone={zone} />
        </Col>
      </Row>
      <PDFInputTextArea
        style={{
          textAlign: "center",
          fontSize: "0.8rem",
        }}
        autoSize={{ minRows: 2 }}
        defaultValue={memoMustBeTranslated(
          "The uncertainty of this result under controlled laboratory conditions is ± 7%. Under site conditions, it is possible that the uncertainty is greater.",
        )}
      />
    </FlexCol>
  )
}

const ZonePageContentPF_CFH: FC<IZonePageContentProps> = ({ user, site, zone, zoneImpactsMap }) => {
  const [fullImpact, getFullImpact] = useUsercommImpactBimodal()
  const { translated } = useTranslationContext()

  const [mostUnfavorableImpactChartProps, setMostUnfavorableImpactChartProps] = useState<{
    impactPoints: IImpactPoints
    impactFrequency: number
  } | null>(null)

  const memoZoneImpacts = useMemo(() => {
    if (zoneImpactsMap === null) {
      return []
    }
    let impacts = zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
    if (impacts === undefined) {
      return []
    }
    return impacts
  }, [zoneImpactsMap])

  useEffect(() => {
    if (memoZoneImpacts.length === 0) {
      return
    }
    memoZoneImpacts.sort((a, b) => {
      return b.impact_gmax - a.impact_gmax
    })
    let _impact = memoZoneImpacts[0]! // length > 0
    getFullImpact(_impact.uuid, true, true, false)
  }, [memoZoneImpacts])

  useEffect(() => {
    if (fullImpact === null) {
      return
    }
    // console.log(`ZonePageContentPF_ADQ: fullImpact`, fullImpact.toObject())
    let [aq_points, ax_points, ay_points, az_points] = decodeImpactDataPoints(fullImpact)
    setMostUnfavorableImpactChartProps({
      impactPoints: {
        aq_points,
        ax_points,
        ay_points,
        az_points,
      },
      impactFrequency: fullImpact.sampling_frequency,
    })
  }, [fullImpact])

  const memoZoneProcessed = useMemo((): IZoneProcessed_CFH | null => {
    if (memoZoneImpacts.length === 0) {
      return null
    }
    let nb_impacts = 0
    for (let impact of memoZoneImpacts) {
      if (impact.deleted_at === 0) {
        nb_impacts++
      }
    }
    let cfh = getCFH_Global(memoZoneImpacts)
    if (cfh === null) {
      return null
    }
    let result = getZoneResultPF_CFH(zone, memoZoneImpacts)
    if (result === null) {
      return null
    }
    return {
      zone: zone,
      nb_impacts,
      cfh,
      result,
    }
  }, [zone, memoZoneImpacts])

  const memoMustBeTranslated = useCallback(
    (value: string) => {
      return translated(value) ?? ""
    },
    [translated],
  )

  return (
    <FlexCol
      style={{
        justifyContent: "start",
        alignItems: "start",
        maxHeight: "90%",
        overflow: "hidden",
      }}
    >
      <h2
        style={{
          width: "100%",
        }}
      >
        <FlexRow
          style={{
            alignItems: "center",
            width: "100%",
          }}
        >
          <Translated keyEn="Zone" />:
          <PDFInput defaultValue={zone.zone_name} />
        </FlexRow>
      </h2>
      <Row gutter={[10, 10]} justify="space-between" style={{ width: "100%" }}>
        <Col xs={14}>
          <h3>
            <Translated keyEn="References" />
          </h3>
          <FlexCol>
            <FlexCol style={{ gap: 0 }}>
              <FlexRow
                style={{
                  alignItems: "center",
                }}
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  <Translated keyEn="Floor thickness" />:
                </span>
                <PDFInput defaultValue={zone.floor_thickness} />
                <span> mm</span>
              </FlexRow>
              <FlexRow
                style={{
                  alignItems: "center",
                }}
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  <Translated keyEn="Specimen humidity" />:
                </span>
                <PDFInput defaultValue={zone.specimen_humidity} />
                <span> %</span>
              </FlexRow>
              <FlexRow
                style={{
                  alignItems: "center",
                }}
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  <Translated keyEn="Specimen temperature" />:
                </span>
                <PDFInput defaultValue={zone.specimen_temperature} />
                <span> °C</span>
              </FlexRow>
            </FlexCol>
            {/* Result */}
            {memoZoneProcessed && (
              <FlexCol style={{ gap: 5 }}>
                <div>
                  <h3>
                    <Translated keyEn="Results" />
                  </h3>
                </div>
                <div />
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="Authorized FFH" />: {(zone.zone_ffh_max / 100).toFixed(2)} m
                </FlexRow>
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="CFH" />: {memoZoneProcessed.cfh?.mean.toFixed(2)} m
                </FlexRow>
                <FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Translated keyEn="Result" />:{" "}
                  {
                    <EResultTagAdequacy
                      result={memoZoneProcessed.result?.result ?? EResult.ToReview}
                    />
                  }
                </FlexRow>
              </FlexCol>
            )}
          </FlexCol>
        </Col>
        <Col xs={10}>
          <FirstPicture pictures={zone.pictures} />
        </Col>
      </Row>
      <h3>
        <Translated keyEn="Results per impact" />
      </h3>
      <ZoneImpactsTablePF_CFH_Print impacts={memoZoneImpacts} />
      <h3>
        <Translated keyEn="Data" />
      </h3>
      <Row
        gutter={[10, 0]}
        style={{ width: "100%", height: 250, marginTop: -20, marginBottom: -15 }}
      >
        <Col xs={10} style={{ height: "100%" }}>
          {mostUnfavorableImpactChartProps && (
            <ImpactChartRawAcceleration
              {...mostUnfavorableImpactChartProps}
              mooSync={null}
              showLegend={false}
            />
          )}
        </Col>
        <Col xs={14} style={{ height: "100%" }}>
          <ZoneChartPF_CFH impacts={memoZoneImpacts} zone={zone} showLegend={false} />
        </Col>
      </Row>
      <PDFInputTextArea
        style={{
          textAlign: "center",
          fontSize: "0.8rem",
        }}
        autoSize={{ minRows: 2 }}
        defaultValue={memoMustBeTranslated(
          "The uncertainty of this result under controlled laboratory conditions is ± 7%. Under site conditions, it is possible that the uncertainty is greater.",
        )}
      />
    </FlexCol>
  )
}

const ZonePageContent: FC<IZonePageContentProps> = (props) => {
  const site = props.site
  if (site === null) {
    return null
  }
  switch (site.norm_type) {
    case NormType.EN_1177:
      switch (site.method_type_en_1177) {
        case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
          return <ZonePageContentPF_CFH {...props} />
        case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
          return <ZonePageContentPF_ADQ {...props} />
      }
  }
  return null
}

const LastPageContent: FC<{
  user: ICloudUser | null
  site: Site
}> = ({ user, site }) => {
  return (
    <FlexCol
      style={{
        marginTop: 30,
        justifyContent: "start",
        alignItems: "center",
        height: `calc(100% - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
      }}
    >
      <PDFInputTextArea defaultValue={"   "} autoSize={{ minRows: 2 }} />
      <PDFEmptySpacePlaceholder />
    </FlexCol>
  )
}

export const ReportMasterPage: FC = () => {
  const location = useLocation()

  const siteUUID = useMemo((): UUID | null => {
    let siteUUID = parsePathForEntityUUID(location.pathname)
    if (siteUUID === null) {
      return null
    }
    return uuidToPbUUID(siteUUID)
  }, [location])

  const [totalPages, setTotalPages] = useState(2)
  const [site, getSite] = useUsercommSiteBimodal()
  const [siteEquipmentsMap, equipmentZonesMap, zoneImpactsMap, getSiteChildrenRecursive] =
    useUsercommSiteChildrenRecursiveBimodal()
  const [siteAnnexes, getSiteAnnexes] = useUsercommSiteAnnexesBimodal()
  const [user, setUser] = useState<ICloudUser | null>(null)
  const [device, setDevice] = useState<ICloudDevice | null>(null)
  const [deviceEvents, setDeviceEvents] = useState<ICloudDeviceEvent[] | null>(null)

  // User
  useEffect(() => {
    if (site === null || !site.user_uuid) {
      return
    }
    callCloudApiV2<ICloudUser | null>(`/user?user_uuid=${pbUUIDToUuid(site.user_uuid)}`).then(
      ({ resp, entity: _user }) => {
        if (!resp.ok || _user === null) {
          console.error(`ReportPage: error fetching user`, resp)
        }
        console.log(`ReportPage: user`, _user)
        setUser(_user)
      },
    )
  }, [site])

  // Device
  useEffect(() => {
    if (site === null) {
      return
    }
    console.log(`ReportPage: site`, site.toObject())
    let deviceSnStr = site.device_sn

    if (deviceSnStr === "" || deviceSnStr === "0") {
      // try to figure out serial number from impacts
      if (zoneImpactsMap === null) {
        return
      }
      for (let zoneUUID in zoneImpactsMap) {
        let impacts = zoneImpactsMap[zoneUUID]
        if (impacts === undefined) {
          continue
        }
        for (let impact of impacts) {
          if (impact.metadata_station_device_sn !== "") {
            deviceSnStr = impact.metadata_station_device_sn
            console.log(
              `ReportPage: retreived deviceSnStr from impact metadata`,
              deviceSnStr,
              impact.toObject(),
            )
            break
          }
        }
        if (deviceSnStr !== "") {
          break
        }
      }
      if (deviceSnStr === "") {
        return
      }
    }

    let deviceSnBigInt = BigInt(deviceSnStr)
    if (deviceSnBigInt !== BigInt(0)) {
      deviceSnStr = deviceSnBigInt.toString(16)
    }

    callCloudApiV2<ICloudDevice | null>(`/device?device_serial_number=${deviceSnStr}`).then(
      ({ resp, entity: _device }) => {
        if (!resp.ok || _device === null) {
          console.error(`ReportPage: error fetching device`, resp)
        }
        // console.log(`ReportPage: device`, _device)
        setDevice(_device)
      },
    )
  }, [site, zoneImpactsMap])

  // Device Events
  useEffect(() => {
    if (device === null) {
      return
    }
    callCloudApiV2<ICloudDeviceEvent[]>(`/device/events?device_uuid=${device.UUID}`).then(
      ({ resp, entity: _deviceEvents }) => {
        if (!resp.ok || _deviceEvents === null) {
          console.error(`ReportPage: error fetching device events`, resp)
        }
        console.log(`ReportPage: device events`, _deviceEvents)
        setDeviceEvents(_deviceEvents)
      },
    )
  }, [device])

  // Site
  useEffect(() => {
    if (siteUUID === null) {
      return
    }
    getSite(siteUUID)
  }, [siteUUID])

  // Site children
  useEffect(() => {
    if (site == null) {
      return
    }
    getSiteChildrenRecursive(site.uuid)
    getSiteAnnexes(site.uuid)
  }, [site])

  useEffect(() => {
    if (
      siteEquipmentsMap === null ||
      equipmentZonesMap === null ||
      siteAnnexes === null ||
      zoneImpactsMap === null
    ) {
      return
    }
    let nbPages = 3
    for (let siteUUID in siteEquipmentsMap) {
      let equipments = siteEquipmentsMap[siteUUID]
      if (equipments === undefined) {
        continue
      }
      for (let equipment of equipments) {
        if (equipment.deleted_at > 0) {
          continue
        }
        nbPages++
        let equipmentZones = equipmentZonesMap[pbUUIDToUuid(equipment.uuid)]
        if (equipmentZones === undefined) {
          continue
        }
        for (let zone of equipmentZones) {
          if (zone.deleted_at > 0) {
            continue
          }
          let impacts = zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
          if (impacts === undefined || impacts.length === 0) {
            continue
          }
          nbPages++
        }
      }
    }
    for (let annex of siteAnnexes) {
      if (annex.deleted_at > 0) {
        continue
      }
      nbPages++
    }
    setTotalPages(nbPages)
  }, [siteEquipmentsMap, equipmentZonesMap, siteAnnexes, zoneImpactsMap])

  const memoGlobalResult = useMemo(() => {
    if (
      site === null ||
      siteEquipmentsMap === null ||
      equipmentZonesMap === null ||
      zoneImpactsMap === null
    ) {
      return null
    }
    let siteEquipments = siteEquipmentsMap[pbUUIDToUuid(site.uuid)]
    if (siteEquipments === undefined) {
      return null
    }
    switch (site.norm_type) {
      case NormType.EN_1177:
        switch (site.method_type_en_1177) {
          case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
            return getSiteResultPF_CFH(siteEquipments, equipmentZonesMap, zoneImpactsMap)
          case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
            return getSiteResultPF_CFH(siteEquipments, equipmentZonesMap, zoneImpactsMap)
        }
    }
    return null
  }, [site, siteEquipmentsMap, equipmentZonesMap, zoneImpactsMap])

  const memoDataPages = useMemo(() => {
    if (
      site === null ||
      siteEquipmentsMap === null ||
      equipmentZonesMap === null ||
      zoneImpactsMap === null ||
      siteAnnexes === null
    ) {
      return <></>
    }
    let nbUnconditionalPagesBeforeContent = 3
    let i = 0
    let pages: JSX.Element[] = []

    for (let siteUUID in siteEquipmentsMap) {
      let equipments = siteEquipmentsMap[siteUUID]
      if (equipments === undefined) {
        continue
      }
      for (let equipment of equipments) {
        if (equipment.deleted_at > 0) {
          continue
        }
        i++
        let equipmentPage = (
          <PDFPage
            site={site}
            user={user}
            pageNumber={nbUnconditionalPagesBeforeContent + i}
            totalPages={totalPages}
            key={pbUUIDToUuid(equipment.uuid)}
          >
            <EquipmentPageContent
              user={user}
              site={site}
              equipment={equipment}
              equipmentZonesMap={equipmentZonesMap}
              zoneImpactsMap={zoneImpactsMap}
            />
          </PDFPage>
        )
        pages.push(equipmentPage)
        let equipmentZones = equipmentZonesMap[pbUUIDToUuid(equipment.uuid)]
        if (equipmentZones === undefined) {
          continue
        }
        for (let zone of equipmentZones) {
          if (zone.deleted_at > 0) {
            continue
          }
          let impacts = zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
          if (impacts === undefined || impacts.length === 0) {
            continue
          }
          i++
          let equipmentZonePage = (
            <PDFPage
              site={site}
              user={user}
              pageNumber={nbUnconditionalPagesBeforeContent + i}
              totalPages={totalPages}
              key={pbUUIDToUuid(zone.uuid)}
            >
              <ZonePageContent
                user={user}
                site={site}
                zone={zone}
                zoneImpactsMap={zoneImpactsMap}
              />
            </PDFPage>
          )
          pages.push(equipmentZonePage)
          //   TEMPORARY BREAK
          //   break
        }
      }
    }

    let filteredSortedSiteAnnexes = siteAnnexes.filter((annex) => annex.deleted_at === 0)
    filteredSortedSiteAnnexes.sort((a, b) => {
      return a.annex_idx - b.annex_idx
    })
    for (let j = 0; j < filteredSortedSiteAnnexes.length; j += 2) {
      let _annexes = filteredSortedSiteAnnexes.slice(j, j + 2)
      i++
      let annexPage = (
        <PDFPage
          site={site}
          user={user}
          pageNumber={nbUnconditionalPagesBeforeContent + i}
          totalPages={totalPages}
          key={Math.random()}
        >
          <AnnexPageContent user={user} site={site} annexes={_annexes} />
        </PDFPage>
      )
      pages.push(annexPage)
    }
    return pages
  }, [siteEquipmentsMap, equipmentZonesMap, zoneImpactsMap, totalPages, user, site])

  if (site === null) {
    return (
      <FlexCol
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </FlexCol>
    )
  }

  return (
    <ReportConfigProvider>
      <div id="pdf" className="A4">
        <PDFPage site={site} user={user} pageNumber={1} totalPages={totalPages}>
          <FirstPageContent user={user} site={site} globalResult={memoGlobalResult} />
        </PDFPage>
        <PDFPage site={site} user={user} pageNumber={2} totalPages={totalPages}>
          <SecondPageContent
            user={user}
            device={device}
            deviceEvents={deviceEvents}
            site={site}
            siteEquipmentsMap={siteEquipmentsMap}
            equipmentZonesMap={equipmentZonesMap}
            zoneImpactsMap={zoneImpactsMap}
          />
        </PDFPage>
        <PDFPage site={site} user={user} pageNumber={3} totalPages={totalPages}>
          <ThirdPageContent
            user={user}
            device={device}
            site={site}
            siteEquipmentsMap={siteEquipmentsMap}
            equipmentZonesMap={equipmentZonesMap}
            zoneImpactsMap={zoneImpactsMap}
          />
        </PDFPage>
        {memoDataPages}
        {/* <PDFPage site={site} user={user} pageNumber={totalPages} totalPages={totalPages}>
          <LastPageContent user={user} site={site} />
        </PDFPage> */}
      </div>
      <div className="pdf-invisible">
        <Popover content={<TranslationLanguageSelectWidget />}>
          <FloatButton
            icon={<GlobalOutlined />}
            description="Lang"
            type="default"
            style={{
              width: 60,
              height: 60,
              right: 180,
            }}
          />
        </Popover>
        <Link to="/">
          <FloatButton
            icon={<LeftCircleOutlined />}
            description="Back"
            type="default"
            style={{
              width: 60,
              height: 60,
              right: 110,
            }}
          />
        </Link>
        <FloatButton
          icon={<FilePdfOutlined />}
          description="Print"
          type="primary"
          style={{
            width: 60,
            height: 60,
            right: 40,
          }}
          onClick={() => {
            window.print()
          }}
        />
      </div>
    </ReportConfigProvider>
  )
}
