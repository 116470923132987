import { UUID } from "../generated/proto-ts/main"
import { parse as uuidParse, stringify as uuidStringify } from "uuid"
import { useState, useEffect } from "react"

export const parsePathForEntityID = (pathname: string): number | null => {
  let parts = pathname.split("/")
  let lastPart = parts[parts.length - 1]
  if (lastPart === undefined) {
    return null
  }
  let id = parseInt(lastPart)
  if (isNaN(id)) {
    return null
  }
  return id
}

export const parsePathForEntityUUID = (pathname: string): string | null => {
  let parts = pathname.split("/")
  let lastPart = parts[parts.length - 1]
  if (lastPart === undefined) {
    return null
  }
  if (lastPart.length !== 36) {
    return null
  }
  return lastPart
}

export const uuidToPbUUID = (uuidStr: string): UUID => {
  if (uuidStr === "") {
    return new UUID({ value: new Uint8Array(16) })
  }
  return new UUID({ value: uuidParse(uuidStr) })
}

export const pbUUIDToUuid = (uuid: UUID | undefined): string => {
  if (uuid === undefined) {
    return ""
  }
  try {
    return uuidStringify(uuid.value)
  } catch (e: any) {
    console.warn("pbUUIDToUuid: error converting UUID to string", e)
    return ""
  }
}

export const useMediaQuery = () => {
  const mediaMatch = window.matchMedia("(max-width: 768px)")
  const [matches, setMatches] = useState(mediaMatch.matches)

  useEffect(() => {
    const handler = (e: any) => {
      setMatches(e.matches)
    }
    mediaMatch.addEventListener("change", handler)
    return () => mediaMatch.removeEventListener("change", handler)
  }, [])
  return matches
}

export const parseFloatOrZero = (value: string | null): number => {
  if (value === null || value === "") {
    return 0
  }
  let valueNumber = parseFloat(value)
  if (isNaN(valueNumber)) {
    return 0
  }
  return valueNumber
}

export const floatToFixedOrNull = (value: number, nDigits: number): string | null => {
  if (value === 0) {
    return null
  }
  return value.toFixed(nDigits)
}

export const colorHexToRgba = (hex: string, alpha: number): string => {
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const COLOR_BG_ROW_DELETED = "#ffffb8" // yellow-2
export const COLOR_TEXT_ROW_DELETED = ""

export const COLOR_BG_NORM_TYPE_PG = ""
export const COLOR_TEXT_NORM_TYPE_PG = ""

export const COLOR_BG_NORM_TYPE_SM = ""
export const COLOR_TEXT_NORM_TYPE_SM = ""

export const COLOR_BG_METHOD_CFH = ""
export const COLOR_TEXT_METHOD_CFH = ""

export const COLOR_BG_METHOD_ADQ = ""
export const COLOR_TEXT_METHOD_ADQ = ""

export const COLOR_BG_RED = "#ff0000"
export const COLOR_BG_GREEN = "#38c172" // "#73d13d"
export const COLOR_BG_BLUE = "#0065ff"
export const COLOR_BG_LIGHT_BLUE = "#7fb2ff"
export const COLOR_BG_DARK_BLUE = "#024dbf"
export const COLOR_BG_DARK_BLUE_TEXT = "#033e96"
export const COLOR_BG_GRAY = "#888"
export const COLOR_BG_DARK = "#333"
export const COLOR_BG_ORANGE = "#f56a00"
export const COLOR_BG_CURVE = "#8884d8"

export const COLOR_HIC = "#8884d8"
export const COLOR_GMAX = "#82ca9d"

export const CENTRAL_CONTAINER_MAX_WIDTH = "45rem"
export const CENTRAL_CONTAINER_GAP = 20

export const ENTITY_SAVE_DEBOUNCE_DELAY_MS = 1000
