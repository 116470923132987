import { HistoryOutlined } from "@ant-design/icons"
import { toast } from "react-toastify"
import { FlexCol } from "../components/commons-ts/common"
import { Button } from "antd"
import { Translated } from "./translated"

export const toastNotifyUpdateIsInstalled = () => {
  toast(
    <FlexCol>
      <div>
        <Translated keyEn="A new version of the Lugsoft App has been installed!" />
        <br />
        <Translated keyEn="You can reload the app to apply changes:" />
      </div>
      <Button type="primary" onClick={() => window.location.reload()}>
        <Translated keyEn="Reload App" />
      </Button>
    </FlexCol>,
    {
      icon: <HistoryOutlined />,
      style: {
        minWidth: "20rem",
        fontSize: "1.2rem",
      },
      autoClose: false,
    },
  )
}
