import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  ECloudRole,
  ICloudBruteUser,
  ICloudDevice,
  ICloudEnterprise,
  ICloudUser,
  ISyncAggregatedProgress,
  ISyncEntityLocationAndState,
} from "../types"
import { useUsercommContextBLE } from "../usercomm/local/ble/usercommProviderBLE"
import {
  callCloudApiV2,
  getLocalStorageCloudApiUser,
  setLocalStorageCloudApiUser,
} from "../utils/cloudApiV2"
import {
  useUsercommGetHICConfigBLE,
  useUsercommGetStationConfigBLE,
  useUsercommGetStationStateBLE,
  useUsercommNetworkGetPrimaryIPAddressesBLE,
} from "../usercomm/local/ble/usercommAsyncRequestBLE"
import {
  DeviceTelemetry,
  HICConfig,
  HICState,
  NetworkGetPrimaryIPAddressInfoResponse,
  StationConfig,
  StationSensors,
  StationState,
} from "../generated/proto-ts/main"
import { useUsercommSetDeviceTelemetryWS } from "../usercomm/cloud/cloudUsercommAsyncRequestWS"
import { uuidToPbUUID } from "../utils/utils"

const useDeviceTelemetry = (currentRemoteDevice: ICloudDevice | null) => {
  const {
    hicState, // soci event feed
    stationSensors, // soci event feed
    bleIsConnected,
  } = useUsercommContextBLE()

  const [setDeviceTelemetryAck, setDeviceTelemetry] = useUsercommSetDeviceTelemetryWS()

  const [stationConfig, getStationConfig] = useUsercommGetStationConfigBLE()
  const [hicConfig, getHicConfig] = useUsercommGetHICConfigBLE()
  const [stationState, getStationState] = useUsercommGetStationStateBLE()
  const [networkPrimaryAddresses, getNetworkPrimaryAddresses] =
    useUsercommNetworkGetPrimaryIPAddressesBLE()

  const currentHicStateRef = useRef<HICState | null>(null)
  const currentStationSensorsRef = useRef<StationSensors | null>(null)
  const currentStationConfigRef = useRef<StationConfig | null>(null)
  const currentHicConfigRef = useRef<HICConfig | null>(null)
  const currentStationStateRef = useRef<StationState | null>(null)
  const currentNetworkPrimaryAddressesRef = useRef<NetworkGetPrimaryIPAddressInfoResponse | null>(
    null,
  )

  // Set received responses to refs
  // HICState
  useEffect(() => {
    if (hicState !== null) {
      currentHicStateRef.current = hicState
    }
  }, [hicState])
  // StationSensors
  useEffect(() => {
    if (stationSensors !== null) {
      currentStationSensorsRef.current = stationSensors
    }
  }, [stationSensors])
  // StationConfig
  useEffect(() => {
    if (stationConfig !== null) {
      currentStationConfigRef.current = stationConfig
    }
  }, [stationConfig])
  // HICConfig
  useEffect(() => {
    if (hicConfig !== null) {
      currentHicConfigRef.current = hicConfig
    }
  }, [hicConfig])
  // StationState
  useEffect(() => {
    if (stationState !== null) {
      currentStationStateRef.current = stationState
    }
  }, [stationState])
  // NetworkPrimaryAddresses
  useEffect(() => {
    if (networkPrimaryAddresses !== null) {
      currentNetworkPrimaryAddressesRef.current = networkPrimaryAddresses
    }
  }, [networkPrimaryAddresses])

  // Recurrently request for the latest data
  useEffect(() => {
    if (!bleIsConnected) {
      currentHicStateRef.current = null
      currentStationSensorsRef.current = null
      currentStationConfigRef.current = null
      currentHicConfigRef.current = null
      currentStationStateRef.current = null
      currentNetworkPrimaryAddressesRef.current = null
      return
    }
    const _getLatestData = () => {
      getStationConfig()
      getHicConfig()
      getStationState()
      getNetworkPrimaryAddresses()
    }
    // Get the latest data on connection
    _getLatestData()

    // Set interval to get the latest data every 25 seconds
    let t = setInterval(() => {
      _getLatestData()
    }, 20e3)
    return () => {
      clearInterval(t)
    }
  }, [bleIsConnected])

  // Recurrently compile the latest data into telemetry
  useEffect(() => {
    if (bleIsConnected === false) {
      console.log(`DeviceTelemetry: bleIsConnected is false`)
      return
    }
    if (currentRemoteDevice === null) {
      console.log(`DeviceTelemetry: currentRemoteDevice is null`)
      return
    }
    console.log(
      `DeviceTelemetry: setting interval for deviceTelemetry: deviceUUID: ${currentRemoteDevice.UUID}`,
    )
    let pbDeviceUUID = uuidToPbUUID(currentRemoteDevice.UUID)
    const _sendDeviceTelemetry = () => {
      let deviceTelemetry = new DeviceTelemetry({
        client_timestamp: new Date().getTime(),
      })
      if (currentHicStateRef.current !== null) {
        deviceTelemetry.hic_state = currentHicStateRef.current
      }
      if (currentStationSensorsRef.current !== null) {
        deviceTelemetry.station_sensors = currentStationSensorsRef.current
      }
      if (currentStationConfigRef.current !== null) {
        deviceTelemetry.station_config = currentStationConfigRef.current
      }
      if (currentHicConfigRef.current !== null) {
        deviceTelemetry.hic_config = currentHicConfigRef.current
      }
      if (currentStationStateRef.current !== null) {
        deviceTelemetry.station_state = currentStationStateRef.current
      }
      if (currentNetworkPrimaryAddressesRef.current !== null) {
        deviceTelemetry.network_primary_ip_addresses = currentNetworkPrimaryAddressesRef.current
      }
      console.log(`DeviceTelemetry:`, deviceTelemetry.toObject())
      setDeviceTelemetry(pbDeviceUUID, deviceTelemetry)
    }
    // Send once on device connection
    setTimeout(() => {
      _sendDeviceTelemetry()
    }, 10e3)
    // Send every 30 seconds afterwards
    let t = setInterval(() => {
      _sendDeviceTelemetry()
    }, 30e3)
    return () => {
      clearInterval(t)
    }
  }, [bleIsConnected, currentRemoteDevice])

  useEffect(() => {
    if (setDeviceTelemetryAck === null) {
      return
    }
    console.log(`DeviceTelemetry: setDeviceTelemetryAck:`, setDeviceTelemetryAck.toObject())
  }, [setDeviceTelemetryAck])
}

interface SyncronizationState {
  enterpriseDevices: ICloudDevice[] | null
  currentRemoteDevice: ICloudDevice | null

  enterpriseUsers: ICloudUser[] | null
  bruteEnterpriseUsers: ICloudBruteUser[] | null
  currentUser: ICloudUser | null
  currentEnterprise: ICloudEnterprise | null
  isAdmin: boolean

  entitySyncStateMap: Record<string, ISyncEntityLocationAndState>
  aggregatedSyncProgressMap: Record<string, ISyncAggregatedProgress>
}

interface SyncronizationContextType extends SyncronizationState {
  setEntitySyncStateMap: (entitySyncStateMap: Record<string, ISyncEntityLocationAndState>) => void
  setAggregatedSyncProgressMap: (
    aggregatedSyncProgressMap: Record<string, ISyncAggregatedProgress>,
  ) => void
  externallySetCurrentUser(user: ICloudUser | null): void
  externallyRefreshEnterpriseDevices(): void
  externallyRefreshEnterpriseUsers(): void
  externallyRefreshBruteEnterpriseUsers(): void
}

const SyncronizationContext = createContext<SyncronizationContextType | undefined>(undefined)

export const useSyncronizationContext = (): SyncronizationContextType => {
  let context = useContext(SyncronizationContext)
  if (context === undefined) {
    throw new Error(
      "useDeviceSyncronizationContext must be used within a DeviceSyncronizationProvider",
    )
  }
  return context
}

export const SyncronizationProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  // DeviceTelemetry

  // Users
  const [enterpriseUsers, setEnterpriseUsers] = useState<ICloudUser[] | null>(null)
  const [bruteEnterpriseUsers, setBruteEnterpriseUsers] = useState<ICloudBruteUser[] | null>(null)
  const [currentUser, setCurrentUser] = useState<ICloudUser | null>(getLocalStorageCloudApiUser())

  // Enterprises
  const [currentEnterprise, setCurrentEnterprise] = useState<ICloudEnterprise | null>(null)

  // Devices (remote)
  const [enterpriseDevices, setEnterpriseDevices] = useState<ICloudDevice[] | null>(null)
  const [currentRemoteDevice, setCurrentRemoteDevice] = useState<ICloudDevice | null>(null)

  // Device (local)
  const { bleIsConnected } = useUsercommContextBLE()
  const [stationConfig, getStationConfig] = useUsercommGetStationConfigBLE()

  // Syncronization
  const [entitySyncStateMap, setEntitySyncStateMap] = useState<
    Record<string, ISyncEntityLocationAndState>
  >({})

  const [aggregatedSyncProgressMap, setAggregatedSyncProgressMap] = useState<
    Record<string, ISyncAggregatedProgress>
  >({})

  useDeviceTelemetry(currentRemoteDevice)

  const memoStationConfigSerialNumber = useMemo(() => {
    if (stationConfig === null) {
      return ""
    }
    return stationConfig.serial_number
  }, [stationConfig])

  const memoStationConfigReadonlyStationDeviceSn = useMemo(() => {
    if (stationConfig === null) {
      return ""
    }
    return stationConfig.readonly_station_device_sn
  }, [stationConfig])

  useEffect(() => {
    if (bleIsConnected) {
      getStationConfig()
    }
  }, [bleIsConnected])

  const getEnterpriseDevices = useCallback(async (): Promise<ICloudDevice[] | null> => {
    let { entity: devices } = await callCloudApiV2<ICloudDevice[]>("/devices")
    return devices
  }, [])

  const getEnterpriseUsers = useCallback(async (): Promise<ICloudUser[] | null> => {
    let { entity: users } = await callCloudApiV2<ICloudUser[]>("/enterprise/users")
    return users
  }, [])

  const getEnterpriseBruteUsers = useCallback(async (): Promise<ICloudBruteUser[] | null> => {
    let { entity: users } = await callCloudApiV2<ICloudBruteUser[]>("/enterprise/brute/users")
    return users
  }, [])

  const getMe = useCallback(async (): Promise<ICloudUser | null> => {
    let { entity: user } = await callCloudApiV2<ICloudUser>("/me")
    return user
  }, [])

  // Enterprise Devices
  const refreshEnterpriseDevices = useCallback(() => {
    getEnterpriseDevices()
      .then((devices) => {
        setEnterpriseDevices(devices)
      })
      .catch((e: any) => {
        console.error(`SyncronizationProvider: refreshEnterpriseDevices: error:`, e)
      })
  }, [])
  useEffect(() => {
    refreshEnterpriseDevices()
  }, [])

  // Enterprise Users
  const refreshEnterpriseUsers = useCallback(() => {
    getEnterpriseUsers()
      .then((users) => {
        setEnterpriseUsers(users)
      })
      .catch((e: any) => {
        console.error(`SyncronizationProvider: refreshEnterpriseUsers: error:`, e)
      })
  }, [])
  useEffect(() => {
    if (currentUser === null) {
      return
    }
    refreshEnterpriseUsers()
  }, [currentUser])

  const refreshBruteEnterpriseUsers = useCallback(() => {
    getEnterpriseBruteUsers()
      .then((users) => {
        setBruteEnterpriseUsers(users)
      })
      .catch((e: any) => {
        console.error(`SyncronizationProvider: refreshBruteEnterpriseUsers: error:`, e)
      })
  }, [currentUser])

  useEffect(() => {
    if (currentUser === null) {
      return
    }
    refreshBruteEnterpriseUsers()
  }, [])

  // Me
  useEffect(() => {
    getMe().then((user) => {
      if (user === null) {
        return
      }
      setCurrentUser(user)
      setLocalStorageCloudApiUser(user) // for future uses in case there is no internet connection
      setCurrentEnterprise(user.Enterprise)
    })
  }, [])

  // Will serach for an existing device (by device_name) in the database
  // and return it if found; otherwise, will create a new device of type
  // LargueurH3 with the given
  // device_name and device_serial_number and return it
  const getOrUpsertRemoteDevice = useCallback(
    async (deviceName: string, deviceSerialNumber: string): Promise<ICloudDevice | null> => {
      let query = new URLSearchParams()
      query.set("device_name", deviceName)
      query.set("device_serial_number", deviceSerialNumber)
      query.set("device_type_uuid", "fcb5ca42-7dfb-45a2-a74f-5f9c59b99e68") // LargueurH3
      query.set("should_upsert", "true")
      let { resp, entity: device } = await callCloudApiV2<ICloudDevice>(
        `/device?${query.toString()}`,
      )
      if (resp && resp.status === 404) {
        throw new Error("not found")
      }
      return device
    },
    [],
  )
  useEffect(() => {
    if (memoStationConfigSerialNumber === "" || memoStationConfigReadonlyStationDeviceSn === "") {
      return
    }
    let deviceSnStr = ""
    let deviceSnBigInt = BigInt(memoStationConfigReadonlyStationDeviceSn)
    if (deviceSnBigInt !== BigInt(0)) {
      deviceSnStr = deviceSnBigInt.toString(16)
    }
    if (deviceSnStr === "") {
      throw new Error(
        "SyncronizationProvider: getOrUpsertRemoteDevice: device serial number is empty",
      )
    }
    getOrUpsertRemoteDevice(memoStationConfigSerialNumber, deviceSnStr)
      .then((device) => {
        setCurrentRemoteDevice(device)
      })
      .catch((err) => {
        console.error(`SyncronizationProvider: getOrUpsertRemoteDevice: error:`, err)
      })
  }, [memoStationConfigSerialNumber, memoStationConfigReadonlyStationDeviceSn])

  useEffect(() => {
    console.log(`DeviceSynchronizationProvider: currentRemoteDevice:`, currentRemoteDevice)
  }, [currentRemoteDevice])

  const memoIsAdmin = useMemo(() => {
    if (currentUser === null || currentUser.Roles === null) {
      return false
    }
    let isAdmin = false
    for (let role of currentUser.Roles) {
      if (role.Name === ECloudRole.SuperAdmin) {
        isAdmin = true
        break
      }
    }
    return isAdmin
  }, [currentUser])

  const externallySetCurrentUser = useCallback(
    (user: ICloudUser | null) => {
      setCurrentUser(user)
    },
    [setCurrentUser],
  )

  return (
    <SyncronizationContext.Provider
      value={{
        enterpriseDevices: enterpriseDevices,
        currentRemoteDevice,

        enterpriseUsers: enterpriseUsers,
        bruteEnterpriseUsers: bruteEnterpriseUsers,
        currentUser: currentUser,
        currentEnterprise: currentEnterprise,
        isAdmin: memoIsAdmin,

        entitySyncStateMap,
        aggregatedSyncProgressMap,

        setEntitySyncStateMap,
        setAggregatedSyncProgressMap,

        externallySetCurrentUser,
        externallyRefreshEnterpriseDevices: refreshEnterpriseDevices,
        externallyRefreshEnterpriseUsers: refreshEnterpriseUsers,
        externallyRefreshBruteEnterpriseUsers: refreshBruteEnterpriseUsers,
      }}
    >
      {children}
    </SyncronizationContext.Provider>
  )
}
