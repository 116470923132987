/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.12.4
 * source: main.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export enum NormType {
    UNKNOWN_NORM_TYPE = 0,
    EN_1177 = 1,
    EN_12503 = 2,
    EN_14960 = 3,
    EN_ISO_23659 = 4
}
export enum MethodTypeEN1177 {
    UNKNOWN_METHOD_TYPE_EN_1177 = 0,
    CRITICAL_FALL_HEIGHT_DETERMINATION = 1,
    IMPACT_ATTENUATION_COMPLIANCE = 2
}
export enum MatTypeEN12503 {
    UNKNOWN_MAT_TYPE_EN_12503 = 0,
    TYPE_1 = 1,
    TYPE_2 = 2,
    TYPE_3 = 3,
    TYPE_4 = 4,
    TYPE_5 = 5,
    TYPE_6 = 6,
    TYPE_7 = 7,
    TYPE_8 = 8,
    TYPE_9 = 9,
    TYPE_10 = 10,
    TYPE_11 = 11,
    TYPE_12 = 12,
    TYPE_EN12572 = 13
}
export enum UCPayloadType {
    SICO_ERROR = 0,
    SICO_GET_HIC_CONFIG_COMMAND = 1,
    SICO_SET_HIC_CONFIG_COMMAND = 2,
    SICO_GET_STATION_CONFIG_COMMAND = 3,
    SICO_SET_STATION_CONFIG_COMMAND = 4,
    SICO_DROP_HIC_COMMAND = 5,
    SICO_RESTART_STATION_COMMAND = 6,
    SICO_REBOOT_STATION_COMMAND = 7,
    SICO_HALT_STATION_COMMAND = 8,
    SICO_ADD_REVERSE_GEOCODE_ENTITY = 9,
    SICO_RESET_REVERSE_GEOCODE_CACHE = 10,
    SICO_LEGACY_HOST_API_REQUEST = 11,
    SICO_MTU_TEST = 12,
    SICO_ECHO_TEST = 13,
    SICO_GET_LOGS = 14,
    SICO_BASH_CMD = 15,
    SICO_WPA_GET_STATUS = 16,
    SICO_WPA_GET_SCAN_RESULTS = 17,
    SICO_WPA_GET_NETWORKS = 18,
    SICO_WPA_SET_NETWORKS = 19,
    SICO_WPA_REASSOCIATE = 20,
    SICO_NETWORK_GET_PRIMARY_IP_ADDRESSES = 21,
    SICO_NETWORK_ENABLE_OVPN = 22,
    SICO_NETWORK_DISABLE_OVPN = 23,
    SICO_GET_STATION_STATE = 24,
    SICO_GNSS_UBLOX_GET_STATE = 25,
    SICO_GNSS_UBLOX_SET_ALP_FILE = 26,
    SICO_GNSS_UBLOX_GET_ALP_FILES = 27,
    SICO_GNSS_UBLOX_WRITE_RTCM = 28,
    SICO_GNSS_UBLOX_RESET = 29,
    SICO_GET_RELEASES = 32,
    SICO_SET_RELEASE = 33,
    SICO_RENAME_RELEASE = 34,
    SICO_DELETE_RELEASE = 35,
    SICO_GET_CURRENT_RELEASE = 36,
    SICO_DOWNLOAD_RELEASE_BY_URI = 37,
    SICO_STORAGE_GET_ENTITY = 64,
    SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE = 65,
    SICO_STORAGE_GET_ENTITY_CHILDREN_RECURSIVE = 66,
    SICO_STORAGE_GET_ALL_ENTITIES_OF_TYPE = 67,
    SICO_STORAGE_CREATE_ENTITY = 68,
    SICO_STORAGE_UPDATE_ENTITY = 69,
    SICO_STORAGE_DELETE_ENTITY = 70,
    SICO_STORAGE_CLONE_ENTITY_RECURSIVE = 71,
    SICO_STORAGE_SET_ENTITY_PARENT = 72,
    SICO_STORAGE_GET_ALL_ENTITIES = 73,
    SICO_STORAGE_GET_ALL_GENERIC_ENTITIES = 74,
    SICO_STORAGE_WIPE_ENTITY = 75,
    SICO_STORAGE_GET_IMPACTS_WITH_REFERENCES = 80,
    SICO_STORAGE_GET_STATS = 81,
    SICO_SET_DEVICE_TELEMETRY = 90,
    SOCI_HIC_STATE_EVENT = 128,
    SOCI_STATION_SENSORS_EVENT = 129,
    SOCI_STATION_STATE_EVENT = 130,
    SOCI_COMBINED_STATION_SENSORS_AND_HIC_STATE_EVENT = 134,
    SOCI_HIC_MEASUREMENT_EVENT = 131,
    SOCI_REVERSE_GEOCODE_REQUEST = 132,
    SOCI_BASH_STDOUT = 133,
    CTRL_PING = 200,
    CTRL_PONG = 201
}
export enum StorageEntityType {
    UNKNOWN_STORAGE_ENTITY_TYPE = 0,
    USER = 1,
    SITE = 2,
    EQUIPMENT = 3,
    ZONE = 4,
    IMPACT = 5,
    ANNEX = 6,
    PICTURE = 7
}
export enum NominatimZoomType {
    UNKNOWN_NOMINATIM_ZOOM_TYPE = 0,
    COUNTRY = 3,
    STATE = 5,
    COUNTY = 8,
    CITY = 10,
    TOWN_BOROUGH = 12,
    VILLAGE_SUBURB = 13,
    NEIGHBOURHOOD = 14,
    ANY_SETTLEMENT = 15,
    MAJOR_STREETS = 16,
    MAJOR_MINOR_STREETS = 17,
    BUILDING = 18
}
export class SpecimenDataRow extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        index?: number;
        key?: string;
        value?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("key" in data && data.key != undefined) {
                this.key = data.key;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
        }
    }
    get index() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set index(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get key() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set key(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        index?: number;
        key?: string;
        value?: string;
    }): SpecimenDataRow {
        const message = new SpecimenDataRow({});
        if (data.index != null) {
            message.index = data.index;
        }
        if (data.key != null) {
            message.key = data.key;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        return message;
    }
    toObject() {
        const data: {
            index?: number;
            key?: string;
            value?: string;
        } = {};
        if (this.index != null) {
            data.index = this.index;
        }
        if (this.key != null) {
            data.key = this.key;
        }
        if (this.value != null) {
            data.value = this.value;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.index != 0)
            writer.writeUint32(1, this.index);
        if (this.key.length)
            writer.writeString(2, this.key);
        if (this.value.length)
            writer.writeString(3, this.value);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpecimenDataRow {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpecimenDataRow();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.index = reader.readUint32();
                    break;
                case 2:
                    message.key = reader.readString();
                    break;
                case 3:
                    message.value = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): SpecimenDataRow {
        return SpecimenDataRow.deserialize(bytes);
    }
}
export class UUID extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        value?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
        }
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set value(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        value?: Uint8Array;
    }): UUID {
        const message = new UUID({});
        if (data.value != null) {
            message.value = data.value;
        }
        return message;
    }
    toObject() {
        const data: {
            value?: Uint8Array;
        } = {};
        if (this.value != null) {
            data.value = this.value;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.value.length)
            writer.writeBytes(1, this.value);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UUID {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UUID();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.value = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): UUID {
        return UUID.deserialize(bytes);
    }
}
export class User extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: UUID;
        username?: string;
        enterprise?: string;
        is_admin?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("username" in data && data.username != undefined) {
                this.username = data.username;
            }
            if ("enterprise" in data && data.enterprise != undefined) {
                this.enterprise = data.enterprise;
            }
            if ("is_admin" in data && data.is_admin != undefined) {
                this.is_admin = data.is_admin;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 1) as UUID;
    }
    set uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_uuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get username() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set username(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get enterprise() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set enterprise(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get is_admin() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set is_admin(value: boolean) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data: {
        uuid?: ReturnType<typeof UUID.prototype.toObject>;
        username?: string;
        enterprise?: string;
        is_admin?: boolean;
    }): User {
        const message = new User({});
        if (data.uuid != null) {
            message.uuid = UUID.fromObject(data.uuid);
        }
        if (data.username != null) {
            message.username = data.username;
        }
        if (data.enterprise != null) {
            message.enterprise = data.enterprise;
        }
        if (data.is_admin != null) {
            message.is_admin = data.is_admin;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: ReturnType<typeof UUID.prototype.toObject>;
            username?: string;
            enterprise?: string;
            is_admin?: boolean;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid.toObject();
        }
        if (this.username != null) {
            data.username = this.username;
        }
        if (this.enterprise != null) {
            data.enterprise = this.enterprise;
        }
        if (this.is_admin != null) {
            data.is_admin = this.is_admin;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_uuid)
            writer.writeMessage(1, this.uuid, () => this.uuid.serialize(writer));
        if (this.username.length)
            writer.writeString(2, this.username);
        if (this.enterprise.length)
            writer.writeString(3, this.enterprise);
        if (this.is_admin != false)
            writer.writeBool(4, this.is_admin);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): User {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new User();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.uuid, () => message.uuid = UUID.deserialize(reader));
                    break;
                case 2:
                    message.username = reader.readString();
                    break;
                case 3:
                    message.enterprise = reader.readString();
                    break;
                case 4:
                    message.is_admin = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): User {
        return User.deserialize(bytes);
    }
}
export class Site extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: UUID;
        user_uuid?: UUID;
        device_sn?: string;
        norm_type?: NormType;
        method_type_en_1177?: MethodTypeEN1177;
        is_lab_test?: boolean;
        site_name?: string;
        mission_name?: string;
        client_name?: string;
        address?: string;
        pictures?: UUID[];
        execution_date?: number;
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [20], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("user_uuid" in data && data.user_uuid != undefined) {
                this.user_uuid = data.user_uuid;
            }
            if ("device_sn" in data && data.device_sn != undefined) {
                this.device_sn = data.device_sn;
            }
            if ("norm_type" in data && data.norm_type != undefined) {
                this.norm_type = data.norm_type;
            }
            if ("method_type_en_1177" in data && data.method_type_en_1177 != undefined) {
                this.method_type_en_1177 = data.method_type_en_1177;
            }
            if ("is_lab_test" in data && data.is_lab_test != undefined) {
                this.is_lab_test = data.is_lab_test;
            }
            if ("site_name" in data && data.site_name != undefined) {
                this.site_name = data.site_name;
            }
            if ("mission_name" in data && data.mission_name != undefined) {
                this.mission_name = data.mission_name;
            }
            if ("client_name" in data && data.client_name != undefined) {
                this.client_name = data.client_name;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("pictures" in data && data.pictures != undefined) {
                this.pictures = data.pictures;
            }
            if ("execution_date" in data && data.execution_date != undefined) {
                this.execution_date = data.execution_date;
            }
            if ("created_at" in data && data.created_at != undefined) {
                this.created_at = data.created_at;
            }
            if ("updated_at" in data && data.updated_at != undefined) {
                this.updated_at = data.updated_at;
            }
            if ("deleted_at" in data && data.deleted_at != undefined) {
                this.deleted_at = data.deleted_at;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 1) as UUID;
    }
    set uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_uuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get user_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 2) as UUID;
    }
    set user_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_user_uuid() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get device_sn() {
        return pb_1.Message.getFieldWithDefault(this, 3, "0") as string;
    }
    set device_sn(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get norm_type() {
        return pb_1.Message.getFieldWithDefault(this, 4, NormType.UNKNOWN_NORM_TYPE) as NormType;
    }
    set norm_type(value: NormType) {
        pb_1.Message.setField(this, 4, value);
    }
    get method_type_en_1177() {
        return pb_1.Message.getFieldWithDefault(this, 5, MethodTypeEN1177.UNKNOWN_METHOD_TYPE_EN_1177) as MethodTypeEN1177;
    }
    set method_type_en_1177(value: MethodTypeEN1177) {
        pb_1.Message.setField(this, 5, value);
    }
    get is_lab_test() {
        return pb_1.Message.getFieldWithDefault(this, 6, false) as boolean;
    }
    set is_lab_test(value: boolean) {
        pb_1.Message.setField(this, 6, value);
    }
    get site_name() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set site_name(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get mission_name() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set mission_name(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get client_name() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set client_name(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get pictures() {
        return pb_1.Message.getRepeatedWrapperField(this, UUID, 20) as UUID[];
    }
    set pictures(value: UUID[]) {
        pb_1.Message.setRepeatedWrapperField(this, 20, value);
    }
    get execution_date() {
        return pb_1.Message.getFieldWithDefault(this, 21, 0) as number;
    }
    set execution_date(value: number) {
        pb_1.Message.setField(this, 21, value);
    }
    get created_at() {
        return pb_1.Message.getFieldWithDefault(this, 30, 0) as number;
    }
    set created_at(value: number) {
        pb_1.Message.setField(this, 30, value);
    }
    get updated_at() {
        return pb_1.Message.getFieldWithDefault(this, 31, 0) as number;
    }
    set updated_at(value: number) {
        pb_1.Message.setField(this, 31, value);
    }
    get deleted_at() {
        return pb_1.Message.getFieldWithDefault(this, 32, 0) as number;
    }
    set deleted_at(value: number) {
        pb_1.Message.setField(this, 32, value);
    }
    static fromObject(data: {
        uuid?: ReturnType<typeof UUID.prototype.toObject>;
        user_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        device_sn?: string;
        norm_type?: NormType;
        method_type_en_1177?: MethodTypeEN1177;
        is_lab_test?: boolean;
        site_name?: string;
        mission_name?: string;
        client_name?: string;
        address?: string;
        pictures?: ReturnType<typeof UUID.prototype.toObject>[];
        execution_date?: number;
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }): Site {
        const message = new Site({});
        if (data.uuid != null) {
            message.uuid = UUID.fromObject(data.uuid);
        }
        if (data.user_uuid != null) {
            message.user_uuid = UUID.fromObject(data.user_uuid);
        }
        if (data.device_sn != null) {
            message.device_sn = data.device_sn;
        }
        if (data.norm_type != null) {
            message.norm_type = data.norm_type;
        }
        if (data.method_type_en_1177 != null) {
            message.method_type_en_1177 = data.method_type_en_1177;
        }
        if (data.is_lab_test != null) {
            message.is_lab_test = data.is_lab_test;
        }
        if (data.site_name != null) {
            message.site_name = data.site_name;
        }
        if (data.mission_name != null) {
            message.mission_name = data.mission_name;
        }
        if (data.client_name != null) {
            message.client_name = data.client_name;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.pictures != null) {
            message.pictures = data.pictures.map(item => UUID.fromObject(item));
        }
        if (data.execution_date != null) {
            message.execution_date = data.execution_date;
        }
        if (data.created_at != null) {
            message.created_at = data.created_at;
        }
        if (data.updated_at != null) {
            message.updated_at = data.updated_at;
        }
        if (data.deleted_at != null) {
            message.deleted_at = data.deleted_at;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: ReturnType<typeof UUID.prototype.toObject>;
            user_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            device_sn?: string;
            norm_type?: NormType;
            method_type_en_1177?: MethodTypeEN1177;
            is_lab_test?: boolean;
            site_name?: string;
            mission_name?: string;
            client_name?: string;
            address?: string;
            pictures?: ReturnType<typeof UUID.prototype.toObject>[];
            execution_date?: number;
            created_at?: number;
            updated_at?: number;
            deleted_at?: number;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid.toObject();
        }
        if (this.user_uuid != null) {
            data.user_uuid = this.user_uuid.toObject();
        }
        if (this.device_sn != null) {
            data.device_sn = this.device_sn;
        }
        if (this.norm_type != null) {
            data.norm_type = this.norm_type;
        }
        if (this.method_type_en_1177 != null) {
            data.method_type_en_1177 = this.method_type_en_1177;
        }
        if (this.is_lab_test != null) {
            data.is_lab_test = this.is_lab_test;
        }
        if (this.site_name != null) {
            data.site_name = this.site_name;
        }
        if (this.mission_name != null) {
            data.mission_name = this.mission_name;
        }
        if (this.client_name != null) {
            data.client_name = this.client_name;
        }
        if (this.address != null) {
            data.address = this.address;
        }
        if (this.pictures != null) {
            data.pictures = this.pictures.map((item: UUID) => item.toObject());
        }
        if (this.execution_date != null) {
            data.execution_date = this.execution_date;
        }
        if (this.created_at != null) {
            data.created_at = this.created_at;
        }
        if (this.updated_at != null) {
            data.updated_at = this.updated_at;
        }
        if (this.deleted_at != null) {
            data.deleted_at = this.deleted_at;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_uuid)
            writer.writeMessage(1, this.uuid, () => this.uuid.serialize(writer));
        if (this.has_user_uuid)
            writer.writeMessage(2, this.user_uuid, () => this.user_uuid.serialize(writer));
        if (this.device_sn != "0")
            writer.writeUint64String(3, this.device_sn);
        if (this.norm_type != NormType.UNKNOWN_NORM_TYPE)
            writer.writeEnum(4, this.norm_type);
        if (this.method_type_en_1177 != MethodTypeEN1177.UNKNOWN_METHOD_TYPE_EN_1177)
            writer.writeEnum(5, this.method_type_en_1177);
        if (this.is_lab_test != false)
            writer.writeBool(6, this.is_lab_test);
        if (this.site_name.length)
            writer.writeString(7, this.site_name);
        if (this.mission_name.length)
            writer.writeString(8, this.mission_name);
        if (this.client_name.length)
            writer.writeString(9, this.client_name);
        if (this.address.length)
            writer.writeString(10, this.address);
        if (this.pictures.length)
            writer.writeRepeatedMessage(20, this.pictures, (item: UUID) => item.serialize(writer));
        if (this.execution_date != 0)
            writer.writeUint64(21, this.execution_date);
        if (this.created_at != 0)
            writer.writeUint64(30, this.created_at);
        if (this.updated_at != 0)
            writer.writeUint64(31, this.updated_at);
        if (this.deleted_at != 0)
            writer.writeUint64(32, this.deleted_at);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Site {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Site();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.uuid, () => message.uuid = UUID.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.user_uuid, () => message.user_uuid = UUID.deserialize(reader));
                    break;
                case 3:
                    message.device_sn = reader.readUint64String();
                    break;
                case 4:
                    message.norm_type = reader.readEnum();
                    break;
                case 5:
                    message.method_type_en_1177 = reader.readEnum();
                    break;
                case 6:
                    message.is_lab_test = reader.readBool();
                    break;
                case 7:
                    message.site_name = reader.readString();
                    break;
                case 8:
                    message.mission_name = reader.readString();
                    break;
                case 9:
                    message.client_name = reader.readString();
                    break;
                case 10:
                    message.address = reader.readString();
                    break;
                case 20:
                    reader.readMessage(message.pictures, () => pb_1.Message.addToRepeatedWrapperField(message, 20, UUID.deserialize(reader), UUID));
                    break;
                case 21:
                    message.execution_date = reader.readUint64();
                    break;
                case 30:
                    message.created_at = reader.readUint64();
                    break;
                case 31:
                    message.updated_at = reader.readUint64();
                    break;
                case 32:
                    message.deleted_at = reader.readUint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Site {
        return Site.deserialize(bytes);
    }
}
export class Annex extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: UUID;
        site_uuid?: UUID;
        annex_content?: string;
        annex_idx?: number;
        pictures?: UUID[];
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [20], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("site_uuid" in data && data.site_uuid != undefined) {
                this.site_uuid = data.site_uuid;
            }
            if ("annex_content" in data && data.annex_content != undefined) {
                this.annex_content = data.annex_content;
            }
            if ("annex_idx" in data && data.annex_idx != undefined) {
                this.annex_idx = data.annex_idx;
            }
            if ("pictures" in data && data.pictures != undefined) {
                this.pictures = data.pictures;
            }
            if ("created_at" in data && data.created_at != undefined) {
                this.created_at = data.created_at;
            }
            if ("updated_at" in data && data.updated_at != undefined) {
                this.updated_at = data.updated_at;
            }
            if ("deleted_at" in data && data.deleted_at != undefined) {
                this.deleted_at = data.deleted_at;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 1) as UUID;
    }
    set uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_uuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get site_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 2) as UUID;
    }
    set site_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_site_uuid() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get annex_content() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set annex_content(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get annex_idx() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set annex_idx(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get pictures() {
        return pb_1.Message.getRepeatedWrapperField(this, UUID, 20) as UUID[];
    }
    set pictures(value: UUID[]) {
        pb_1.Message.setRepeatedWrapperField(this, 20, value);
    }
    get created_at() {
        return pb_1.Message.getFieldWithDefault(this, 30, 0) as number;
    }
    set created_at(value: number) {
        pb_1.Message.setField(this, 30, value);
    }
    get updated_at() {
        return pb_1.Message.getFieldWithDefault(this, 31, 0) as number;
    }
    set updated_at(value: number) {
        pb_1.Message.setField(this, 31, value);
    }
    get deleted_at() {
        return pb_1.Message.getFieldWithDefault(this, 32, 0) as number;
    }
    set deleted_at(value: number) {
        pb_1.Message.setField(this, 32, value);
    }
    static fromObject(data: {
        uuid?: ReturnType<typeof UUID.prototype.toObject>;
        site_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        annex_content?: string;
        annex_idx?: number;
        pictures?: ReturnType<typeof UUID.prototype.toObject>[];
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }): Annex {
        const message = new Annex({});
        if (data.uuid != null) {
            message.uuid = UUID.fromObject(data.uuid);
        }
        if (data.site_uuid != null) {
            message.site_uuid = UUID.fromObject(data.site_uuid);
        }
        if (data.annex_content != null) {
            message.annex_content = data.annex_content;
        }
        if (data.annex_idx != null) {
            message.annex_idx = data.annex_idx;
        }
        if (data.pictures != null) {
            message.pictures = data.pictures.map(item => UUID.fromObject(item));
        }
        if (data.created_at != null) {
            message.created_at = data.created_at;
        }
        if (data.updated_at != null) {
            message.updated_at = data.updated_at;
        }
        if (data.deleted_at != null) {
            message.deleted_at = data.deleted_at;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: ReturnType<typeof UUID.prototype.toObject>;
            site_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            annex_content?: string;
            annex_idx?: number;
            pictures?: ReturnType<typeof UUID.prototype.toObject>[];
            created_at?: number;
            updated_at?: number;
            deleted_at?: number;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid.toObject();
        }
        if (this.site_uuid != null) {
            data.site_uuid = this.site_uuid.toObject();
        }
        if (this.annex_content != null) {
            data.annex_content = this.annex_content;
        }
        if (this.annex_idx != null) {
            data.annex_idx = this.annex_idx;
        }
        if (this.pictures != null) {
            data.pictures = this.pictures.map((item: UUID) => item.toObject());
        }
        if (this.created_at != null) {
            data.created_at = this.created_at;
        }
        if (this.updated_at != null) {
            data.updated_at = this.updated_at;
        }
        if (this.deleted_at != null) {
            data.deleted_at = this.deleted_at;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_uuid)
            writer.writeMessage(1, this.uuid, () => this.uuid.serialize(writer));
        if (this.has_site_uuid)
            writer.writeMessage(2, this.site_uuid, () => this.site_uuid.serialize(writer));
        if (this.annex_content.length)
            writer.writeString(3, this.annex_content);
        if (this.annex_idx != 0)
            writer.writeUint32(4, this.annex_idx);
        if (this.pictures.length)
            writer.writeRepeatedMessage(20, this.pictures, (item: UUID) => item.serialize(writer));
        if (this.created_at != 0)
            writer.writeUint64(30, this.created_at);
        if (this.updated_at != 0)
            writer.writeUint64(31, this.updated_at);
        if (this.deleted_at != 0)
            writer.writeUint64(32, this.deleted_at);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Annex {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Annex();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.uuid, () => message.uuid = UUID.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.site_uuid, () => message.site_uuid = UUID.deserialize(reader));
                    break;
                case 3:
                    message.annex_content = reader.readString();
                    break;
                case 4:
                    message.annex_idx = reader.readUint32();
                    break;
                case 20:
                    reader.readMessage(message.pictures, () => pb_1.Message.addToRepeatedWrapperField(message, 20, UUID.deserialize(reader), UUID));
                    break;
                case 30:
                    message.created_at = reader.readUint64();
                    break;
                case 31:
                    message.updated_at = reader.readUint64();
                    break;
                case 32:
                    message.deleted_at = reader.readUint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Annex {
        return Annex.deserialize(bytes);
    }
}
export class Equipment extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: UUID;
        site_uuid?: UUID;
        equipment_name?: string;
        equipment_reference?: string;
        equipment_manufacturer?: string;
        equipment_ffh_max?: number;
        floor_reference?: string;
        floor_manufacturer?: string;
        floor_kind?: string;
        floor_substrat?: string;
        floor_condition?: string;
        sports_mat_type?: MatTypeEN12503;
        sports_mat_length?: number;
        sports_mat_width?: number;
        sports_mat_thickness_side_one?: number;
        sports_mat_thickness_side_two?: number;
        sports_mat_thickness_side_three?: number;
        sports_mat_thickness_side_four?: number;
        sports_mat_metal_frame_is_present?: boolean;
        trampoline_impactor_weight?: number;
        pictures?: UUID[];
        equipment_installation_date?: number;
        floor_installation_date?: number;
        specimen_data?: SpecimenDataRow[];
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [20, 23], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("site_uuid" in data && data.site_uuid != undefined) {
                this.site_uuid = data.site_uuid;
            }
            if ("equipment_name" in data && data.equipment_name != undefined) {
                this.equipment_name = data.equipment_name;
            }
            if ("equipment_reference" in data && data.equipment_reference != undefined) {
                this.equipment_reference = data.equipment_reference;
            }
            if ("equipment_manufacturer" in data && data.equipment_manufacturer != undefined) {
                this.equipment_manufacturer = data.equipment_manufacturer;
            }
            if ("equipment_ffh_max" in data && data.equipment_ffh_max != undefined) {
                this.equipment_ffh_max = data.equipment_ffh_max;
            }
            if ("floor_reference" in data && data.floor_reference != undefined) {
                this.floor_reference = data.floor_reference;
            }
            if ("floor_manufacturer" in data && data.floor_manufacturer != undefined) {
                this.floor_manufacturer = data.floor_manufacturer;
            }
            if ("floor_kind" in data && data.floor_kind != undefined) {
                this.floor_kind = data.floor_kind;
            }
            if ("floor_substrat" in data && data.floor_substrat != undefined) {
                this.floor_substrat = data.floor_substrat;
            }
            if ("floor_condition" in data && data.floor_condition != undefined) {
                this.floor_condition = data.floor_condition;
            }
            if ("sports_mat_type" in data && data.sports_mat_type != undefined) {
                this.sports_mat_type = data.sports_mat_type;
            }
            if ("sports_mat_length" in data && data.sports_mat_length != undefined) {
                this.sports_mat_length = data.sports_mat_length;
            }
            if ("sports_mat_width" in data && data.sports_mat_width != undefined) {
                this.sports_mat_width = data.sports_mat_width;
            }
            if ("sports_mat_thickness_side_one" in data && data.sports_mat_thickness_side_one != undefined) {
                this.sports_mat_thickness_side_one = data.sports_mat_thickness_side_one;
            }
            if ("sports_mat_thickness_side_two" in data && data.sports_mat_thickness_side_two != undefined) {
                this.sports_mat_thickness_side_two = data.sports_mat_thickness_side_two;
            }
            if ("sports_mat_thickness_side_three" in data && data.sports_mat_thickness_side_three != undefined) {
                this.sports_mat_thickness_side_three = data.sports_mat_thickness_side_three;
            }
            if ("sports_mat_thickness_side_four" in data && data.sports_mat_thickness_side_four != undefined) {
                this.sports_mat_thickness_side_four = data.sports_mat_thickness_side_four;
            }
            if ("sports_mat_metal_frame_is_present" in data && data.sports_mat_metal_frame_is_present != undefined) {
                this.sports_mat_metal_frame_is_present = data.sports_mat_metal_frame_is_present;
            }
            if ("trampoline_impactor_weight" in data && data.trampoline_impactor_weight != undefined) {
                this.trampoline_impactor_weight = data.trampoline_impactor_weight;
            }
            if ("pictures" in data && data.pictures != undefined) {
                this.pictures = data.pictures;
            }
            if ("equipment_installation_date" in data && data.equipment_installation_date != undefined) {
                this.equipment_installation_date = data.equipment_installation_date;
            }
            if ("floor_installation_date" in data && data.floor_installation_date != undefined) {
                this.floor_installation_date = data.floor_installation_date;
            }
            if ("specimen_data" in data && data.specimen_data != undefined) {
                this.specimen_data = data.specimen_data;
            }
            if ("created_at" in data && data.created_at != undefined) {
                this.created_at = data.created_at;
            }
            if ("updated_at" in data && data.updated_at != undefined) {
                this.updated_at = data.updated_at;
            }
            if ("deleted_at" in data && data.deleted_at != undefined) {
                this.deleted_at = data.deleted_at;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 1) as UUID;
    }
    set uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_uuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get site_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 2) as UUID;
    }
    set site_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_site_uuid() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get equipment_name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set equipment_name(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get equipment_reference() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set equipment_reference(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get equipment_manufacturer() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set equipment_manufacturer(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get equipment_ffh_max() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set equipment_ffh_max(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get floor_reference() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set floor_reference(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get floor_manufacturer() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set floor_manufacturer(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get floor_kind() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set floor_kind(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get floor_substrat() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set floor_substrat(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get floor_condition() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set floor_condition(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get sports_mat_type() {
        return pb_1.Message.getFieldWithDefault(this, 12, MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503) as MatTypeEN12503;
    }
    set sports_mat_type(value: MatTypeEN12503) {
        pb_1.Message.setField(this, 12, value);
    }
    get sports_mat_length() {
        return pb_1.Message.getFieldWithDefault(this, 13, 0) as number;
    }
    set sports_mat_length(value: number) {
        pb_1.Message.setField(this, 13, value);
    }
    get sports_mat_width() {
        return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
    }
    set sports_mat_width(value: number) {
        pb_1.Message.setField(this, 14, value);
    }
    get sports_mat_thickness_side_one() {
        return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
    }
    set sports_mat_thickness_side_one(value: number) {
        pb_1.Message.setField(this, 15, value);
    }
    get sports_mat_thickness_side_two() {
        return pb_1.Message.getFieldWithDefault(this, 16, 0) as number;
    }
    set sports_mat_thickness_side_two(value: number) {
        pb_1.Message.setField(this, 16, value);
    }
    get sports_mat_thickness_side_three() {
        return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
    }
    set sports_mat_thickness_side_three(value: number) {
        pb_1.Message.setField(this, 17, value);
    }
    get sports_mat_thickness_side_four() {
        return pb_1.Message.getFieldWithDefault(this, 18, 0) as number;
    }
    set sports_mat_thickness_side_four(value: number) {
        pb_1.Message.setField(this, 18, value);
    }
    get sports_mat_metal_frame_is_present() {
        return pb_1.Message.getFieldWithDefault(this, 19, false) as boolean;
    }
    set sports_mat_metal_frame_is_present(value: boolean) {
        pb_1.Message.setField(this, 19, value);
    }
    get trampoline_impactor_weight() {
        return pb_1.Message.getFieldWithDefault(this, 24, 0) as number;
    }
    set trampoline_impactor_weight(value: number) {
        pb_1.Message.setField(this, 24, value);
    }
    get pictures() {
        return pb_1.Message.getRepeatedWrapperField(this, UUID, 20) as UUID[];
    }
    set pictures(value: UUID[]) {
        pb_1.Message.setRepeatedWrapperField(this, 20, value);
    }
    get equipment_installation_date() {
        return pb_1.Message.getFieldWithDefault(this, 21, 0) as number;
    }
    set equipment_installation_date(value: number) {
        pb_1.Message.setField(this, 21, value);
    }
    get floor_installation_date() {
        return pb_1.Message.getFieldWithDefault(this, 22, 0) as number;
    }
    set floor_installation_date(value: number) {
        pb_1.Message.setField(this, 22, value);
    }
    get specimen_data() {
        return pb_1.Message.getRepeatedWrapperField(this, SpecimenDataRow, 23) as SpecimenDataRow[];
    }
    set specimen_data(value: SpecimenDataRow[]) {
        pb_1.Message.setRepeatedWrapperField(this, 23, value);
    }
    get created_at() {
        return pb_1.Message.getFieldWithDefault(this, 30, 0) as number;
    }
    set created_at(value: number) {
        pb_1.Message.setField(this, 30, value);
    }
    get updated_at() {
        return pb_1.Message.getFieldWithDefault(this, 31, 0) as number;
    }
    set updated_at(value: number) {
        pb_1.Message.setField(this, 31, value);
    }
    get deleted_at() {
        return pb_1.Message.getFieldWithDefault(this, 32, 0) as number;
    }
    set deleted_at(value: number) {
        pb_1.Message.setField(this, 32, value);
    }
    static fromObject(data: {
        uuid?: ReturnType<typeof UUID.prototype.toObject>;
        site_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        equipment_name?: string;
        equipment_reference?: string;
        equipment_manufacturer?: string;
        equipment_ffh_max?: number;
        floor_reference?: string;
        floor_manufacturer?: string;
        floor_kind?: string;
        floor_substrat?: string;
        floor_condition?: string;
        sports_mat_type?: MatTypeEN12503;
        sports_mat_length?: number;
        sports_mat_width?: number;
        sports_mat_thickness_side_one?: number;
        sports_mat_thickness_side_two?: number;
        sports_mat_thickness_side_three?: number;
        sports_mat_thickness_side_four?: number;
        sports_mat_metal_frame_is_present?: boolean;
        trampoline_impactor_weight?: number;
        pictures?: ReturnType<typeof UUID.prototype.toObject>[];
        equipment_installation_date?: number;
        floor_installation_date?: number;
        specimen_data?: ReturnType<typeof SpecimenDataRow.prototype.toObject>[];
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }): Equipment {
        const message = new Equipment({});
        if (data.uuid != null) {
            message.uuid = UUID.fromObject(data.uuid);
        }
        if (data.site_uuid != null) {
            message.site_uuid = UUID.fromObject(data.site_uuid);
        }
        if (data.equipment_name != null) {
            message.equipment_name = data.equipment_name;
        }
        if (data.equipment_reference != null) {
            message.equipment_reference = data.equipment_reference;
        }
        if (data.equipment_manufacturer != null) {
            message.equipment_manufacturer = data.equipment_manufacturer;
        }
        if (data.equipment_ffh_max != null) {
            message.equipment_ffh_max = data.equipment_ffh_max;
        }
        if (data.floor_reference != null) {
            message.floor_reference = data.floor_reference;
        }
        if (data.floor_manufacturer != null) {
            message.floor_manufacturer = data.floor_manufacturer;
        }
        if (data.floor_kind != null) {
            message.floor_kind = data.floor_kind;
        }
        if (data.floor_substrat != null) {
            message.floor_substrat = data.floor_substrat;
        }
        if (data.floor_condition != null) {
            message.floor_condition = data.floor_condition;
        }
        if (data.sports_mat_type != null) {
            message.sports_mat_type = data.sports_mat_type;
        }
        if (data.sports_mat_length != null) {
            message.sports_mat_length = data.sports_mat_length;
        }
        if (data.sports_mat_width != null) {
            message.sports_mat_width = data.sports_mat_width;
        }
        if (data.sports_mat_thickness_side_one != null) {
            message.sports_mat_thickness_side_one = data.sports_mat_thickness_side_one;
        }
        if (data.sports_mat_thickness_side_two != null) {
            message.sports_mat_thickness_side_two = data.sports_mat_thickness_side_two;
        }
        if (data.sports_mat_thickness_side_three != null) {
            message.sports_mat_thickness_side_three = data.sports_mat_thickness_side_three;
        }
        if (data.sports_mat_thickness_side_four != null) {
            message.sports_mat_thickness_side_four = data.sports_mat_thickness_side_four;
        }
        if (data.sports_mat_metal_frame_is_present != null) {
            message.sports_mat_metal_frame_is_present = data.sports_mat_metal_frame_is_present;
        }
        if (data.trampoline_impactor_weight != null) {
            message.trampoline_impactor_weight = data.trampoline_impactor_weight;
        }
        if (data.pictures != null) {
            message.pictures = data.pictures.map(item => UUID.fromObject(item));
        }
        if (data.equipment_installation_date != null) {
            message.equipment_installation_date = data.equipment_installation_date;
        }
        if (data.floor_installation_date != null) {
            message.floor_installation_date = data.floor_installation_date;
        }
        if (data.specimen_data != null) {
            message.specimen_data = data.specimen_data.map(item => SpecimenDataRow.fromObject(item));
        }
        if (data.created_at != null) {
            message.created_at = data.created_at;
        }
        if (data.updated_at != null) {
            message.updated_at = data.updated_at;
        }
        if (data.deleted_at != null) {
            message.deleted_at = data.deleted_at;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: ReturnType<typeof UUID.prototype.toObject>;
            site_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            equipment_name?: string;
            equipment_reference?: string;
            equipment_manufacturer?: string;
            equipment_ffh_max?: number;
            floor_reference?: string;
            floor_manufacturer?: string;
            floor_kind?: string;
            floor_substrat?: string;
            floor_condition?: string;
            sports_mat_type?: MatTypeEN12503;
            sports_mat_length?: number;
            sports_mat_width?: number;
            sports_mat_thickness_side_one?: number;
            sports_mat_thickness_side_two?: number;
            sports_mat_thickness_side_three?: number;
            sports_mat_thickness_side_four?: number;
            sports_mat_metal_frame_is_present?: boolean;
            trampoline_impactor_weight?: number;
            pictures?: ReturnType<typeof UUID.prototype.toObject>[];
            equipment_installation_date?: number;
            floor_installation_date?: number;
            specimen_data?: ReturnType<typeof SpecimenDataRow.prototype.toObject>[];
            created_at?: number;
            updated_at?: number;
            deleted_at?: number;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid.toObject();
        }
        if (this.site_uuid != null) {
            data.site_uuid = this.site_uuid.toObject();
        }
        if (this.equipment_name != null) {
            data.equipment_name = this.equipment_name;
        }
        if (this.equipment_reference != null) {
            data.equipment_reference = this.equipment_reference;
        }
        if (this.equipment_manufacturer != null) {
            data.equipment_manufacturer = this.equipment_manufacturer;
        }
        if (this.equipment_ffh_max != null) {
            data.equipment_ffh_max = this.equipment_ffh_max;
        }
        if (this.floor_reference != null) {
            data.floor_reference = this.floor_reference;
        }
        if (this.floor_manufacturer != null) {
            data.floor_manufacturer = this.floor_manufacturer;
        }
        if (this.floor_kind != null) {
            data.floor_kind = this.floor_kind;
        }
        if (this.floor_substrat != null) {
            data.floor_substrat = this.floor_substrat;
        }
        if (this.floor_condition != null) {
            data.floor_condition = this.floor_condition;
        }
        if (this.sports_mat_type != null) {
            data.sports_mat_type = this.sports_mat_type;
        }
        if (this.sports_mat_length != null) {
            data.sports_mat_length = this.sports_mat_length;
        }
        if (this.sports_mat_width != null) {
            data.sports_mat_width = this.sports_mat_width;
        }
        if (this.sports_mat_thickness_side_one != null) {
            data.sports_mat_thickness_side_one = this.sports_mat_thickness_side_one;
        }
        if (this.sports_mat_thickness_side_two != null) {
            data.sports_mat_thickness_side_two = this.sports_mat_thickness_side_two;
        }
        if (this.sports_mat_thickness_side_three != null) {
            data.sports_mat_thickness_side_three = this.sports_mat_thickness_side_three;
        }
        if (this.sports_mat_thickness_side_four != null) {
            data.sports_mat_thickness_side_four = this.sports_mat_thickness_side_four;
        }
        if (this.sports_mat_metal_frame_is_present != null) {
            data.sports_mat_metal_frame_is_present = this.sports_mat_metal_frame_is_present;
        }
        if (this.trampoline_impactor_weight != null) {
            data.trampoline_impactor_weight = this.trampoline_impactor_weight;
        }
        if (this.pictures != null) {
            data.pictures = this.pictures.map((item: UUID) => item.toObject());
        }
        if (this.equipment_installation_date != null) {
            data.equipment_installation_date = this.equipment_installation_date;
        }
        if (this.floor_installation_date != null) {
            data.floor_installation_date = this.floor_installation_date;
        }
        if (this.specimen_data != null) {
            data.specimen_data = this.specimen_data.map((item: SpecimenDataRow) => item.toObject());
        }
        if (this.created_at != null) {
            data.created_at = this.created_at;
        }
        if (this.updated_at != null) {
            data.updated_at = this.updated_at;
        }
        if (this.deleted_at != null) {
            data.deleted_at = this.deleted_at;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_uuid)
            writer.writeMessage(1, this.uuid, () => this.uuid.serialize(writer));
        if (this.has_site_uuid)
            writer.writeMessage(2, this.site_uuid, () => this.site_uuid.serialize(writer));
        if (this.equipment_name.length)
            writer.writeString(3, this.equipment_name);
        if (this.equipment_reference.length)
            writer.writeString(4, this.equipment_reference);
        if (this.equipment_manufacturer.length)
            writer.writeString(5, this.equipment_manufacturer);
        if (this.equipment_ffh_max != 0)
            writer.writeFloat(6, this.equipment_ffh_max);
        if (this.floor_reference.length)
            writer.writeString(7, this.floor_reference);
        if (this.floor_manufacturer.length)
            writer.writeString(8, this.floor_manufacturer);
        if (this.floor_kind.length)
            writer.writeString(9, this.floor_kind);
        if (this.floor_substrat.length)
            writer.writeString(10, this.floor_substrat);
        if (this.floor_condition.length)
            writer.writeString(11, this.floor_condition);
        if (this.sports_mat_type != MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503)
            writer.writeEnum(12, this.sports_mat_type);
        if (this.sports_mat_length != 0)
            writer.writeUint32(13, this.sports_mat_length);
        if (this.sports_mat_width != 0)
            writer.writeUint32(14, this.sports_mat_width);
        if (this.sports_mat_thickness_side_one != 0)
            writer.writeUint32(15, this.sports_mat_thickness_side_one);
        if (this.sports_mat_thickness_side_two != 0)
            writer.writeUint32(16, this.sports_mat_thickness_side_two);
        if (this.sports_mat_thickness_side_three != 0)
            writer.writeUint32(17, this.sports_mat_thickness_side_three);
        if (this.sports_mat_thickness_side_four != 0)
            writer.writeUint32(18, this.sports_mat_thickness_side_four);
        if (this.sports_mat_metal_frame_is_present != false)
            writer.writeBool(19, this.sports_mat_metal_frame_is_present);
        if (this.trampoline_impactor_weight != 0)
            writer.writeFloat(24, this.trampoline_impactor_weight);
        if (this.pictures.length)
            writer.writeRepeatedMessage(20, this.pictures, (item: UUID) => item.serialize(writer));
        if (this.equipment_installation_date != 0)
            writer.writeUint64(21, this.equipment_installation_date);
        if (this.floor_installation_date != 0)
            writer.writeUint64(22, this.floor_installation_date);
        if (this.specimen_data.length)
            writer.writeRepeatedMessage(23, this.specimen_data, (item: SpecimenDataRow) => item.serialize(writer));
        if (this.created_at != 0)
            writer.writeUint64(30, this.created_at);
        if (this.updated_at != 0)
            writer.writeUint64(31, this.updated_at);
        if (this.deleted_at != 0)
            writer.writeUint64(32, this.deleted_at);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Equipment {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Equipment();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.uuid, () => message.uuid = UUID.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.site_uuid, () => message.site_uuid = UUID.deserialize(reader));
                    break;
                case 3:
                    message.equipment_name = reader.readString();
                    break;
                case 4:
                    message.equipment_reference = reader.readString();
                    break;
                case 5:
                    message.equipment_manufacturer = reader.readString();
                    break;
                case 6:
                    message.equipment_ffh_max = reader.readFloat();
                    break;
                case 7:
                    message.floor_reference = reader.readString();
                    break;
                case 8:
                    message.floor_manufacturer = reader.readString();
                    break;
                case 9:
                    message.floor_kind = reader.readString();
                    break;
                case 10:
                    message.floor_substrat = reader.readString();
                    break;
                case 11:
                    message.floor_condition = reader.readString();
                    break;
                case 12:
                    message.sports_mat_type = reader.readEnum();
                    break;
                case 13:
                    message.sports_mat_length = reader.readUint32();
                    break;
                case 14:
                    message.sports_mat_width = reader.readUint32();
                    break;
                case 15:
                    message.sports_mat_thickness_side_one = reader.readUint32();
                    break;
                case 16:
                    message.sports_mat_thickness_side_two = reader.readUint32();
                    break;
                case 17:
                    message.sports_mat_thickness_side_three = reader.readUint32();
                    break;
                case 18:
                    message.sports_mat_thickness_side_four = reader.readUint32();
                    break;
                case 19:
                    message.sports_mat_metal_frame_is_present = reader.readBool();
                    break;
                case 24:
                    message.trampoline_impactor_weight = reader.readFloat();
                    break;
                case 20:
                    reader.readMessage(message.pictures, () => pb_1.Message.addToRepeatedWrapperField(message, 20, UUID.deserialize(reader), UUID));
                    break;
                case 21:
                    message.equipment_installation_date = reader.readUint64();
                    break;
                case 22:
                    message.floor_installation_date = reader.readUint64();
                    break;
                case 23:
                    reader.readMessage(message.specimen_data, () => pb_1.Message.addToRepeatedWrapperField(message, 23, SpecimenDataRow.deserialize(reader), SpecimenDataRow));
                    break;
                case 30:
                    message.created_at = reader.readUint64();
                    break;
                case 31:
                    message.updated_at = reader.readUint64();
                    break;
                case 32:
                    message.deleted_at = reader.readUint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Equipment {
        return Equipment.deserialize(bytes);
    }
}
export class Zone extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: UUID;
        equipment_uuid?: UUID;
        zone_name?: string;
        zone_ffh_max?: number;
        zone_temperature?: number;
        zone_humidity?: number;
        specimen_temperature?: number;
        specimen_humidity?: number;
        floor_thickness?: number;
        comments?: string;
        pictures?: UUID[];
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [20], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("equipment_uuid" in data && data.equipment_uuid != undefined) {
                this.equipment_uuid = data.equipment_uuid;
            }
            if ("zone_name" in data && data.zone_name != undefined) {
                this.zone_name = data.zone_name;
            }
            if ("zone_ffh_max" in data && data.zone_ffh_max != undefined) {
                this.zone_ffh_max = data.zone_ffh_max;
            }
            if ("zone_temperature" in data && data.zone_temperature != undefined) {
                this.zone_temperature = data.zone_temperature;
            }
            if ("zone_humidity" in data && data.zone_humidity != undefined) {
                this.zone_humidity = data.zone_humidity;
            }
            if ("specimen_temperature" in data && data.specimen_temperature != undefined) {
                this.specimen_temperature = data.specimen_temperature;
            }
            if ("specimen_humidity" in data && data.specimen_humidity != undefined) {
                this.specimen_humidity = data.specimen_humidity;
            }
            if ("floor_thickness" in data && data.floor_thickness != undefined) {
                this.floor_thickness = data.floor_thickness;
            }
            if ("comments" in data && data.comments != undefined) {
                this.comments = data.comments;
            }
            if ("pictures" in data && data.pictures != undefined) {
                this.pictures = data.pictures;
            }
            if ("created_at" in data && data.created_at != undefined) {
                this.created_at = data.created_at;
            }
            if ("updated_at" in data && data.updated_at != undefined) {
                this.updated_at = data.updated_at;
            }
            if ("deleted_at" in data && data.deleted_at != undefined) {
                this.deleted_at = data.deleted_at;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 1) as UUID;
    }
    set uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_uuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get equipment_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 2) as UUID;
    }
    set equipment_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_equipment_uuid() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get zone_name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set zone_name(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get zone_ffh_max() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set zone_ffh_max(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get zone_temperature() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set zone_temperature(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    get zone_humidity() {
        return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
    }
    set zone_humidity(value: number) {
        pb_1.Message.setField(this, 8, value);
    }
    get specimen_temperature() {
        return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
    }
    set specimen_temperature(value: number) {
        pb_1.Message.setField(this, 9, value);
    }
    get specimen_humidity() {
        return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
    }
    set specimen_humidity(value: number) {
        pb_1.Message.setField(this, 10, value);
    }
    get floor_thickness() {
        return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
    }
    set floor_thickness(value: number) {
        pb_1.Message.setField(this, 11, value);
    }
    get comments() {
        return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
    }
    set comments(value: string) {
        pb_1.Message.setField(this, 12, value);
    }
    get pictures() {
        return pb_1.Message.getRepeatedWrapperField(this, UUID, 20) as UUID[];
    }
    set pictures(value: UUID[]) {
        pb_1.Message.setRepeatedWrapperField(this, 20, value);
    }
    get created_at() {
        return pb_1.Message.getFieldWithDefault(this, 30, 0) as number;
    }
    set created_at(value: number) {
        pb_1.Message.setField(this, 30, value);
    }
    get updated_at() {
        return pb_1.Message.getFieldWithDefault(this, 31, 0) as number;
    }
    set updated_at(value: number) {
        pb_1.Message.setField(this, 31, value);
    }
    get deleted_at() {
        return pb_1.Message.getFieldWithDefault(this, 32, 0) as number;
    }
    set deleted_at(value: number) {
        pb_1.Message.setField(this, 32, value);
    }
    static fromObject(data: {
        uuid?: ReturnType<typeof UUID.prototype.toObject>;
        equipment_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        zone_name?: string;
        zone_ffh_max?: number;
        zone_temperature?: number;
        zone_humidity?: number;
        specimen_temperature?: number;
        specimen_humidity?: number;
        floor_thickness?: number;
        comments?: string;
        pictures?: ReturnType<typeof UUID.prototype.toObject>[];
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }): Zone {
        const message = new Zone({});
        if (data.uuid != null) {
            message.uuid = UUID.fromObject(data.uuid);
        }
        if (data.equipment_uuid != null) {
            message.equipment_uuid = UUID.fromObject(data.equipment_uuid);
        }
        if (data.zone_name != null) {
            message.zone_name = data.zone_name;
        }
        if (data.zone_ffh_max != null) {
            message.zone_ffh_max = data.zone_ffh_max;
        }
        if (data.zone_temperature != null) {
            message.zone_temperature = data.zone_temperature;
        }
        if (data.zone_humidity != null) {
            message.zone_humidity = data.zone_humidity;
        }
        if (data.specimen_temperature != null) {
            message.specimen_temperature = data.specimen_temperature;
        }
        if (data.specimen_humidity != null) {
            message.specimen_humidity = data.specimen_humidity;
        }
        if (data.floor_thickness != null) {
            message.floor_thickness = data.floor_thickness;
        }
        if (data.comments != null) {
            message.comments = data.comments;
        }
        if (data.pictures != null) {
            message.pictures = data.pictures.map(item => UUID.fromObject(item));
        }
        if (data.created_at != null) {
            message.created_at = data.created_at;
        }
        if (data.updated_at != null) {
            message.updated_at = data.updated_at;
        }
        if (data.deleted_at != null) {
            message.deleted_at = data.deleted_at;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: ReturnType<typeof UUID.prototype.toObject>;
            equipment_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            zone_name?: string;
            zone_ffh_max?: number;
            zone_temperature?: number;
            zone_humidity?: number;
            specimen_temperature?: number;
            specimen_humidity?: number;
            floor_thickness?: number;
            comments?: string;
            pictures?: ReturnType<typeof UUID.prototype.toObject>[];
            created_at?: number;
            updated_at?: number;
            deleted_at?: number;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid.toObject();
        }
        if (this.equipment_uuid != null) {
            data.equipment_uuid = this.equipment_uuid.toObject();
        }
        if (this.zone_name != null) {
            data.zone_name = this.zone_name;
        }
        if (this.zone_ffh_max != null) {
            data.zone_ffh_max = this.zone_ffh_max;
        }
        if (this.zone_temperature != null) {
            data.zone_temperature = this.zone_temperature;
        }
        if (this.zone_humidity != null) {
            data.zone_humidity = this.zone_humidity;
        }
        if (this.specimen_temperature != null) {
            data.specimen_temperature = this.specimen_temperature;
        }
        if (this.specimen_humidity != null) {
            data.specimen_humidity = this.specimen_humidity;
        }
        if (this.floor_thickness != null) {
            data.floor_thickness = this.floor_thickness;
        }
        if (this.comments != null) {
            data.comments = this.comments;
        }
        if (this.pictures != null) {
            data.pictures = this.pictures.map((item: UUID) => item.toObject());
        }
        if (this.created_at != null) {
            data.created_at = this.created_at;
        }
        if (this.updated_at != null) {
            data.updated_at = this.updated_at;
        }
        if (this.deleted_at != null) {
            data.deleted_at = this.deleted_at;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_uuid)
            writer.writeMessage(1, this.uuid, () => this.uuid.serialize(writer));
        if (this.has_equipment_uuid)
            writer.writeMessage(2, this.equipment_uuid, () => this.equipment_uuid.serialize(writer));
        if (this.zone_name.length)
            writer.writeString(3, this.zone_name);
        if (this.zone_ffh_max != 0)
            writer.writeFloat(6, this.zone_ffh_max);
        if (this.zone_temperature != 0)
            writer.writeFloat(7, this.zone_temperature);
        if (this.zone_humidity != 0)
            writer.writeFloat(8, this.zone_humidity);
        if (this.specimen_temperature != 0)
            writer.writeFloat(9, this.specimen_temperature);
        if (this.specimen_humidity != 0)
            writer.writeFloat(10, this.specimen_humidity);
        if (this.floor_thickness != 0)
            writer.writeFloat(11, this.floor_thickness);
        if (this.comments.length)
            writer.writeString(12, this.comments);
        if (this.pictures.length)
            writer.writeRepeatedMessage(20, this.pictures, (item: UUID) => item.serialize(writer));
        if (this.created_at != 0)
            writer.writeUint64(30, this.created_at);
        if (this.updated_at != 0)
            writer.writeUint64(31, this.updated_at);
        if (this.deleted_at != 0)
            writer.writeUint64(32, this.deleted_at);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Zone {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Zone();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.uuid, () => message.uuid = UUID.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.equipment_uuid, () => message.equipment_uuid = UUID.deserialize(reader));
                    break;
                case 3:
                    message.zone_name = reader.readString();
                    break;
                case 6:
                    message.zone_ffh_max = reader.readFloat();
                    break;
                case 7:
                    message.zone_temperature = reader.readFloat();
                    break;
                case 8:
                    message.zone_humidity = reader.readFloat();
                    break;
                case 9:
                    message.specimen_temperature = reader.readFloat();
                    break;
                case 10:
                    message.specimen_humidity = reader.readFloat();
                    break;
                case 11:
                    message.floor_thickness = reader.readFloat();
                    break;
                case 12:
                    message.comments = reader.readString();
                    break;
                case 20:
                    reader.readMessage(message.pictures, () => pb_1.Message.addToRepeatedWrapperField(message, 20, UUID.deserialize(reader), UUID));
                    break;
                case 30:
                    message.created_at = reader.readUint64();
                    break;
                case 31:
                    message.updated_at = reader.readUint64();
                    break;
                case 32:
                    message.deleted_at = reader.readUint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Zone {
        return Zone.deserialize(bytes);
    }
}
export class Impact extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: UUID;
        zone_uuid?: UUID;
        impact_hic?: number;
        impact_gmax?: number;
        impact_ffh?: number;
        impact_deflection?: number;
        impact_resilience?: number;
        impact_rebounce_height?: number;
        impact_velocity_inward?: number;
        impact_velocity_outward?: number;
        impact_duration?: number;
        impact_index_DEPRECATED?: number;
        sampling_frequency?: number;
        decimated_frequency?: number;
        impact_sample_threshold_index?: number;
        impact_table_threshold_index?: number;
        impact_table_impact_index?: number;
        impact_sample_impact_index?: number;
        impact_table_gmax_index?: number;
        impact_table_deflection_index?: number;
        impact_table_final_index?: number;
        impact_table_rebounce_index?: number;
        ax_points?: Uint8Array;
        ay_points?: Uint8Array;
        az_points?: Uint8Array;
        aq_points?: Uint8Array;
        rf_ax_points_decoded?: number[];
        rf_ay_points_decoded?: number[];
        rf_az_points_decoded?: number[];
        rf_aq_points_decoded?: number[];
        axay_diff_step?: number;
        axaz_diff_step?: number;
        impactor_weight?: number;
        longitude_DEPRECATED?: number;
        latitude_DEPRECATED?: number;
        longitude?: number;
        latitude?: number;
        temperature?: number;
        relative_humidity?: number;
        metadata_head_hic_version?: string;
        metadata_head_hic_serial_number?: string;
        metadata_station_version?: string;
        metadata_station_serial_number?: string;
        metadata_station_device_sn?: string;
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [113, 114, 115, 116], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("zone_uuid" in data && data.zone_uuid != undefined) {
                this.zone_uuid = data.zone_uuid;
            }
            if ("impact_hic" in data && data.impact_hic != undefined) {
                this.impact_hic = data.impact_hic;
            }
            if ("impact_gmax" in data && data.impact_gmax != undefined) {
                this.impact_gmax = data.impact_gmax;
            }
            if ("impact_ffh" in data && data.impact_ffh != undefined) {
                this.impact_ffh = data.impact_ffh;
            }
            if ("impact_deflection" in data && data.impact_deflection != undefined) {
                this.impact_deflection = data.impact_deflection;
            }
            if ("impact_resilience" in data && data.impact_resilience != undefined) {
                this.impact_resilience = data.impact_resilience;
            }
            if ("impact_rebounce_height" in data && data.impact_rebounce_height != undefined) {
                this.impact_rebounce_height = data.impact_rebounce_height;
            }
            if ("impact_velocity_inward" in data && data.impact_velocity_inward != undefined) {
                this.impact_velocity_inward = data.impact_velocity_inward;
            }
            if ("impact_velocity_outward" in data && data.impact_velocity_outward != undefined) {
                this.impact_velocity_outward = data.impact_velocity_outward;
            }
            if ("impact_duration" in data && data.impact_duration != undefined) {
                this.impact_duration = data.impact_duration;
            }
            if ("impact_index_DEPRECATED" in data && data.impact_index_DEPRECATED != undefined) {
                this.impact_index_DEPRECATED = data.impact_index_DEPRECATED;
            }
            if ("sampling_frequency" in data && data.sampling_frequency != undefined) {
                this.sampling_frequency = data.sampling_frequency;
            }
            if ("decimated_frequency" in data && data.decimated_frequency != undefined) {
                this.decimated_frequency = data.decimated_frequency;
            }
            if ("impact_sample_threshold_index" in data && data.impact_sample_threshold_index != undefined) {
                this.impact_sample_threshold_index = data.impact_sample_threshold_index;
            }
            if ("impact_table_threshold_index" in data && data.impact_table_threshold_index != undefined) {
                this.impact_table_threshold_index = data.impact_table_threshold_index;
            }
            if ("impact_table_impact_index" in data && data.impact_table_impact_index != undefined) {
                this.impact_table_impact_index = data.impact_table_impact_index;
            }
            if ("impact_sample_impact_index" in data && data.impact_sample_impact_index != undefined) {
                this.impact_sample_impact_index = data.impact_sample_impact_index;
            }
            if ("impact_table_gmax_index" in data && data.impact_table_gmax_index != undefined) {
                this.impact_table_gmax_index = data.impact_table_gmax_index;
            }
            if ("impact_table_deflection_index" in data && data.impact_table_deflection_index != undefined) {
                this.impact_table_deflection_index = data.impact_table_deflection_index;
            }
            if ("impact_table_final_index" in data && data.impact_table_final_index != undefined) {
                this.impact_table_final_index = data.impact_table_final_index;
            }
            if ("impact_table_rebounce_index" in data && data.impact_table_rebounce_index != undefined) {
                this.impact_table_rebounce_index = data.impact_table_rebounce_index;
            }
            if ("ax_points" in data && data.ax_points != undefined) {
                this.ax_points = data.ax_points;
            }
            if ("ay_points" in data && data.ay_points != undefined) {
                this.ay_points = data.ay_points;
            }
            if ("az_points" in data && data.az_points != undefined) {
                this.az_points = data.az_points;
            }
            if ("aq_points" in data && data.aq_points != undefined) {
                this.aq_points = data.aq_points;
            }
            if ("rf_ax_points_decoded" in data && data.rf_ax_points_decoded != undefined) {
                this.rf_ax_points_decoded = data.rf_ax_points_decoded;
            }
            if ("rf_ay_points_decoded" in data && data.rf_ay_points_decoded != undefined) {
                this.rf_ay_points_decoded = data.rf_ay_points_decoded;
            }
            if ("rf_az_points_decoded" in data && data.rf_az_points_decoded != undefined) {
                this.rf_az_points_decoded = data.rf_az_points_decoded;
            }
            if ("rf_aq_points_decoded" in data && data.rf_aq_points_decoded != undefined) {
                this.rf_aq_points_decoded = data.rf_aq_points_decoded;
            }
            if ("axay_diff_step" in data && data.axay_diff_step != undefined) {
                this.axay_diff_step = data.axay_diff_step;
            }
            if ("axaz_diff_step" in data && data.axaz_diff_step != undefined) {
                this.axaz_diff_step = data.axaz_diff_step;
            }
            if ("impactor_weight" in data && data.impactor_weight != undefined) {
                this.impactor_weight = data.impactor_weight;
            }
            if ("longitude_DEPRECATED" in data && data.longitude_DEPRECATED != undefined) {
                this.longitude_DEPRECATED = data.longitude_DEPRECATED;
            }
            if ("latitude_DEPRECATED" in data && data.latitude_DEPRECATED != undefined) {
                this.latitude_DEPRECATED = data.latitude_DEPRECATED;
            }
            if ("longitude" in data && data.longitude != undefined) {
                this.longitude = data.longitude;
            }
            if ("latitude" in data && data.latitude != undefined) {
                this.latitude = data.latitude;
            }
            if ("temperature" in data && data.temperature != undefined) {
                this.temperature = data.temperature;
            }
            if ("relative_humidity" in data && data.relative_humidity != undefined) {
                this.relative_humidity = data.relative_humidity;
            }
            if ("metadata_head_hic_version" in data && data.metadata_head_hic_version != undefined) {
                this.metadata_head_hic_version = data.metadata_head_hic_version;
            }
            if ("metadata_head_hic_serial_number" in data && data.metadata_head_hic_serial_number != undefined) {
                this.metadata_head_hic_serial_number = data.metadata_head_hic_serial_number;
            }
            if ("metadata_station_version" in data && data.metadata_station_version != undefined) {
                this.metadata_station_version = data.metadata_station_version;
            }
            if ("metadata_station_serial_number" in data && data.metadata_station_serial_number != undefined) {
                this.metadata_station_serial_number = data.metadata_station_serial_number;
            }
            if ("metadata_station_device_sn" in data && data.metadata_station_device_sn != undefined) {
                this.metadata_station_device_sn = data.metadata_station_device_sn;
            }
            if ("created_at" in data && data.created_at != undefined) {
                this.created_at = data.created_at;
            }
            if ("updated_at" in data && data.updated_at != undefined) {
                this.updated_at = data.updated_at;
            }
            if ("deleted_at" in data && data.deleted_at != undefined) {
                this.deleted_at = data.deleted_at;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 1) as UUID;
    }
    set uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_uuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get zone_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 2) as UUID;
    }
    set zone_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_zone_uuid() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get impact_hic() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set impact_hic(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get impact_gmax() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set impact_gmax(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get impact_ffh() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set impact_ffh(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get impact_deflection() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set impact_deflection(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get impact_resilience() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set impact_resilience(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    get impact_rebounce_height() {
        return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
    }
    set impact_rebounce_height(value: number) {
        pb_1.Message.setField(this, 17, value);
    }
    get impact_velocity_inward() {
        return pb_1.Message.getFieldWithDefault(this, 18, 0) as number;
    }
    set impact_velocity_inward(value: number) {
        pb_1.Message.setField(this, 18, value);
    }
    get impact_velocity_outward() {
        return pb_1.Message.getFieldWithDefault(this, 19, 0) as number;
    }
    set impact_velocity_outward(value: number) {
        pb_1.Message.setField(this, 19, value);
    }
    get impact_duration() {
        return pb_1.Message.getFieldWithDefault(this, 25, 0) as number;
    }
    set impact_duration(value: number) {
        pb_1.Message.setField(this, 25, value);
    }
    get impact_index_DEPRECATED() {
        return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
    }
    set impact_index_DEPRECATED(value: number) {
        pb_1.Message.setField(this, 8, value);
    }
    get sampling_frequency() {
        return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
    }
    set sampling_frequency(value: number) {
        pb_1.Message.setField(this, 9, value);
    }
    get decimated_frequency() {
        return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
    }
    set decimated_frequency(value: number) {
        pb_1.Message.setField(this, 10, value);
    }
    get impact_sample_threshold_index() {
        return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
    }
    set impact_sample_threshold_index(value: number) {
        pb_1.Message.setField(this, 11, value);
    }
    get impact_table_threshold_index() {
        return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
    }
    set impact_table_threshold_index(value: number) {
        pb_1.Message.setField(this, 12, value);
    }
    get impact_table_impact_index() {
        return pb_1.Message.getFieldWithDefault(this, 42, 0) as number;
    }
    set impact_table_impact_index(value: number) {
        pb_1.Message.setField(this, 42, value);
    }
    get impact_sample_impact_index() {
        return pb_1.Message.getFieldWithDefault(this, 43, 0) as number;
    }
    set impact_sample_impact_index(value: number) {
        pb_1.Message.setField(this, 43, value);
    }
    get impact_table_gmax_index() {
        return pb_1.Message.getFieldWithDefault(this, 44, 0) as number;
    }
    set impact_table_gmax_index(value: number) {
        pb_1.Message.setField(this, 44, value);
    }
    get impact_table_deflection_index() {
        return pb_1.Message.getFieldWithDefault(this, 45, 0) as number;
    }
    set impact_table_deflection_index(value: number) {
        pb_1.Message.setField(this, 45, value);
    }
    get impact_table_final_index() {
        return pb_1.Message.getFieldWithDefault(this, 46, 0) as number;
    }
    set impact_table_final_index(value: number) {
        pb_1.Message.setField(this, 46, value);
    }
    get impact_table_rebounce_index() {
        return pb_1.Message.getFieldWithDefault(this, 47, 0) as number;
    }
    set impact_table_rebounce_index(value: number) {
        pb_1.Message.setField(this, 47, value);
    }
    get ax_points() {
        return pb_1.Message.getFieldWithDefault(this, 13, new Uint8Array(0)) as Uint8Array;
    }
    set ax_points(value: Uint8Array) {
        pb_1.Message.setField(this, 13, value);
    }
    get ay_points() {
        return pb_1.Message.getFieldWithDefault(this, 14, new Uint8Array(0)) as Uint8Array;
    }
    set ay_points(value: Uint8Array) {
        pb_1.Message.setField(this, 14, value);
    }
    get az_points() {
        return pb_1.Message.getFieldWithDefault(this, 15, new Uint8Array(0)) as Uint8Array;
    }
    set az_points(value: Uint8Array) {
        pb_1.Message.setField(this, 15, value);
    }
    get aq_points() {
        return pb_1.Message.getFieldWithDefault(this, 16, new Uint8Array(0)) as Uint8Array;
    }
    set aq_points(value: Uint8Array) {
        pb_1.Message.setField(this, 16, value);
    }
    get rf_ax_points_decoded() {
        return pb_1.Message.getFieldWithDefault(this, 113, []) as number[];
    }
    set rf_ax_points_decoded(value: number[]) {
        pb_1.Message.setField(this, 113, value);
    }
    get rf_ay_points_decoded() {
        return pb_1.Message.getFieldWithDefault(this, 114, []) as number[];
    }
    set rf_ay_points_decoded(value: number[]) {
        pb_1.Message.setField(this, 114, value);
    }
    get rf_az_points_decoded() {
        return pb_1.Message.getFieldWithDefault(this, 115, []) as number[];
    }
    set rf_az_points_decoded(value: number[]) {
        pb_1.Message.setField(this, 115, value);
    }
    get rf_aq_points_decoded() {
        return pb_1.Message.getFieldWithDefault(this, 116, []) as number[];
    }
    set rf_aq_points_decoded(value: number[]) {
        pb_1.Message.setField(this, 116, value);
    }
    get axay_diff_step() {
        return pb_1.Message.getFieldWithDefault(this, 28, 0) as number;
    }
    set axay_diff_step(value: number) {
        pb_1.Message.setField(this, 28, value);
    }
    get axaz_diff_step() {
        return pb_1.Message.getFieldWithDefault(this, 29, 0) as number;
    }
    set axaz_diff_step(value: number) {
        pb_1.Message.setField(this, 29, value);
    }
    get impactor_weight() {
        return pb_1.Message.getFieldWithDefault(this, 20, 0) as number;
    }
    set impactor_weight(value: number) {
        pb_1.Message.setField(this, 20, value);
    }
    get longitude_DEPRECATED() {
        return pb_1.Message.getFieldWithDefault(this, 21, 0) as number;
    }
    set longitude_DEPRECATED(value: number) {
        pb_1.Message.setField(this, 21, value);
    }
    get latitude_DEPRECATED() {
        return pb_1.Message.getFieldWithDefault(this, 22, 0) as number;
    }
    set latitude_DEPRECATED(value: number) {
        pb_1.Message.setField(this, 22, value);
    }
    get longitude() {
        return pb_1.Message.getFieldWithDefault(this, 26, 0) as number;
    }
    set longitude(value: number) {
        pb_1.Message.setField(this, 26, value);
    }
    get latitude() {
        return pb_1.Message.getFieldWithDefault(this, 27, 0) as number;
    }
    set latitude(value: number) {
        pb_1.Message.setField(this, 27, value);
    }
    get temperature() {
        return pb_1.Message.getFieldWithDefault(this, 23, 0) as number;
    }
    set temperature(value: number) {
        pb_1.Message.setField(this, 23, value);
    }
    get relative_humidity() {
        return pb_1.Message.getFieldWithDefault(this, 24, 0) as number;
    }
    set relative_humidity(value: number) {
        pb_1.Message.setField(this, 24, value);
    }
    get metadata_head_hic_version() {
        return pb_1.Message.getFieldWithDefault(this, 64, "") as string;
    }
    set metadata_head_hic_version(value: string) {
        pb_1.Message.setField(this, 64, value);
    }
    get metadata_head_hic_serial_number() {
        return pb_1.Message.getFieldWithDefault(this, 65, "") as string;
    }
    set metadata_head_hic_serial_number(value: string) {
        pb_1.Message.setField(this, 65, value);
    }
    get metadata_station_version() {
        return pb_1.Message.getFieldWithDefault(this, 66, "") as string;
    }
    set metadata_station_version(value: string) {
        pb_1.Message.setField(this, 66, value);
    }
    get metadata_station_serial_number() {
        return pb_1.Message.getFieldWithDefault(this, 67, "") as string;
    }
    set metadata_station_serial_number(value: string) {
        pb_1.Message.setField(this, 67, value);
    }
    get metadata_station_device_sn() {
        return pb_1.Message.getFieldWithDefault(this, 68, "0") as string;
    }
    set metadata_station_device_sn(value: string) {
        pb_1.Message.setField(this, 68, value);
    }
    get created_at() {
        return pb_1.Message.getFieldWithDefault(this, 30, 0) as number;
    }
    set created_at(value: number) {
        pb_1.Message.setField(this, 30, value);
    }
    get updated_at() {
        return pb_1.Message.getFieldWithDefault(this, 31, 0) as number;
    }
    set updated_at(value: number) {
        pb_1.Message.setField(this, 31, value);
    }
    get deleted_at() {
        return pb_1.Message.getFieldWithDefault(this, 32, 0) as number;
    }
    set deleted_at(value: number) {
        pb_1.Message.setField(this, 32, value);
    }
    static fromObject(data: {
        uuid?: ReturnType<typeof UUID.prototype.toObject>;
        zone_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        impact_hic?: number;
        impact_gmax?: number;
        impact_ffh?: number;
        impact_deflection?: number;
        impact_resilience?: number;
        impact_rebounce_height?: number;
        impact_velocity_inward?: number;
        impact_velocity_outward?: number;
        impact_duration?: number;
        impact_index_DEPRECATED?: number;
        sampling_frequency?: number;
        decimated_frequency?: number;
        impact_sample_threshold_index?: number;
        impact_table_threshold_index?: number;
        impact_table_impact_index?: number;
        impact_sample_impact_index?: number;
        impact_table_gmax_index?: number;
        impact_table_deflection_index?: number;
        impact_table_final_index?: number;
        impact_table_rebounce_index?: number;
        ax_points?: Uint8Array;
        ay_points?: Uint8Array;
        az_points?: Uint8Array;
        aq_points?: Uint8Array;
        rf_ax_points_decoded?: number[];
        rf_ay_points_decoded?: number[];
        rf_az_points_decoded?: number[];
        rf_aq_points_decoded?: number[];
        axay_diff_step?: number;
        axaz_diff_step?: number;
        impactor_weight?: number;
        longitude_DEPRECATED?: number;
        latitude_DEPRECATED?: number;
        longitude?: number;
        latitude?: number;
        temperature?: number;
        relative_humidity?: number;
        metadata_head_hic_version?: string;
        metadata_head_hic_serial_number?: string;
        metadata_station_version?: string;
        metadata_station_serial_number?: string;
        metadata_station_device_sn?: string;
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }): Impact {
        const message = new Impact({});
        if (data.uuid != null) {
            message.uuid = UUID.fromObject(data.uuid);
        }
        if (data.zone_uuid != null) {
            message.zone_uuid = UUID.fromObject(data.zone_uuid);
        }
        if (data.impact_hic != null) {
            message.impact_hic = data.impact_hic;
        }
        if (data.impact_gmax != null) {
            message.impact_gmax = data.impact_gmax;
        }
        if (data.impact_ffh != null) {
            message.impact_ffh = data.impact_ffh;
        }
        if (data.impact_deflection != null) {
            message.impact_deflection = data.impact_deflection;
        }
        if (data.impact_resilience != null) {
            message.impact_resilience = data.impact_resilience;
        }
        if (data.impact_rebounce_height != null) {
            message.impact_rebounce_height = data.impact_rebounce_height;
        }
        if (data.impact_velocity_inward != null) {
            message.impact_velocity_inward = data.impact_velocity_inward;
        }
        if (data.impact_velocity_outward != null) {
            message.impact_velocity_outward = data.impact_velocity_outward;
        }
        if (data.impact_duration != null) {
            message.impact_duration = data.impact_duration;
        }
        if (data.impact_index_DEPRECATED != null) {
            message.impact_index_DEPRECATED = data.impact_index_DEPRECATED;
        }
        if (data.sampling_frequency != null) {
            message.sampling_frequency = data.sampling_frequency;
        }
        if (data.decimated_frequency != null) {
            message.decimated_frequency = data.decimated_frequency;
        }
        if (data.impact_sample_threshold_index != null) {
            message.impact_sample_threshold_index = data.impact_sample_threshold_index;
        }
        if (data.impact_table_threshold_index != null) {
            message.impact_table_threshold_index = data.impact_table_threshold_index;
        }
        if (data.impact_table_impact_index != null) {
            message.impact_table_impact_index = data.impact_table_impact_index;
        }
        if (data.impact_sample_impact_index != null) {
            message.impact_sample_impact_index = data.impact_sample_impact_index;
        }
        if (data.impact_table_gmax_index != null) {
            message.impact_table_gmax_index = data.impact_table_gmax_index;
        }
        if (data.impact_table_deflection_index != null) {
            message.impact_table_deflection_index = data.impact_table_deflection_index;
        }
        if (data.impact_table_final_index != null) {
            message.impact_table_final_index = data.impact_table_final_index;
        }
        if (data.impact_table_rebounce_index != null) {
            message.impact_table_rebounce_index = data.impact_table_rebounce_index;
        }
        if (data.ax_points != null) {
            message.ax_points = data.ax_points;
        }
        if (data.ay_points != null) {
            message.ay_points = data.ay_points;
        }
        if (data.az_points != null) {
            message.az_points = data.az_points;
        }
        if (data.aq_points != null) {
            message.aq_points = data.aq_points;
        }
        if (data.rf_ax_points_decoded != null) {
            message.rf_ax_points_decoded = data.rf_ax_points_decoded;
        }
        if (data.rf_ay_points_decoded != null) {
            message.rf_ay_points_decoded = data.rf_ay_points_decoded;
        }
        if (data.rf_az_points_decoded != null) {
            message.rf_az_points_decoded = data.rf_az_points_decoded;
        }
        if (data.rf_aq_points_decoded != null) {
            message.rf_aq_points_decoded = data.rf_aq_points_decoded;
        }
        if (data.axay_diff_step != null) {
            message.axay_diff_step = data.axay_diff_step;
        }
        if (data.axaz_diff_step != null) {
            message.axaz_diff_step = data.axaz_diff_step;
        }
        if (data.impactor_weight != null) {
            message.impactor_weight = data.impactor_weight;
        }
        if (data.longitude_DEPRECATED != null) {
            message.longitude_DEPRECATED = data.longitude_DEPRECATED;
        }
        if (data.latitude_DEPRECATED != null) {
            message.latitude_DEPRECATED = data.latitude_DEPRECATED;
        }
        if (data.longitude != null) {
            message.longitude = data.longitude;
        }
        if (data.latitude != null) {
            message.latitude = data.latitude;
        }
        if (data.temperature != null) {
            message.temperature = data.temperature;
        }
        if (data.relative_humidity != null) {
            message.relative_humidity = data.relative_humidity;
        }
        if (data.metadata_head_hic_version != null) {
            message.metadata_head_hic_version = data.metadata_head_hic_version;
        }
        if (data.metadata_head_hic_serial_number != null) {
            message.metadata_head_hic_serial_number = data.metadata_head_hic_serial_number;
        }
        if (data.metadata_station_version != null) {
            message.metadata_station_version = data.metadata_station_version;
        }
        if (data.metadata_station_serial_number != null) {
            message.metadata_station_serial_number = data.metadata_station_serial_number;
        }
        if (data.metadata_station_device_sn != null) {
            message.metadata_station_device_sn = data.metadata_station_device_sn;
        }
        if (data.created_at != null) {
            message.created_at = data.created_at;
        }
        if (data.updated_at != null) {
            message.updated_at = data.updated_at;
        }
        if (data.deleted_at != null) {
            message.deleted_at = data.deleted_at;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: ReturnType<typeof UUID.prototype.toObject>;
            zone_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            impact_hic?: number;
            impact_gmax?: number;
            impact_ffh?: number;
            impact_deflection?: number;
            impact_resilience?: number;
            impact_rebounce_height?: number;
            impact_velocity_inward?: number;
            impact_velocity_outward?: number;
            impact_duration?: number;
            impact_index_DEPRECATED?: number;
            sampling_frequency?: number;
            decimated_frequency?: number;
            impact_sample_threshold_index?: number;
            impact_table_threshold_index?: number;
            impact_table_impact_index?: number;
            impact_sample_impact_index?: number;
            impact_table_gmax_index?: number;
            impact_table_deflection_index?: number;
            impact_table_final_index?: number;
            impact_table_rebounce_index?: number;
            ax_points?: Uint8Array;
            ay_points?: Uint8Array;
            az_points?: Uint8Array;
            aq_points?: Uint8Array;
            rf_ax_points_decoded?: number[];
            rf_ay_points_decoded?: number[];
            rf_az_points_decoded?: number[];
            rf_aq_points_decoded?: number[];
            axay_diff_step?: number;
            axaz_diff_step?: number;
            impactor_weight?: number;
            longitude_DEPRECATED?: number;
            latitude_DEPRECATED?: number;
            longitude?: number;
            latitude?: number;
            temperature?: number;
            relative_humidity?: number;
            metadata_head_hic_version?: string;
            metadata_head_hic_serial_number?: string;
            metadata_station_version?: string;
            metadata_station_serial_number?: string;
            metadata_station_device_sn?: string;
            created_at?: number;
            updated_at?: number;
            deleted_at?: number;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid.toObject();
        }
        if (this.zone_uuid != null) {
            data.zone_uuid = this.zone_uuid.toObject();
        }
        if (this.impact_hic != null) {
            data.impact_hic = this.impact_hic;
        }
        if (this.impact_gmax != null) {
            data.impact_gmax = this.impact_gmax;
        }
        if (this.impact_ffh != null) {
            data.impact_ffh = this.impact_ffh;
        }
        if (this.impact_deflection != null) {
            data.impact_deflection = this.impact_deflection;
        }
        if (this.impact_resilience != null) {
            data.impact_resilience = this.impact_resilience;
        }
        if (this.impact_rebounce_height != null) {
            data.impact_rebounce_height = this.impact_rebounce_height;
        }
        if (this.impact_velocity_inward != null) {
            data.impact_velocity_inward = this.impact_velocity_inward;
        }
        if (this.impact_velocity_outward != null) {
            data.impact_velocity_outward = this.impact_velocity_outward;
        }
        if (this.impact_duration != null) {
            data.impact_duration = this.impact_duration;
        }
        if (this.impact_index_DEPRECATED != null) {
            data.impact_index_DEPRECATED = this.impact_index_DEPRECATED;
        }
        if (this.sampling_frequency != null) {
            data.sampling_frequency = this.sampling_frequency;
        }
        if (this.decimated_frequency != null) {
            data.decimated_frequency = this.decimated_frequency;
        }
        if (this.impact_sample_threshold_index != null) {
            data.impact_sample_threshold_index = this.impact_sample_threshold_index;
        }
        if (this.impact_table_threshold_index != null) {
            data.impact_table_threshold_index = this.impact_table_threshold_index;
        }
        if (this.impact_table_impact_index != null) {
            data.impact_table_impact_index = this.impact_table_impact_index;
        }
        if (this.impact_sample_impact_index != null) {
            data.impact_sample_impact_index = this.impact_sample_impact_index;
        }
        if (this.impact_table_gmax_index != null) {
            data.impact_table_gmax_index = this.impact_table_gmax_index;
        }
        if (this.impact_table_deflection_index != null) {
            data.impact_table_deflection_index = this.impact_table_deflection_index;
        }
        if (this.impact_table_final_index != null) {
            data.impact_table_final_index = this.impact_table_final_index;
        }
        if (this.impact_table_rebounce_index != null) {
            data.impact_table_rebounce_index = this.impact_table_rebounce_index;
        }
        if (this.ax_points != null) {
            data.ax_points = this.ax_points;
        }
        if (this.ay_points != null) {
            data.ay_points = this.ay_points;
        }
        if (this.az_points != null) {
            data.az_points = this.az_points;
        }
        if (this.aq_points != null) {
            data.aq_points = this.aq_points;
        }
        if (this.rf_ax_points_decoded != null) {
            data.rf_ax_points_decoded = this.rf_ax_points_decoded;
        }
        if (this.rf_ay_points_decoded != null) {
            data.rf_ay_points_decoded = this.rf_ay_points_decoded;
        }
        if (this.rf_az_points_decoded != null) {
            data.rf_az_points_decoded = this.rf_az_points_decoded;
        }
        if (this.rf_aq_points_decoded != null) {
            data.rf_aq_points_decoded = this.rf_aq_points_decoded;
        }
        if (this.axay_diff_step != null) {
            data.axay_diff_step = this.axay_diff_step;
        }
        if (this.axaz_diff_step != null) {
            data.axaz_diff_step = this.axaz_diff_step;
        }
        if (this.impactor_weight != null) {
            data.impactor_weight = this.impactor_weight;
        }
        if (this.longitude_DEPRECATED != null) {
            data.longitude_DEPRECATED = this.longitude_DEPRECATED;
        }
        if (this.latitude_DEPRECATED != null) {
            data.latitude_DEPRECATED = this.latitude_DEPRECATED;
        }
        if (this.longitude != null) {
            data.longitude = this.longitude;
        }
        if (this.latitude != null) {
            data.latitude = this.latitude;
        }
        if (this.temperature != null) {
            data.temperature = this.temperature;
        }
        if (this.relative_humidity != null) {
            data.relative_humidity = this.relative_humidity;
        }
        if (this.metadata_head_hic_version != null) {
            data.metadata_head_hic_version = this.metadata_head_hic_version;
        }
        if (this.metadata_head_hic_serial_number != null) {
            data.metadata_head_hic_serial_number = this.metadata_head_hic_serial_number;
        }
        if (this.metadata_station_version != null) {
            data.metadata_station_version = this.metadata_station_version;
        }
        if (this.metadata_station_serial_number != null) {
            data.metadata_station_serial_number = this.metadata_station_serial_number;
        }
        if (this.metadata_station_device_sn != null) {
            data.metadata_station_device_sn = this.metadata_station_device_sn;
        }
        if (this.created_at != null) {
            data.created_at = this.created_at;
        }
        if (this.updated_at != null) {
            data.updated_at = this.updated_at;
        }
        if (this.deleted_at != null) {
            data.deleted_at = this.deleted_at;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_uuid)
            writer.writeMessage(1, this.uuid, () => this.uuid.serialize(writer));
        if (this.has_zone_uuid)
            writer.writeMessage(2, this.zone_uuid, () => this.zone_uuid.serialize(writer));
        if (this.impact_hic != 0)
            writer.writeFloat(3, this.impact_hic);
        if (this.impact_gmax != 0)
            writer.writeFloat(4, this.impact_gmax);
        if (this.impact_ffh != 0)
            writer.writeFloat(5, this.impact_ffh);
        if (this.impact_deflection != 0)
            writer.writeFloat(6, this.impact_deflection);
        if (this.impact_resilience != 0)
            writer.writeFloat(7, this.impact_resilience);
        if (this.impact_rebounce_height != 0)
            writer.writeFloat(17, this.impact_rebounce_height);
        if (this.impact_velocity_inward != 0)
            writer.writeFloat(18, this.impact_velocity_inward);
        if (this.impact_velocity_outward != 0)
            writer.writeFloat(19, this.impact_velocity_outward);
        if (this.impact_duration != 0)
            writer.writeFloat(25, this.impact_duration);
        if (this.impact_index_DEPRECATED != 0)
            writer.writeUint32(8, this.impact_index_DEPRECATED);
        if (this.sampling_frequency != 0)
            writer.writeUint32(9, this.sampling_frequency);
        if (this.decimated_frequency != 0)
            writer.writeFloat(10, this.decimated_frequency);
        if (this.impact_sample_threshold_index != 0)
            writer.writeUint32(11, this.impact_sample_threshold_index);
        if (this.impact_table_threshold_index != 0)
            writer.writeUint32(12, this.impact_table_threshold_index);
        if (this.impact_table_impact_index != 0)
            writer.writeUint32(42, this.impact_table_impact_index);
        if (this.impact_sample_impact_index != 0)
            writer.writeUint32(43, this.impact_sample_impact_index);
        if (this.impact_table_gmax_index != 0)
            writer.writeUint32(44, this.impact_table_gmax_index);
        if (this.impact_table_deflection_index != 0)
            writer.writeUint32(45, this.impact_table_deflection_index);
        if (this.impact_table_final_index != 0)
            writer.writeUint32(46, this.impact_table_final_index);
        if (this.impact_table_rebounce_index != 0)
            writer.writeUint32(47, this.impact_table_rebounce_index);
        if (this.ax_points.length)
            writer.writeBytes(13, this.ax_points);
        if (this.ay_points.length)
            writer.writeBytes(14, this.ay_points);
        if (this.az_points.length)
            writer.writeBytes(15, this.az_points);
        if (this.aq_points.length)
            writer.writeBytes(16, this.aq_points);
        if (this.rf_ax_points_decoded.length)
            writer.writePackedFloat(113, this.rf_ax_points_decoded);
        if (this.rf_ay_points_decoded.length)
            writer.writePackedFloat(114, this.rf_ay_points_decoded);
        if (this.rf_az_points_decoded.length)
            writer.writePackedFloat(115, this.rf_az_points_decoded);
        if (this.rf_aq_points_decoded.length)
            writer.writePackedFloat(116, this.rf_aq_points_decoded);
        if (this.axay_diff_step != 0)
            writer.writeFloat(28, this.axay_diff_step);
        if (this.axaz_diff_step != 0)
            writer.writeFloat(29, this.axaz_diff_step);
        if (this.impactor_weight != 0)
            writer.writeFloat(20, this.impactor_weight);
        if (this.longitude_DEPRECATED != 0)
            writer.writeFloat(21, this.longitude_DEPRECATED);
        if (this.latitude_DEPRECATED != 0)
            writer.writeFloat(22, this.latitude_DEPRECATED);
        if (this.longitude != 0)
            writer.writeDouble(26, this.longitude);
        if (this.latitude != 0)
            writer.writeDouble(27, this.latitude);
        if (this.temperature != 0)
            writer.writeFloat(23, this.temperature);
        if (this.relative_humidity != 0)
            writer.writeFloat(24, this.relative_humidity);
        if (this.metadata_head_hic_version.length)
            writer.writeString(64, this.metadata_head_hic_version);
        if (this.metadata_head_hic_serial_number.length)
            writer.writeString(65, this.metadata_head_hic_serial_number);
        if (this.metadata_station_version.length)
            writer.writeString(66, this.metadata_station_version);
        if (this.metadata_station_serial_number.length)
            writer.writeString(67, this.metadata_station_serial_number);
        if (this.metadata_station_device_sn != "0")
            writer.writeUint64String(68, this.metadata_station_device_sn);
        if (this.created_at != 0)
            writer.writeUint64(30, this.created_at);
        if (this.updated_at != 0)
            writer.writeUint64(31, this.updated_at);
        if (this.deleted_at != 0)
            writer.writeUint64(32, this.deleted_at);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Impact {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Impact();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.uuid, () => message.uuid = UUID.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.zone_uuid, () => message.zone_uuid = UUID.deserialize(reader));
                    break;
                case 3:
                    message.impact_hic = reader.readFloat();
                    break;
                case 4:
                    message.impact_gmax = reader.readFloat();
                    break;
                case 5:
                    message.impact_ffh = reader.readFloat();
                    break;
                case 6:
                    message.impact_deflection = reader.readFloat();
                    break;
                case 7:
                    message.impact_resilience = reader.readFloat();
                    break;
                case 17:
                    message.impact_rebounce_height = reader.readFloat();
                    break;
                case 18:
                    message.impact_velocity_inward = reader.readFloat();
                    break;
                case 19:
                    message.impact_velocity_outward = reader.readFloat();
                    break;
                case 25:
                    message.impact_duration = reader.readFloat();
                    break;
                case 8:
                    message.impact_index_DEPRECATED = reader.readUint32();
                    break;
                case 9:
                    message.sampling_frequency = reader.readUint32();
                    break;
                case 10:
                    message.decimated_frequency = reader.readFloat();
                    break;
                case 11:
                    message.impact_sample_threshold_index = reader.readUint32();
                    break;
                case 12:
                    message.impact_table_threshold_index = reader.readUint32();
                    break;
                case 42:
                    message.impact_table_impact_index = reader.readUint32();
                    break;
                case 43:
                    message.impact_sample_impact_index = reader.readUint32();
                    break;
                case 44:
                    message.impact_table_gmax_index = reader.readUint32();
                    break;
                case 45:
                    message.impact_table_deflection_index = reader.readUint32();
                    break;
                case 46:
                    message.impact_table_final_index = reader.readUint32();
                    break;
                case 47:
                    message.impact_table_rebounce_index = reader.readUint32();
                    break;
                case 13:
                    message.ax_points = reader.readBytes();
                    break;
                case 14:
                    message.ay_points = reader.readBytes();
                    break;
                case 15:
                    message.az_points = reader.readBytes();
                    break;
                case 16:
                    message.aq_points = reader.readBytes();
                    break;
                case 113:
                    message.rf_ax_points_decoded = reader.readPackedFloat();
                    break;
                case 114:
                    message.rf_ay_points_decoded = reader.readPackedFloat();
                    break;
                case 115:
                    message.rf_az_points_decoded = reader.readPackedFloat();
                    break;
                case 116:
                    message.rf_aq_points_decoded = reader.readPackedFloat();
                    break;
                case 28:
                    message.axay_diff_step = reader.readFloat();
                    break;
                case 29:
                    message.axaz_diff_step = reader.readFloat();
                    break;
                case 20:
                    message.impactor_weight = reader.readFloat();
                    break;
                case 21:
                    message.longitude_DEPRECATED = reader.readFloat();
                    break;
                case 22:
                    message.latitude_DEPRECATED = reader.readFloat();
                    break;
                case 26:
                    message.longitude = reader.readDouble();
                    break;
                case 27:
                    message.latitude = reader.readDouble();
                    break;
                case 23:
                    message.temperature = reader.readFloat();
                    break;
                case 24:
                    message.relative_humidity = reader.readFloat();
                    break;
                case 64:
                    message.metadata_head_hic_version = reader.readString();
                    break;
                case 65:
                    message.metadata_head_hic_serial_number = reader.readString();
                    break;
                case 66:
                    message.metadata_station_version = reader.readString();
                    break;
                case 67:
                    message.metadata_station_serial_number = reader.readString();
                    break;
                case 68:
                    message.metadata_station_device_sn = reader.readUint64String();
                    break;
                case 30:
                    message.created_at = reader.readUint64();
                    break;
                case 31:
                    message.updated_at = reader.readUint64();
                    break;
                case 32:
                    message.deleted_at = reader.readUint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Impact {
        return Impact.deserialize(bytes);
    }
}
export class Picture extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: UUID;
        parent_uuid?: UUID;
        legacy_path?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("parent_uuid" in data && data.parent_uuid != undefined) {
                this.parent_uuid = data.parent_uuid;
            }
            if ("legacy_path" in data && data.legacy_path != undefined) {
                this.legacy_path = data.legacy_path;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 1) as UUID;
    }
    set uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_uuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get parent_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 2) as UUID;
    }
    set parent_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_parent_uuid() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get legacy_path() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set legacy_path(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        uuid?: ReturnType<typeof UUID.prototype.toObject>;
        parent_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        legacy_path?: string;
    }): Picture {
        const message = new Picture({});
        if (data.uuid != null) {
            message.uuid = UUID.fromObject(data.uuid);
        }
        if (data.parent_uuid != null) {
            message.parent_uuid = UUID.fromObject(data.parent_uuid);
        }
        if (data.legacy_path != null) {
            message.legacy_path = data.legacy_path;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: ReturnType<typeof UUID.prototype.toObject>;
            parent_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            legacy_path?: string;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid.toObject();
        }
        if (this.parent_uuid != null) {
            data.parent_uuid = this.parent_uuid.toObject();
        }
        if (this.legacy_path != null) {
            data.legacy_path = this.legacy_path;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_uuid)
            writer.writeMessage(1, this.uuid, () => this.uuid.serialize(writer));
        if (this.has_parent_uuid)
            writer.writeMessage(2, this.parent_uuid, () => this.parent_uuid.serialize(writer));
        if (this.legacy_path.length)
            writer.writeString(3, this.legacy_path);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Picture {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Picture();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.uuid, () => message.uuid = UUID.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.parent_uuid, () => message.parent_uuid = UUID.deserialize(reader));
                    break;
                case 3:
                    message.legacy_path = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Picture {
        return Picture.deserialize(bytes);
    }
}
export class StorageStatsCount extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        entity_type?: StorageEntityType;
        count?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("entity_type" in data && data.entity_type != undefined) {
                this.entity_type = data.entity_type;
            }
            if ("count" in data && data.count != undefined) {
                this.count = data.count;
            }
        }
    }
    get entity_type() {
        return pb_1.Message.getFieldWithDefault(this, 1, StorageEntityType.UNKNOWN_STORAGE_ENTITY_TYPE) as StorageEntityType;
    }
    set entity_type(value: StorageEntityType) {
        pb_1.Message.setField(this, 1, value);
    }
    get count() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set count(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        entity_type?: StorageEntityType;
        count?: number;
    }): StorageStatsCount {
        const message = new StorageStatsCount({});
        if (data.entity_type != null) {
            message.entity_type = data.entity_type;
        }
        if (data.count != null) {
            message.count = data.count;
        }
        return message;
    }
    toObject() {
        const data: {
            entity_type?: StorageEntityType;
            count?: number;
        } = {};
        if (this.entity_type != null) {
            data.entity_type = this.entity_type;
        }
        if (this.count != null) {
            data.count = this.count;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.entity_type != StorageEntityType.UNKNOWN_STORAGE_ENTITY_TYPE)
            writer.writeEnum(1, this.entity_type);
        if (this.count != 0)
            writer.writeInt32(2, this.count);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StorageStatsCount {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StorageStatsCount();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.entity_type = reader.readEnum();
                    break;
                case 2:
                    message.count = reader.readInt32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): StorageStatsCount {
        return StorageStatsCount.deserialize(bytes);
    }
}
export class StorageStats extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        counts?: StorageStatsCount[];
        max_gmax?: number;
        max_hic?: number;
        count_gmax_above_limit?: number;
        max_gmax_impact_uuid?: UUID;
        max_hic_impact_uuid?: UUID;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("counts" in data && data.counts != undefined) {
                this.counts = data.counts;
            }
            if ("max_gmax" in data && data.max_gmax != undefined) {
                this.max_gmax = data.max_gmax;
            }
            if ("max_hic" in data && data.max_hic != undefined) {
                this.max_hic = data.max_hic;
            }
            if ("count_gmax_above_limit" in data && data.count_gmax_above_limit != undefined) {
                this.count_gmax_above_limit = data.count_gmax_above_limit;
            }
            if ("max_gmax_impact_uuid" in data && data.max_gmax_impact_uuid != undefined) {
                this.max_gmax_impact_uuid = data.max_gmax_impact_uuid;
            }
            if ("max_hic_impact_uuid" in data && data.max_hic_impact_uuid != undefined) {
                this.max_hic_impact_uuid = data.max_hic_impact_uuid;
            }
        }
    }
    get counts() {
        return pb_1.Message.getRepeatedWrapperField(this, StorageStatsCount, 1) as StorageStatsCount[];
    }
    set counts(value: StorageStatsCount[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get max_gmax() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set max_gmax(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get max_hic() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set max_hic(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get count_gmax_above_limit() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set count_gmax_above_limit(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get max_gmax_impact_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 5) as UUID;
    }
    set max_gmax_impact_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 5, value);
    }
    get has_max_gmax_impact_uuid() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get max_hic_impact_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 6) as UUID;
    }
    set max_hic_impact_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 6, value);
    }
    get has_max_hic_impact_uuid() {
        return pb_1.Message.getField(this, 6) != null;
    }
    static fromObject(data: {
        counts?: ReturnType<typeof StorageStatsCount.prototype.toObject>[];
        max_gmax?: number;
        max_hic?: number;
        count_gmax_above_limit?: number;
        max_gmax_impact_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        max_hic_impact_uuid?: ReturnType<typeof UUID.prototype.toObject>;
    }): StorageStats {
        const message = new StorageStats({});
        if (data.counts != null) {
            message.counts = data.counts.map(item => StorageStatsCount.fromObject(item));
        }
        if (data.max_gmax != null) {
            message.max_gmax = data.max_gmax;
        }
        if (data.max_hic != null) {
            message.max_hic = data.max_hic;
        }
        if (data.count_gmax_above_limit != null) {
            message.count_gmax_above_limit = data.count_gmax_above_limit;
        }
        if (data.max_gmax_impact_uuid != null) {
            message.max_gmax_impact_uuid = UUID.fromObject(data.max_gmax_impact_uuid);
        }
        if (data.max_hic_impact_uuid != null) {
            message.max_hic_impact_uuid = UUID.fromObject(data.max_hic_impact_uuid);
        }
        return message;
    }
    toObject() {
        const data: {
            counts?: ReturnType<typeof StorageStatsCount.prototype.toObject>[];
            max_gmax?: number;
            max_hic?: number;
            count_gmax_above_limit?: number;
            max_gmax_impact_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            max_hic_impact_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        } = {};
        if (this.counts != null) {
            data.counts = this.counts.map((item: StorageStatsCount) => item.toObject());
        }
        if (this.max_gmax != null) {
            data.max_gmax = this.max_gmax;
        }
        if (this.max_hic != null) {
            data.max_hic = this.max_hic;
        }
        if (this.count_gmax_above_limit != null) {
            data.count_gmax_above_limit = this.count_gmax_above_limit;
        }
        if (this.max_gmax_impact_uuid != null) {
            data.max_gmax_impact_uuid = this.max_gmax_impact_uuid.toObject();
        }
        if (this.max_hic_impact_uuid != null) {
            data.max_hic_impact_uuid = this.max_hic_impact_uuid.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.counts.length)
            writer.writeRepeatedMessage(1, this.counts, (item: StorageStatsCount) => item.serialize(writer));
        if (this.max_gmax != 0)
            writer.writeFloat(2, this.max_gmax);
        if (this.max_hic != 0)
            writer.writeFloat(3, this.max_hic);
        if (this.count_gmax_above_limit != 0)
            writer.writeInt32(4, this.count_gmax_above_limit);
        if (this.has_max_gmax_impact_uuid)
            writer.writeMessage(5, this.max_gmax_impact_uuid, () => this.max_gmax_impact_uuid.serialize(writer));
        if (this.has_max_hic_impact_uuid)
            writer.writeMessage(6, this.max_hic_impact_uuid, () => this.max_hic_impact_uuid.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StorageStats {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StorageStats();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.counts, () => pb_1.Message.addToRepeatedWrapperField(message, 1, StorageStatsCount.deserialize(reader), StorageStatsCount));
                    break;
                case 2:
                    message.max_gmax = reader.readFloat();
                    break;
                case 3:
                    message.max_hic = reader.readFloat();
                    break;
                case 4:
                    message.count_gmax_above_limit = reader.readInt32();
                    break;
                case 5:
                    reader.readMessage(message.max_gmax_impact_uuid, () => message.max_gmax_impact_uuid = UUID.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.max_hic_impact_uuid, () => message.max_hic_impact_uuid = UUID.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): StorageStats {
        return StorageStats.deserialize(bytes);
    }
}
export class ImpactWithReferences extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        impact?: Impact;
        user_uuid?: UUID;
        enterprise_uuid?: UUID;
        zone_uuid?: UUID;
        zone_name?: string;
        equipment_uuid?: UUID;
        equipment_name?: string;
        equipment_sports_mat_type?: MatTypeEN12503;
        equipment_thickness?: number;
        site_uuid?: UUID;
        site_name?: string;
        site_client_name?: string;
        site_mission_name?: string;
        site_norm_type?: NormType;
        site_method_type_en_1177?: MethodTypeEN1177;
        site_is_lab_test?: boolean;
        site_device_sn?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("impact" in data && data.impact != undefined) {
                this.impact = data.impact;
            }
            if ("user_uuid" in data && data.user_uuid != undefined) {
                this.user_uuid = data.user_uuid;
            }
            if ("enterprise_uuid" in data && data.enterprise_uuid != undefined) {
                this.enterprise_uuid = data.enterprise_uuid;
            }
            if ("zone_uuid" in data && data.zone_uuid != undefined) {
                this.zone_uuid = data.zone_uuid;
            }
            if ("zone_name" in data && data.zone_name != undefined) {
                this.zone_name = data.zone_name;
            }
            if ("equipment_uuid" in data && data.equipment_uuid != undefined) {
                this.equipment_uuid = data.equipment_uuid;
            }
            if ("equipment_name" in data && data.equipment_name != undefined) {
                this.equipment_name = data.equipment_name;
            }
            if ("equipment_sports_mat_type" in data && data.equipment_sports_mat_type != undefined) {
                this.equipment_sports_mat_type = data.equipment_sports_mat_type;
            }
            if ("equipment_thickness" in data && data.equipment_thickness != undefined) {
                this.equipment_thickness = data.equipment_thickness;
            }
            if ("site_uuid" in data && data.site_uuid != undefined) {
                this.site_uuid = data.site_uuid;
            }
            if ("site_name" in data && data.site_name != undefined) {
                this.site_name = data.site_name;
            }
            if ("site_client_name" in data && data.site_client_name != undefined) {
                this.site_client_name = data.site_client_name;
            }
            if ("site_mission_name" in data && data.site_mission_name != undefined) {
                this.site_mission_name = data.site_mission_name;
            }
            if ("site_norm_type" in data && data.site_norm_type != undefined) {
                this.site_norm_type = data.site_norm_type;
            }
            if ("site_method_type_en_1177" in data && data.site_method_type_en_1177 != undefined) {
                this.site_method_type_en_1177 = data.site_method_type_en_1177;
            }
            if ("site_is_lab_test" in data && data.site_is_lab_test != undefined) {
                this.site_is_lab_test = data.site_is_lab_test;
            }
            if ("site_device_sn" in data && data.site_device_sn != undefined) {
                this.site_device_sn = data.site_device_sn;
            }
        }
    }
    get impact() {
        return pb_1.Message.getWrapperField(this, Impact, 1) as Impact;
    }
    set impact(value: Impact) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_impact() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get user_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 80) as UUID;
    }
    set user_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 80, value);
    }
    get has_user_uuid() {
        return pb_1.Message.getField(this, 80) != null;
    }
    get enterprise_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 81) as UUID;
    }
    set enterprise_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 81, value);
    }
    get has_enterprise_uuid() {
        return pb_1.Message.getField(this, 81) != null;
    }
    get zone_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 82) as UUID;
    }
    set zone_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 82, value);
    }
    get has_zone_uuid() {
        return pb_1.Message.getField(this, 82) != null;
    }
    get zone_name() {
        return pb_1.Message.getFieldWithDefault(this, 83, "") as string;
    }
    set zone_name(value: string) {
        pb_1.Message.setField(this, 83, value);
    }
    get equipment_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 2) as UUID;
    }
    set equipment_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_equipment_uuid() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get equipment_name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set equipment_name(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get equipment_sports_mat_type() {
        return pb_1.Message.getFieldWithDefault(this, 4, MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503) as MatTypeEN12503;
    }
    set equipment_sports_mat_type(value: MatTypeEN12503) {
        pb_1.Message.setField(this, 4, value);
    }
    get equipment_thickness() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set equipment_thickness(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get site_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 11) as UUID;
    }
    set site_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 11, value);
    }
    get has_site_uuid() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get site_name() {
        return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
    }
    set site_name(value: string) {
        pb_1.Message.setField(this, 12, value);
    }
    get site_client_name() {
        return pb_1.Message.getFieldWithDefault(this, 15, "") as string;
    }
    set site_client_name(value: string) {
        pb_1.Message.setField(this, 15, value);
    }
    get site_mission_name() {
        return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
    }
    set site_mission_name(value: string) {
        pb_1.Message.setField(this, 16, value);
    }
    get site_norm_type() {
        return pb_1.Message.getFieldWithDefault(this, 13, NormType.UNKNOWN_NORM_TYPE) as NormType;
    }
    set site_norm_type(value: NormType) {
        pb_1.Message.setField(this, 13, value);
    }
    get site_method_type_en_1177() {
        return pb_1.Message.getFieldWithDefault(this, 14, MethodTypeEN1177.UNKNOWN_METHOD_TYPE_EN_1177) as MethodTypeEN1177;
    }
    set site_method_type_en_1177(value: MethodTypeEN1177) {
        pb_1.Message.setField(this, 14, value);
    }
    get site_is_lab_test() {
        return pb_1.Message.getFieldWithDefault(this, 17, false) as boolean;
    }
    set site_is_lab_test(value: boolean) {
        pb_1.Message.setField(this, 17, value);
    }
    get site_device_sn() {
        return pb_1.Message.getFieldWithDefault(this, 18, "0") as string;
    }
    set site_device_sn(value: string) {
        pb_1.Message.setField(this, 18, value);
    }
    static fromObject(data: {
        impact?: ReturnType<typeof Impact.prototype.toObject>;
        user_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        enterprise_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        zone_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        zone_name?: string;
        equipment_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        equipment_name?: string;
        equipment_sports_mat_type?: MatTypeEN12503;
        equipment_thickness?: number;
        site_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        site_name?: string;
        site_client_name?: string;
        site_mission_name?: string;
        site_norm_type?: NormType;
        site_method_type_en_1177?: MethodTypeEN1177;
        site_is_lab_test?: boolean;
        site_device_sn?: string;
    }): ImpactWithReferences {
        const message = new ImpactWithReferences({});
        if (data.impact != null) {
            message.impact = Impact.fromObject(data.impact);
        }
        if (data.user_uuid != null) {
            message.user_uuid = UUID.fromObject(data.user_uuid);
        }
        if (data.enterprise_uuid != null) {
            message.enterprise_uuid = UUID.fromObject(data.enterprise_uuid);
        }
        if (data.zone_uuid != null) {
            message.zone_uuid = UUID.fromObject(data.zone_uuid);
        }
        if (data.zone_name != null) {
            message.zone_name = data.zone_name;
        }
        if (data.equipment_uuid != null) {
            message.equipment_uuid = UUID.fromObject(data.equipment_uuid);
        }
        if (data.equipment_name != null) {
            message.equipment_name = data.equipment_name;
        }
        if (data.equipment_sports_mat_type != null) {
            message.equipment_sports_mat_type = data.equipment_sports_mat_type;
        }
        if (data.equipment_thickness != null) {
            message.equipment_thickness = data.equipment_thickness;
        }
        if (data.site_uuid != null) {
            message.site_uuid = UUID.fromObject(data.site_uuid);
        }
        if (data.site_name != null) {
            message.site_name = data.site_name;
        }
        if (data.site_client_name != null) {
            message.site_client_name = data.site_client_name;
        }
        if (data.site_mission_name != null) {
            message.site_mission_name = data.site_mission_name;
        }
        if (data.site_norm_type != null) {
            message.site_norm_type = data.site_norm_type;
        }
        if (data.site_method_type_en_1177 != null) {
            message.site_method_type_en_1177 = data.site_method_type_en_1177;
        }
        if (data.site_is_lab_test != null) {
            message.site_is_lab_test = data.site_is_lab_test;
        }
        if (data.site_device_sn != null) {
            message.site_device_sn = data.site_device_sn;
        }
        return message;
    }
    toObject() {
        const data: {
            impact?: ReturnType<typeof Impact.prototype.toObject>;
            user_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            enterprise_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            zone_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            zone_name?: string;
            equipment_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            equipment_name?: string;
            equipment_sports_mat_type?: MatTypeEN12503;
            equipment_thickness?: number;
            site_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            site_name?: string;
            site_client_name?: string;
            site_mission_name?: string;
            site_norm_type?: NormType;
            site_method_type_en_1177?: MethodTypeEN1177;
            site_is_lab_test?: boolean;
            site_device_sn?: string;
        } = {};
        if (this.impact != null) {
            data.impact = this.impact.toObject();
        }
        if (this.user_uuid != null) {
            data.user_uuid = this.user_uuid.toObject();
        }
        if (this.enterprise_uuid != null) {
            data.enterprise_uuid = this.enterprise_uuid.toObject();
        }
        if (this.zone_uuid != null) {
            data.zone_uuid = this.zone_uuid.toObject();
        }
        if (this.zone_name != null) {
            data.zone_name = this.zone_name;
        }
        if (this.equipment_uuid != null) {
            data.equipment_uuid = this.equipment_uuid.toObject();
        }
        if (this.equipment_name != null) {
            data.equipment_name = this.equipment_name;
        }
        if (this.equipment_sports_mat_type != null) {
            data.equipment_sports_mat_type = this.equipment_sports_mat_type;
        }
        if (this.equipment_thickness != null) {
            data.equipment_thickness = this.equipment_thickness;
        }
        if (this.site_uuid != null) {
            data.site_uuid = this.site_uuid.toObject();
        }
        if (this.site_name != null) {
            data.site_name = this.site_name;
        }
        if (this.site_client_name != null) {
            data.site_client_name = this.site_client_name;
        }
        if (this.site_mission_name != null) {
            data.site_mission_name = this.site_mission_name;
        }
        if (this.site_norm_type != null) {
            data.site_norm_type = this.site_norm_type;
        }
        if (this.site_method_type_en_1177 != null) {
            data.site_method_type_en_1177 = this.site_method_type_en_1177;
        }
        if (this.site_is_lab_test != null) {
            data.site_is_lab_test = this.site_is_lab_test;
        }
        if (this.site_device_sn != null) {
            data.site_device_sn = this.site_device_sn;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_impact)
            writer.writeMessage(1, this.impact, () => this.impact.serialize(writer));
        if (this.has_user_uuid)
            writer.writeMessage(80, this.user_uuid, () => this.user_uuid.serialize(writer));
        if (this.has_enterprise_uuid)
            writer.writeMessage(81, this.enterprise_uuid, () => this.enterprise_uuid.serialize(writer));
        if (this.has_zone_uuid)
            writer.writeMessage(82, this.zone_uuid, () => this.zone_uuid.serialize(writer));
        if (this.zone_name.length)
            writer.writeString(83, this.zone_name);
        if (this.has_equipment_uuid)
            writer.writeMessage(2, this.equipment_uuid, () => this.equipment_uuid.serialize(writer));
        if (this.equipment_name.length)
            writer.writeString(3, this.equipment_name);
        if (this.equipment_sports_mat_type != MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503)
            writer.writeEnum(4, this.equipment_sports_mat_type);
        if (this.equipment_thickness != 0)
            writer.writeUint32(5, this.equipment_thickness);
        if (this.has_site_uuid)
            writer.writeMessage(11, this.site_uuid, () => this.site_uuid.serialize(writer));
        if (this.site_name.length)
            writer.writeString(12, this.site_name);
        if (this.site_client_name.length)
            writer.writeString(15, this.site_client_name);
        if (this.site_mission_name.length)
            writer.writeString(16, this.site_mission_name);
        if (this.site_norm_type != NormType.UNKNOWN_NORM_TYPE)
            writer.writeEnum(13, this.site_norm_type);
        if (this.site_method_type_en_1177 != MethodTypeEN1177.UNKNOWN_METHOD_TYPE_EN_1177)
            writer.writeEnum(14, this.site_method_type_en_1177);
        if (this.site_is_lab_test != false)
            writer.writeBool(17, this.site_is_lab_test);
        if (this.site_device_sn != "0")
            writer.writeUint64String(18, this.site_device_sn);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ImpactWithReferences {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ImpactWithReferences();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.impact, () => message.impact = Impact.deserialize(reader));
                    break;
                case 80:
                    reader.readMessage(message.user_uuid, () => message.user_uuid = UUID.deserialize(reader));
                    break;
                case 81:
                    reader.readMessage(message.enterprise_uuid, () => message.enterprise_uuid = UUID.deserialize(reader));
                    break;
                case 82:
                    reader.readMessage(message.zone_uuid, () => message.zone_uuid = UUID.deserialize(reader));
                    break;
                case 83:
                    message.zone_name = reader.readString();
                    break;
                case 2:
                    reader.readMessage(message.equipment_uuid, () => message.equipment_uuid = UUID.deserialize(reader));
                    break;
                case 3:
                    message.equipment_name = reader.readString();
                    break;
                case 4:
                    message.equipment_sports_mat_type = reader.readEnum();
                    break;
                case 5:
                    message.equipment_thickness = reader.readUint32();
                    break;
                case 11:
                    reader.readMessage(message.site_uuid, () => message.site_uuid = UUID.deserialize(reader));
                    break;
                case 12:
                    message.site_name = reader.readString();
                    break;
                case 15:
                    message.site_client_name = reader.readString();
                    break;
                case 16:
                    message.site_mission_name = reader.readString();
                    break;
                case 13:
                    message.site_norm_type = reader.readEnum();
                    break;
                case 14:
                    message.site_method_type_en_1177 = reader.readEnum();
                    break;
                case 17:
                    message.site_is_lab_test = reader.readBool();
                    break;
                case 18:
                    message.site_device_sn = reader.readUint64String();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): ImpactWithReferences {
        return ImpactWithReferences.deserialize(bytes);
    }
}
export class UCPayload extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: UUID;
        type?: UCPayloadType;
        request_type?: UCPayloadType;
        data?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("type" in data && data.type != undefined) {
                this.type = data.type;
            }
            if ("request_type" in data && data.request_type != undefined) {
                this.request_type = data.request_type;
            }
            if ("data" in data && data.data != undefined) {
                this.data = data.data;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 1) as UUID;
    }
    set uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_uuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get type() {
        return pb_1.Message.getFieldWithDefault(this, 2, UCPayloadType.SICO_ERROR) as UCPayloadType;
    }
    set type(value: UCPayloadType) {
        pb_1.Message.setField(this, 2, value);
    }
    get request_type() {
        return pb_1.Message.getFieldWithDefault(this, 4, UCPayloadType.SICO_ERROR) as UCPayloadType;
    }
    set request_type(value: UCPayloadType) {
        pb_1.Message.setField(this, 4, value);
    }
    get data() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
    }
    set data(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        uuid?: ReturnType<typeof UUID.prototype.toObject>;
        type?: UCPayloadType;
        request_type?: UCPayloadType;
        data?: Uint8Array;
    }): UCPayload {
        const message = new UCPayload({});
        if (data.uuid != null) {
            message.uuid = UUID.fromObject(data.uuid);
        }
        if (data.type != null) {
            message.type = data.type;
        }
        if (data.request_type != null) {
            message.request_type = data.request_type;
        }
        if (data.data != null) {
            message.data = data.data;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: ReturnType<typeof UUID.prototype.toObject>;
            type?: UCPayloadType;
            request_type?: UCPayloadType;
            data?: Uint8Array;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid.toObject();
        }
        if (this.type != null) {
            data.type = this.type;
        }
        if (this.request_type != null) {
            data.request_type = this.request_type;
        }
        if (this.data != null) {
            data.data = this.data;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_uuid)
            writer.writeMessage(1, this.uuid, () => this.uuid.serialize(writer));
        if (this.type != UCPayloadType.SICO_ERROR)
            writer.writeEnum(2, this.type);
        if (this.request_type != UCPayloadType.SICO_ERROR)
            writer.writeEnum(4, this.request_type);
        if (this.data.length)
            writer.writeBytes(3, this.data);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UCPayload {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UCPayload();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.uuid, () => message.uuid = UUID.deserialize(reader));
                    break;
                case 2:
                    message.type = reader.readEnum();
                    break;
                case 4:
                    message.request_type = reader.readEnum();
                    break;
                case 3:
                    message.data = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): UCPayload {
        return UCPayload.deserialize(bytes);
    }
}
export class UCAck extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        value?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
        }
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set value(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        value?: boolean;
    }): UCAck {
        const message = new UCAck({});
        if (data.value != null) {
            message.value = data.value;
        }
        return message;
    }
    toObject() {
        const data: {
            value?: boolean;
        } = {};
        if (this.value != null) {
            data.value = this.value;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.value != false)
            writer.writeBool(1, this.value);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UCAck {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UCAck();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.value = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): UCAck {
        return UCAck.deserialize(bytes);
    }
}
export class UCError extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        value?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
        }
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        value?: string;
    }): UCError {
        const message = new UCError({});
        if (data.value != null) {
            message.value = data.value;
        }
        return message;
    }
    toObject() {
        const data: {
            value?: string;
        } = {};
        if (this.value != null) {
            data.value = this.value;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.value.length)
            writer.writeString(1, this.value);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UCError {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UCError();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.value = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): UCError {
        return UCError.deserialize(bytes);
    }
}
export class HICConfig extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        serial_number?: string;
        last_control_date?: string;
        sensor_x_sensitivity?: string;
        sensor_x_offset?: string;
        sensor_x_serial_number?: string;
        sensor_y_sensitivity?: string;
        sensor_y_offset?: string;
        sensor_y_serial_number?: string;
        sensor_z_sensitivity?: string;
        sensor_z_offset?: string;
        sensor_z_serial_number?: string;
        real_5v_corrected?: string;
        trigger_point_in_g?: string;
        fall_count?: string;
        all_time_max_g?: string;
        battery_coef?: string;
        fall_offset?: string;
        firmware_version?: string;
        debug_mode?: string;
        hold_fall_count?: string;
        battery_level?: string;
        decimation_factor?: string;
        sample_size?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("serial_number" in data && data.serial_number != undefined) {
                this.serial_number = data.serial_number;
            }
            if ("last_control_date" in data && data.last_control_date != undefined) {
                this.last_control_date = data.last_control_date;
            }
            if ("sensor_x_sensitivity" in data && data.sensor_x_sensitivity != undefined) {
                this.sensor_x_sensitivity = data.sensor_x_sensitivity;
            }
            if ("sensor_x_offset" in data && data.sensor_x_offset != undefined) {
                this.sensor_x_offset = data.sensor_x_offset;
            }
            if ("sensor_x_serial_number" in data && data.sensor_x_serial_number != undefined) {
                this.sensor_x_serial_number = data.sensor_x_serial_number;
            }
            if ("sensor_y_sensitivity" in data && data.sensor_y_sensitivity != undefined) {
                this.sensor_y_sensitivity = data.sensor_y_sensitivity;
            }
            if ("sensor_y_offset" in data && data.sensor_y_offset != undefined) {
                this.sensor_y_offset = data.sensor_y_offset;
            }
            if ("sensor_y_serial_number" in data && data.sensor_y_serial_number != undefined) {
                this.sensor_y_serial_number = data.sensor_y_serial_number;
            }
            if ("sensor_z_sensitivity" in data && data.sensor_z_sensitivity != undefined) {
                this.sensor_z_sensitivity = data.sensor_z_sensitivity;
            }
            if ("sensor_z_offset" in data && data.sensor_z_offset != undefined) {
                this.sensor_z_offset = data.sensor_z_offset;
            }
            if ("sensor_z_serial_number" in data && data.sensor_z_serial_number != undefined) {
                this.sensor_z_serial_number = data.sensor_z_serial_number;
            }
            if ("real_5v_corrected" in data && data.real_5v_corrected != undefined) {
                this.real_5v_corrected = data.real_5v_corrected;
            }
            if ("trigger_point_in_g" in data && data.trigger_point_in_g != undefined) {
                this.trigger_point_in_g = data.trigger_point_in_g;
            }
            if ("fall_count" in data && data.fall_count != undefined) {
                this.fall_count = data.fall_count;
            }
            if ("all_time_max_g" in data && data.all_time_max_g != undefined) {
                this.all_time_max_g = data.all_time_max_g;
            }
            if ("battery_coef" in data && data.battery_coef != undefined) {
                this.battery_coef = data.battery_coef;
            }
            if ("fall_offset" in data && data.fall_offset != undefined) {
                this.fall_offset = data.fall_offset;
            }
            if ("firmware_version" in data && data.firmware_version != undefined) {
                this.firmware_version = data.firmware_version;
            }
            if ("debug_mode" in data && data.debug_mode != undefined) {
                this.debug_mode = data.debug_mode;
            }
            if ("hold_fall_count" in data && data.hold_fall_count != undefined) {
                this.hold_fall_count = data.hold_fall_count;
            }
            if ("battery_level" in data && data.battery_level != undefined) {
                this.battery_level = data.battery_level;
            }
            if ("decimation_factor" in data && data.decimation_factor != undefined) {
                this.decimation_factor = data.decimation_factor;
            }
            if ("sample_size" in data && data.sample_size != undefined) {
                this.sample_size = data.sample_size;
            }
        }
    }
    get serial_number() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set serial_number(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get last_control_date() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set last_control_date(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get sensor_x_sensitivity() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set sensor_x_sensitivity(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get sensor_x_offset() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set sensor_x_offset(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get sensor_x_serial_number() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set sensor_x_serial_number(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get sensor_y_sensitivity() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set sensor_y_sensitivity(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get sensor_y_offset() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set sensor_y_offset(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get sensor_y_serial_number() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set sensor_y_serial_number(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get sensor_z_sensitivity() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set sensor_z_sensitivity(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get sensor_z_offset() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set sensor_z_offset(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get sensor_z_serial_number() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set sensor_z_serial_number(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get real_5v_corrected() {
        return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
    }
    set real_5v_corrected(value: string) {
        pb_1.Message.setField(this, 12, value);
    }
    get trigger_point_in_g() {
        return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
    }
    set trigger_point_in_g(value: string) {
        pb_1.Message.setField(this, 13, value);
    }
    get fall_count() {
        return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
    }
    set fall_count(value: string) {
        pb_1.Message.setField(this, 16, value);
    }
    get all_time_max_g() {
        return pb_1.Message.getFieldWithDefault(this, 17, "") as string;
    }
    set all_time_max_g(value: string) {
        pb_1.Message.setField(this, 17, value);
    }
    get battery_coef() {
        return pb_1.Message.getFieldWithDefault(this, 18, "") as string;
    }
    set battery_coef(value: string) {
        pb_1.Message.setField(this, 18, value);
    }
    get fall_offset() {
        return pb_1.Message.getFieldWithDefault(this, 19, "") as string;
    }
    set fall_offset(value: string) {
        pb_1.Message.setField(this, 19, value);
    }
    get firmware_version() {
        return pb_1.Message.getFieldWithDefault(this, 20, "") as string;
    }
    set firmware_version(value: string) {
        pb_1.Message.setField(this, 20, value);
    }
    get debug_mode() {
        return pb_1.Message.getFieldWithDefault(this, 21, "") as string;
    }
    set debug_mode(value: string) {
        pb_1.Message.setField(this, 21, value);
    }
    get hold_fall_count() {
        return pb_1.Message.getFieldWithDefault(this, 22, "") as string;
    }
    set hold_fall_count(value: string) {
        pb_1.Message.setField(this, 22, value);
    }
    get battery_level() {
        return pb_1.Message.getFieldWithDefault(this, 23, "") as string;
    }
    set battery_level(value: string) {
        pb_1.Message.setField(this, 23, value);
    }
    get decimation_factor() {
        return pb_1.Message.getFieldWithDefault(this, 24, "") as string;
    }
    set decimation_factor(value: string) {
        pb_1.Message.setField(this, 24, value);
    }
    get sample_size() {
        return pb_1.Message.getFieldWithDefault(this, 25, "") as string;
    }
    set sample_size(value: string) {
        pb_1.Message.setField(this, 25, value);
    }
    static fromObject(data: {
        serial_number?: string;
        last_control_date?: string;
        sensor_x_sensitivity?: string;
        sensor_x_offset?: string;
        sensor_x_serial_number?: string;
        sensor_y_sensitivity?: string;
        sensor_y_offset?: string;
        sensor_y_serial_number?: string;
        sensor_z_sensitivity?: string;
        sensor_z_offset?: string;
        sensor_z_serial_number?: string;
        real_5v_corrected?: string;
        trigger_point_in_g?: string;
        fall_count?: string;
        all_time_max_g?: string;
        battery_coef?: string;
        fall_offset?: string;
        firmware_version?: string;
        debug_mode?: string;
        hold_fall_count?: string;
        battery_level?: string;
        decimation_factor?: string;
        sample_size?: string;
    }): HICConfig {
        const message = new HICConfig({});
        if (data.serial_number != null) {
            message.serial_number = data.serial_number;
        }
        if (data.last_control_date != null) {
            message.last_control_date = data.last_control_date;
        }
        if (data.sensor_x_sensitivity != null) {
            message.sensor_x_sensitivity = data.sensor_x_sensitivity;
        }
        if (data.sensor_x_offset != null) {
            message.sensor_x_offset = data.sensor_x_offset;
        }
        if (data.sensor_x_serial_number != null) {
            message.sensor_x_serial_number = data.sensor_x_serial_number;
        }
        if (data.sensor_y_sensitivity != null) {
            message.sensor_y_sensitivity = data.sensor_y_sensitivity;
        }
        if (data.sensor_y_offset != null) {
            message.sensor_y_offset = data.sensor_y_offset;
        }
        if (data.sensor_y_serial_number != null) {
            message.sensor_y_serial_number = data.sensor_y_serial_number;
        }
        if (data.sensor_z_sensitivity != null) {
            message.sensor_z_sensitivity = data.sensor_z_sensitivity;
        }
        if (data.sensor_z_offset != null) {
            message.sensor_z_offset = data.sensor_z_offset;
        }
        if (data.sensor_z_serial_number != null) {
            message.sensor_z_serial_number = data.sensor_z_serial_number;
        }
        if (data.real_5v_corrected != null) {
            message.real_5v_corrected = data.real_5v_corrected;
        }
        if (data.trigger_point_in_g != null) {
            message.trigger_point_in_g = data.trigger_point_in_g;
        }
        if (data.fall_count != null) {
            message.fall_count = data.fall_count;
        }
        if (data.all_time_max_g != null) {
            message.all_time_max_g = data.all_time_max_g;
        }
        if (data.battery_coef != null) {
            message.battery_coef = data.battery_coef;
        }
        if (data.fall_offset != null) {
            message.fall_offset = data.fall_offset;
        }
        if (data.firmware_version != null) {
            message.firmware_version = data.firmware_version;
        }
        if (data.debug_mode != null) {
            message.debug_mode = data.debug_mode;
        }
        if (data.hold_fall_count != null) {
            message.hold_fall_count = data.hold_fall_count;
        }
        if (data.battery_level != null) {
            message.battery_level = data.battery_level;
        }
        if (data.decimation_factor != null) {
            message.decimation_factor = data.decimation_factor;
        }
        if (data.sample_size != null) {
            message.sample_size = data.sample_size;
        }
        return message;
    }
    toObject() {
        const data: {
            serial_number?: string;
            last_control_date?: string;
            sensor_x_sensitivity?: string;
            sensor_x_offset?: string;
            sensor_x_serial_number?: string;
            sensor_y_sensitivity?: string;
            sensor_y_offset?: string;
            sensor_y_serial_number?: string;
            sensor_z_sensitivity?: string;
            sensor_z_offset?: string;
            sensor_z_serial_number?: string;
            real_5v_corrected?: string;
            trigger_point_in_g?: string;
            fall_count?: string;
            all_time_max_g?: string;
            battery_coef?: string;
            fall_offset?: string;
            firmware_version?: string;
            debug_mode?: string;
            hold_fall_count?: string;
            battery_level?: string;
            decimation_factor?: string;
            sample_size?: string;
        } = {};
        if (this.serial_number != null) {
            data.serial_number = this.serial_number;
        }
        if (this.last_control_date != null) {
            data.last_control_date = this.last_control_date;
        }
        if (this.sensor_x_sensitivity != null) {
            data.sensor_x_sensitivity = this.sensor_x_sensitivity;
        }
        if (this.sensor_x_offset != null) {
            data.sensor_x_offset = this.sensor_x_offset;
        }
        if (this.sensor_x_serial_number != null) {
            data.sensor_x_serial_number = this.sensor_x_serial_number;
        }
        if (this.sensor_y_sensitivity != null) {
            data.sensor_y_sensitivity = this.sensor_y_sensitivity;
        }
        if (this.sensor_y_offset != null) {
            data.sensor_y_offset = this.sensor_y_offset;
        }
        if (this.sensor_y_serial_number != null) {
            data.sensor_y_serial_number = this.sensor_y_serial_number;
        }
        if (this.sensor_z_sensitivity != null) {
            data.sensor_z_sensitivity = this.sensor_z_sensitivity;
        }
        if (this.sensor_z_offset != null) {
            data.sensor_z_offset = this.sensor_z_offset;
        }
        if (this.sensor_z_serial_number != null) {
            data.sensor_z_serial_number = this.sensor_z_serial_number;
        }
        if (this.real_5v_corrected != null) {
            data.real_5v_corrected = this.real_5v_corrected;
        }
        if (this.trigger_point_in_g != null) {
            data.trigger_point_in_g = this.trigger_point_in_g;
        }
        if (this.fall_count != null) {
            data.fall_count = this.fall_count;
        }
        if (this.all_time_max_g != null) {
            data.all_time_max_g = this.all_time_max_g;
        }
        if (this.battery_coef != null) {
            data.battery_coef = this.battery_coef;
        }
        if (this.fall_offset != null) {
            data.fall_offset = this.fall_offset;
        }
        if (this.firmware_version != null) {
            data.firmware_version = this.firmware_version;
        }
        if (this.debug_mode != null) {
            data.debug_mode = this.debug_mode;
        }
        if (this.hold_fall_count != null) {
            data.hold_fall_count = this.hold_fall_count;
        }
        if (this.battery_level != null) {
            data.battery_level = this.battery_level;
        }
        if (this.decimation_factor != null) {
            data.decimation_factor = this.decimation_factor;
        }
        if (this.sample_size != null) {
            data.sample_size = this.sample_size;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.serial_number.length)
            writer.writeString(1, this.serial_number);
        if (this.last_control_date.length)
            writer.writeString(2, this.last_control_date);
        if (this.sensor_x_sensitivity.length)
            writer.writeString(3, this.sensor_x_sensitivity);
        if (this.sensor_x_offset.length)
            writer.writeString(4, this.sensor_x_offset);
        if (this.sensor_x_serial_number.length)
            writer.writeString(5, this.sensor_x_serial_number);
        if (this.sensor_y_sensitivity.length)
            writer.writeString(6, this.sensor_y_sensitivity);
        if (this.sensor_y_offset.length)
            writer.writeString(7, this.sensor_y_offset);
        if (this.sensor_y_serial_number.length)
            writer.writeString(8, this.sensor_y_serial_number);
        if (this.sensor_z_sensitivity.length)
            writer.writeString(9, this.sensor_z_sensitivity);
        if (this.sensor_z_offset.length)
            writer.writeString(10, this.sensor_z_offset);
        if (this.sensor_z_serial_number.length)
            writer.writeString(11, this.sensor_z_serial_number);
        if (this.real_5v_corrected.length)
            writer.writeString(12, this.real_5v_corrected);
        if (this.trigger_point_in_g.length)
            writer.writeString(13, this.trigger_point_in_g);
        if (this.fall_count.length)
            writer.writeString(16, this.fall_count);
        if (this.all_time_max_g.length)
            writer.writeString(17, this.all_time_max_g);
        if (this.battery_coef.length)
            writer.writeString(18, this.battery_coef);
        if (this.fall_offset.length)
            writer.writeString(19, this.fall_offset);
        if (this.firmware_version.length)
            writer.writeString(20, this.firmware_version);
        if (this.debug_mode.length)
            writer.writeString(21, this.debug_mode);
        if (this.hold_fall_count.length)
            writer.writeString(22, this.hold_fall_count);
        if (this.battery_level.length)
            writer.writeString(23, this.battery_level);
        if (this.decimation_factor.length)
            writer.writeString(24, this.decimation_factor);
        if (this.sample_size.length)
            writer.writeString(25, this.sample_size);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): HICConfig {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new HICConfig();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.serial_number = reader.readString();
                    break;
                case 2:
                    message.last_control_date = reader.readString();
                    break;
                case 3:
                    message.sensor_x_sensitivity = reader.readString();
                    break;
                case 4:
                    message.sensor_x_offset = reader.readString();
                    break;
                case 5:
                    message.sensor_x_serial_number = reader.readString();
                    break;
                case 6:
                    message.sensor_y_sensitivity = reader.readString();
                    break;
                case 7:
                    message.sensor_y_offset = reader.readString();
                    break;
                case 8:
                    message.sensor_y_serial_number = reader.readString();
                    break;
                case 9:
                    message.sensor_z_sensitivity = reader.readString();
                    break;
                case 10:
                    message.sensor_z_offset = reader.readString();
                    break;
                case 11:
                    message.sensor_z_serial_number = reader.readString();
                    break;
                case 12:
                    message.real_5v_corrected = reader.readString();
                    break;
                case 13:
                    message.trigger_point_in_g = reader.readString();
                    break;
                case 16:
                    message.fall_count = reader.readString();
                    break;
                case 17:
                    message.all_time_max_g = reader.readString();
                    break;
                case 18:
                    message.battery_coef = reader.readString();
                    break;
                case 19:
                    message.fall_offset = reader.readString();
                    break;
                case 20:
                    message.firmware_version = reader.readString();
                    break;
                case 21:
                    message.debug_mode = reader.readString();
                    break;
                case 22:
                    message.hold_fall_count = reader.readString();
                    break;
                case 23:
                    message.battery_level = reader.readString();
                    break;
                case 24:
                    message.decimation_factor = reader.readString();
                    break;
                case 25:
                    message.sample_size = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): HICConfig {
        return HICConfig.deserialize(bytes);
    }
}
export class HICState extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        is_connected?: boolean;
        effective_bd_addr?: string;
        battery_level_volt?: number;
        battery_level_coef?: number;
        battery_level_perc?: number;
        effective_serial_number?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("is_connected" in data && data.is_connected != undefined) {
                this.is_connected = data.is_connected;
            }
            if ("effective_bd_addr" in data && data.effective_bd_addr != undefined) {
                this.effective_bd_addr = data.effective_bd_addr;
            }
            if ("battery_level_volt" in data && data.battery_level_volt != undefined) {
                this.battery_level_volt = data.battery_level_volt;
            }
            if ("battery_level_coef" in data && data.battery_level_coef != undefined) {
                this.battery_level_coef = data.battery_level_coef;
            }
            if ("battery_level_perc" in data && data.battery_level_perc != undefined) {
                this.battery_level_perc = data.battery_level_perc;
            }
            if ("effective_serial_number" in data && data.effective_serial_number != undefined) {
                this.effective_serial_number = data.effective_serial_number;
            }
        }
    }
    get is_connected() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set is_connected(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    get effective_bd_addr() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set effective_bd_addr(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get battery_level_volt() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set battery_level_volt(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get battery_level_coef() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set battery_level_coef(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get battery_level_perc() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set battery_level_perc(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get effective_serial_number() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set effective_serial_number(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    static fromObject(data: {
        is_connected?: boolean;
        effective_bd_addr?: string;
        battery_level_volt?: number;
        battery_level_coef?: number;
        battery_level_perc?: number;
        effective_serial_number?: string;
    }): HICState {
        const message = new HICState({});
        if (data.is_connected != null) {
            message.is_connected = data.is_connected;
        }
        if (data.effective_bd_addr != null) {
            message.effective_bd_addr = data.effective_bd_addr;
        }
        if (data.battery_level_volt != null) {
            message.battery_level_volt = data.battery_level_volt;
        }
        if (data.battery_level_coef != null) {
            message.battery_level_coef = data.battery_level_coef;
        }
        if (data.battery_level_perc != null) {
            message.battery_level_perc = data.battery_level_perc;
        }
        if (data.effective_serial_number != null) {
            message.effective_serial_number = data.effective_serial_number;
        }
        return message;
    }
    toObject() {
        const data: {
            is_connected?: boolean;
            effective_bd_addr?: string;
            battery_level_volt?: number;
            battery_level_coef?: number;
            battery_level_perc?: number;
            effective_serial_number?: string;
        } = {};
        if (this.is_connected != null) {
            data.is_connected = this.is_connected;
        }
        if (this.effective_bd_addr != null) {
            data.effective_bd_addr = this.effective_bd_addr;
        }
        if (this.battery_level_volt != null) {
            data.battery_level_volt = this.battery_level_volt;
        }
        if (this.battery_level_coef != null) {
            data.battery_level_coef = this.battery_level_coef;
        }
        if (this.battery_level_perc != null) {
            data.battery_level_perc = this.battery_level_perc;
        }
        if (this.effective_serial_number != null) {
            data.effective_serial_number = this.effective_serial_number;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.is_connected != false)
            writer.writeBool(1, this.is_connected);
        if (this.effective_bd_addr.length)
            writer.writeString(2, this.effective_bd_addr);
        if (this.battery_level_volt != 0)
            writer.writeFloat(3, this.battery_level_volt);
        if (this.battery_level_coef != 0)
            writer.writeFloat(4, this.battery_level_coef);
        if (this.battery_level_perc != 0)
            writer.writeFloat(5, this.battery_level_perc);
        if (this.effective_serial_number.length)
            writer.writeString(6, this.effective_serial_number);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): HICState {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new HICState();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.is_connected = reader.readBool();
                    break;
                case 2:
                    message.effective_bd_addr = reader.readString();
                    break;
                case 3:
                    message.battery_level_volt = reader.readFloat();
                    break;
                case 4:
                    message.battery_level_coef = reader.readFloat();
                    break;
                case 5:
                    message.battery_level_perc = reader.readFloat();
                    break;
                case 6:
                    message.effective_serial_number = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): HICState {
        return HICState.deserialize(bytes);
    }
}
export class StationConfig extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        head_hic_bd_addr?: string;
        serial_number?: string;
        buzzer_state?: boolean;
        lidar_distance_offset_cm?: number;
        last_metrology_date?: number;
        next_metrology_date?: number;
        station_rfcomm_bd_addr?: string;
        station_ble_bd_addr?: string;
        readonly_station_device_sn?: string;
        readonly_station_release?: string;
        readonly_station_version?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("head_hic_bd_addr" in data && data.head_hic_bd_addr != undefined) {
                this.head_hic_bd_addr = data.head_hic_bd_addr;
            }
            if ("serial_number" in data && data.serial_number != undefined) {
                this.serial_number = data.serial_number;
            }
            if ("buzzer_state" in data && data.buzzer_state != undefined) {
                this.buzzer_state = data.buzzer_state;
            }
            if ("lidar_distance_offset_cm" in data && data.lidar_distance_offset_cm != undefined) {
                this.lidar_distance_offset_cm = data.lidar_distance_offset_cm;
            }
            if ("last_metrology_date" in data && data.last_metrology_date != undefined) {
                this.last_metrology_date = data.last_metrology_date;
            }
            if ("next_metrology_date" in data && data.next_metrology_date != undefined) {
                this.next_metrology_date = data.next_metrology_date;
            }
            if ("station_rfcomm_bd_addr" in data && data.station_rfcomm_bd_addr != undefined) {
                this.station_rfcomm_bd_addr = data.station_rfcomm_bd_addr;
            }
            if ("station_ble_bd_addr" in data && data.station_ble_bd_addr != undefined) {
                this.station_ble_bd_addr = data.station_ble_bd_addr;
            }
            if ("readonly_station_device_sn" in data && data.readonly_station_device_sn != undefined) {
                this.readonly_station_device_sn = data.readonly_station_device_sn;
            }
            if ("readonly_station_release" in data && data.readonly_station_release != undefined) {
                this.readonly_station_release = data.readonly_station_release;
            }
            if ("readonly_station_version" in data && data.readonly_station_version != undefined) {
                this.readonly_station_version = data.readonly_station_version;
            }
        }
    }
    get head_hic_bd_addr() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set head_hic_bd_addr(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get serial_number() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set serial_number(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get buzzer_state() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set buzzer_state(value: boolean) {
        pb_1.Message.setField(this, 3, value);
    }
    get lidar_distance_offset_cm() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set lidar_distance_offset_cm(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get last_metrology_date() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set last_metrology_date(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get next_metrology_date() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set next_metrology_date(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get station_rfcomm_bd_addr() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set station_rfcomm_bd_addr(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get station_ble_bd_addr() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set station_ble_bd_addr(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get readonly_station_device_sn() {
        return pb_1.Message.getFieldWithDefault(this, 11, "0") as string;
    }
    set readonly_station_device_sn(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get readonly_station_release() {
        return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
    }
    set readonly_station_release(value: string) {
        pb_1.Message.setField(this, 12, value);
    }
    get readonly_station_version() {
        return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
    }
    set readonly_station_version(value: string) {
        pb_1.Message.setField(this, 13, value);
    }
    static fromObject(data: {
        head_hic_bd_addr?: string;
        serial_number?: string;
        buzzer_state?: boolean;
        lidar_distance_offset_cm?: number;
        last_metrology_date?: number;
        next_metrology_date?: number;
        station_rfcomm_bd_addr?: string;
        station_ble_bd_addr?: string;
        readonly_station_device_sn?: string;
        readonly_station_release?: string;
        readonly_station_version?: string;
    }): StationConfig {
        const message = new StationConfig({});
        if (data.head_hic_bd_addr != null) {
            message.head_hic_bd_addr = data.head_hic_bd_addr;
        }
        if (data.serial_number != null) {
            message.serial_number = data.serial_number;
        }
        if (data.buzzer_state != null) {
            message.buzzer_state = data.buzzer_state;
        }
        if (data.lidar_distance_offset_cm != null) {
            message.lidar_distance_offset_cm = data.lidar_distance_offset_cm;
        }
        if (data.last_metrology_date != null) {
            message.last_metrology_date = data.last_metrology_date;
        }
        if (data.next_metrology_date != null) {
            message.next_metrology_date = data.next_metrology_date;
        }
        if (data.station_rfcomm_bd_addr != null) {
            message.station_rfcomm_bd_addr = data.station_rfcomm_bd_addr;
        }
        if (data.station_ble_bd_addr != null) {
            message.station_ble_bd_addr = data.station_ble_bd_addr;
        }
        if (data.readonly_station_device_sn != null) {
            message.readonly_station_device_sn = data.readonly_station_device_sn;
        }
        if (data.readonly_station_release != null) {
            message.readonly_station_release = data.readonly_station_release;
        }
        if (data.readonly_station_version != null) {
            message.readonly_station_version = data.readonly_station_version;
        }
        return message;
    }
    toObject() {
        const data: {
            head_hic_bd_addr?: string;
            serial_number?: string;
            buzzer_state?: boolean;
            lidar_distance_offset_cm?: number;
            last_metrology_date?: number;
            next_metrology_date?: number;
            station_rfcomm_bd_addr?: string;
            station_ble_bd_addr?: string;
            readonly_station_device_sn?: string;
            readonly_station_release?: string;
            readonly_station_version?: string;
        } = {};
        if (this.head_hic_bd_addr != null) {
            data.head_hic_bd_addr = this.head_hic_bd_addr;
        }
        if (this.serial_number != null) {
            data.serial_number = this.serial_number;
        }
        if (this.buzzer_state != null) {
            data.buzzer_state = this.buzzer_state;
        }
        if (this.lidar_distance_offset_cm != null) {
            data.lidar_distance_offset_cm = this.lidar_distance_offset_cm;
        }
        if (this.last_metrology_date != null) {
            data.last_metrology_date = this.last_metrology_date;
        }
        if (this.next_metrology_date != null) {
            data.next_metrology_date = this.next_metrology_date;
        }
        if (this.station_rfcomm_bd_addr != null) {
            data.station_rfcomm_bd_addr = this.station_rfcomm_bd_addr;
        }
        if (this.station_ble_bd_addr != null) {
            data.station_ble_bd_addr = this.station_ble_bd_addr;
        }
        if (this.readonly_station_device_sn != null) {
            data.readonly_station_device_sn = this.readonly_station_device_sn;
        }
        if (this.readonly_station_release != null) {
            data.readonly_station_release = this.readonly_station_release;
        }
        if (this.readonly_station_version != null) {
            data.readonly_station_version = this.readonly_station_version;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.head_hic_bd_addr.length)
            writer.writeString(1, this.head_hic_bd_addr);
        if (this.serial_number.length)
            writer.writeString(2, this.serial_number);
        if (this.buzzer_state != false)
            writer.writeBool(3, this.buzzer_state);
        if (this.lidar_distance_offset_cm != 0)
            writer.writeInt32(4, this.lidar_distance_offset_cm);
        if (this.last_metrology_date != 0)
            writer.writeInt64(5, this.last_metrology_date);
        if (this.next_metrology_date != 0)
            writer.writeInt64(6, this.next_metrology_date);
        if (this.station_rfcomm_bd_addr.length)
            writer.writeString(9, this.station_rfcomm_bd_addr);
        if (this.station_ble_bd_addr.length)
            writer.writeString(10, this.station_ble_bd_addr);
        if (this.readonly_station_device_sn != "0")
            writer.writeUint64String(11, this.readonly_station_device_sn);
        if (this.readonly_station_release.length)
            writer.writeString(12, this.readonly_station_release);
        if (this.readonly_station_version.length)
            writer.writeString(13, this.readonly_station_version);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StationConfig {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StationConfig();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.head_hic_bd_addr = reader.readString();
                    break;
                case 2:
                    message.serial_number = reader.readString();
                    break;
                case 3:
                    message.buzzer_state = reader.readBool();
                    break;
                case 4:
                    message.lidar_distance_offset_cm = reader.readInt32();
                    break;
                case 5:
                    message.last_metrology_date = reader.readInt64();
                    break;
                case 6:
                    message.next_metrology_date = reader.readInt64();
                    break;
                case 9:
                    message.station_rfcomm_bd_addr = reader.readString();
                    break;
                case 10:
                    message.station_ble_bd_addr = reader.readString();
                    break;
                case 11:
                    message.readonly_station_device_sn = reader.readUint64String();
                    break;
                case 12:
                    message.readonly_station_release = reader.readString();
                    break;
                case 13:
                    message.readonly_station_version = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): StationConfig {
        return StationConfig.deserialize(bytes);
    }
}
export class StationSensors extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        lidar_distance?: number;
        temperature?: number;
        relative_humidity?: number;
        battery_level?: number;
        gnss_lat?: number;
        gnss_lon?: number;
        core_temp?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("lidar_distance" in data && data.lidar_distance != undefined) {
                this.lidar_distance = data.lidar_distance;
            }
            if ("temperature" in data && data.temperature != undefined) {
                this.temperature = data.temperature;
            }
            if ("relative_humidity" in data && data.relative_humidity != undefined) {
                this.relative_humidity = data.relative_humidity;
            }
            if ("battery_level" in data && data.battery_level != undefined) {
                this.battery_level = data.battery_level;
            }
            if ("gnss_lat" in data && data.gnss_lat != undefined) {
                this.gnss_lat = data.gnss_lat;
            }
            if ("gnss_lon" in data && data.gnss_lon != undefined) {
                this.gnss_lon = data.gnss_lon;
            }
            if ("core_temp" in data && data.core_temp != undefined) {
                this.core_temp = data.core_temp;
            }
        }
    }
    get lidar_distance() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set lidar_distance(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get temperature() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set temperature(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get relative_humidity() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set relative_humidity(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get battery_level() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set battery_level(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get gnss_lat() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set gnss_lat(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get gnss_lon() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set gnss_lon(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get core_temp() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set core_temp(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    static fromObject(data: {
        lidar_distance?: number;
        temperature?: number;
        relative_humidity?: number;
        battery_level?: number;
        gnss_lat?: number;
        gnss_lon?: number;
        core_temp?: number;
    }): StationSensors {
        const message = new StationSensors({});
        if (data.lidar_distance != null) {
            message.lidar_distance = data.lidar_distance;
        }
        if (data.temperature != null) {
            message.temperature = data.temperature;
        }
        if (data.relative_humidity != null) {
            message.relative_humidity = data.relative_humidity;
        }
        if (data.battery_level != null) {
            message.battery_level = data.battery_level;
        }
        if (data.gnss_lat != null) {
            message.gnss_lat = data.gnss_lat;
        }
        if (data.gnss_lon != null) {
            message.gnss_lon = data.gnss_lon;
        }
        if (data.core_temp != null) {
            message.core_temp = data.core_temp;
        }
        return message;
    }
    toObject() {
        const data: {
            lidar_distance?: number;
            temperature?: number;
            relative_humidity?: number;
            battery_level?: number;
            gnss_lat?: number;
            gnss_lon?: number;
            core_temp?: number;
        } = {};
        if (this.lidar_distance != null) {
            data.lidar_distance = this.lidar_distance;
        }
        if (this.temperature != null) {
            data.temperature = this.temperature;
        }
        if (this.relative_humidity != null) {
            data.relative_humidity = this.relative_humidity;
        }
        if (this.battery_level != null) {
            data.battery_level = this.battery_level;
        }
        if (this.gnss_lat != null) {
            data.gnss_lat = this.gnss_lat;
        }
        if (this.gnss_lon != null) {
            data.gnss_lon = this.gnss_lon;
        }
        if (this.core_temp != null) {
            data.core_temp = this.core_temp;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.lidar_distance != 0)
            writer.writeInt32(1, this.lidar_distance);
        if (this.temperature != 0)
            writer.writeFloat(2, this.temperature);
        if (this.relative_humidity != 0)
            writer.writeFloat(3, this.relative_humidity);
        if (this.battery_level != 0)
            writer.writeFloat(4, this.battery_level);
        if (this.gnss_lat != 0)
            writer.writeDouble(5, this.gnss_lat);
        if (this.gnss_lon != 0)
            writer.writeDouble(6, this.gnss_lon);
        if (this.core_temp != 0)
            writer.writeFloat(7, this.core_temp);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StationSensors {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StationSensors();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.lidar_distance = reader.readInt32();
                    break;
                case 2:
                    message.temperature = reader.readFloat();
                    break;
                case 3:
                    message.relative_humidity = reader.readFloat();
                    break;
                case 4:
                    message.battery_level = reader.readFloat();
                    break;
                case 5:
                    message.gnss_lat = reader.readDouble();
                    break;
                case 6:
                    message.gnss_lon = reader.readDouble();
                    break;
                case 7:
                    message.core_temp = reader.readFloat();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): StationSensors {
        return StationSensors.deserialize(bytes);
    }
}
export class CombinedStationSensorsAndHICState extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        station_sensors?: StationSensors;
        hic_state?: HICState;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("station_sensors" in data && data.station_sensors != undefined) {
                this.station_sensors = data.station_sensors;
            }
            if ("hic_state" in data && data.hic_state != undefined) {
                this.hic_state = data.hic_state;
            }
        }
    }
    get station_sensors() {
        return pb_1.Message.getWrapperField(this, StationSensors, 1) as StationSensors;
    }
    set station_sensors(value: StationSensors) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_station_sensors() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get hic_state() {
        return pb_1.Message.getWrapperField(this, HICState, 2) as HICState;
    }
    set hic_state(value: HICState) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_hic_state() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        station_sensors?: ReturnType<typeof StationSensors.prototype.toObject>;
        hic_state?: ReturnType<typeof HICState.prototype.toObject>;
    }): CombinedStationSensorsAndHICState {
        const message = new CombinedStationSensorsAndHICState({});
        if (data.station_sensors != null) {
            message.station_sensors = StationSensors.fromObject(data.station_sensors);
        }
        if (data.hic_state != null) {
            message.hic_state = HICState.fromObject(data.hic_state);
        }
        return message;
    }
    toObject() {
        const data: {
            station_sensors?: ReturnType<typeof StationSensors.prototype.toObject>;
            hic_state?: ReturnType<typeof HICState.prototype.toObject>;
        } = {};
        if (this.station_sensors != null) {
            data.station_sensors = this.station_sensors.toObject();
        }
        if (this.hic_state != null) {
            data.hic_state = this.hic_state.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_station_sensors)
            writer.writeMessage(1, this.station_sensors, () => this.station_sensors.serialize(writer));
        if (this.has_hic_state)
            writer.writeMessage(2, this.hic_state, () => this.hic_state.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CombinedStationSensorsAndHICState {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CombinedStationSensorsAndHICState();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.station_sensors, () => message.station_sensors = StationSensors.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.hic_state, () => message.hic_state = HICState.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): CombinedStationSensorsAndHICState {
        return CombinedStationSensorsAndHICState.deserialize(bytes);
    }
}
export class RemoteBluetoothDevice extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        is_connected?: boolean;
        bd_addr?: string;
        device_name?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("is_connected" in data && data.is_connected != undefined) {
                this.is_connected = data.is_connected;
            }
            if ("bd_addr" in data && data.bd_addr != undefined) {
                this.bd_addr = data.bd_addr;
            }
            if ("device_name" in data && data.device_name != undefined) {
                this.device_name = data.device_name;
            }
        }
    }
    get is_connected() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set is_connected(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    get bd_addr() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set bd_addr(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get device_name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set device_name(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        is_connected?: boolean;
        bd_addr?: string;
        device_name?: string;
    }): RemoteBluetoothDevice {
        const message = new RemoteBluetoothDevice({});
        if (data.is_connected != null) {
            message.is_connected = data.is_connected;
        }
        if (data.bd_addr != null) {
            message.bd_addr = data.bd_addr;
        }
        if (data.device_name != null) {
            message.device_name = data.device_name;
        }
        return message;
    }
    toObject() {
        const data: {
            is_connected?: boolean;
            bd_addr?: string;
            device_name?: string;
        } = {};
        if (this.is_connected != null) {
            data.is_connected = this.is_connected;
        }
        if (this.bd_addr != null) {
            data.bd_addr = this.bd_addr;
        }
        if (this.device_name != null) {
            data.device_name = this.device_name;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.is_connected != false)
            writer.writeBool(1, this.is_connected);
        if (this.bd_addr.length)
            writer.writeString(2, this.bd_addr);
        if (this.device_name.length)
            writer.writeString(3, this.device_name);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RemoteBluetoothDevice {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RemoteBluetoothDevice();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.is_connected = reader.readBool();
                    break;
                case 2:
                    message.bd_addr = reader.readString();
                    break;
                case 3:
                    message.device_name = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): RemoteBluetoothDevice {
        return RemoteBluetoothDevice.deserialize(bytes);
    }
}
export class StationState extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        effective_ble_adapter_bd_addr?: string;
        effective_rfcomm_adapter_bd_addr?: string;
        effective_rfcomm_head_hic_bd_addr_candidates?: RemoteBluetoothDevice[];
        effective_ble_clients?: RemoteBluetoothDevice[];
        is_online?: boolean;
        system_timestamp?: number;
        effective_serial_number?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3, 4], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("effective_ble_adapter_bd_addr" in data && data.effective_ble_adapter_bd_addr != undefined) {
                this.effective_ble_adapter_bd_addr = data.effective_ble_adapter_bd_addr;
            }
            if ("effective_rfcomm_adapter_bd_addr" in data && data.effective_rfcomm_adapter_bd_addr != undefined) {
                this.effective_rfcomm_adapter_bd_addr = data.effective_rfcomm_adapter_bd_addr;
            }
            if ("effective_rfcomm_head_hic_bd_addr_candidates" in data && data.effective_rfcomm_head_hic_bd_addr_candidates != undefined) {
                this.effective_rfcomm_head_hic_bd_addr_candidates = data.effective_rfcomm_head_hic_bd_addr_candidates;
            }
            if ("effective_ble_clients" in data && data.effective_ble_clients != undefined) {
                this.effective_ble_clients = data.effective_ble_clients;
            }
            if ("is_online" in data && data.is_online != undefined) {
                this.is_online = data.is_online;
            }
            if ("system_timestamp" in data && data.system_timestamp != undefined) {
                this.system_timestamp = data.system_timestamp;
            }
            if ("effective_serial_number" in data && data.effective_serial_number != undefined) {
                this.effective_serial_number = data.effective_serial_number;
            }
        }
    }
    get effective_ble_adapter_bd_addr() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set effective_ble_adapter_bd_addr(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get effective_rfcomm_adapter_bd_addr() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set effective_rfcomm_adapter_bd_addr(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get effective_rfcomm_head_hic_bd_addr_candidates() {
        return pb_1.Message.getRepeatedWrapperField(this, RemoteBluetoothDevice, 3) as RemoteBluetoothDevice[];
    }
    set effective_rfcomm_head_hic_bd_addr_candidates(value: RemoteBluetoothDevice[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    get effective_ble_clients() {
        return pb_1.Message.getRepeatedWrapperField(this, RemoteBluetoothDevice, 4) as RemoteBluetoothDevice[];
    }
    set effective_ble_clients(value: RemoteBluetoothDevice[]) {
        pb_1.Message.setRepeatedWrapperField(this, 4, value);
    }
    get is_online() {
        return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
    }
    set is_online(value: boolean) {
        pb_1.Message.setField(this, 5, value);
    }
    get system_timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set system_timestamp(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get effective_serial_number() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set effective_serial_number(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    static fromObject(data: {
        effective_ble_adapter_bd_addr?: string;
        effective_rfcomm_adapter_bd_addr?: string;
        effective_rfcomm_head_hic_bd_addr_candidates?: ReturnType<typeof RemoteBluetoothDevice.prototype.toObject>[];
        effective_ble_clients?: ReturnType<typeof RemoteBluetoothDevice.prototype.toObject>[];
        is_online?: boolean;
        system_timestamp?: number;
        effective_serial_number?: string;
    }): StationState {
        const message = new StationState({});
        if (data.effective_ble_adapter_bd_addr != null) {
            message.effective_ble_adapter_bd_addr = data.effective_ble_adapter_bd_addr;
        }
        if (data.effective_rfcomm_adapter_bd_addr != null) {
            message.effective_rfcomm_adapter_bd_addr = data.effective_rfcomm_adapter_bd_addr;
        }
        if (data.effective_rfcomm_head_hic_bd_addr_candidates != null) {
            message.effective_rfcomm_head_hic_bd_addr_candidates = data.effective_rfcomm_head_hic_bd_addr_candidates.map(item => RemoteBluetoothDevice.fromObject(item));
        }
        if (data.effective_ble_clients != null) {
            message.effective_ble_clients = data.effective_ble_clients.map(item => RemoteBluetoothDevice.fromObject(item));
        }
        if (data.is_online != null) {
            message.is_online = data.is_online;
        }
        if (data.system_timestamp != null) {
            message.system_timestamp = data.system_timestamp;
        }
        if (data.effective_serial_number != null) {
            message.effective_serial_number = data.effective_serial_number;
        }
        return message;
    }
    toObject() {
        const data: {
            effective_ble_adapter_bd_addr?: string;
            effective_rfcomm_adapter_bd_addr?: string;
            effective_rfcomm_head_hic_bd_addr_candidates?: ReturnType<typeof RemoteBluetoothDevice.prototype.toObject>[];
            effective_ble_clients?: ReturnType<typeof RemoteBluetoothDevice.prototype.toObject>[];
            is_online?: boolean;
            system_timestamp?: number;
            effective_serial_number?: string;
        } = {};
        if (this.effective_ble_adapter_bd_addr != null) {
            data.effective_ble_adapter_bd_addr = this.effective_ble_adapter_bd_addr;
        }
        if (this.effective_rfcomm_adapter_bd_addr != null) {
            data.effective_rfcomm_adapter_bd_addr = this.effective_rfcomm_adapter_bd_addr;
        }
        if (this.effective_rfcomm_head_hic_bd_addr_candidates != null) {
            data.effective_rfcomm_head_hic_bd_addr_candidates = this.effective_rfcomm_head_hic_bd_addr_candidates.map((item: RemoteBluetoothDevice) => item.toObject());
        }
        if (this.effective_ble_clients != null) {
            data.effective_ble_clients = this.effective_ble_clients.map((item: RemoteBluetoothDevice) => item.toObject());
        }
        if (this.is_online != null) {
            data.is_online = this.is_online;
        }
        if (this.system_timestamp != null) {
            data.system_timestamp = this.system_timestamp;
        }
        if (this.effective_serial_number != null) {
            data.effective_serial_number = this.effective_serial_number;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.effective_ble_adapter_bd_addr.length)
            writer.writeString(1, this.effective_ble_adapter_bd_addr);
        if (this.effective_rfcomm_adapter_bd_addr.length)
            writer.writeString(2, this.effective_rfcomm_adapter_bd_addr);
        if (this.effective_rfcomm_head_hic_bd_addr_candidates.length)
            writer.writeRepeatedMessage(3, this.effective_rfcomm_head_hic_bd_addr_candidates, (item: RemoteBluetoothDevice) => item.serialize(writer));
        if (this.effective_ble_clients.length)
            writer.writeRepeatedMessage(4, this.effective_ble_clients, (item: RemoteBluetoothDevice) => item.serialize(writer));
        if (this.is_online != false)
            writer.writeBool(5, this.is_online);
        if (this.system_timestamp != 0)
            writer.writeUint64(6, this.system_timestamp);
        if (this.effective_serial_number.length)
            writer.writeString(7, this.effective_serial_number);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StationState {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StationState();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.effective_ble_adapter_bd_addr = reader.readString();
                    break;
                case 2:
                    message.effective_rfcomm_adapter_bd_addr = reader.readString();
                    break;
                case 3:
                    reader.readMessage(message.effective_rfcomm_head_hic_bd_addr_candidates, () => pb_1.Message.addToRepeatedWrapperField(message, 3, RemoteBluetoothDevice.deserialize(reader), RemoteBluetoothDevice));
                    break;
                case 4:
                    reader.readMessage(message.effective_ble_clients, () => pb_1.Message.addToRepeatedWrapperField(message, 4, RemoteBluetoothDevice.deserialize(reader), RemoteBluetoothDevice));
                    break;
                case 5:
                    message.is_online = reader.readBool();
                    break;
                case 6:
                    message.system_timestamp = reader.readUint64();
                    break;
                case 7:
                    message.effective_serial_number = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): StationState {
        return StationState.deserialize(bytes);
    }
}
export class StorageRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        entity_uuid?: UUID;
        parent_uuid?: UUID;
        entity_type?: StorageEntityType;
        single_entity_data?: Uint8Array;
        current_timestamp?: number;
        preprocess_decimation_rate?: number;
        preprocess_include_axes_points?: boolean;
        preprocess_include_quadratic_points?: boolean;
        preprocess_differential_encode_points?: boolean;
        allow_upsert_site_of_different_device_sn?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("entity_uuid" in data && data.entity_uuid != undefined) {
                this.entity_uuid = data.entity_uuid;
            }
            if ("parent_uuid" in data && data.parent_uuid != undefined) {
                this.parent_uuid = data.parent_uuid;
            }
            if ("entity_type" in data && data.entity_type != undefined) {
                this.entity_type = data.entity_type;
            }
            if ("single_entity_data" in data && data.single_entity_data != undefined) {
                this.single_entity_data = data.single_entity_data;
            }
            if ("current_timestamp" in data && data.current_timestamp != undefined) {
                this.current_timestamp = data.current_timestamp;
            }
            if ("preprocess_decimation_rate" in data && data.preprocess_decimation_rate != undefined) {
                this.preprocess_decimation_rate = data.preprocess_decimation_rate;
            }
            if ("preprocess_include_axes_points" in data && data.preprocess_include_axes_points != undefined) {
                this.preprocess_include_axes_points = data.preprocess_include_axes_points;
            }
            if ("preprocess_include_quadratic_points" in data && data.preprocess_include_quadratic_points != undefined) {
                this.preprocess_include_quadratic_points = data.preprocess_include_quadratic_points;
            }
            if ("preprocess_differential_encode_points" in data && data.preprocess_differential_encode_points != undefined) {
                this.preprocess_differential_encode_points = data.preprocess_differential_encode_points;
            }
            if ("allow_upsert_site_of_different_device_sn" in data && data.allow_upsert_site_of_different_device_sn != undefined) {
                this.allow_upsert_site_of_different_device_sn = data.allow_upsert_site_of_different_device_sn;
            }
        }
    }
    get entity_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 1) as UUID;
    }
    set entity_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_entity_uuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get parent_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 2) as UUID;
    }
    set parent_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_parent_uuid() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get entity_type() {
        return pb_1.Message.getFieldWithDefault(this, 3, StorageEntityType.UNKNOWN_STORAGE_ENTITY_TYPE) as StorageEntityType;
    }
    set entity_type(value: StorageEntityType) {
        pb_1.Message.setField(this, 3, value);
    }
    get single_entity_data() {
        return pb_1.Message.getFieldWithDefault(this, 4, new Uint8Array(0)) as Uint8Array;
    }
    set single_entity_data(value: Uint8Array) {
        pb_1.Message.setField(this, 4, value);
    }
    get current_timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set current_timestamp(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get preprocess_decimation_rate() {
        return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
    }
    set preprocess_decimation_rate(value: number) {
        pb_1.Message.setField(this, 10, value);
    }
    get preprocess_include_axes_points() {
        return pb_1.Message.getFieldWithDefault(this, 11, false) as boolean;
    }
    set preprocess_include_axes_points(value: boolean) {
        pb_1.Message.setField(this, 11, value);
    }
    get preprocess_include_quadratic_points() {
        return pb_1.Message.getFieldWithDefault(this, 12, false) as boolean;
    }
    set preprocess_include_quadratic_points(value: boolean) {
        pb_1.Message.setField(this, 12, value);
    }
    get preprocess_differential_encode_points() {
        return pb_1.Message.getFieldWithDefault(this, 14, false) as boolean;
    }
    set preprocess_differential_encode_points(value: boolean) {
        pb_1.Message.setField(this, 14, value);
    }
    get allow_upsert_site_of_different_device_sn() {
        return pb_1.Message.getFieldWithDefault(this, 13, false) as boolean;
    }
    set allow_upsert_site_of_different_device_sn(value: boolean) {
        pb_1.Message.setField(this, 13, value);
    }
    static fromObject(data: {
        entity_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        parent_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        entity_type?: StorageEntityType;
        single_entity_data?: Uint8Array;
        current_timestamp?: number;
        preprocess_decimation_rate?: number;
        preprocess_include_axes_points?: boolean;
        preprocess_include_quadratic_points?: boolean;
        preprocess_differential_encode_points?: boolean;
        allow_upsert_site_of_different_device_sn?: boolean;
    }): StorageRequest {
        const message = new StorageRequest({});
        if (data.entity_uuid != null) {
            message.entity_uuid = UUID.fromObject(data.entity_uuid);
        }
        if (data.parent_uuid != null) {
            message.parent_uuid = UUID.fromObject(data.parent_uuid);
        }
        if (data.entity_type != null) {
            message.entity_type = data.entity_type;
        }
        if (data.single_entity_data != null) {
            message.single_entity_data = data.single_entity_data;
        }
        if (data.current_timestamp != null) {
            message.current_timestamp = data.current_timestamp;
        }
        if (data.preprocess_decimation_rate != null) {
            message.preprocess_decimation_rate = data.preprocess_decimation_rate;
        }
        if (data.preprocess_include_axes_points != null) {
            message.preprocess_include_axes_points = data.preprocess_include_axes_points;
        }
        if (data.preprocess_include_quadratic_points != null) {
            message.preprocess_include_quadratic_points = data.preprocess_include_quadratic_points;
        }
        if (data.preprocess_differential_encode_points != null) {
            message.preprocess_differential_encode_points = data.preprocess_differential_encode_points;
        }
        if (data.allow_upsert_site_of_different_device_sn != null) {
            message.allow_upsert_site_of_different_device_sn = data.allow_upsert_site_of_different_device_sn;
        }
        return message;
    }
    toObject() {
        const data: {
            entity_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            parent_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            entity_type?: StorageEntityType;
            single_entity_data?: Uint8Array;
            current_timestamp?: number;
            preprocess_decimation_rate?: number;
            preprocess_include_axes_points?: boolean;
            preprocess_include_quadratic_points?: boolean;
            preprocess_differential_encode_points?: boolean;
            allow_upsert_site_of_different_device_sn?: boolean;
        } = {};
        if (this.entity_uuid != null) {
            data.entity_uuid = this.entity_uuid.toObject();
        }
        if (this.parent_uuid != null) {
            data.parent_uuid = this.parent_uuid.toObject();
        }
        if (this.entity_type != null) {
            data.entity_type = this.entity_type;
        }
        if (this.single_entity_data != null) {
            data.single_entity_data = this.single_entity_data;
        }
        if (this.current_timestamp != null) {
            data.current_timestamp = this.current_timestamp;
        }
        if (this.preprocess_decimation_rate != null) {
            data.preprocess_decimation_rate = this.preprocess_decimation_rate;
        }
        if (this.preprocess_include_axes_points != null) {
            data.preprocess_include_axes_points = this.preprocess_include_axes_points;
        }
        if (this.preprocess_include_quadratic_points != null) {
            data.preprocess_include_quadratic_points = this.preprocess_include_quadratic_points;
        }
        if (this.preprocess_differential_encode_points != null) {
            data.preprocess_differential_encode_points = this.preprocess_differential_encode_points;
        }
        if (this.allow_upsert_site_of_different_device_sn != null) {
            data.allow_upsert_site_of_different_device_sn = this.allow_upsert_site_of_different_device_sn;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_entity_uuid)
            writer.writeMessage(1, this.entity_uuid, () => this.entity_uuid.serialize(writer));
        if (this.has_parent_uuid)
            writer.writeMessage(2, this.parent_uuid, () => this.parent_uuid.serialize(writer));
        if (this.entity_type != StorageEntityType.UNKNOWN_STORAGE_ENTITY_TYPE)
            writer.writeEnum(3, this.entity_type);
        if (this.single_entity_data.length)
            writer.writeBytes(4, this.single_entity_data);
        if (this.current_timestamp != 0)
            writer.writeUint64(5, this.current_timestamp);
        if (this.preprocess_decimation_rate != 0)
            writer.writeUint32(10, this.preprocess_decimation_rate);
        if (this.preprocess_include_axes_points != false)
            writer.writeBool(11, this.preprocess_include_axes_points);
        if (this.preprocess_include_quadratic_points != false)
            writer.writeBool(12, this.preprocess_include_quadratic_points);
        if (this.preprocess_differential_encode_points != false)
            writer.writeBool(14, this.preprocess_differential_encode_points);
        if (this.allow_upsert_site_of_different_device_sn != false)
            writer.writeBool(13, this.allow_upsert_site_of_different_device_sn);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StorageRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StorageRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.entity_uuid, () => message.entity_uuid = UUID.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.parent_uuid, () => message.parent_uuid = UUID.deserialize(reader));
                    break;
                case 3:
                    message.entity_type = reader.readEnum();
                    break;
                case 4:
                    message.single_entity_data = reader.readBytes();
                    break;
                case 5:
                    message.current_timestamp = reader.readUint64();
                    break;
                case 10:
                    message.preprocess_decimation_rate = reader.readUint32();
                    break;
                case 11:
                    message.preprocess_include_axes_points = reader.readBool();
                    break;
                case 12:
                    message.preprocess_include_quadratic_points = reader.readBool();
                    break;
                case 14:
                    message.preprocess_differential_encode_points = reader.readBool();
                    break;
                case 13:
                    message.allow_upsert_site_of_different_device_sn = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): StorageRequest {
        return StorageRequest.deserialize(bytes);
    }
}
export class StorageResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        single_entity_data?: Uint8Array;
        multiple_entities_data?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("single_entity_data" in data && data.single_entity_data != undefined) {
                this.single_entity_data = data.single_entity_data;
            }
            if ("multiple_entities_data" in data && data.multiple_entities_data != undefined) {
                this.multiple_entities_data = data.multiple_entities_data;
            }
        }
    }
    get single_entity_data() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set single_entity_data(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get multiple_entities_data() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set multiple_entities_data(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        single_entity_data?: Uint8Array;
        multiple_entities_data?: Uint8Array;
    }): StorageResponse {
        const message = new StorageResponse({});
        if (data.single_entity_data != null) {
            message.single_entity_data = data.single_entity_data;
        }
        if (data.multiple_entities_data != null) {
            message.multiple_entities_data = data.multiple_entities_data;
        }
        return message;
    }
    toObject() {
        const data: {
            single_entity_data?: Uint8Array;
            multiple_entities_data?: Uint8Array;
        } = {};
        if (this.single_entity_data != null) {
            data.single_entity_data = this.single_entity_data;
        }
        if (this.multiple_entities_data != null) {
            data.multiple_entities_data = this.multiple_entities_data;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.single_entity_data.length)
            writer.writeBytes(1, this.single_entity_data);
        if (this.multiple_entities_data.length)
            writer.writeBytes(2, this.multiple_entities_data);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StorageResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StorageResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.single_entity_data = reader.readBytes();
                    break;
                case 2:
                    message.multiple_entities_data = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): StorageResponse {
        return StorageResponse.deserialize(bytes);
    }
}
export class RawStorageEntity extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: UUID;
        parent_uuid?: UUID;
        entity_type?: StorageEntityType;
        data?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("parent_uuid" in data && data.parent_uuid != undefined) {
                this.parent_uuid = data.parent_uuid;
            }
            if ("entity_type" in data && data.entity_type != undefined) {
                this.entity_type = data.entity_type;
            }
            if ("data" in data && data.data != undefined) {
                this.data = data.data;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 1) as UUID;
    }
    set uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_uuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get parent_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 2) as UUID;
    }
    set parent_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_parent_uuid() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get entity_type() {
        return pb_1.Message.getFieldWithDefault(this, 3, StorageEntityType.UNKNOWN_STORAGE_ENTITY_TYPE) as StorageEntityType;
    }
    set entity_type(value: StorageEntityType) {
        pb_1.Message.setField(this, 3, value);
    }
    get data() {
        return pb_1.Message.getFieldWithDefault(this, 4, new Uint8Array(0)) as Uint8Array;
    }
    set data(value: Uint8Array) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data: {
        uuid?: ReturnType<typeof UUID.prototype.toObject>;
        parent_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        entity_type?: StorageEntityType;
        data?: Uint8Array;
    }): RawStorageEntity {
        const message = new RawStorageEntity({});
        if (data.uuid != null) {
            message.uuid = UUID.fromObject(data.uuid);
        }
        if (data.parent_uuid != null) {
            message.parent_uuid = UUID.fromObject(data.parent_uuid);
        }
        if (data.entity_type != null) {
            message.entity_type = data.entity_type;
        }
        if (data.data != null) {
            message.data = data.data;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: ReturnType<typeof UUID.prototype.toObject>;
            parent_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            entity_type?: StorageEntityType;
            data?: Uint8Array;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid.toObject();
        }
        if (this.parent_uuid != null) {
            data.parent_uuid = this.parent_uuid.toObject();
        }
        if (this.entity_type != null) {
            data.entity_type = this.entity_type;
        }
        if (this.data != null) {
            data.data = this.data;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_uuid)
            writer.writeMessage(1, this.uuid, () => this.uuid.serialize(writer));
        if (this.has_parent_uuid)
            writer.writeMessage(2, this.parent_uuid, () => this.parent_uuid.serialize(writer));
        if (this.entity_type != StorageEntityType.UNKNOWN_STORAGE_ENTITY_TYPE)
            writer.writeEnum(3, this.entity_type);
        if (this.data.length)
            writer.writeBytes(4, this.data);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RawStorageEntity {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RawStorageEntity();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.uuid, () => message.uuid = UUID.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.parent_uuid, () => message.parent_uuid = UUID.deserialize(reader));
                    break;
                case 3:
                    message.entity_type = reader.readEnum();
                    break;
                case 4:
                    message.data = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): RawStorageEntity {
        return RawStorageEntity.deserialize(bytes);
    }
}
export class GenericStorageEntity extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: UUID;
        parent_uuid?: UUID;
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("parent_uuid" in data && data.parent_uuid != undefined) {
                this.parent_uuid = data.parent_uuid;
            }
            if ("created_at" in data && data.created_at != undefined) {
                this.created_at = data.created_at;
            }
            if ("updated_at" in data && data.updated_at != undefined) {
                this.updated_at = data.updated_at;
            }
            if ("deleted_at" in data && data.deleted_at != undefined) {
                this.deleted_at = data.deleted_at;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 1) as UUID;
    }
    set uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_uuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get parent_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 2) as UUID;
    }
    set parent_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_parent_uuid() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get created_at() {
        return pb_1.Message.getFieldWithDefault(this, 30, 0) as number;
    }
    set created_at(value: number) {
        pb_1.Message.setField(this, 30, value);
    }
    get updated_at() {
        return pb_1.Message.getFieldWithDefault(this, 31, 0) as number;
    }
    set updated_at(value: number) {
        pb_1.Message.setField(this, 31, value);
    }
    get deleted_at() {
        return pb_1.Message.getFieldWithDefault(this, 32, 0) as number;
    }
    set deleted_at(value: number) {
        pb_1.Message.setField(this, 32, value);
    }
    static fromObject(data: {
        uuid?: ReturnType<typeof UUID.prototype.toObject>;
        parent_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        created_at?: number;
        updated_at?: number;
        deleted_at?: number;
    }): GenericStorageEntity {
        const message = new GenericStorageEntity({});
        if (data.uuid != null) {
            message.uuid = UUID.fromObject(data.uuid);
        }
        if (data.parent_uuid != null) {
            message.parent_uuid = UUID.fromObject(data.parent_uuid);
        }
        if (data.created_at != null) {
            message.created_at = data.created_at;
        }
        if (data.updated_at != null) {
            message.updated_at = data.updated_at;
        }
        if (data.deleted_at != null) {
            message.deleted_at = data.deleted_at;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: ReturnType<typeof UUID.prototype.toObject>;
            parent_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            created_at?: number;
            updated_at?: number;
            deleted_at?: number;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid.toObject();
        }
        if (this.parent_uuid != null) {
            data.parent_uuid = this.parent_uuid.toObject();
        }
        if (this.created_at != null) {
            data.created_at = this.created_at;
        }
        if (this.updated_at != null) {
            data.updated_at = this.updated_at;
        }
        if (this.deleted_at != null) {
            data.deleted_at = this.deleted_at;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_uuid)
            writer.writeMessage(1, this.uuid, () => this.uuid.serialize(writer));
        if (this.has_parent_uuid)
            writer.writeMessage(2, this.parent_uuid, () => this.parent_uuid.serialize(writer));
        if (this.created_at != 0)
            writer.writeUint64(30, this.created_at);
        if (this.updated_at != 0)
            writer.writeUint64(31, this.updated_at);
        if (this.deleted_at != 0)
            writer.writeUint64(32, this.deleted_at);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GenericStorageEntity {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GenericStorageEntity();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.uuid, () => message.uuid = UUID.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.parent_uuid, () => message.parent_uuid = UUID.deserialize(reader));
                    break;
                case 30:
                    message.created_at = reader.readUint64();
                    break;
                case 31:
                    message.updated_at = reader.readUint64();
                    break;
                case 32:
                    message.deleted_at = reader.readUint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): GenericStorageEntity {
        return GenericStorageEntity.deserialize(bytes);
    }
}
export class ReleaseRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        release_key?: string;
        new_release_key?: string;
        release_data?: Uint8Array;
        offset?: number;
        release_uri?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("release_key" in data && data.release_key != undefined) {
                this.release_key = data.release_key;
            }
            if ("new_release_key" in data && data.new_release_key != undefined) {
                this.new_release_key = data.new_release_key;
            }
            if ("release_data" in data && data.release_data != undefined) {
                this.release_data = data.release_data;
            }
            if ("offset" in data && data.offset != undefined) {
                this.offset = data.offset;
            }
            if ("release_uri" in data && data.release_uri != undefined) {
                this.release_uri = data.release_uri;
            }
        }
    }
    get release_key() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set release_key(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get new_release_key() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set new_release_key(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get release_data() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
    }
    set release_data(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    get offset() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set offset(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get release_uri() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set release_uri(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        release_key?: string;
        new_release_key?: string;
        release_data?: Uint8Array;
        offset?: number;
        release_uri?: string;
    }): ReleaseRequest {
        const message = new ReleaseRequest({});
        if (data.release_key != null) {
            message.release_key = data.release_key;
        }
        if (data.new_release_key != null) {
            message.new_release_key = data.new_release_key;
        }
        if (data.release_data != null) {
            message.release_data = data.release_data;
        }
        if (data.offset != null) {
            message.offset = data.offset;
        }
        if (data.release_uri != null) {
            message.release_uri = data.release_uri;
        }
        return message;
    }
    toObject() {
        const data: {
            release_key?: string;
            new_release_key?: string;
            release_data?: Uint8Array;
            offset?: number;
            release_uri?: string;
        } = {};
        if (this.release_key != null) {
            data.release_key = this.release_key;
        }
        if (this.new_release_key != null) {
            data.new_release_key = this.new_release_key;
        }
        if (this.release_data != null) {
            data.release_data = this.release_data;
        }
        if (this.offset != null) {
            data.offset = this.offset;
        }
        if (this.release_uri != null) {
            data.release_uri = this.release_uri;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.release_key.length)
            writer.writeString(1, this.release_key);
        if (this.new_release_key.length)
            writer.writeString(2, this.new_release_key);
        if (this.release_data.length)
            writer.writeBytes(3, this.release_data);
        if (this.offset != 0)
            writer.writeUint32(4, this.offset);
        if (this.release_uri.length)
            writer.writeString(5, this.release_uri);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ReleaseRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ReleaseRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.release_key = reader.readString();
                    break;
                case 2:
                    message.new_release_key = reader.readString();
                    break;
                case 3:
                    message.release_data = reader.readBytes();
                    break;
                case 4:
                    message.offset = reader.readUint32();
                    break;
                case 5:
                    message.release_uri = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): ReleaseRequest {
        return ReleaseRequest.deserialize(bytes);
    }
}
export class ReleaseDetails extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        release_key?: string;
        release_size?: number;
        release_md5_hash?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("release_key" in data && data.release_key != undefined) {
                this.release_key = data.release_key;
            }
            if ("release_size" in data && data.release_size != undefined) {
                this.release_size = data.release_size;
            }
            if ("release_md5_hash" in data && data.release_md5_hash != undefined) {
                this.release_md5_hash = data.release_md5_hash;
            }
        }
    }
    get release_key() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set release_key(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get release_size() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set release_size(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get release_md5_hash() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set release_md5_hash(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        release_key?: string;
        release_size?: number;
        release_md5_hash?: string;
    }): ReleaseDetails {
        const message = new ReleaseDetails({});
        if (data.release_key != null) {
            message.release_key = data.release_key;
        }
        if (data.release_size != null) {
            message.release_size = data.release_size;
        }
        if (data.release_md5_hash != null) {
            message.release_md5_hash = data.release_md5_hash;
        }
        return message;
    }
    toObject() {
        const data: {
            release_key?: string;
            release_size?: number;
            release_md5_hash?: string;
        } = {};
        if (this.release_key != null) {
            data.release_key = this.release_key;
        }
        if (this.release_size != null) {
            data.release_size = this.release_size;
        }
        if (this.release_md5_hash != null) {
            data.release_md5_hash = this.release_md5_hash;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.release_key.length)
            writer.writeString(1, this.release_key);
        if (this.release_size != 0)
            writer.writeUint64(2, this.release_size);
        if (this.release_md5_hash.length)
            writer.writeString(3, this.release_md5_hash);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ReleaseDetails {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ReleaseDetails();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.release_key = reader.readString();
                    break;
                case 2:
                    message.release_size = reader.readUint64();
                    break;
                case 3:
                    message.release_md5_hash = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): ReleaseDetails {
        return ReleaseDetails.deserialize(bytes);
    }
}
export class ReleaseResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        releases?: ReleaseDetails[];
        current_release_key?: string;
        current_release_offset?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("releases" in data && data.releases != undefined) {
                this.releases = data.releases;
            }
            if ("current_release_key" in data && data.current_release_key != undefined) {
                this.current_release_key = data.current_release_key;
            }
            if ("current_release_offset" in data && data.current_release_offset != undefined) {
                this.current_release_offset = data.current_release_offset;
            }
        }
    }
    get releases() {
        return pb_1.Message.getRepeatedWrapperField(this, ReleaseDetails, 2) as ReleaseDetails[];
    }
    set releases(value: ReleaseDetails[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    get current_release_key() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set current_release_key(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get current_release_offset() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set current_release_offset(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data: {
        releases?: ReturnType<typeof ReleaseDetails.prototype.toObject>[];
        current_release_key?: string;
        current_release_offset?: number;
    }): ReleaseResponse {
        const message = new ReleaseResponse({});
        if (data.releases != null) {
            message.releases = data.releases.map(item => ReleaseDetails.fromObject(item));
        }
        if (data.current_release_key != null) {
            message.current_release_key = data.current_release_key;
        }
        if (data.current_release_offset != null) {
            message.current_release_offset = data.current_release_offset;
        }
        return message;
    }
    toObject() {
        const data: {
            releases?: ReturnType<typeof ReleaseDetails.prototype.toObject>[];
            current_release_key?: string;
            current_release_offset?: number;
        } = {};
        if (this.releases != null) {
            data.releases = this.releases.map((item: ReleaseDetails) => item.toObject());
        }
        if (this.current_release_key != null) {
            data.current_release_key = this.current_release_key;
        }
        if (this.current_release_offset != null) {
            data.current_release_offset = this.current_release_offset;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.releases.length)
            writer.writeRepeatedMessage(2, this.releases, (item: ReleaseDetails) => item.serialize(writer));
        if (this.current_release_key.length)
            writer.writeString(3, this.current_release_key);
        if (this.current_release_offset != 0)
            writer.writeUint32(4, this.current_release_offset);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ReleaseResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ReleaseResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 2:
                    reader.readMessage(message.releases, () => pb_1.Message.addToRepeatedWrapperField(message, 2, ReleaseDetails.deserialize(reader), ReleaseDetails));
                    break;
                case 3:
                    message.current_release_key = reader.readString();
                    break;
                case 4:
                    message.current_release_offset = reader.readUint32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): ReleaseResponse {
        return ReleaseResponse.deserialize(bytes);
    }
}
export class NominatimAddress extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        road?: string;
        neighbourhood?: string;
        suburb?: string;
        village?: string;
        city?: string;
        county?: string;
        municipality?: string;
        state_district?: string;
        state?: string;
        postcode?: string;
        country?: string;
        country_code?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("road" in data && data.road != undefined) {
                this.road = data.road;
            }
            if ("neighbourhood" in data && data.neighbourhood != undefined) {
                this.neighbourhood = data.neighbourhood;
            }
            if ("suburb" in data && data.suburb != undefined) {
                this.suburb = data.suburb;
            }
            if ("village" in data && data.village != undefined) {
                this.village = data.village;
            }
            if ("city" in data && data.city != undefined) {
                this.city = data.city;
            }
            if ("county" in data && data.county != undefined) {
                this.county = data.county;
            }
            if ("municipality" in data && data.municipality != undefined) {
                this.municipality = data.municipality;
            }
            if ("state_district" in data && data.state_district != undefined) {
                this.state_district = data.state_district;
            }
            if ("state" in data && data.state != undefined) {
                this.state = data.state;
            }
            if ("postcode" in data && data.postcode != undefined) {
                this.postcode = data.postcode;
            }
            if ("country" in data && data.country != undefined) {
                this.country = data.country;
            }
            if ("country_code" in data && data.country_code != undefined) {
                this.country_code = data.country_code;
            }
        }
    }
    get road() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set road(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get neighbourhood() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set neighbourhood(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get suburb() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set suburb(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get village() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set village(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get city() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set city(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get county() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set county(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get municipality() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set municipality(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get state_district() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set state_district(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get state() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set state(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get postcode() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set postcode(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get country() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set country(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get country_code() {
        return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
    }
    set country_code(value: string) {
        pb_1.Message.setField(this, 12, value);
    }
    static fromObject(data: {
        road?: string;
        neighbourhood?: string;
        suburb?: string;
        village?: string;
        city?: string;
        county?: string;
        municipality?: string;
        state_district?: string;
        state?: string;
        postcode?: string;
        country?: string;
        country_code?: string;
    }): NominatimAddress {
        const message = new NominatimAddress({});
        if (data.road != null) {
            message.road = data.road;
        }
        if (data.neighbourhood != null) {
            message.neighbourhood = data.neighbourhood;
        }
        if (data.suburb != null) {
            message.suburb = data.suburb;
        }
        if (data.village != null) {
            message.village = data.village;
        }
        if (data.city != null) {
            message.city = data.city;
        }
        if (data.county != null) {
            message.county = data.county;
        }
        if (data.municipality != null) {
            message.municipality = data.municipality;
        }
        if (data.state_district != null) {
            message.state_district = data.state_district;
        }
        if (data.state != null) {
            message.state = data.state;
        }
        if (data.postcode != null) {
            message.postcode = data.postcode;
        }
        if (data.country != null) {
            message.country = data.country;
        }
        if (data.country_code != null) {
            message.country_code = data.country_code;
        }
        return message;
    }
    toObject() {
        const data: {
            road?: string;
            neighbourhood?: string;
            suburb?: string;
            village?: string;
            city?: string;
            county?: string;
            municipality?: string;
            state_district?: string;
            state?: string;
            postcode?: string;
            country?: string;
            country_code?: string;
        } = {};
        if (this.road != null) {
            data.road = this.road;
        }
        if (this.neighbourhood != null) {
            data.neighbourhood = this.neighbourhood;
        }
        if (this.suburb != null) {
            data.suburb = this.suburb;
        }
        if (this.village != null) {
            data.village = this.village;
        }
        if (this.city != null) {
            data.city = this.city;
        }
        if (this.county != null) {
            data.county = this.county;
        }
        if (this.municipality != null) {
            data.municipality = this.municipality;
        }
        if (this.state_district != null) {
            data.state_district = this.state_district;
        }
        if (this.state != null) {
            data.state = this.state;
        }
        if (this.postcode != null) {
            data.postcode = this.postcode;
        }
        if (this.country != null) {
            data.country = this.country;
        }
        if (this.country_code != null) {
            data.country_code = this.country_code;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.road.length)
            writer.writeString(1, this.road);
        if (this.neighbourhood.length)
            writer.writeString(2, this.neighbourhood);
        if (this.suburb.length)
            writer.writeString(3, this.suburb);
        if (this.village.length)
            writer.writeString(4, this.village);
        if (this.city.length)
            writer.writeString(5, this.city);
        if (this.county.length)
            writer.writeString(6, this.county);
        if (this.municipality.length)
            writer.writeString(7, this.municipality);
        if (this.state_district.length)
            writer.writeString(8, this.state_district);
        if (this.state.length)
            writer.writeString(9, this.state);
        if (this.postcode.length)
            writer.writeString(10, this.postcode);
        if (this.country.length)
            writer.writeString(11, this.country);
        if (this.country_code.length)
            writer.writeString(12, this.country_code);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NominatimAddress {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NominatimAddress();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.road = reader.readString();
                    break;
                case 2:
                    message.neighbourhood = reader.readString();
                    break;
                case 3:
                    message.suburb = reader.readString();
                    break;
                case 4:
                    message.village = reader.readString();
                    break;
                case 5:
                    message.city = reader.readString();
                    break;
                case 6:
                    message.county = reader.readString();
                    break;
                case 7:
                    message.municipality = reader.readString();
                    break;
                case 8:
                    message.state_district = reader.readString();
                    break;
                case 9:
                    message.state = reader.readString();
                    break;
                case 10:
                    message.postcode = reader.readString();
                    break;
                case 11:
                    message.country = reader.readString();
                    break;
                case 12:
                    message.country_code = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): NominatimAddress {
        return NominatimAddress.deserialize(bytes);
    }
}
export class NominatimReverseGeocodeEntity extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        place_id?: number;
        licence?: string;
        osm_type?: string;
        osm_id?: number;
        lat?: string;
        lon?: string;
        category?: string;
        type?: string;
        place_rank?: number;
        importance?: number;
        address_type?: string;
        name?: string;
        display_name?: string;
        address?: NominatimAddress;
        bounding_box?: string[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [15], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("place_id" in data && data.place_id != undefined) {
                this.place_id = data.place_id;
            }
            if ("licence" in data && data.licence != undefined) {
                this.licence = data.licence;
            }
            if ("osm_type" in data && data.osm_type != undefined) {
                this.osm_type = data.osm_type;
            }
            if ("osm_id" in data && data.osm_id != undefined) {
                this.osm_id = data.osm_id;
            }
            if ("lat" in data && data.lat != undefined) {
                this.lat = data.lat;
            }
            if ("lon" in data && data.lon != undefined) {
                this.lon = data.lon;
            }
            if ("category" in data && data.category != undefined) {
                this.category = data.category;
            }
            if ("type" in data && data.type != undefined) {
                this.type = data.type;
            }
            if ("place_rank" in data && data.place_rank != undefined) {
                this.place_rank = data.place_rank;
            }
            if ("importance" in data && data.importance != undefined) {
                this.importance = data.importance;
            }
            if ("address_type" in data && data.address_type != undefined) {
                this.address_type = data.address_type;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("display_name" in data && data.display_name != undefined) {
                this.display_name = data.display_name;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("bounding_box" in data && data.bounding_box != undefined) {
                this.bounding_box = data.bounding_box;
            }
        }
    }
    get place_id() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set place_id(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get licence() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set licence(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get osm_type() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set osm_type(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get osm_id() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set osm_id(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get lat() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set lat(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get lon() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set lon(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get category() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set category(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get type() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set type(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get place_rank() {
        return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
    }
    set place_rank(value: number) {
        pb_1.Message.setField(this, 9, value);
    }
    get importance() {
        return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
    }
    set importance(value: number) {
        pb_1.Message.setField(this, 10, value);
    }
    get address_type() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set address_type(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 12, value);
    }
    get display_name() {
        return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
    }
    set display_name(value: string) {
        pb_1.Message.setField(this, 13, value);
    }
    get address() {
        return pb_1.Message.getWrapperField(this, NominatimAddress, 14) as NominatimAddress;
    }
    set address(value: NominatimAddress) {
        pb_1.Message.setWrapperField(this, 14, value);
    }
    get has_address() {
        return pb_1.Message.getField(this, 14) != null;
    }
    get bounding_box() {
        return pb_1.Message.getFieldWithDefault(this, 15, []) as string[];
    }
    set bounding_box(value: string[]) {
        pb_1.Message.setField(this, 15, value);
    }
    static fromObject(data: {
        place_id?: number;
        licence?: string;
        osm_type?: string;
        osm_id?: number;
        lat?: string;
        lon?: string;
        category?: string;
        type?: string;
        place_rank?: number;
        importance?: number;
        address_type?: string;
        name?: string;
        display_name?: string;
        address?: ReturnType<typeof NominatimAddress.prototype.toObject>;
        bounding_box?: string[];
    }): NominatimReverseGeocodeEntity {
        const message = new NominatimReverseGeocodeEntity({});
        if (data.place_id != null) {
            message.place_id = data.place_id;
        }
        if (data.licence != null) {
            message.licence = data.licence;
        }
        if (data.osm_type != null) {
            message.osm_type = data.osm_type;
        }
        if (data.osm_id != null) {
            message.osm_id = data.osm_id;
        }
        if (data.lat != null) {
            message.lat = data.lat;
        }
        if (data.lon != null) {
            message.lon = data.lon;
        }
        if (data.category != null) {
            message.category = data.category;
        }
        if (data.type != null) {
            message.type = data.type;
        }
        if (data.place_rank != null) {
            message.place_rank = data.place_rank;
        }
        if (data.importance != null) {
            message.importance = data.importance;
        }
        if (data.address_type != null) {
            message.address_type = data.address_type;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.display_name != null) {
            message.display_name = data.display_name;
        }
        if (data.address != null) {
            message.address = NominatimAddress.fromObject(data.address);
        }
        if (data.bounding_box != null) {
            message.bounding_box = data.bounding_box;
        }
        return message;
    }
    toObject() {
        const data: {
            place_id?: number;
            licence?: string;
            osm_type?: string;
            osm_id?: number;
            lat?: string;
            lon?: string;
            category?: string;
            type?: string;
            place_rank?: number;
            importance?: number;
            address_type?: string;
            name?: string;
            display_name?: string;
            address?: ReturnType<typeof NominatimAddress.prototype.toObject>;
            bounding_box?: string[];
        } = {};
        if (this.place_id != null) {
            data.place_id = this.place_id;
        }
        if (this.licence != null) {
            data.licence = this.licence;
        }
        if (this.osm_type != null) {
            data.osm_type = this.osm_type;
        }
        if (this.osm_id != null) {
            data.osm_id = this.osm_id;
        }
        if (this.lat != null) {
            data.lat = this.lat;
        }
        if (this.lon != null) {
            data.lon = this.lon;
        }
        if (this.category != null) {
            data.category = this.category;
        }
        if (this.type != null) {
            data.type = this.type;
        }
        if (this.place_rank != null) {
            data.place_rank = this.place_rank;
        }
        if (this.importance != null) {
            data.importance = this.importance;
        }
        if (this.address_type != null) {
            data.address_type = this.address_type;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.display_name != null) {
            data.display_name = this.display_name;
        }
        if (this.address != null) {
            data.address = this.address.toObject();
        }
        if (this.bounding_box != null) {
            data.bounding_box = this.bounding_box;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.place_id != 0)
            writer.writeUint64(1, this.place_id);
        if (this.licence.length)
            writer.writeString(2, this.licence);
        if (this.osm_type.length)
            writer.writeString(3, this.osm_type);
        if (this.osm_id != 0)
            writer.writeUint64(4, this.osm_id);
        if (this.lat.length)
            writer.writeString(5, this.lat);
        if (this.lon.length)
            writer.writeString(6, this.lon);
        if (this.category.length)
            writer.writeString(7, this.category);
        if (this.type.length)
            writer.writeString(8, this.type);
        if (this.place_rank != 0)
            writer.writeUint64(9, this.place_rank);
        if (this.importance != 0)
            writer.writeDouble(10, this.importance);
        if (this.address_type.length)
            writer.writeString(11, this.address_type);
        if (this.name.length)
            writer.writeString(12, this.name);
        if (this.display_name.length)
            writer.writeString(13, this.display_name);
        if (this.has_address)
            writer.writeMessage(14, this.address, () => this.address.serialize(writer));
        if (this.bounding_box.length)
            writer.writeRepeatedString(15, this.bounding_box);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NominatimReverseGeocodeEntity {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NominatimReverseGeocodeEntity();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.place_id = reader.readUint64();
                    break;
                case 2:
                    message.licence = reader.readString();
                    break;
                case 3:
                    message.osm_type = reader.readString();
                    break;
                case 4:
                    message.osm_id = reader.readUint64();
                    break;
                case 5:
                    message.lat = reader.readString();
                    break;
                case 6:
                    message.lon = reader.readString();
                    break;
                case 7:
                    message.category = reader.readString();
                    break;
                case 8:
                    message.type = reader.readString();
                    break;
                case 9:
                    message.place_rank = reader.readUint64();
                    break;
                case 10:
                    message.importance = reader.readDouble();
                    break;
                case 11:
                    message.address_type = reader.readString();
                    break;
                case 12:
                    message.name = reader.readString();
                    break;
                case 13:
                    message.display_name = reader.readString();
                    break;
                case 14:
                    reader.readMessage(message.address, () => message.address = NominatimAddress.deserialize(reader));
                    break;
                case 15:
                    pb_1.Message.addToRepeatedField(message, 15, reader.readString());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): NominatimReverseGeocodeEntity {
        return NominatimReverseGeocodeEntity.deserialize(bytes);
    }
}
export class ReverseGeocodeRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        lat?: number;
        lon?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("lat" in data && data.lat != undefined) {
                this.lat = data.lat;
            }
            if ("lon" in data && data.lon != undefined) {
                this.lon = data.lon;
            }
        }
    }
    get lat() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set lat(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get lon() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set lon(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        lat?: number;
        lon?: number;
    }): ReverseGeocodeRequest {
        const message = new ReverseGeocodeRequest({});
        if (data.lat != null) {
            message.lat = data.lat;
        }
        if (data.lon != null) {
            message.lon = data.lon;
        }
        return message;
    }
    toObject() {
        const data: {
            lat?: number;
            lon?: number;
        } = {};
        if (this.lat != null) {
            data.lat = this.lat;
        }
        if (this.lon != null) {
            data.lon = this.lon;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.lat != 0)
            writer.writeDouble(1, this.lat);
        if (this.lon != 0)
            writer.writeDouble(2, this.lon);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ReverseGeocodeRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ReverseGeocodeRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.lat = reader.readDouble();
                    break;
                case 2:
                    message.lon = reader.readDouble();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): ReverseGeocodeRequest {
        return ReverseGeocodeRequest.deserialize(bytes);
    }
}
export class ReverseGeocodeResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        zoom?: number;
        nominatim_entity?: NominatimReverseGeocodeEntity;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("zoom" in data && data.zoom != undefined) {
                this.zoom = data.zoom;
            }
            if ("nominatim_entity" in data && data.nominatim_entity != undefined) {
                this.nominatim_entity = data.nominatim_entity;
            }
        }
    }
    get zoom() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set zoom(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get nominatim_entity() {
        return pb_1.Message.getWrapperField(this, NominatimReverseGeocodeEntity, 2) as NominatimReverseGeocodeEntity;
    }
    set nominatim_entity(value: NominatimReverseGeocodeEntity) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_nominatim_entity() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        zoom?: number;
        nominatim_entity?: ReturnType<typeof NominatimReverseGeocodeEntity.prototype.toObject>;
    }): ReverseGeocodeResponse {
        const message = new ReverseGeocodeResponse({});
        if (data.zoom != null) {
            message.zoom = data.zoom;
        }
        if (data.nominatim_entity != null) {
            message.nominatim_entity = NominatimReverseGeocodeEntity.fromObject(data.nominatim_entity);
        }
        return message;
    }
    toObject() {
        const data: {
            zoom?: number;
            nominatim_entity?: ReturnType<typeof NominatimReverseGeocodeEntity.prototype.toObject>;
        } = {};
        if (this.zoom != null) {
            data.zoom = this.zoom;
        }
        if (this.nominatim_entity != null) {
            data.nominatim_entity = this.nominatim_entity.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.zoom != 0)
            writer.writeUint32(1, this.zoom);
        if (this.has_nominatim_entity)
            writer.writeMessage(2, this.nominatim_entity, () => this.nominatim_entity.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ReverseGeocodeResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ReverseGeocodeResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.zoom = reader.readUint32();
                    break;
                case 2:
                    reader.readMessage(message.nominatim_entity, () => message.nominatim_entity = NominatimReverseGeocodeEntity.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): ReverseGeocodeResponse {
        return ReverseGeocodeResponse.deserialize(bytes);
    }
}
export class LegacyHostApiRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        path?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("path" in data && data.path != undefined) {
                this.path = data.path;
            }
        }
    }
    get path() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set path(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        path?: string;
    }): LegacyHostApiRequest {
        const message = new LegacyHostApiRequest({});
        if (data.path != null) {
            message.path = data.path;
        }
        return message;
    }
    toObject() {
        const data: {
            path?: string;
        } = {};
        if (this.path != null) {
            data.path = this.path;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.path.length)
            writer.writeString(1, this.path);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LegacyHostApiRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LegacyHostApiRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.path = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): LegacyHostApiRequest {
        return LegacyHostApiRequest.deserialize(bytes);
    }
}
export class LegacyHostApiResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        path?: string;
        body?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("path" in data && data.path != undefined) {
                this.path = data.path;
            }
            if ("body" in data && data.body != undefined) {
                this.body = data.body;
            }
        }
    }
    get path() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set path(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get body() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set body(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        path?: string;
        body?: Uint8Array;
    }): LegacyHostApiResponse {
        const message = new LegacyHostApiResponse({});
        if (data.path != null) {
            message.path = data.path;
        }
        if (data.body != null) {
            message.body = data.body;
        }
        return message;
    }
    toObject() {
        const data: {
            path?: string;
            body?: Uint8Array;
        } = {};
        if (this.path != null) {
            data.path = this.path;
        }
        if (this.body != null) {
            data.body = this.body;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.path.length)
            writer.writeString(2, this.path);
        if (this.body.length)
            writer.writeBytes(1, this.body);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LegacyHostApiResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LegacyHostApiResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 2:
                    message.path = reader.readString();
                    break;
                case 1:
                    message.body = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): LegacyHostApiResponse {
        return LegacyHostApiResponse.deserialize(bytes);
    }
}
export class MTUTestResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        received_size?: number;
        data?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("received_size" in data && data.received_size != undefined) {
                this.received_size = data.received_size;
            }
            if ("data" in data && data.data != undefined) {
                this.data = data.data;
            }
        }
    }
    get received_size() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set received_size(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get data() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set data(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        received_size?: number;
        data?: Uint8Array;
    }): MTUTestResponse {
        const message = new MTUTestResponse({});
        if (data.received_size != null) {
            message.received_size = data.received_size;
        }
        if (data.data != null) {
            message.data = data.data;
        }
        return message;
    }
    toObject() {
        const data: {
            received_size?: number;
            data?: Uint8Array;
        } = {};
        if (this.received_size != null) {
            data.received_size = this.received_size;
        }
        if (this.data != null) {
            data.data = this.data;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.received_size != 0)
            writer.writeUint32(1, this.received_size);
        if (this.data.length)
            writer.writeBytes(2, this.data);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): MTUTestResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new MTUTestResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.received_size = reader.readUint32();
                    break;
                case 2:
                    message.data = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): MTUTestResponse {
        return MTUTestResponse.deserialize(bytes);
    }
}
export class BashCmd extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        cmd?: string;
        is_interactive?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("cmd" in data && data.cmd != undefined) {
                this.cmd = data.cmd;
            }
            if ("is_interactive" in data && data.is_interactive != undefined) {
                this.is_interactive = data.is_interactive;
            }
        }
    }
    get cmd() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set cmd(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get is_interactive() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set is_interactive(value: boolean) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        cmd?: string;
        is_interactive?: boolean;
    }): BashCmd {
        const message = new BashCmd({});
        if (data.cmd != null) {
            message.cmd = data.cmd;
        }
        if (data.is_interactive != null) {
            message.is_interactive = data.is_interactive;
        }
        return message;
    }
    toObject() {
        const data: {
            cmd?: string;
            is_interactive?: boolean;
        } = {};
        if (this.cmd != null) {
            data.cmd = this.cmd;
        }
        if (this.is_interactive != null) {
            data.is_interactive = this.is_interactive;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.cmd.length)
            writer.writeString(1, this.cmd);
        if (this.is_interactive != false)
            writer.writeBool(2, this.is_interactive);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BashCmd {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BashCmd();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.cmd = reader.readString();
                    break;
                case 2:
                    message.is_interactive = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): BashCmd {
        return BashCmd.deserialize(bytes);
    }
}
export class BashStdout extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        sequence?: number;
        stdout?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("sequence" in data && data.sequence != undefined) {
                this.sequence = data.sequence;
            }
            if ("stdout" in data && data.stdout != undefined) {
                this.stdout = data.stdout;
            }
        }
    }
    get sequence() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set sequence(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get stdout() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set stdout(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        sequence?: number;
        stdout?: string;
    }): BashStdout {
        const message = new BashStdout({});
        if (data.sequence != null) {
            message.sequence = data.sequence;
        }
        if (data.stdout != null) {
            message.stdout = data.stdout;
        }
        return message;
    }
    toObject() {
        const data: {
            sequence?: number;
            stdout?: string;
        } = {};
        if (this.sequence != null) {
            data.sequence = this.sequence;
        }
        if (this.stdout != null) {
            data.stdout = this.stdout;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.sequence != 0)
            writer.writeUint32(1, this.sequence);
        if (this.stdout.length)
            writer.writeString(2, this.stdout);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BashStdout {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BashStdout();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.sequence = reader.readUint32();
                    break;
                case 2:
                    message.stdout = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): BashStdout {
        return BashStdout.deserialize(bytes);
    }
}
export class WPAStatusResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        wpa_state?: string;
        id?: number;
        uuid?: string;
        bssid?: string;
        ssid?: string;
        freq?: number;
        mode?: string;
        pairwise_cipher?: string;
        group_cipher?: string;
        key_mgmt?: string;
        p2p_device_addr?: string;
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("wpa_state" in data && data.wpa_state != undefined) {
                this.wpa_state = data.wpa_state;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("bssid" in data && data.bssid != undefined) {
                this.bssid = data.bssid;
            }
            if ("ssid" in data && data.ssid != undefined) {
                this.ssid = data.ssid;
            }
            if ("freq" in data && data.freq != undefined) {
                this.freq = data.freq;
            }
            if ("mode" in data && data.mode != undefined) {
                this.mode = data.mode;
            }
            if ("pairwise_cipher" in data && data.pairwise_cipher != undefined) {
                this.pairwise_cipher = data.pairwise_cipher;
            }
            if ("group_cipher" in data && data.group_cipher != undefined) {
                this.group_cipher = data.group_cipher;
            }
            if ("key_mgmt" in data && data.key_mgmt != undefined) {
                this.key_mgmt = data.key_mgmt;
            }
            if ("p2p_device_addr" in data && data.p2p_device_addr != undefined) {
                this.p2p_device_addr = data.p2p_device_addr;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get wpa_state() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set wpa_state(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set id(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set uuid(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get bssid() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set bssid(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get ssid() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set ssid(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get freq() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set freq(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get mode() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set mode(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get pairwise_cipher() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set pairwise_cipher(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get group_cipher() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set group_cipher(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get key_mgmt() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set key_mgmt(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get p2p_device_addr() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set p2p_device_addr(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 12, value);
    }
    static fromObject(data: {
        wpa_state?: string;
        id?: number;
        uuid?: string;
        bssid?: string;
        ssid?: string;
        freq?: number;
        mode?: string;
        pairwise_cipher?: string;
        group_cipher?: string;
        key_mgmt?: string;
        p2p_device_addr?: string;
        address?: string;
    }): WPAStatusResponse {
        const message = new WPAStatusResponse({});
        if (data.wpa_state != null) {
            message.wpa_state = data.wpa_state;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.bssid != null) {
            message.bssid = data.bssid;
        }
        if (data.ssid != null) {
            message.ssid = data.ssid;
        }
        if (data.freq != null) {
            message.freq = data.freq;
        }
        if (data.mode != null) {
            message.mode = data.mode;
        }
        if (data.pairwise_cipher != null) {
            message.pairwise_cipher = data.pairwise_cipher;
        }
        if (data.group_cipher != null) {
            message.group_cipher = data.group_cipher;
        }
        if (data.key_mgmt != null) {
            message.key_mgmt = data.key_mgmt;
        }
        if (data.p2p_device_addr != null) {
            message.p2p_device_addr = data.p2p_device_addr;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: {
            wpa_state?: string;
            id?: number;
            uuid?: string;
            bssid?: string;
            ssid?: string;
            freq?: number;
            mode?: string;
            pairwise_cipher?: string;
            group_cipher?: string;
            key_mgmt?: string;
            p2p_device_addr?: string;
            address?: string;
        } = {};
        if (this.wpa_state != null) {
            data.wpa_state = this.wpa_state;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.bssid != null) {
            data.bssid = this.bssid;
        }
        if (this.ssid != null) {
            data.ssid = this.ssid;
        }
        if (this.freq != null) {
            data.freq = this.freq;
        }
        if (this.mode != null) {
            data.mode = this.mode;
        }
        if (this.pairwise_cipher != null) {
            data.pairwise_cipher = this.pairwise_cipher;
        }
        if (this.group_cipher != null) {
            data.group_cipher = this.group_cipher;
        }
        if (this.key_mgmt != null) {
            data.key_mgmt = this.key_mgmt;
        }
        if (this.p2p_device_addr != null) {
            data.p2p_device_addr = this.p2p_device_addr;
        }
        if (this.address != null) {
            data.address = this.address;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.wpa_state.length)
            writer.writeString(1, this.wpa_state);
        if (this.id != 0)
            writer.writeUint32(2, this.id);
        if (this.uuid.length)
            writer.writeString(3, this.uuid);
        if (this.bssid.length)
            writer.writeString(4, this.bssid);
        if (this.ssid.length)
            writer.writeString(5, this.ssid);
        if (this.freq != 0)
            writer.writeUint32(6, this.freq);
        if (this.mode.length)
            writer.writeString(7, this.mode);
        if (this.pairwise_cipher.length)
            writer.writeString(8, this.pairwise_cipher);
        if (this.group_cipher.length)
            writer.writeString(9, this.group_cipher);
        if (this.key_mgmt.length)
            writer.writeString(10, this.key_mgmt);
        if (this.p2p_device_addr.length)
            writer.writeString(11, this.p2p_device_addr);
        if (this.address.length)
            writer.writeString(12, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WPAStatusResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WPAStatusResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.wpa_state = reader.readString();
                    break;
                case 2:
                    message.id = reader.readUint32();
                    break;
                case 3:
                    message.uuid = reader.readString();
                    break;
                case 4:
                    message.bssid = reader.readString();
                    break;
                case 5:
                    message.ssid = reader.readString();
                    break;
                case 6:
                    message.freq = reader.readUint32();
                    break;
                case 7:
                    message.mode = reader.readString();
                    break;
                case 8:
                    message.pairwise_cipher = reader.readString();
                    break;
                case 9:
                    message.group_cipher = reader.readString();
                    break;
                case 10:
                    message.key_mgmt = reader.readString();
                    break;
                case 11:
                    message.p2p_device_addr = reader.readString();
                    break;
                case 12:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): WPAStatusResponse {
        return WPAStatusResponse.deserialize(bytes);
    }
}
export class WPAScanResult extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        bssid?: string;
        frequency?: number;
        signal_level?: number;
        flags?: string;
        ssid?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("bssid" in data && data.bssid != undefined) {
                this.bssid = data.bssid;
            }
            if ("frequency" in data && data.frequency != undefined) {
                this.frequency = data.frequency;
            }
            if ("signal_level" in data && data.signal_level != undefined) {
                this.signal_level = data.signal_level;
            }
            if ("flags" in data && data.flags != undefined) {
                this.flags = data.flags;
            }
            if ("ssid" in data && data.ssid != undefined) {
                this.ssid = data.ssid;
            }
        }
    }
    get bssid() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set bssid(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get frequency() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set frequency(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get signal_level() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set signal_level(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get flags() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set flags(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get ssid() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set ssid(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        bssid?: string;
        frequency?: number;
        signal_level?: number;
        flags?: string;
        ssid?: string;
    }): WPAScanResult {
        const message = new WPAScanResult({});
        if (data.bssid != null) {
            message.bssid = data.bssid;
        }
        if (data.frequency != null) {
            message.frequency = data.frequency;
        }
        if (data.signal_level != null) {
            message.signal_level = data.signal_level;
        }
        if (data.flags != null) {
            message.flags = data.flags;
        }
        if (data.ssid != null) {
            message.ssid = data.ssid;
        }
        return message;
    }
    toObject() {
        const data: {
            bssid?: string;
            frequency?: number;
            signal_level?: number;
            flags?: string;
            ssid?: string;
        } = {};
        if (this.bssid != null) {
            data.bssid = this.bssid;
        }
        if (this.frequency != null) {
            data.frequency = this.frequency;
        }
        if (this.signal_level != null) {
            data.signal_level = this.signal_level;
        }
        if (this.flags != null) {
            data.flags = this.flags;
        }
        if (this.ssid != null) {
            data.ssid = this.ssid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.bssid.length)
            writer.writeString(1, this.bssid);
        if (this.frequency != 0)
            writer.writeUint32(2, this.frequency);
        if (this.signal_level != 0)
            writer.writeInt32(3, this.signal_level);
        if (this.flags.length)
            writer.writeString(4, this.flags);
        if (this.ssid.length)
            writer.writeString(5, this.ssid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WPAScanResult {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WPAScanResult();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.bssid = reader.readString();
                    break;
                case 2:
                    message.frequency = reader.readUint32();
                    break;
                case 3:
                    message.signal_level = reader.readInt32();
                    break;
                case 4:
                    message.flags = reader.readString();
                    break;
                case 5:
                    message.ssid = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): WPAScanResult {
        return WPAScanResult.deserialize(bytes);
    }
}
export class WPAScanResultsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        scan_results?: WPAScanResult[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("scan_results" in data && data.scan_results != undefined) {
                this.scan_results = data.scan_results;
            }
        }
    }
    get scan_results() {
        return pb_1.Message.getRepeatedWrapperField(this, WPAScanResult, 1) as WPAScanResult[];
    }
    set scan_results(value: WPAScanResult[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        scan_results?: ReturnType<typeof WPAScanResult.prototype.toObject>[];
    }): WPAScanResultsResponse {
        const message = new WPAScanResultsResponse({});
        if (data.scan_results != null) {
            message.scan_results = data.scan_results.map(item => WPAScanResult.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            scan_results?: ReturnType<typeof WPAScanResult.prototype.toObject>[];
        } = {};
        if (this.scan_results != null) {
            data.scan_results = this.scan_results.map((item: WPAScanResult) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.scan_results.length)
            writer.writeRepeatedMessage(1, this.scan_results, (item: WPAScanResult) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WPAScanResultsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WPAScanResultsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.scan_results, () => pb_1.Message.addToRepeatedWrapperField(message, 1, WPAScanResult.deserialize(reader), WPAScanResult));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): WPAScanResultsResponse {
        return WPAScanResultsResponse.deserialize(bytes);
    }
}
export class WPANetworkConfig extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: number;
        ssid?: string;
        bssid?: string;
        psk?: string;
        priority?: number;
        key_mgmt?: string;
        flags?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("ssid" in data && data.ssid != undefined) {
                this.ssid = data.ssid;
            }
            if ("bssid" in data && data.bssid != undefined) {
                this.bssid = data.bssid;
            }
            if ("psk" in data && data.psk != undefined) {
                this.psk = data.psk;
            }
            if ("priority" in data && data.priority != undefined) {
                this.priority = data.priority;
            }
            if ("key_mgmt" in data && data.key_mgmt != undefined) {
                this.key_mgmt = data.key_mgmt;
            }
            if ("flags" in data && data.flags != undefined) {
                this.flags = data.flags;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set id(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get ssid() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set ssid(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get bssid() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set bssid(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get psk() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set psk(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get priority() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set priority(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get key_mgmt() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set key_mgmt(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get flags() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set flags(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    static fromObject(data: {
        id?: number;
        ssid?: string;
        bssid?: string;
        psk?: string;
        priority?: number;
        key_mgmt?: string;
        flags?: string;
    }): WPANetworkConfig {
        const message = new WPANetworkConfig({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.ssid != null) {
            message.ssid = data.ssid;
        }
        if (data.bssid != null) {
            message.bssid = data.bssid;
        }
        if (data.psk != null) {
            message.psk = data.psk;
        }
        if (data.priority != null) {
            message.priority = data.priority;
        }
        if (data.key_mgmt != null) {
            message.key_mgmt = data.key_mgmt;
        }
        if (data.flags != null) {
            message.flags = data.flags;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: number;
            ssid?: string;
            bssid?: string;
            psk?: string;
            priority?: number;
            key_mgmt?: string;
            flags?: string;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.ssid != null) {
            data.ssid = this.ssid;
        }
        if (this.bssid != null) {
            data.bssid = this.bssid;
        }
        if (this.psk != null) {
            data.psk = this.psk;
        }
        if (this.priority != null) {
            data.priority = this.priority;
        }
        if (this.key_mgmt != null) {
            data.key_mgmt = this.key_mgmt;
        }
        if (this.flags != null) {
            data.flags = this.flags;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id != 0)
            writer.writeUint32(1, this.id);
        if (this.ssid.length)
            writer.writeString(2, this.ssid);
        if (this.bssid.length)
            writer.writeString(3, this.bssid);
        if (this.psk.length)
            writer.writeString(4, this.psk);
        if (this.priority != 0)
            writer.writeInt32(5, this.priority);
        if (this.key_mgmt.length)
            writer.writeString(6, this.key_mgmt);
        if (this.flags.length)
            writer.writeString(7, this.flags);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WPANetworkConfig {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WPANetworkConfig();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readUint32();
                    break;
                case 2:
                    message.ssid = reader.readString();
                    break;
                case 3:
                    message.bssid = reader.readString();
                    break;
                case 4:
                    message.psk = reader.readString();
                    break;
                case 5:
                    message.priority = reader.readInt32();
                    break;
                case 6:
                    message.key_mgmt = reader.readString();
                    break;
                case 7:
                    message.flags = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): WPANetworkConfig {
        return WPANetworkConfig.deserialize(bytes);
    }
}
export class WPASetNetworksRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        networks?: WPANetworkConfig[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("networks" in data && data.networks != undefined) {
                this.networks = data.networks;
            }
        }
    }
    get networks() {
        return pb_1.Message.getRepeatedWrapperField(this, WPANetworkConfig, 1) as WPANetworkConfig[];
    }
    set networks(value: WPANetworkConfig[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        networks?: ReturnType<typeof WPANetworkConfig.prototype.toObject>[];
    }): WPASetNetworksRequest {
        const message = new WPASetNetworksRequest({});
        if (data.networks != null) {
            message.networks = data.networks.map(item => WPANetworkConfig.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            networks?: ReturnType<typeof WPANetworkConfig.prototype.toObject>[];
        } = {};
        if (this.networks != null) {
            data.networks = this.networks.map((item: WPANetworkConfig) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.networks.length)
            writer.writeRepeatedMessage(1, this.networks, (item: WPANetworkConfig) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WPASetNetworksRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WPASetNetworksRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.networks, () => pb_1.Message.addToRepeatedWrapperField(message, 1, WPANetworkConfig.deserialize(reader), WPANetworkConfig));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): WPASetNetworksRequest {
        return WPASetNetworksRequest.deserialize(bytes);
    }
}
export class WPAGetNetworksResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        networks?: WPANetworkConfig[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("networks" in data && data.networks != undefined) {
                this.networks = data.networks;
            }
        }
    }
    get networks() {
        return pb_1.Message.getRepeatedWrapperField(this, WPANetworkConfig, 1) as WPANetworkConfig[];
    }
    set networks(value: WPANetworkConfig[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        networks?: ReturnType<typeof WPANetworkConfig.prototype.toObject>[];
    }): WPAGetNetworksResponse {
        const message = new WPAGetNetworksResponse({});
        if (data.networks != null) {
            message.networks = data.networks.map(item => WPANetworkConfig.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            networks?: ReturnType<typeof WPANetworkConfig.prototype.toObject>[];
        } = {};
        if (this.networks != null) {
            data.networks = this.networks.map((item: WPANetworkConfig) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.networks.length)
            writer.writeRepeatedMessage(1, this.networks, (item: WPANetworkConfig) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WPAGetNetworksResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WPAGetNetworksResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.networks, () => pb_1.Message.addToRepeatedWrapperField(message, 1, WPANetworkConfig.deserialize(reader), WPANetworkConfig));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): WPAGetNetworksResponse {
        return WPAGetNetworksResponse.deserialize(bytes);
    }
}
export class NetworkPrimaryIPAddressInfo extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        if_name?: string;
        ip_address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("if_name" in data && data.if_name != undefined) {
                this.if_name = data.if_name;
            }
            if ("ip_address" in data && data.ip_address != undefined) {
                this.ip_address = data.ip_address;
            }
        }
    }
    get if_name() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set if_name(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get ip_address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set ip_address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        if_name?: string;
        ip_address?: string;
    }): NetworkPrimaryIPAddressInfo {
        const message = new NetworkPrimaryIPAddressInfo({});
        if (data.if_name != null) {
            message.if_name = data.if_name;
        }
        if (data.ip_address != null) {
            message.ip_address = data.ip_address;
        }
        return message;
    }
    toObject() {
        const data: {
            if_name?: string;
            ip_address?: string;
        } = {};
        if (this.if_name != null) {
            data.if_name = this.if_name;
        }
        if (this.ip_address != null) {
            data.ip_address = this.ip_address;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.if_name.length)
            writer.writeString(1, this.if_name);
        if (this.ip_address.length)
            writer.writeString(2, this.ip_address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NetworkPrimaryIPAddressInfo {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NetworkPrimaryIPAddressInfo();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.if_name = reader.readString();
                    break;
                case 2:
                    message.ip_address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): NetworkPrimaryIPAddressInfo {
        return NetworkPrimaryIPAddressInfo.deserialize(bytes);
    }
}
export class NetworkGetPrimaryIPAddressInfoResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        primary_ip_addresses?: NetworkPrimaryIPAddressInfo[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("primary_ip_addresses" in data && data.primary_ip_addresses != undefined) {
                this.primary_ip_addresses = data.primary_ip_addresses;
            }
        }
    }
    get primary_ip_addresses() {
        return pb_1.Message.getRepeatedWrapperField(this, NetworkPrimaryIPAddressInfo, 1) as NetworkPrimaryIPAddressInfo[];
    }
    set primary_ip_addresses(value: NetworkPrimaryIPAddressInfo[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        primary_ip_addresses?: ReturnType<typeof NetworkPrimaryIPAddressInfo.prototype.toObject>[];
    }): NetworkGetPrimaryIPAddressInfoResponse {
        const message = new NetworkGetPrimaryIPAddressInfoResponse({});
        if (data.primary_ip_addresses != null) {
            message.primary_ip_addresses = data.primary_ip_addresses.map(item => NetworkPrimaryIPAddressInfo.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            primary_ip_addresses?: ReturnType<typeof NetworkPrimaryIPAddressInfo.prototype.toObject>[];
        } = {};
        if (this.primary_ip_addresses != null) {
            data.primary_ip_addresses = this.primary_ip_addresses.map((item: NetworkPrimaryIPAddressInfo) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.primary_ip_addresses.length)
            writer.writeRepeatedMessage(1, this.primary_ip_addresses, (item: NetworkPrimaryIPAddressInfo) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NetworkGetPrimaryIPAddressInfoResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NetworkGetPrimaryIPAddressInfoResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.primary_ip_addresses, () => pb_1.Message.addToRepeatedWrapperField(message, 1, NetworkPrimaryIPAddressInfo.deserialize(reader), NetworkPrimaryIPAddressInfo));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): NetworkGetPrimaryIPAddressInfoResponse {
        return NetworkGetPrimaryIPAddressInfoResponse.deserialize(bytes);
    }
}
export class GNSSUbloxNMEASpaceVehicleInfo extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        sv?: number;
        elv?: number;
        az?: number;
        cno?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("sv" in data && data.sv != undefined) {
                this.sv = data.sv;
            }
            if ("elv" in data && data.elv != undefined) {
                this.elv = data.elv;
            }
            if ("az" in data && data.az != undefined) {
                this.az = data.az;
            }
            if ("cno" in data && data.cno != undefined) {
                this.cno = data.cno;
            }
        }
    }
    get sv() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set sv(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get elv() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set elv(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get az() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set az(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get cno() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set cno(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data: {
        sv?: number;
        elv?: number;
        az?: number;
        cno?: number;
    }): GNSSUbloxNMEASpaceVehicleInfo {
        const message = new GNSSUbloxNMEASpaceVehicleInfo({});
        if (data.sv != null) {
            message.sv = data.sv;
        }
        if (data.elv != null) {
            message.elv = data.elv;
        }
        if (data.az != null) {
            message.az = data.az;
        }
        if (data.cno != null) {
            message.cno = data.cno;
        }
        return message;
    }
    toObject() {
        const data: {
            sv?: number;
            elv?: number;
            az?: number;
            cno?: number;
        } = {};
        if (this.sv != null) {
            data.sv = this.sv;
        }
        if (this.elv != null) {
            data.elv = this.elv;
        }
        if (this.az != null) {
            data.az = this.az;
        }
        if (this.cno != null) {
            data.cno = this.cno;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.sv != 0)
            writer.writeInt32(1, this.sv);
        if (this.elv != 0)
            writer.writeInt32(2, this.elv);
        if (this.az != 0)
            writer.writeInt32(3, this.az);
        if (this.cno != 0)
            writer.writeInt32(4, this.cno);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GNSSUbloxNMEASpaceVehicleInfo {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GNSSUbloxNMEASpaceVehicleInfo();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.sv = reader.readInt32();
                    break;
                case 2:
                    message.elv = reader.readInt32();
                    break;
                case 3:
                    message.az = reader.readInt32();
                    break;
                case 4:
                    message.cno = reader.readInt32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): GNSSUbloxNMEASpaceVehicleInfo {
        return GNSSUbloxNMEASpaceVehicleInfo.deserialize(bytes);
    }
}
export class GNSSUbloxState extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        datetime?: string;
        lat?: number;
        lon?: number;
        alt?: number;
        hdop?: number;
        vdop?: number;
        pdop?: number;
        h_acc_m?: number;
        v_acc_m?: number;
        p_acc_m?: number;
        ttff?: number;
        msss?: number;
        quality?: number;
        nav_mode?: number;
        alp_age_sec?: number;
        alp_duration_sec?: number;
        rtcm_age?: number;
        rtcm_nb_msgs?: number;
        nb_sv_used?: number;
        nb_sv_visible?: number;
        svs?: GNSSUbloxNMEASpaceVehicleInfo[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [21], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("datetime" in data && data.datetime != undefined) {
                this.datetime = data.datetime;
            }
            if ("lat" in data && data.lat != undefined) {
                this.lat = data.lat;
            }
            if ("lon" in data && data.lon != undefined) {
                this.lon = data.lon;
            }
            if ("alt" in data && data.alt != undefined) {
                this.alt = data.alt;
            }
            if ("hdop" in data && data.hdop != undefined) {
                this.hdop = data.hdop;
            }
            if ("vdop" in data && data.vdop != undefined) {
                this.vdop = data.vdop;
            }
            if ("pdop" in data && data.pdop != undefined) {
                this.pdop = data.pdop;
            }
            if ("h_acc_m" in data && data.h_acc_m != undefined) {
                this.h_acc_m = data.h_acc_m;
            }
            if ("v_acc_m" in data && data.v_acc_m != undefined) {
                this.v_acc_m = data.v_acc_m;
            }
            if ("p_acc_m" in data && data.p_acc_m != undefined) {
                this.p_acc_m = data.p_acc_m;
            }
            if ("ttff" in data && data.ttff != undefined) {
                this.ttff = data.ttff;
            }
            if ("msss" in data && data.msss != undefined) {
                this.msss = data.msss;
            }
            if ("quality" in data && data.quality != undefined) {
                this.quality = data.quality;
            }
            if ("nav_mode" in data && data.nav_mode != undefined) {
                this.nav_mode = data.nav_mode;
            }
            if ("alp_age_sec" in data && data.alp_age_sec != undefined) {
                this.alp_age_sec = data.alp_age_sec;
            }
            if ("alp_duration_sec" in data && data.alp_duration_sec != undefined) {
                this.alp_duration_sec = data.alp_duration_sec;
            }
            if ("rtcm_age" in data && data.rtcm_age != undefined) {
                this.rtcm_age = data.rtcm_age;
            }
            if ("rtcm_nb_msgs" in data && data.rtcm_nb_msgs != undefined) {
                this.rtcm_nb_msgs = data.rtcm_nb_msgs;
            }
            if ("nb_sv_used" in data && data.nb_sv_used != undefined) {
                this.nb_sv_used = data.nb_sv_used;
            }
            if ("nb_sv_visible" in data && data.nb_sv_visible != undefined) {
                this.nb_sv_visible = data.nb_sv_visible;
            }
            if ("svs" in data && data.svs != undefined) {
                this.svs = data.svs;
            }
        }
    }
    get datetime() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set datetime(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get lat() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set lat(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get lon() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set lon(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get alt() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set alt(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get hdop() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set hdop(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get vdop() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set vdop(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get pdop() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set pdop(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    get h_acc_m() {
        return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
    }
    set h_acc_m(value: number) {
        pb_1.Message.setField(this, 8, value);
    }
    get v_acc_m() {
        return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
    }
    set v_acc_m(value: number) {
        pb_1.Message.setField(this, 9, value);
    }
    get p_acc_m() {
        return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
    }
    set p_acc_m(value: number) {
        pb_1.Message.setField(this, 10, value);
    }
    get ttff() {
        return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
    }
    set ttff(value: number) {
        pb_1.Message.setField(this, 11, value);
    }
    get msss() {
        return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
    }
    set msss(value: number) {
        pb_1.Message.setField(this, 12, value);
    }
    get quality() {
        return pb_1.Message.getFieldWithDefault(this, 13, 0) as number;
    }
    set quality(value: number) {
        pb_1.Message.setField(this, 13, value);
    }
    get nav_mode() {
        return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
    }
    set nav_mode(value: number) {
        pb_1.Message.setField(this, 14, value);
    }
    get alp_age_sec() {
        return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
    }
    set alp_age_sec(value: number) {
        pb_1.Message.setField(this, 15, value);
    }
    get alp_duration_sec() {
        return pb_1.Message.getFieldWithDefault(this, 16, 0) as number;
    }
    set alp_duration_sec(value: number) {
        pb_1.Message.setField(this, 16, value);
    }
    get rtcm_age() {
        return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
    }
    set rtcm_age(value: number) {
        pb_1.Message.setField(this, 17, value);
    }
    get rtcm_nb_msgs() {
        return pb_1.Message.getFieldWithDefault(this, 18, 0) as number;
    }
    set rtcm_nb_msgs(value: number) {
        pb_1.Message.setField(this, 18, value);
    }
    get nb_sv_used() {
        return pb_1.Message.getFieldWithDefault(this, 19, 0) as number;
    }
    set nb_sv_used(value: number) {
        pb_1.Message.setField(this, 19, value);
    }
    get nb_sv_visible() {
        return pb_1.Message.getFieldWithDefault(this, 20, 0) as number;
    }
    set nb_sv_visible(value: number) {
        pb_1.Message.setField(this, 20, value);
    }
    get svs() {
        return pb_1.Message.getRepeatedWrapperField(this, GNSSUbloxNMEASpaceVehicleInfo, 21) as GNSSUbloxNMEASpaceVehicleInfo[];
    }
    set svs(value: GNSSUbloxNMEASpaceVehicleInfo[]) {
        pb_1.Message.setRepeatedWrapperField(this, 21, value);
    }
    static fromObject(data: {
        datetime?: string;
        lat?: number;
        lon?: number;
        alt?: number;
        hdop?: number;
        vdop?: number;
        pdop?: number;
        h_acc_m?: number;
        v_acc_m?: number;
        p_acc_m?: number;
        ttff?: number;
        msss?: number;
        quality?: number;
        nav_mode?: number;
        alp_age_sec?: number;
        alp_duration_sec?: number;
        rtcm_age?: number;
        rtcm_nb_msgs?: number;
        nb_sv_used?: number;
        nb_sv_visible?: number;
        svs?: ReturnType<typeof GNSSUbloxNMEASpaceVehicleInfo.prototype.toObject>[];
    }): GNSSUbloxState {
        const message = new GNSSUbloxState({});
        if (data.datetime != null) {
            message.datetime = data.datetime;
        }
        if (data.lat != null) {
            message.lat = data.lat;
        }
        if (data.lon != null) {
            message.lon = data.lon;
        }
        if (data.alt != null) {
            message.alt = data.alt;
        }
        if (data.hdop != null) {
            message.hdop = data.hdop;
        }
        if (data.vdop != null) {
            message.vdop = data.vdop;
        }
        if (data.pdop != null) {
            message.pdop = data.pdop;
        }
        if (data.h_acc_m != null) {
            message.h_acc_m = data.h_acc_m;
        }
        if (data.v_acc_m != null) {
            message.v_acc_m = data.v_acc_m;
        }
        if (data.p_acc_m != null) {
            message.p_acc_m = data.p_acc_m;
        }
        if (data.ttff != null) {
            message.ttff = data.ttff;
        }
        if (data.msss != null) {
            message.msss = data.msss;
        }
        if (data.quality != null) {
            message.quality = data.quality;
        }
        if (data.nav_mode != null) {
            message.nav_mode = data.nav_mode;
        }
        if (data.alp_age_sec != null) {
            message.alp_age_sec = data.alp_age_sec;
        }
        if (data.alp_duration_sec != null) {
            message.alp_duration_sec = data.alp_duration_sec;
        }
        if (data.rtcm_age != null) {
            message.rtcm_age = data.rtcm_age;
        }
        if (data.rtcm_nb_msgs != null) {
            message.rtcm_nb_msgs = data.rtcm_nb_msgs;
        }
        if (data.nb_sv_used != null) {
            message.nb_sv_used = data.nb_sv_used;
        }
        if (data.nb_sv_visible != null) {
            message.nb_sv_visible = data.nb_sv_visible;
        }
        if (data.svs != null) {
            message.svs = data.svs.map(item => GNSSUbloxNMEASpaceVehicleInfo.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            datetime?: string;
            lat?: number;
            lon?: number;
            alt?: number;
            hdop?: number;
            vdop?: number;
            pdop?: number;
            h_acc_m?: number;
            v_acc_m?: number;
            p_acc_m?: number;
            ttff?: number;
            msss?: number;
            quality?: number;
            nav_mode?: number;
            alp_age_sec?: number;
            alp_duration_sec?: number;
            rtcm_age?: number;
            rtcm_nb_msgs?: number;
            nb_sv_used?: number;
            nb_sv_visible?: number;
            svs?: ReturnType<typeof GNSSUbloxNMEASpaceVehicleInfo.prototype.toObject>[];
        } = {};
        if (this.datetime != null) {
            data.datetime = this.datetime;
        }
        if (this.lat != null) {
            data.lat = this.lat;
        }
        if (this.lon != null) {
            data.lon = this.lon;
        }
        if (this.alt != null) {
            data.alt = this.alt;
        }
        if (this.hdop != null) {
            data.hdop = this.hdop;
        }
        if (this.vdop != null) {
            data.vdop = this.vdop;
        }
        if (this.pdop != null) {
            data.pdop = this.pdop;
        }
        if (this.h_acc_m != null) {
            data.h_acc_m = this.h_acc_m;
        }
        if (this.v_acc_m != null) {
            data.v_acc_m = this.v_acc_m;
        }
        if (this.p_acc_m != null) {
            data.p_acc_m = this.p_acc_m;
        }
        if (this.ttff != null) {
            data.ttff = this.ttff;
        }
        if (this.msss != null) {
            data.msss = this.msss;
        }
        if (this.quality != null) {
            data.quality = this.quality;
        }
        if (this.nav_mode != null) {
            data.nav_mode = this.nav_mode;
        }
        if (this.alp_age_sec != null) {
            data.alp_age_sec = this.alp_age_sec;
        }
        if (this.alp_duration_sec != null) {
            data.alp_duration_sec = this.alp_duration_sec;
        }
        if (this.rtcm_age != null) {
            data.rtcm_age = this.rtcm_age;
        }
        if (this.rtcm_nb_msgs != null) {
            data.rtcm_nb_msgs = this.rtcm_nb_msgs;
        }
        if (this.nb_sv_used != null) {
            data.nb_sv_used = this.nb_sv_used;
        }
        if (this.nb_sv_visible != null) {
            data.nb_sv_visible = this.nb_sv_visible;
        }
        if (this.svs != null) {
            data.svs = this.svs.map((item: GNSSUbloxNMEASpaceVehicleInfo) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.datetime.length)
            writer.writeString(1, this.datetime);
        if (this.lat != 0)
            writer.writeDouble(2, this.lat);
        if (this.lon != 0)
            writer.writeDouble(3, this.lon);
        if (this.alt != 0)
            writer.writeFloat(4, this.alt);
        if (this.hdop != 0)
            writer.writeFloat(5, this.hdop);
        if (this.vdop != 0)
            writer.writeFloat(6, this.vdop);
        if (this.pdop != 0)
            writer.writeFloat(7, this.pdop);
        if (this.h_acc_m != 0)
            writer.writeFloat(8, this.h_acc_m);
        if (this.v_acc_m != 0)
            writer.writeFloat(9, this.v_acc_m);
        if (this.p_acc_m != 0)
            writer.writeFloat(10, this.p_acc_m);
        if (this.ttff != 0)
            writer.writeUint32(11, this.ttff);
        if (this.msss != 0)
            writer.writeUint32(12, this.msss);
        if (this.quality != 0)
            writer.writeUint32(13, this.quality);
        if (this.nav_mode != 0)
            writer.writeUint32(14, this.nav_mode);
        if (this.alp_age_sec != 0)
            writer.writeSint32(15, this.alp_age_sec);
        if (this.alp_duration_sec != 0)
            writer.writeUint32(16, this.alp_duration_sec);
        if (this.rtcm_age != 0)
            writer.writeFloat(17, this.rtcm_age);
        if (this.rtcm_nb_msgs != 0)
            writer.writeUint32(18, this.rtcm_nb_msgs);
        if (this.nb_sv_used != 0)
            writer.writeUint32(19, this.nb_sv_used);
        if (this.nb_sv_visible != 0)
            writer.writeUint32(20, this.nb_sv_visible);
        if (this.svs.length)
            writer.writeRepeatedMessage(21, this.svs, (item: GNSSUbloxNMEASpaceVehicleInfo) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GNSSUbloxState {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GNSSUbloxState();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.datetime = reader.readString();
                    break;
                case 2:
                    message.lat = reader.readDouble();
                    break;
                case 3:
                    message.lon = reader.readDouble();
                    break;
                case 4:
                    message.alt = reader.readFloat();
                    break;
                case 5:
                    message.hdop = reader.readFloat();
                    break;
                case 6:
                    message.vdop = reader.readFloat();
                    break;
                case 7:
                    message.pdop = reader.readFloat();
                    break;
                case 8:
                    message.h_acc_m = reader.readFloat();
                    break;
                case 9:
                    message.v_acc_m = reader.readFloat();
                    break;
                case 10:
                    message.p_acc_m = reader.readFloat();
                    break;
                case 11:
                    message.ttff = reader.readUint32();
                    break;
                case 12:
                    message.msss = reader.readUint32();
                    break;
                case 13:
                    message.quality = reader.readUint32();
                    break;
                case 14:
                    message.nav_mode = reader.readUint32();
                    break;
                case 15:
                    message.alp_age_sec = reader.readSint32();
                    break;
                case 16:
                    message.alp_duration_sec = reader.readUint32();
                    break;
                case 17:
                    message.rtcm_age = reader.readFloat();
                    break;
                case 18:
                    message.rtcm_nb_msgs = reader.readUint32();
                    break;
                case 19:
                    message.nb_sv_used = reader.readUint32();
                    break;
                case 20:
                    message.nb_sv_visible = reader.readUint32();
                    break;
                case 21:
                    reader.readMessage(message.svs, () => pb_1.Message.addToRepeatedWrapperField(message, 21, GNSSUbloxNMEASpaceVehicleInfo.deserialize(reader), GNSSUbloxNMEASpaceVehicleInfo));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): GNSSUbloxState {
        return GNSSUbloxState.deserialize(bytes);
    }
}
export class GNSSUbloxSetALPFileRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        key?: string;
        data?: Uint8Array;
        offset?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("key" in data && data.key != undefined) {
                this.key = data.key;
            }
            if ("data" in data && data.data != undefined) {
                this.data = data.data;
            }
            if ("offset" in data && data.offset != undefined) {
                this.offset = data.offset;
            }
        }
    }
    get key() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set key(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get data() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set data(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get offset() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set offset(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        key?: string;
        data?: Uint8Array;
        offset?: number;
    }): GNSSUbloxSetALPFileRequest {
        const message = new GNSSUbloxSetALPFileRequest({});
        if (data.key != null) {
            message.key = data.key;
        }
        if (data.data != null) {
            message.data = data.data;
        }
        if (data.offset != null) {
            message.offset = data.offset;
        }
        return message;
    }
    toObject() {
        const data: {
            key?: string;
            data?: Uint8Array;
            offset?: number;
        } = {};
        if (this.key != null) {
            data.key = this.key;
        }
        if (this.data != null) {
            data.data = this.data;
        }
        if (this.offset != null) {
            data.offset = this.offset;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.key.length)
            writer.writeString(1, this.key);
        if (this.data.length)
            writer.writeBytes(2, this.data);
        if (this.offset != 0)
            writer.writeUint32(3, this.offset);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GNSSUbloxSetALPFileRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GNSSUbloxSetALPFileRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.key = reader.readString();
                    break;
                case 2:
                    message.data = reader.readBytes();
                    break;
                case 3:
                    message.offset = reader.readUint32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): GNSSUbloxSetALPFileRequest {
        return GNSSUbloxSetALPFileRequest.deserialize(bytes);
    }
}
export class GNSSUbloxGetALPFilesResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        keys?: string[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("keys" in data && data.keys != undefined) {
                this.keys = data.keys;
            }
        }
    }
    get keys() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
    }
    set keys(value: string[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        keys?: string[];
    }): GNSSUbloxGetALPFilesResponse {
        const message = new GNSSUbloxGetALPFilesResponse({});
        if (data.keys != null) {
            message.keys = data.keys;
        }
        return message;
    }
    toObject() {
        const data: {
            keys?: string[];
        } = {};
        if (this.keys != null) {
            data.keys = this.keys;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.keys.length)
            writer.writeRepeatedString(1, this.keys);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GNSSUbloxGetALPFilesResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GNSSUbloxGetALPFilesResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): GNSSUbloxGetALPFilesResponse {
        return GNSSUbloxGetALPFilesResponse.deserialize(bytes);
    }
}
export class GNSSUbloxWriteRTCMRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        data?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("data" in data && data.data != undefined) {
                this.data = data.data;
            }
        }
    }
    get data() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set data(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        data?: Uint8Array;
    }): GNSSUbloxWriteRTCMRequest {
        const message = new GNSSUbloxWriteRTCMRequest({});
        if (data.data != null) {
            message.data = data.data;
        }
        return message;
    }
    toObject() {
        const data: {
            data?: Uint8Array;
        } = {};
        if (this.data != null) {
            data.data = this.data;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.data.length)
            writer.writeBytes(1, this.data);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GNSSUbloxWriteRTCMRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GNSSUbloxWriteRTCMRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.data = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): GNSSUbloxWriteRTCMRequest {
        return GNSSUbloxWriteRTCMRequest.deserialize(bytes);
    }
}
export class GNSSUbloxResetRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        reset_type?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("reset_type" in data && data.reset_type != undefined) {
                this.reset_type = data.reset_type;
            }
        }
    }
    get reset_type() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set reset_type(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        reset_type?: number;
    }): GNSSUbloxResetRequest {
        const message = new GNSSUbloxResetRequest({});
        if (data.reset_type != null) {
            message.reset_type = data.reset_type;
        }
        return message;
    }
    toObject() {
        const data: {
            reset_type?: number;
        } = {};
        if (this.reset_type != null) {
            data.reset_type = this.reset_type;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.reset_type != 0)
            writer.writeUint32(1, this.reset_type);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GNSSUbloxResetRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GNSSUbloxResetRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.reset_type = reader.readUint32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): GNSSUbloxResetRequest {
        return GNSSUbloxResetRequest.deserialize(bytes);
    }
}
export class DeviceTelemetry extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        client_timestamp?: number;
        station_state?: StationState;
        station_sensors?: StationSensors;
        station_config?: StationConfig;
        hic_state?: HICState;
        hic_config?: HICConfig;
        network_primary_ip_addresses?: NetworkGetPrimaryIPAddressInfoResponse;
        server_timestamp?: number;
        user_uuid?: UUID;
        device_uuid?: UUID;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("client_timestamp" in data && data.client_timestamp != undefined) {
                this.client_timestamp = data.client_timestamp;
            }
            if ("station_state" in data && data.station_state != undefined) {
                this.station_state = data.station_state;
            }
            if ("station_sensors" in data && data.station_sensors != undefined) {
                this.station_sensors = data.station_sensors;
            }
            if ("station_config" in data && data.station_config != undefined) {
                this.station_config = data.station_config;
            }
            if ("hic_state" in data && data.hic_state != undefined) {
                this.hic_state = data.hic_state;
            }
            if ("hic_config" in data && data.hic_config != undefined) {
                this.hic_config = data.hic_config;
            }
            if ("network_primary_ip_addresses" in data && data.network_primary_ip_addresses != undefined) {
                this.network_primary_ip_addresses = data.network_primary_ip_addresses;
            }
            if ("server_timestamp" in data && data.server_timestamp != undefined) {
                this.server_timestamp = data.server_timestamp;
            }
            if ("user_uuid" in data && data.user_uuid != undefined) {
                this.user_uuid = data.user_uuid;
            }
            if ("device_uuid" in data && data.device_uuid != undefined) {
                this.device_uuid = data.device_uuid;
            }
        }
    }
    get client_timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set client_timestamp(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get station_state() {
        return pb_1.Message.getWrapperField(this, StationState, 2) as StationState;
    }
    set station_state(value: StationState) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_station_state() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get station_sensors() {
        return pb_1.Message.getWrapperField(this, StationSensors, 3) as StationSensors;
    }
    set station_sensors(value: StationSensors) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get has_station_sensors() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get station_config() {
        return pb_1.Message.getWrapperField(this, StationConfig, 4) as StationConfig;
    }
    set station_config(value: StationConfig) {
        pb_1.Message.setWrapperField(this, 4, value);
    }
    get has_station_config() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get hic_state() {
        return pb_1.Message.getWrapperField(this, HICState, 5) as HICState;
    }
    set hic_state(value: HICState) {
        pb_1.Message.setWrapperField(this, 5, value);
    }
    get has_hic_state() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get hic_config() {
        return pb_1.Message.getWrapperField(this, HICConfig, 6) as HICConfig;
    }
    set hic_config(value: HICConfig) {
        pb_1.Message.setWrapperField(this, 6, value);
    }
    get has_hic_config() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get network_primary_ip_addresses() {
        return pb_1.Message.getWrapperField(this, NetworkGetPrimaryIPAddressInfoResponse, 7) as NetworkGetPrimaryIPAddressInfoResponse;
    }
    set network_primary_ip_addresses(value: NetworkGetPrimaryIPAddressInfoResponse) {
        pb_1.Message.setWrapperField(this, 7, value);
    }
    get has_network_primary_ip_addresses() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get server_timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
    }
    set server_timestamp(value: number) {
        pb_1.Message.setField(this, 11, value);
    }
    get user_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 12) as UUID;
    }
    set user_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 12, value);
    }
    get has_user_uuid() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get device_uuid() {
        return pb_1.Message.getWrapperField(this, UUID, 13) as UUID;
    }
    set device_uuid(value: UUID) {
        pb_1.Message.setWrapperField(this, 13, value);
    }
    get has_device_uuid() {
        return pb_1.Message.getField(this, 13) != null;
    }
    static fromObject(data: {
        client_timestamp?: number;
        station_state?: ReturnType<typeof StationState.prototype.toObject>;
        station_sensors?: ReturnType<typeof StationSensors.prototype.toObject>;
        station_config?: ReturnType<typeof StationConfig.prototype.toObject>;
        hic_state?: ReturnType<typeof HICState.prototype.toObject>;
        hic_config?: ReturnType<typeof HICConfig.prototype.toObject>;
        network_primary_ip_addresses?: ReturnType<typeof NetworkGetPrimaryIPAddressInfoResponse.prototype.toObject>;
        server_timestamp?: number;
        user_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        device_uuid?: ReturnType<typeof UUID.prototype.toObject>;
    }): DeviceTelemetry {
        const message = new DeviceTelemetry({});
        if (data.client_timestamp != null) {
            message.client_timestamp = data.client_timestamp;
        }
        if (data.station_state != null) {
            message.station_state = StationState.fromObject(data.station_state);
        }
        if (data.station_sensors != null) {
            message.station_sensors = StationSensors.fromObject(data.station_sensors);
        }
        if (data.station_config != null) {
            message.station_config = StationConfig.fromObject(data.station_config);
        }
        if (data.hic_state != null) {
            message.hic_state = HICState.fromObject(data.hic_state);
        }
        if (data.hic_config != null) {
            message.hic_config = HICConfig.fromObject(data.hic_config);
        }
        if (data.network_primary_ip_addresses != null) {
            message.network_primary_ip_addresses = NetworkGetPrimaryIPAddressInfoResponse.fromObject(data.network_primary_ip_addresses);
        }
        if (data.server_timestamp != null) {
            message.server_timestamp = data.server_timestamp;
        }
        if (data.user_uuid != null) {
            message.user_uuid = UUID.fromObject(data.user_uuid);
        }
        if (data.device_uuid != null) {
            message.device_uuid = UUID.fromObject(data.device_uuid);
        }
        return message;
    }
    toObject() {
        const data: {
            client_timestamp?: number;
            station_state?: ReturnType<typeof StationState.prototype.toObject>;
            station_sensors?: ReturnType<typeof StationSensors.prototype.toObject>;
            station_config?: ReturnType<typeof StationConfig.prototype.toObject>;
            hic_state?: ReturnType<typeof HICState.prototype.toObject>;
            hic_config?: ReturnType<typeof HICConfig.prototype.toObject>;
            network_primary_ip_addresses?: ReturnType<typeof NetworkGetPrimaryIPAddressInfoResponse.prototype.toObject>;
            server_timestamp?: number;
            user_uuid?: ReturnType<typeof UUID.prototype.toObject>;
            device_uuid?: ReturnType<typeof UUID.prototype.toObject>;
        } = {};
        if (this.client_timestamp != null) {
            data.client_timestamp = this.client_timestamp;
        }
        if (this.station_state != null) {
            data.station_state = this.station_state.toObject();
        }
        if (this.station_sensors != null) {
            data.station_sensors = this.station_sensors.toObject();
        }
        if (this.station_config != null) {
            data.station_config = this.station_config.toObject();
        }
        if (this.hic_state != null) {
            data.hic_state = this.hic_state.toObject();
        }
        if (this.hic_config != null) {
            data.hic_config = this.hic_config.toObject();
        }
        if (this.network_primary_ip_addresses != null) {
            data.network_primary_ip_addresses = this.network_primary_ip_addresses.toObject();
        }
        if (this.server_timestamp != null) {
            data.server_timestamp = this.server_timestamp;
        }
        if (this.user_uuid != null) {
            data.user_uuid = this.user_uuid.toObject();
        }
        if (this.device_uuid != null) {
            data.device_uuid = this.device_uuid.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.client_timestamp != 0)
            writer.writeUint64(1, this.client_timestamp);
        if (this.has_station_state)
            writer.writeMessage(2, this.station_state, () => this.station_state.serialize(writer));
        if (this.has_station_sensors)
            writer.writeMessage(3, this.station_sensors, () => this.station_sensors.serialize(writer));
        if (this.has_station_config)
            writer.writeMessage(4, this.station_config, () => this.station_config.serialize(writer));
        if (this.has_hic_state)
            writer.writeMessage(5, this.hic_state, () => this.hic_state.serialize(writer));
        if (this.has_hic_config)
            writer.writeMessage(6, this.hic_config, () => this.hic_config.serialize(writer));
        if (this.has_network_primary_ip_addresses)
            writer.writeMessage(7, this.network_primary_ip_addresses, () => this.network_primary_ip_addresses.serialize(writer));
        if (this.server_timestamp != 0)
            writer.writeUint64(11, this.server_timestamp);
        if (this.has_user_uuid)
            writer.writeMessage(12, this.user_uuid, () => this.user_uuid.serialize(writer));
        if (this.has_device_uuid)
            writer.writeMessage(13, this.device_uuid, () => this.device_uuid.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeviceTelemetry {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeviceTelemetry();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.client_timestamp = reader.readUint64();
                    break;
                case 2:
                    reader.readMessage(message.station_state, () => message.station_state = StationState.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.station_sensors, () => message.station_sensors = StationSensors.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.station_config, () => message.station_config = StationConfig.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.hic_state, () => message.hic_state = HICState.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.hic_config, () => message.hic_config = HICConfig.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.network_primary_ip_addresses, () => message.network_primary_ip_addresses = NetworkGetPrimaryIPAddressInfoResponse.deserialize(reader));
                    break;
                case 11:
                    message.server_timestamp = reader.readUint64();
                    break;
                case 12:
                    reader.readMessage(message.user_uuid, () => message.user_uuid = UUID.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.device_uuid, () => message.device_uuid = UUID.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): DeviceTelemetry {
        return DeviceTelemetry.deserialize(bytes);
    }
}
export class StorageSliceResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        sites?: Site[];
        equipments?: Equipment[];
        zones?: Zone[];
        annexes?: Annex[];
        impacts?: Impact[];
        impacts_with_references?: ImpactWithReferences[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1, 2, 3, 5, 4, 6], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("sites" in data && data.sites != undefined) {
                this.sites = data.sites;
            }
            if ("equipments" in data && data.equipments != undefined) {
                this.equipments = data.equipments;
            }
            if ("zones" in data && data.zones != undefined) {
                this.zones = data.zones;
            }
            if ("annexes" in data && data.annexes != undefined) {
                this.annexes = data.annexes;
            }
            if ("impacts" in data && data.impacts != undefined) {
                this.impacts = data.impacts;
            }
            if ("impacts_with_references" in data && data.impacts_with_references != undefined) {
                this.impacts_with_references = data.impacts_with_references;
            }
        }
    }
    get sites() {
        return pb_1.Message.getRepeatedWrapperField(this, Site, 1) as Site[];
    }
    set sites(value: Site[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get equipments() {
        return pb_1.Message.getRepeatedWrapperField(this, Equipment, 2) as Equipment[];
    }
    set equipments(value: Equipment[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    get zones() {
        return pb_1.Message.getRepeatedWrapperField(this, Zone, 3) as Zone[];
    }
    set zones(value: Zone[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    get annexes() {
        return pb_1.Message.getRepeatedWrapperField(this, Annex, 5) as Annex[];
    }
    set annexes(value: Annex[]) {
        pb_1.Message.setRepeatedWrapperField(this, 5, value);
    }
    get impacts() {
        return pb_1.Message.getRepeatedWrapperField(this, Impact, 4) as Impact[];
    }
    set impacts(value: Impact[]) {
        pb_1.Message.setRepeatedWrapperField(this, 4, value);
    }
    get impacts_with_references() {
        return pb_1.Message.getRepeatedWrapperField(this, ImpactWithReferences, 6) as ImpactWithReferences[];
    }
    set impacts_with_references(value: ImpactWithReferences[]) {
        pb_1.Message.setRepeatedWrapperField(this, 6, value);
    }
    static fromObject(data: {
        sites?: ReturnType<typeof Site.prototype.toObject>[];
        equipments?: ReturnType<typeof Equipment.prototype.toObject>[];
        zones?: ReturnType<typeof Zone.prototype.toObject>[];
        annexes?: ReturnType<typeof Annex.prototype.toObject>[];
        impacts?: ReturnType<typeof Impact.prototype.toObject>[];
        impacts_with_references?: ReturnType<typeof ImpactWithReferences.prototype.toObject>[];
    }): StorageSliceResponse {
        const message = new StorageSliceResponse({});
        if (data.sites != null) {
            message.sites = data.sites.map(item => Site.fromObject(item));
        }
        if (data.equipments != null) {
            message.equipments = data.equipments.map(item => Equipment.fromObject(item));
        }
        if (data.zones != null) {
            message.zones = data.zones.map(item => Zone.fromObject(item));
        }
        if (data.annexes != null) {
            message.annexes = data.annexes.map(item => Annex.fromObject(item));
        }
        if (data.impacts != null) {
            message.impacts = data.impacts.map(item => Impact.fromObject(item));
        }
        if (data.impacts_with_references != null) {
            message.impacts_with_references = data.impacts_with_references.map(item => ImpactWithReferences.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            sites?: ReturnType<typeof Site.prototype.toObject>[];
            equipments?: ReturnType<typeof Equipment.prototype.toObject>[];
            zones?: ReturnType<typeof Zone.prototype.toObject>[];
            annexes?: ReturnType<typeof Annex.prototype.toObject>[];
            impacts?: ReturnType<typeof Impact.prototype.toObject>[];
            impacts_with_references?: ReturnType<typeof ImpactWithReferences.prototype.toObject>[];
        } = {};
        if (this.sites != null) {
            data.sites = this.sites.map((item: Site) => item.toObject());
        }
        if (this.equipments != null) {
            data.equipments = this.equipments.map((item: Equipment) => item.toObject());
        }
        if (this.zones != null) {
            data.zones = this.zones.map((item: Zone) => item.toObject());
        }
        if (this.annexes != null) {
            data.annexes = this.annexes.map((item: Annex) => item.toObject());
        }
        if (this.impacts != null) {
            data.impacts = this.impacts.map((item: Impact) => item.toObject());
        }
        if (this.impacts_with_references != null) {
            data.impacts_with_references = this.impacts_with_references.map((item: ImpactWithReferences) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.sites.length)
            writer.writeRepeatedMessage(1, this.sites, (item: Site) => item.serialize(writer));
        if (this.equipments.length)
            writer.writeRepeatedMessage(2, this.equipments, (item: Equipment) => item.serialize(writer));
        if (this.zones.length)
            writer.writeRepeatedMessage(3, this.zones, (item: Zone) => item.serialize(writer));
        if (this.annexes.length)
            writer.writeRepeatedMessage(5, this.annexes, (item: Annex) => item.serialize(writer));
        if (this.impacts.length)
            writer.writeRepeatedMessage(4, this.impacts, (item: Impact) => item.serialize(writer));
        if (this.impacts_with_references.length)
            writer.writeRepeatedMessage(6, this.impacts_with_references, (item: ImpactWithReferences) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StorageSliceResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StorageSliceResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.sites, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Site.deserialize(reader), Site));
                    break;
                case 2:
                    reader.readMessage(message.equipments, () => pb_1.Message.addToRepeatedWrapperField(message, 2, Equipment.deserialize(reader), Equipment));
                    break;
                case 3:
                    reader.readMessage(message.zones, () => pb_1.Message.addToRepeatedWrapperField(message, 3, Zone.deserialize(reader), Zone));
                    break;
                case 5:
                    reader.readMessage(message.annexes, () => pb_1.Message.addToRepeatedWrapperField(message, 5, Annex.deserialize(reader), Annex));
                    break;
                case 4:
                    reader.readMessage(message.impacts, () => pb_1.Message.addToRepeatedWrapperField(message, 4, Impact.deserialize(reader), Impact));
                    break;
                case 6:
                    reader.readMessage(message.impacts_with_references, () => pb_1.Message.addToRepeatedWrapperField(message, 6, ImpactWithReferences.deserialize(reader), ImpactWithReferences));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): StorageSliceResponse {
        return StorageSliceResponse.deserialize(bytes);
    }
}
