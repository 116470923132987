import { MethodTypeEN1177 } from "../generated/proto-ts/main"
import { ICloudTranslation } from "../types"
import { gzip, ungzip } from "pako"
// USER
export const loadLocalStorageUser = (): string | null => {
  let user = localStorage.getItem("normenjeu-user")
  if (user === null) {
    user = localStorage.getItem("x-user")
    if (user !== null) {
      localStorage.setItem("normenjeu-user", user)
    }
  }
  localStorage.removeItem("x-user")
  return user
}

export const saveLocalStorageUser = (user: string | null) => {
  if (user === null) {
    localStorage.removeItem("normenjeu-user")
    return
  }
  localStorage.setItem("normenjeu-user", user)
}

// PREFERENCES
export const loadLocalStorageDefaultSiteMethodType = (): MethodTypeEN1177 => {
  let persistentDefaultmethodStr = localStorage.getItem("normenjeu-preferences-default-method")
  if (persistentDefaultmethodStr) {
    let persistentDefaultmethodInt = parseInt(persistentDefaultmethodStr)
    if (!isNaN(persistentDefaultmethodInt)) {
      return persistentDefaultmethodInt as MethodTypeEN1177
    }
  }
  return MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
}
export const saveLocalStorageDefaultSiteMethodType = (defaultMethod: MethodTypeEN1177) => {
  localStorage.setItem("normenjeu-preferences-default-method", defaultMethod.toString())
}

// REPORT
// LEFT LOGO
export const loadLocalStorageReportLeftLogoDataURL = (): string | null => {
  return localStorage.getItem("normenjeu-report-left-logo-data-url")
}

export const saveLocalStorageReportLeftLogoDataURL = (dataURL: string | null) => {
  if (dataURL === null) {
    localStorage.removeItem("normenjeu-report-left-logo-data-url")
    return
  }
  localStorage.setItem("normenjeu-report-left-logo-data-url", dataURL)
}

// LEFT LOGO
export const loadLocalStorageReportRightLogoDataURL = (): string | null => {
  return localStorage.getItem("normenjeu-report-right-logo-data-url")
}

export const saveLocalStorageReportRightLogoDataURL = (dataURL: string | null) => {
  if (dataURL === null) {
    localStorage.removeItem("normenjeu-report-right-logo-data-url")
    return
  }
  localStorage.setItem("normenjeu-report-right-logo-data-url", dataURL)
}

// LEFT FOOTER TEXT
export const loadLocalStorageReportLeftFooterText = (): string | null => {
  return localStorage.getItem("normenjeu-report-left-footer-text")
}

export const saveLocalStorageReportLeftFooterText = (text: string | null) => {
  if (text === null) {
    localStorage.removeItem("normenjeu-report-left-footer-text")
    return
  }
  localStorage.setItem("normenjeu-report-left-footer-text", text)
}

// RIGHT FOOTER TEXT
export const loadLocalStorageReportRightFooterText = (): string | null => {
  return localStorage.getItem("normenjeu-report-right-footer-text")
}

export const saveLocalStorageReportRightFooterText = (text: string | null) => {
  if (text === null) {
    localStorage.removeItem("normenjeu-report-right-footer-text")
    return
  }
  localStorage.setItem("normenjeu-report-right-footer-text", text)
}

export const loadLocalStorageLeftSignatureDataURL = (): string | null => {
  return localStorage.getItem("normenjeu-report-left-signature-data-url")
}

export const saveLocalStorageLeftSignatureDataURL = (dataURL: string | null) => {
  if (dataURL === null) {
    localStorage.removeItem("normenjeu-report-left-signature-data-url")
    return
  }
  localStorage.setItem("normenjeu-report-left-signature-data-url", dataURL)
}

export const loadLocalStorageRightSignatureDataURL = (): string | null => {
  return localStorage.getItem("normenjeu-report-right-signature-data-url")
}

export const saveLocalStorageRightSignatureDataURL = (dataURL: string | null) => {
  if (dataURL === null) {
    localStorage.removeItem("normenjeu-report-right-signature-data-url")
    return
  }
  localStorage.setItem("normenjeu-report-right-signature-data-url", dataURL)
}

export const loadLocalStorageLeftSignatureName = (): string | null => {
  return localStorage.getItem("normenjeu-report-left-signature-name")
}

export const saveLocalStorageLeftSignatureName = (name: string | null) => {
  if (name === null) {
    localStorage.removeItem("normenjeu-report-left-signature-name")
    return
  }
  localStorage.setItem("normenjeu-report-left-signature-name", name)
}

// Translations
// LanguageCode
export const loadLocalStorageLanguageCode = (): string => {
  let languageCode = localStorage.getItem("normenjeu-language-code")
  if (languageCode === null) {
    languageCode = "en"
  }
  return languageCode
}

export const saveLocalStorageLanguageCode = (languageCode: string | null) => {
  if (languageCode === null) {
    localStorage.removeItem("normenjeu-language-code")
    return
  }
  localStorage.setItem("normenjeu-language-code", languageCode)
}

const uint8ToHexString = (uint8Array: Uint8Array) => {
  return Array.from(uint8Array, (byte) => byte.toString(16).padStart(2, "0")).join("")
}

const hexStringToUint8 = (hexString: string) => {
  return new Uint8Array(hexString.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)))
}

export const loadLocalStorageTranslations = (
  languageCode: string,
): Record<string, ICloudTranslation> | null => {
  let encodedStr = localStorage.getItem(`normenjeu-translations-map-${languageCode}`)
  if (encodedStr === null) {
    return null
  }
  let ungzippedUint8Array = ungzip(hexStringToUint8(encodedStr), { to: "string" })
  return JSON.parse(ungzippedUint8Array)
}

export const saveLocalStorageTranslations = (
  languageCode: string,
  translationMap: Record<string, ICloudTranslation>,
) => {
  for (let keyEn in translationMap) {
    translationMap[keyEn]!.UUID = null
  }
  let gzippedBuffer = gzip(JSON.stringify(translationMap))
  localStorage.setItem(
    `normenjeu-translations-map-${languageCode}`,
    uint8ToHexString(gzippedBuffer),
  )
}
